import { StateCreator } from "zustand";
import { Delegations, StakingSlice } from "store/interfaces/staking.interface";
import { INJECTIVE_BURN_WALLET } from "constants/constants";

export const stakingSlice: StateCreator<StakingSlice, [], [], StakingSlice> = (
  set
) => ({
  stakingWallet: undefined,
  setStakingWallet: (wallet: string) =>
    set((state) => ({
      ...state,
      stakingWallet: wallet,
    })),
  isStakingLoading: false,
  setIsStakingLoading: (isLoading: boolean) =>
    set(() => ({
      isStakingLoading: isLoading,
    })),
  delegations: undefined,
  setDelegations: (delegate: Delegations, wallet: string) => {
    const stakingWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET;
    set((s) => ({
      ...s,
      delegations: {
        ...s.delegations,
        [stakingWallet]: {
          ...s.delegations?.[stakingWallet],
          ...delegate,
        },
      },
    }));
  },
});
