import { NETWORK } from "libs/services"
import { Network } from "@injectivelabs/networks"
import {
  DOJO,
  KAGE,
  POINT,
  ROLL,
  serializeToken,
  FarmToken,
  TokenType,
  DAOJO,
} from "./tokens"
export interface PoolConfig {
  name: string
  lpAddress: string
  poolToken: FarmToken
  earningToken: FarmToken
  stakingAddress: string
  hidden?: boolean
}

export interface PublicPoolData extends PoolConfig {
  vault: {
    config: {
      distribution_schedule: [number, number, string][]
      dojo_token: string
      staking_token: string
    }
    stakedBalance: string
    walletBalance: string
    staker: {
      bond_amount: string
      pending_reward: string
      reward_index: string
      staker: string
    }
    state: {
      global_reward_index: string
      last_distributed: number
      total_bond_amount: string
    }
    lpInfo: {
      decimals: number
      name: string
      symbol: string
      total_supply: string
    }
    total_rewards: string
  }
}

const allPoolTokens = {
  //TODO: Replace with correct mainnet address
  mainnet: {
    POINT,
    DOJO,
    KAGE,
  },
  testnet: {
    POINT: serializeToken(
      "POINT",
      "POINT Token",
      "inj16ncdyyhwl7gz6q6vvz447fyn70myc22vw45zvt",
      18,
      TokenType.CW20,
    ),
  },
}

export const POOLTOKENS =
  allPoolTokens[NETWORK === Network.Mainnet ? "mainnet" : "testnet"]

export const pools: { mainnet: PoolConfig[]; testnet: PoolConfig[] } = {
  mainnet: [
    {
      name: "DOJO",
      lpAddress: DOJO.address,
      poolToken: DOJO,
      earningToken: DOJO,
      stakingAddress: "inj1p0vntlcq7t3uksx56dny7wn334vrtpuwcj884z",
    },
    {
      name: "DAOJO (Old)",
      lpAddress: DAOJO.address,
      poolToken: DAOJO,
      earningToken: DAOJO,
      stakingAddress: "inj15xuk8u6j9r7p3avpyqm980cc6w9pxq4uenl8rc",
    },
    {
      name: "DAOJO earn DAOJO",
      lpAddress: DAOJO.address,
      poolToken: DAOJO,
      earningToken: DAOJO,
      stakingAddress: "inj1fzgfsl4x7usplwythz9y4ajzs5sd8khzdjlhn4",
    },
    {
      name: "DOJO earn KAGE",
      lpAddress: DOJO.address,
      poolToken: DOJO,
      earningToken: KAGE,
      stakingAddress: "inj18z6q85cccm7t8gdv8zkqn2u5ml8s6cmd36e7c4",
    },
    {
      name: "KAGE earn KAGE",
      lpAddress: KAGE.address,
      poolToken: KAGE,
      earningToken: KAGE,
      stakingAddress: "inj19lwej7yvqjk90ju4ztr79vz8gjdg2zy2gc73rm",
    },
    {
      name: "POINT",
      lpAddress: POINT.address,
      poolToken: POINT,
      earningToken: DOJO,
      stakingAddress: "inj1eu0xap9qlv0z346a2eruzccakhk6mkp4qpj6ev",
    },
    {
      name: "ROLL earn ROLL",
      lpAddress: ROLL.address,
      poolToken: ROLL,
      earningToken: ROLL,
      stakingAddress: "inj1fuf8u3d8fk2p34anz3f72tct6q8sr5hvxxv4x4",
    },
  ],
  testnet: [
    {
      name: "POINT",
      lpAddress: POOLTOKENS.POINT.address,
      poolToken: POOLTOKENS.POINT,
      earningToken: POOLTOKENS.POINT,
      stakingAddress: "inj1v38hvwppv0zaxq93dx94xfacwekymae3800k2m",
    },
  ],
}

export const getPoolByTokenAddress = (address: string) => {
  const _pools = NETWORK === Network.Mainnet ? pools.mainnet : pools.testnet
  return _pools.find((item) => item.lpAddress === address)
}
