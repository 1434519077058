import { useEffect } from "react";
import BN from "bignumber.js";
import useEphemeralStore from "../store/ephemeralStore";

import { stakingApi } from "libs/services";

import useWallet from "./useWallet";
import { DELEGATORS } from "constants/delegators";

export const useStaking = () => {
  const cachedStakingWallet = useEphemeralStore((s) => s.stakingWallet);
  const setStakingWallet = useEphemeralStore((s) => s.setStakingWallet);
  const setDelegations = useEphemeralStore((s) => s.setDelegations);
  const setIsStakingLoading = useEphemeralStore((s) => s.setIsStakingLoading);
  const { wallet } = useWallet();

  const fetchStaking = async () => {
    if (wallet.address !== "") {
      setIsStakingLoading(true);
      console.log("--Staking Call--", wallet.address);
      let STAKING_MAPPING = {};

      const stakingResult = await stakingApi.fetchDelegations({
        injectiveAddress: wallet.address,
      });

      const unstakingResult = await stakingApi.fetchUnbondingDelegations({
        injectiveAddress: wallet.address,
      });

      stakingResult.delegations.forEach((delegation) => {
        const foundValidator = DELEGATORS.find(
          (item) =>
            item.operator_address === delegation.delegation.validatorAddress
        );
        const delegationBalance = new BN(delegation.balance.amount).div(1e18);
        STAKING_MAPPING[delegation.delegation.validatorAddress] = {
          balance: delegationBalance,
          earningBalance: 0,
          name: foundValidator
            ? foundValidator.moniker
            : delegation.delegation.validatorAddress,
          image: foundValidator?.imageURL
            ? foundValidator?.imageURL
            : `https://hub.injective.network/vendor/@injectivelabs/sdk-ui-ts/${foundValidator?.operator_address}.webp`,
          address: delegation.delegation.validatorAddress,
          commission: foundValidator ? foundValidator.commission_rate : 0,
        };
      });

      setDelegations(STAKING_MAPPING, wallet?.address);
      setStakingWallet(wallet.address);
      setIsStakingLoading(false);
    }
  };

  useEffect(() => {
    const run = async () => {
      await fetchStaking();
    };
    if (wallet.address !== "" && cachedStakingWallet !== wallet.address) {
      console.log("refreshing all staking due to wallet change");
      run();
    }
  }, [wallet.address]);

  return { fetchStaking };
};
