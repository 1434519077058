import classNames from "classnames";

const Staking = ({ className }: { className?: string }) => {
  return (
    <div className={classNames(className)}>
      <svg
        fill="currentColor"
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M21,9H13V7a3,3,0,0,1,3-3,1,1,0,0,0,0-2,5,5,0,0,0-4.64,3.163A5.42,5.42,0,0,0,4,4s1.793,4.46,7.06,2.411A4.777,4.777,0,0,0,11,7V9H3a1,1,0,0,0-1,1V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V10A1,1,0,0,0,21,9ZM4,20V17a3,3,0,0,1,3,3Zm0-6V11H7A3,3,0,0,1,4,14Zm8,3a2,2,0,1,1,2-2A2,2,0,0,1,12,17Zm8,3H17a3,3,0,0,1,3-3Zm0-6a3,3,0,0,1-3-3h3Z" />
      </svg>
    </div>
  );
};

export default Staking;
