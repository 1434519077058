import { useEffect } from "react";
import BN, { BigNumber } from "bignumber.js";
import { Network } from "@injectivelabs/networks";
import useEphemeralStore from "../store/ephemeralStore";
import useNetwork from "hooks/useNetwork";
import useURL from "hooks/useURL";
import { NETWORK, bankApi } from "libs/services";
import { PublicFarmData, farmsV2 as FARMS, FarmConfig } from "constants/farms";
import { getFormattedValue } from "libs/getFormattedValue";
import { ChainGrpcBankApi, toBase64 } from "@injectivelabs/sdk-ts";
import { contracts } from "../types/StakingPool";
import { INJECTIVE_BURN_WALLET } from "constants/constants";
import { BABYDOJO, DOJO, FarmToken, TokenType } from "constants/tokens";
import { multicallFetcher } from "utils/multicallFetcher";
import _ from "lodash";
import dayjs from "dayjs";
import { FarmData } from "store/interfaces/farms.interface";
import useWallet from "./useWallet";
import useDashboardAPI from "rest/useDashboardAPI";

// Balances will be cached for below duration,
// and not fetched whilst cached.

const BIG_TEN = new BN(10);
const { StakingPool } = contracts;

// Re-fetching of the full balance data is selective, based on how stale each farm is
// TODO: Fetching of balance is specific, only make required calls
export const useBalances = () => {
  const { api } = useDashboardAPI();

  const cachedBalancesBySymbol = useEphemeralStore((s) => s.balancesBySymbol);
  const cachedBalanceWallet = useEphemeralStore((s) => s.balanceWallet);
  const setBalanceWallet = useEphemeralStore((s) => s.setBalanceWallet);
  const setBalancesBySymbol = useEphemeralStore((s) => s.setBalancesBySymbol);
  const setIsBalanceLoading = useEphemeralStore((s) => s.setIsBalanceLoading);
  const setPricesByTokenAddress = useEphemeralStore(
    (s) => s.setPricesByTokenAddress
  );

  const { multicall } = useNetwork();
  const { wallet } = useWallet();
  const getURL = useURL();

  const key = NETWORK === Network.Testnet ? "testnet" : "mainnet";

  // ------------------
  // EXECUTION
  // ------------------

  // Fetches only balances that NEED to be fetched every FETCH_BALANCES_FREQUENCY_SECS.
  // Conditions are:
  // 1. Balance not hidden
  // 2. Farm is stale for more than CACHED_FARM_DURATION_SECS
  // 3. Wallet address has changed (if wallet is disconnected, dont refetch)
  // TODO: Stagger loading such that only maximum of 5-10 farms are refetched per FETCH_FARMS_FREQUENCY_SECS
  const fetchBalances = async () => {
    if (wallet.address !== "") {
      setIsBalanceLoading(true);
      console.log("--Balance Call--", wallet.address);
      const PRICE_BY_SYMBOL_MAPPING = {};
      const tokenPrices = await api.prices.supportList();
      Object.entries(tokenPrices).map(
        (item) => (PRICE_BY_SYMBOL_MAPPING[item[0]] = item[1]["price"])
      );
      setPricesByTokenAddress(PRICE_BY_SYMBOL_MAPPING);

      let TOKEN_BALANCE_MAPPING = {};
      const cw20Queries = [];

      const tokenResp = await fetch("./json/tokens-dictionary.json");
      const tokenData = await tokenResp.json();
      const ALL_BALANCE_TOKENS: FarmToken[] = Object.values(tokenData);

      const cw20Tokens = ALL_BALANCE_TOKENS.filter(
        (token) => token.type.toLowerCase() === "cw20"
      );
      const nonCw20Tokens = ALL_BALANCE_TOKENS.filter(
        (token) => token.type.toLowerCase() !== "cw20"
      );

      cw20Tokens.map((token) => {
        cw20Queries.push({
          address: token.address,
          data: toBase64({ balance: { address: wallet.address } }),
        });
      });
      const fetcher = multicallFetcher(multicall, getURL);
      const resp = await fetcher([cw20Queries]);
      const collectResults = (resp ?? []).reduce(
        (acc, curr) => acc.concat(curr.data.data.return_data),
        []
      );
      const cw20Results = collectResults?.map((e: any) => {
        return e.length == 0
          ? null
          : JSON.parse(Buffer.from(e.data, "base64").toString());
      });
      cw20Tokens.map((token, index) => {
        TOKEN_BALANCE_MAPPING[token.symbol] = {
          ...token,
          balance: new BN(cw20Results?.[index]?.["balance"]).div(
            new BN(10).pow(token.decimals)
          ),
        };
      });

      // for (let index = 0; index < nonCw20Tokens.length; index++) {
      //   const token = nonCw20Tokens[index];
      //   const result = await bankApi.fetchBalance({
      //     accountAddress: wallet.address,
      //     denom: token.address,
      //   });
      //   TOKEN_BALANCE_MAPPING[token.symbol] = result?.amount;
      // }

      const result = await bankApi.fetchBalances(wallet.address);

      nonCw20Tokens.forEach((token) => {
        const tokenBalance =
          result.balances.find(
            (i) => i?.denom?.toLowerCase() === token?.address?.toLowerCase()
          )?.amount ?? "0";
        TOKEN_BALANCE_MAPPING[token.symbol] = {
          ...token,
          balance: new BN(tokenBalance).div(new BN(10).pow(token.decimals)),
        };
      });

      setBalancesBySymbol(TOKEN_BALANCE_MAPPING, wallet?.address);
      setBalanceWallet(wallet.address);
      setIsBalanceLoading(false);
    }
  };

  useEffect(() => {
    const run = async () => {
      await fetchBalances();
    };
    if (wallet.address !== "" && cachedBalanceWallet !== wallet.address) {
      console.log("refreshing all balances due to wallet change");
      run();
    }
  }, [wallet.address]);

  return { fetchBalances };
};
