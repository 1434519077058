import { create, StoreApi, UseBoundStore } from "zustand";
import { EphemeralStore } from "./interfaces/ephemeralStore.interface";
import { txStateSlice } from "./slices/txStateSlice";
import { farmsSlice } from "./slices/farmsSlice";
import { poolsSlice } from "./slices/poolsSlice";
import { vaultsSlice } from "./slices/vaultsSlice";
import { pricesSlice } from "./slices/pricesSlice";
import { balancesSlice } from "./slices/balancesSlice";
import { stakingSlice } from "./slices/stakingSlice";
import { jsonSlice } from "./slices/jsonSlice";
import { dexScreenerSlice } from "./slices/dexScreenerSlice";
import { presalesSlice } from "./slices/presalesSlice";
import { moneyMarketSlice } from "./slices/moneyMarketSlice";

let useEphemeralStore: UseBoundStore<StoreApi<EphemeralStore>>;

useEphemeralStore = create<EphemeralStore>()((...a) => ({
  ...txStateSlice(...a),
  ...farmsSlice(...a),
  ...poolsSlice(...a),
  ...vaultsSlice(...a),
  ...pricesSlice(...a),
  ...balancesSlice(...a),
  ...stakingSlice(...a),
  ...jsonSlice(...a),
  ...dexScreenerSlice(...a),
  ...presalesSlice(...a),
  ...moneyMarketSlice(...a),
}));

export default useEphemeralStore;
