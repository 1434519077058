export const DELEGATORS = [
  {
    id: "",
    moniker: "Binance Staking",
    operator_address: "injvaloper1lsuqpgm8kgwpq96ewyew26xnfwyn3lh3ncrkrk",
    consensus_address: "injvalcons1893nm5rlsl3pdcx26q0qhcskughcn2el3w335z",
    jailed: false,
    status: 3,
    tokens: "2993919583735153140497359",
    delegator_shares: "3009235017745806317198444.108047822285568062",
    description: {
      moniker: "Binance Staking",
      identity: "",
      website: "https://www.binance.com/en/earn",
      security_contact: "poolvip@binance.com",
      details: "",
      imageURL: "",
    },
    unbonding_height: 54201239,
    unbonding_time: "2024-01-03 04:59:39.185 +0000 UTC",
    commission_rate: "0.070000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 130807,
    signed: 1265500,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 40001,
        blockTimestamp: "2021-07-01 19:23:21.078 +0000 UTC",
        power: 1225,
        reason: "missing_signature",
        jailed: "injvalcons1893nm5rlsl3pdcx26q0qhcskughcn2el3w335z",
      },
    ],
    uptime_percentage: 88.62740922159514,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Huobi",
    operator_address: "injvaloper1d4ndu7jeeln3qaxuyakazkm7v4kep5qrtdz9k7",
    consensus_address: "injvalcons1vs5fcc4unph9t8w6u576wcweg8ep7kkdhzgg3q",
    jailed: true,
    status: 1,
    tokens: "613153633180027208206",
    delegator_shares: "615365536725416714841.400809547594261475",
    description: {
      moniker: "Huobi",
      identity: "",
      website: "https://www.huobi.com",
      security_contact: "huobipool@huobi.com",
      details:
        "As a world-leading company in the blockchain industry, Huobi Group was founded in 2013 with a mission to make breakthroughs in core blockchain technology and the integration of blockchain technology to other industries.",
      imageURL: "",
    },
    unbonding_height: 10887973,
    unbonding_time: "2022-06-09 22:15:30.182 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 522075,
        blockTimestamp: "2021-07-17 19:19:07.106 +0000 UTC",
        power: 2374,
        reason: "missing_signature",
        jailed: "injvalcons1vs5fcc4unph9t8w6u576wcweg8ep7kkdhzgg3q",
      },
      {
        blockNumber: 2065754,
        blockTimestamp: "2021-09-02 06:40:33.938 +0000 UTC",
        power: 2733,
        reason: "missing_signature",
        jailed: "injvalcons1vs5fcc4unph9t8w6u576wcweg8ep7kkdhzgg3q",
      },
      {
        blockNumber: 2247953,
        blockTimestamp: "2021-09-07 19:12:22.369 +0000 UTC",
        power: 2591,
        reason: "missing_signature",
        jailed: "injvalcons1vs5fcc4unph9t8w6u576wcweg8ep7kkdhzgg3q",
      },
      {
        blockNumber: 2280519,
        blockTimestamp: "2021-09-08 18:26:11.794 +0000 UTC",
        power: 2591,
        reason: "missing_signature",
        jailed: "injvalcons1vs5fcc4unph9t8w6u576wcweg8ep7kkdhzgg3q",
      },
      {
        blockNumber: 2313368,
        blockTimestamp: "2021-09-09 18:01:23.118 +0000 UTC",
        power: 2591,
        reason: "missing_signature",
        jailed: "injvalcons1vs5fcc4unph9t8w6u576wcweg8ep7kkdhzgg3q",
      },
      {
        blockNumber: 7685437,
        blockTimestamp: "2022-02-12 17:48:38.576 +0000 UTC",
        power: 505328,
        reason: "missing_signature",
        jailed: "injvalcons1vs5fcc4unph9t8w6u576wcweg8ep7kkdhzgg3q",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "METAops",
    operator_address: "injvaloper1tsc68z3rlqqsp5fhl8hhmf302m7twq7xxw6w6g",
    consensus_address: "injvalcons1xdmc5l8vwf9zspdud8xr7x2jm4msmvfjfkxnpk",
    jailed: true,
    status: 1,
    tokens: "999900000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "METAops",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 882131,
    unbonding_time: "2021-08-18 13:33:38.948 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2021-07-26 14:59:23.525 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 882131,
        blockTimestamp: "2021-07-28 13:33:38.948 +0000 UTC",
        power: 1,
        reason: "missing_signature",
        jailed: "injvalcons1xdmc5l8vwf9zspdud8xr7x2jm4msmvfjfkxnpk",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Helios Staking",
    operator_address: "injvaloper1ffsdugrhfzdyxltjve8v68n6aazyc6p97uhfn0",
    consensus_address: "injvalcons1jdeeykx9vf5s8wjygy5vld6rn23342flka3rgj",
    jailed: false,
    status: 3,
    tokens: "1160567126234330426395257",
    delegator_shares: "1162193661438141169233028.113489894140156403",
    description: {
      moniker: "Helios Staking",
      identity: "",
      website: "https://heliosstaking.com",
      security_contact: "kevin@heliosstatking.com",
      details:
        "Helios Staking is a Staking-as-a-Service provider strictly dedicated to the growth of Proof of Stake (PoS) blockchain networks.",
      imageURL: "",
    },
    unbonding_height: 11257733,
    unbonding_time: "2022-06-20 09:37:20.977 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 43640,
    signed: 1636182,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 990225,
        blockTimestamp: "2021-07-31 17:54:47.203 +0000 UTC",
        power: 460437,
        reason: "missing_signature",
        jailed: "injvalcons1jdeeykx9vf5s8wjygy5vld6rn23342flka3rgj",
      },
      {
        blockNumber: 2425791,
        blockTimestamp: "2021-09-13 04:14:47.504 +0000 UTC",
        power: 815782,
        reason: "missing_signature",
        jailed: "injvalcons1jdeeykx9vf5s8wjygy5vld6rn23342flka3rgj",
      },
      {
        blockNumber: 7686628,
        blockTimestamp: "2022-02-12 18:46:45.297 +0000 UTC",
        power: 734726,
        reason: "missing_signature",
        jailed: "injvalcons1jdeeykx9vf5s8wjygy5vld6rn23342flka3rgj",
      },
      {
        blockNumber: 11257733,
        blockTimestamp: "2022-05-30 09:37:20.977 +0000 UTC",
        power: 1201245,
        reason: "missing_signature",
        jailed: "injvalcons1jdeeykx9vf5s8wjygy5vld6rn23342flka3rgj",
      },
    ],
    uptime_percentage: 94.02786314279861,
    imageURL: "",
  },
  {
    id: "",
    moniker: "InfStones",
    operator_address: "injvaloper1xnaddzjzml9uw88cruyljm93v8quexnsawtr4j",
    consensus_address: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
    jailed: true,
    status: 1,
    tokens: "1318913749909269127739",
    delegator_shares: "1321554893760105792583.671649627842947903",
    description: {
      moniker: "InfStones",
      identity: "E9B7CDC7623E0327",
      website: "https://infstones.com",
      security_contact: "contact@infstones.com",
      details: "Fueling Blockchain Beyond Infinity!",
      imageURL: "",
    },
    unbonding_height: 18637705,
    unbonding_time: "2022-11-28 22:01:52.537 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2021-12-07 22:33:38.62 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 1733451,
        blockTimestamp: "2021-08-23 06:10:20.328 +0000 UTC",
        power: 14754,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 4371998,
        blockTimestamp: "2021-11-09 07:44:14.282 +0000 UTC",
        power: 7875,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 4621238,
        blockTimestamp: "2021-11-16 09:25:43.007 +0000 UTC",
        power: 9197,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 6021131,
        blockTimestamp: "2021-12-26 14:32:36.106 +0000 UTC",
        power: 10153,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 7087570,
        blockTimestamp: "2022-01-26 05:01:52.311 +0000 UTC",
        power: 10167,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 12582729,
        blockTimestamp: "2022-07-06 07:58:15.205 +0000 UTC",
        power: 185223,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 14466268,
        blockTimestamp: "2022-08-25 22:03:42.753 +0000 UTC",
        power: 184991,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 14750989,
        blockTimestamp: "2022-09-02 02:49:02.466 +0000 UTC",
        power: 184925,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 15891994,
        blockTimestamp: "2022-09-27 05:52:51.763 +0000 UTC",
        power: 184742,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
      {
        blockNumber: 17485589,
        blockTimestamp: "2022-10-24 22:28:42.685 +0000 UTC",
        power: 10040,
        reason: "missing_signature",
        jailed: "injvalcons1l24zl70q0jw3jefgx5ajg5cqnk3scnxqh8fhwt",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/fd6d26fb4f284a20280f9bb44cfd6305_360_360.jpg",
  },
  {
    id: "",
    moniker: "Orman.xyz",
    operator_address: "injvaloper14ncezrfu3hxauehjntf6pdpme7l5etqsep2qp6",
    consensus_address: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
    jailed: false,
    status: 3,
    tokens: "367606993699060643427110",
    delegator_shares: "371191812969206829324698.867254208274430257",
    description: {
      moniker: "Orman.xyz",
      identity: "",
      website: "https://orman.xyz",
      security_contact: "staking@orman.xyz",
      details:
        "Validators hosted by nerds - highly available architecture with redundant failover nodes across multiple datacenters. Featuring a transparent, state-of-the-art monitoring system, publicly available 24/7 at orman.xyz/monitor",
      imageURL: "",
    },
    unbonding_height: 58420350,
    unbonding_time: "2024-02-14 23:56:14.105 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-04-10 20:43:43.29 +0000 UTC",
    proposed: 13836,
    signed: 1694764,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 2065754,
        blockTimestamp: "2021-09-02 06:40:33.938 +0000 UTC",
        power: 24932,
        reason: "missing_signature",
        jailed: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
      },
      {
        blockNumber: 7686116,
        blockTimestamp: "2022-02-12 18:22:20.419 +0000 UTC",
        power: 214040,
        reason: "missing_signature",
        jailed: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
      },
      {
        blockNumber: 8546742,
        blockTimestamp: "2022-03-10 11:17:33.5 +0000 UTC",
        power: 260018,
        reason: "missing_signature",
        jailed: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
      },
      {
        blockNumber: 24977362,
        blockTimestamp: "2023-01-28 18:17:02.812 +0000 UTC",
        power: 692333,
        reason: "missing_signature",
        jailed: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
      },
      {
        blockNumber: 28754226,
        blockTimestamp: "2023-03-16 08:18:22.782 +0000 UTC",
        power: 636305,
        reason: "missing_signature",
        jailed: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
      },
      {
        blockNumber: 34211648,
        blockTimestamp: "2023-05-25 05:41:07.125 +0000 UTC",
        power: 424406,
        reason: "missing_signature",
        jailed: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
      },
      {
        blockNumber: 58420350,
        blockTimestamp: "2024-01-24 23:56:14.105 +0000 UTC",
        power: 378987,
        reason: "missing_signature",
        jailed: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
      },
    ],
    uptime_percentage: 96.80636711666331,
    imageURL: "",
  },
  {
    id: "",
    moniker: "AUDIT.one",
    operator_address: "injvaloper16gquuvkj7yreyvmmmha4uke9cjtd6t3nh5r76d",
    consensus_address: "injvalcons19u74jddypfwvug04y0hzs54507cdnegrqlqyee",
    jailed: false,
    status: 3,
    tokens: "188949842362449938596257",
    delegator_shares: "189347242119427359712738.805825840568956898",
    description: {
      moniker: "AUDIT.one",
      identity: "5736C325251A8046",
      website: "https://audit.one",
      security_contact: "hello@audit.one",
      details: "Validators of today, Auditors of tomorrow",
      imageURL: "",
    },
    unbonding_height: 44693843,
    unbonding_time: "2023-10-02 12:08:05.185 +0000 UTC",
    commission_rate: "0.070000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 7106,
    signed: 1728533,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 2595552,
        blockTimestamp: "2021-09-18 06:42:46.252 +0000 UTC",
        power: 60270,
        reason: "missing_signature",
        jailed: "injvalcons19u74jddypfwvug04y0hzs54507cdnegrqlqyee",
      },
    ],
    uptime_percentage: 97.89177793394238,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/39002ba27b7a8267410a2fedd5528305_360_360.jpg",
  },
  {
    id: "",
    moniker: "Node X",
    operator_address: "injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24",
    consensus_address: "injvalcons12k9m6tm87p8lt4xwk27g5xgvfpg3uqpm8y9eln",
    jailed: true,
    status: 1,
    tokens: "170102237414036625614",
    delegator_shares: "170204326571564678806.351051206442977473",
    description: {
      moniker: "Node X",
      identity: "CE33078D690BAC6A",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 49944171,
    unbonding_time: "2023-11-22 11:17:48.358 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2021-09-20 08:18:23.666 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 2775087,
        blockTimestamp: "2021-09-23 21:11:49.92 +0000 UTC",
        power: 10,
        reason: "missing_signature",
        jailed: "injvalcons12k9m6tm87p8lt4xwk27g5xgvfpg3uqpm8y9eln",
      },
      {
        blockNumber: 3321877,
        blockTimestamp: "2021-10-10 11:15:34.254 +0000 UTC",
        power: 3390,
        reason: "missing_signature",
        jailed: "injvalcons12k9m6tm87p8lt4xwk27g5xgvfpg3uqpm8y9eln",
      },
      {
        blockNumber: 4371990,
        blockTimestamp: "2021-11-09 07:43:28.758 +0000 UTC",
        power: 3390,
        reason: "missing_signature",
        jailed: "injvalcons12k9m6tm87p8lt4xwk27g5xgvfpg3uqpm8y9eln",
      },
      {
        blockNumber: 5948517,
        blockTimestamp: "2021-12-24 13:00:38.787 +0000 UTC",
        power: 5177,
        reason: "missing_signature",
        jailed: "injvalcons12k9m6tm87p8lt4xwk27g5xgvfpg3uqpm8y9eln",
      },
      {
        blockNumber: 6179116,
        blockTimestamp: "2021-12-31 04:02:30.902 +0000 UTC",
        power: 5177,
        reason: "missing_signature",
        jailed: "injvalcons12k9m6tm87p8lt4xwk27g5xgvfpg3uqpm8y9eln",
      },
      {
        blockNumber: 7705437,
        blockTimestamp: "2022-02-13 07:58:45.881 +0000 UTC",
        power: 3037,
        reason: "missing_signature",
        jailed: "injvalcons12k9m6tm87p8lt4xwk27g5xgvfpg3uqpm8y9eln",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/1d9d9bbb5b09182d1b855cb3d2c64b05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Blockval",
    operator_address: "injvaloper1nc584wu0yl6c3vj2zxv6700mdq5c8hx3e7ag5t",
    consensus_address: "injvalcons1amz4lxdn8ctk4kwa649algmkwp4ka0m8tenmhe",
    jailed: true,
    status: 1,
    tokens: "21741430038078052",
    delegator_shares: "21747952616213709.895054311275343667",
    description: {
      moniker: "Blockval",
      identity: "",
      website: "blockval.io",
      security_contact: "info@blockval.io",
      details: "",
      imageURL: "",
    },
    unbonding_height: 4292336,
    unbonding_time: "2021-11-27 21:56:30.703 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2021-10-28 08:56:32.293 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 4041877,
        blockTimestamp: "2021-10-30 20:48:38.228 +0000 UTC",
        power: 2628,
        reason: "missing_signature",
        jailed: "injvalcons1amz4lxdn8ctk4kwa649algmkwp4ka0m8tenmhe",
      },
      {
        blockNumber: 4212927,
        blockTimestamp: "2021-11-04 16:28:35.89 +0000 UTC",
        power: 2811,
        reason: "missing_signature",
        jailed: "injvalcons1amz4lxdn8ctk4kwa649algmkwp4ka0m8tenmhe",
      },
      {
        blockNumber: 4292336,
        blockTimestamp: "2021-11-06 21:56:30.703 +0000 UTC",
        power: 3010,
        reason: "missing_signature",
        jailed: "injvalcons1amz4lxdn8ctk4kwa649algmkwp4ka0m8tenmhe",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "EarnX(Phasing Out)",
    operator_address: "injvaloper1y28s5jpaxg7qr06c0thfqf5wnjurq6etx7aret",
    consensus_address: "injvalcons1j7pf6mpfccfn340nqtlms7rrhqtncshcm3535j",
    jailed: true,
    status: 1,
    tokens: "45294484749312835",
    delegator_shares: "45435171404026159.388524327570806383",
    description: {
      moniker: "EarnX(Phasing Out)",
      identity: "4B0D604AB7A4E480",
      website: "",
      security_contact: "",
      details: "do not delegate, phasing out",
      imageURL: "",
    },
    unbonding_height: 5932704,
    unbonding_time: "2022-01-14 02:15:29.696 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2021-09-30 01:04:01.055 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 5480007,
        blockTimestamp: "2021-12-11 02:52:03.26 +0000 UTC",
        power: 10683,
        reason: "missing_signature",
        jailed: "injvalcons1j7pf6mpfccfn340nqtlms7rrhqtncshcm3535j",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/13f61ee05cdf6a8850b217486f85fe05_360_360.jpg",
  },
  {
    id: "",
    moniker: "PFC - Stake for $SOUL airdrop",
    operator_address: "injvaloper15vlkdnu2c0k0gaclgycnyjm7c5f3hsde034f5p",
    consensus_address: "injvalcons1a9efq9vresqrcnpc3mewda2hp2ls6km4qsyzm8",
    jailed: false,
    status: 3,
    tokens: "1135226527526438936739874",
    delegator_shares: "1141261696845812513796881.855977816774366210",
    description: {
      moniker: "PFC - Stake for $SOUL airdrop",
      identity: "86AC709C230079D0",
      website: "https://pfc.zone/",
      security_contact: "pfc@pfc.zone",
      details:
        "$SOUL airdrop details - https://twitter.com/BackBone_Labs/status/1745576351682171179\n\nGenesis Validator. Relayer. Former Senate member. We've been validating for 3+ years on multiple chains",
      imageURL: "",
    },
    unbonding_height: 52614911,
    unbonding_time: "2023-12-18 23:22:04.372 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-05-18 22:47:18.061 +0000 UTC",
    proposed: 38340,
    signed: 1761735,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 5948518,
        blockTimestamp: "2021-12-24 13:00:41.183 +0000 UTC",
        power: 2243,
        reason: "missing_signature",
        jailed: "injvalcons1a9efq9vresqrcnpc3mewda2hp2ls6km4qsyzm8",
      },
      {
        blockNumber: 6051766,
        blockTimestamp: "2021-12-27 11:42:05.31 +0000 UTC",
        power: 2243,
        reason: "missing_signature",
        jailed: "injvalcons1a9efq9vresqrcnpc3mewda2hp2ls6km4qsyzm8",
      },
      {
        blockNumber: 38855510,
        blockTimestamp: "2023-07-16 05:11:29.522 +0000 UTC",
        power: 575413,
        reason: "missing_signature",
        jailed: "injvalcons1a9efq9vresqrcnpc3mewda2hp2ls6km4qsyzm8",
      },
    ],
    uptime_percentage: 99.97952497952498,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/7fc06f8bbd08e6f6c495d0f1e0ec4905_360_360.jpg",
  },
  {
    id: "",
    moniker: "HighStakes.ch | Stake for Airdrop",
    operator_address: "injvaloper1f2kdg34689x93cvw2y59z7y46dvz2fk8lhddfz",
    consensus_address: "injvalcons1mrdfewvpvfn4a80t8z8wzlpkp4cz6kukepxuxc",
    jailed: false,
    status: 3,
    tokens: "754854627166732342049196",
    delegator_shares: "759627444809536137637661.696702632399945400",
    description: {
      moniker: "HighStakes.ch | Stake for Airdrop",
      identity: "2CB281A714F6133B",
      website: "https://highstakes.ch/",
      security_contact: "contact@highstakes.ch",
      details:
        "Increase your staking rewards with our IBEX program. Learn more at https://highstakes.ch/earn-ibex/",
      imageURL: "",
    },
    unbonding_height: 41660894,
    unbonding_time: "2023-09-03 06:31:05.412 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-03-05 12:48:59.747 +0000 UTC",
    proposed: 28141,
    signed: 1680490,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 5953985,
        blockTimestamp: "2021-12-24 16:44:20.556 +0000 UTC",
        power: 1009,
        reason: "missing_signature",
        jailed: "injvalcons1mrdfewvpvfn4a80t8z8wzlpkp4cz6kukepxuxc",
      },
      {
        blockNumber: 9866932,
        blockTimestamp: "2022-04-19 02:15:20.977 +0000 UTC",
        power: 16067,
        reason: "missing_signature",
        jailed: "injvalcons1mrdfewvpvfn4a80t8z8wzlpkp4cz6kukepxuxc",
      },
      {
        blockNumber: 26633723,
        blockTimestamp: "2023-02-18 06:16:45.806 +0000 UTC",
        power: 609255,
        reason: "missing_signature",
        jailed: "injvalcons1mrdfewvpvfn4a80t8z8wzlpkp4cz6kukepxuxc",
      },
    ],
    uptime_percentage: 94.91163924436319,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/53b008f12f37e3ffa0dec3676d375a05_360_360.jpg",
  },
  {
    id: "",
    moniker: " polkachu.com",
    operator_address: "injvaloper125fkz3mq6qxxpkmphdl3ep92t0d3y9695mhclt",
    consensus_address: "injvalcons1x0knjmysfhvwngwz5slmxlq57h0akm52r8ef7t",
    jailed: false,
    status: 3,
    tokens: "2293118298054563170370488",
    delegator_shares: "2293347632817844952582518.970470839161433210",
    description: {
      moniker: " polkachu.com",
      identity: "0A6AF02D1557E5B4",
      website: "https://polkachu.com",
      security_contact: "hello@polkachu.com",
      details:
        "Polkachu is the trusted staking service provider for blockchain projects. 100% refund for downtime slash. Contact us at hello@polkachu.com",
      imageURL: "",
    },
    unbonding_height: 10540418,
    unbonding_time: "2022-05-30 10:24:08.539 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-04-21 20:31:08.354 +0000 UTC",
    proposed: 84897,
    signed: 1682786,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 5979117,
        blockTimestamp: "2021-12-25 09:50:52.275 +0000 UTC",
        power: 151,
        reason: "missing_signature",
        jailed: "injvalcons1x0knjmysfhvwngwz5slmxlq57h0akm52r8ef7t",
      },
    ],
    uptime_percentage: 99.74824294555754,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/d56ce0bdda17f73d4aa895d1626e2505_360_360.jpg",
  },
  {
    id: "",
    moniker: "frontrunner",
    operator_address: "injvaloper1rkn63ra8597j0w2m6qc7sffjvz6xpdy2g8vuc8",
    consensus_address: "injvalcons1wym9udsj8jef57s79asdrtsaayktkxk3zpa467",
    jailed: true,
    status: 1,
    tokens: "999900000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "frontrunner",
      identity: "",
      website: "https://getfrontrunner.com",
      security_contact: "security@getfrontrunner.com",
      details: "Frontrunner's node",
      imageURL: "",
    },
    unbonding_height: 6127935,
    unbonding_time: "2022-01-19 16:16:23.278 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2021-12-27 17:23:54.312 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 6127935,
        blockTimestamp: "2021-12-29 16:16:23.278 +0000 UTC",
        power: 1,
        reason: "missing_signature",
        jailed: "injvalcons1wym9udsj8jef57s79asdrtsaayktkxk3zpa467",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Bi23 Labs",
    operator_address: "injvaloper13c0m2t3hmaqp43rw0t97utvy5dc7cktes0swce",
    consensus_address: "injvalcons1wse36q8vwyt8rwdm9uxes49zxf23yy3x6ftrhu",
    jailed: true,
    status: 1,
    tokens: "144863944784830980759",
    delegator_shares: "145168526255678598374.657769582893645122",
    description: {
      moniker: "Bi23 Labs",
      identity: "EB3470949B3E89E2",
      website: "https://bi23.com",
      security_contact: "support@bi23.com",
      details:
        "PoS validators. Co-builder of https://bitgraph.io https://weteam.io https://thecelo.com https://celo.dance.",
      imageURL: "",
    },
    unbonding_height: 10887973,
    unbonding_time: "2022-06-09 22:15:30.182 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-01-25 21:45:15.461 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 6387156,
        blockTimestamp: "2022-01-06 00:28:33.518 +0000 UTC",
        power: 1670,
        reason: "missing_signature",
        jailed: "injvalcons1wse36q8vwyt8rwdm9uxes49zxf23yy3x6ftrhu",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/a084e1a96ecc3f24bd57dfec517a9205_360_360.jpg",
  },
  {
    id: "",
    moniker: "Orion - Auto-Compound & 0% Fees",
    operator_address: "injvaloper10zxm0rklrnurd999xgr4ss8v2q9qsjlfhhk9mk",
    consensus_address: "injvalcons1nx802hvk40x02mlh8qya3nuuep2sejtgxn838l",
    jailed: true,
    status: 1,
    tokens: "708959047415579634282",
    delegator_shares: "709810535535449369843.102046563728582554",
    description: {
      moniker: "Orion - Auto-Compound & 0% Fees",
      identity: "535A2B455DC7F064",
      website: "https://orionstaking.com",
      security_contact: "contact@orionstaking.com",
      details:
        "Get one of the highest APY with Zero Fees and Auto-Componding with Orion validator! Orion is running high uptime validators across Cosmos ecosystem.",
      imageURL: "",
    },
    unbonding_height: 28002618,
    unbonding_time: "2023-03-28 02:22:00.484 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.099000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-01-20 14:12:01.372 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 7249303,
        blockTimestamp: "2022-01-30 21:19:04.521 +0000 UTC",
        power: 10,
        reason: "missing_signature",
        jailed: "injvalcons1nx802hvk40x02mlh8qya3nuuep2sejtgxn838l",
      },
      {
        blockNumber: 12589156,
        blockTimestamp: "2022-07-06 14:24:59.856 +0000 UTC",
        power: 46698,
        reason: "missing_signature",
        jailed: "injvalcons1nx802hvk40x02mlh8qya3nuuep2sejtgxn838l",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/0c6385396acbe5ed0d2c60e12c58c005_360_360.jpg",
  },
  {
    id: "",
    moniker: "Innovating Capital",
    operator_address: "injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07",
    consensus_address: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
    jailed: false,
    status: 3,
    tokens: "164376579864511409186780",
    delegator_shares: "166744988598426625009306.764781401049799213",
    description: {
      moniker: "Innovating Capital",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 58551880,
    unbonding_time: "2024-02-16 06:51:32.735 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-02-26 20:36:59.043 +0000 UTC",
    proposed: 1500,
    signed: 538428,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 8400444,
        blockTimestamp: "2022-03-06 02:38:47.165 +0000 UTC",
        power: 165238,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 8708591,
        blockTimestamp: "2022-03-15 05:45:32.189 +0000 UTC",
        power: 165221,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 8827283,
        blockTimestamp: "2022-03-18 18:50:21.088 +0000 UTC",
        power: 165205,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 8953523,
        blockTimestamp: "2022-03-22 12:20:55.179 +0000 UTC",
        power: 165188,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 9113866,
        blockTimestamp: "2022-03-27 09:25:43.851 +0000 UTC",
        power: 165172,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 9535763,
        blockTimestamp: "2022-04-09 05:43:58.247 +0000 UTC",
        power: 165155,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 9700299,
        blockTimestamp: "2022-04-14 05:01:30.429 +0000 UTC",
        power: 166155,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 9915679,
        blockTimestamp: "2022-04-20 13:38:48.091 +0000 UTC",
        power: 166161,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 10041587,
        blockTimestamp: "2022-04-24 07:51:32.034 +0000 UTC",
        power: 165978,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 10240201,
        blockTimestamp: "2022-04-30 07:21:40.166 +0000 UTC",
        power: 165962,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 10372918,
        blockTimestamp: "2022-05-04 07:42:15.262 +0000 UTC",
        power: 165945,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 10410385,
        blockTimestamp: "2022-05-05 11:09:29.239 +0000 UTC",
        power: 165929,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 10652393,
        blockTimestamp: "2022-05-12 20:53:18.875 +0000 UTC",
        power: 165912,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 10863875,
        blockTimestamp: "2022-05-19 05:48:29.135 +0000 UTC",
        power: 165895,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 10944329,
        blockTimestamp: "2022-05-21 12:17:08.023 +0000 UTC",
        power: 145947,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 11125088,
        blockTimestamp: "2022-05-26 14:20:47.669 +0000 UTC",
        power: 145932,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 11401070,
        blockTimestamp: "2022-06-03 10:11:52.569 +0000 UTC",
        power: 145918,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 11530966,
        blockTimestamp: "2022-06-07 00:58:37.957 +0000 UTC",
        power: 145903,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 11802898,
        blockTimestamp: "2022-06-14 16:04:46.85 +0000 UTC",
        power: 145888,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 11873368,
        blockTimestamp: "2022-06-16 14:44:03.84 +0000 UTC",
        power: 145874,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 12461038,
        blockTimestamp: "2022-07-02 16:21:58.818 +0000 UTC",
        power: 135723,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 13138894,
        blockTimestamp: "2022-07-21 09:37:06.51 +0000 UTC",
        power: 135710,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 14740361,
        blockTimestamp: "2022-09-01 21:04:48.535 +0000 UTC",
        power: 135697,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 14864625,
        blockTimestamp: "2022-09-04 14:15:07.968 +0000 UTC",
        power: 135684,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 15072951,
        blockTimestamp: "2022-09-09 02:36:43.094 +0000 UTC",
        power: 135670,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 15189977,
        blockTimestamp: "2022-09-11 15:41:56.464 +0000 UTC",
        power: 135657,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 15493249,
        blockTimestamp: "2022-09-18 10:15:04.831 +0000 UTC",
        power: 135643,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 24222986,
        blockTimestamp: "2023-01-18 20:31:49.064 +0000 UTC",
        power: 166861,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 24325587,
        blockTimestamp: "2023-01-20 03:46:27.407 +0000 UTC",
        power: 166844,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 42273668,
        blockTimestamp: "2023-08-19 04:12:05.08 +0000 UTC",
        power: 126479,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 42745324,
        blockTimestamp: "2023-08-23 16:41:46.634 +0000 UTC",
        power: 126758,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 54757172,
        blockTimestamp: "2023-12-18 11:45:23.854 +0000 UTC",
        power: 127591,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 57062781,
        blockTimestamp: "2024-01-11 13:51:40.687 +0000 UTC",
        power: 99201,
        reason: "missing_signature",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
    ],
    uptime_percentage: 96.82921934577564,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Nala DAO",
    operator_address: "injvaloper1tly0qyahfpe53jkrxem9lmfcr3f2mmxtgl2kzc",
    consensus_address: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
    jailed: true,
    status: 1,
    tokens: "74932913651792879213",
    delegator_shares: "75000174312463931602.047669068528458096",
    description: {
      moniker: "Nala DAO",
      identity: "",
      website: "https://www.naladao.com/",
      security_contact: "node@naladao.com",
      details:
        "Institutional grade staking services with a 24/7 globally distributed operations team.",
      imageURL: "",
    },
    unbonding_height: 9719523,
    unbonding_time: "2022-05-05 18:29:50.718 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-03-10 01:44:50.341 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 8629340,
        blockTimestamp: "2022-03-12 21:14:53.406 +0000 UTC",
        power: 75,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
      {
        blockNumber: 8732894,
        blockTimestamp: "2022-03-15 23:07:29.326 +0000 UTC",
        power: 74,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
      {
        blockNumber: 8771913,
        blockTimestamp: "2022-03-17 02:48:30.1 +0000 UTC",
        power: 74,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
      {
        blockNumber: 9366393,
        blockTimestamp: "2022-04-04 01:16:01.497 +0000 UTC",
        power: 1827,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
      {
        blockNumber: 9486492,
        blockTimestamp: "2022-04-07 17:21:53.916 +0000 UTC",
        power: 1827,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
      {
        blockNumber: 9634860,
        blockTimestamp: "2022-04-12 07:16:05.766 +0000 UTC",
        power: 1827,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
      {
        blockNumber: 9657506,
        blockTimestamp: "2022-04-12 22:59:48.743 +0000 UTC",
        power: 1827,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
      {
        blockNumber: 9687955,
        blockTimestamp: "2022-04-13 20:10:06.536 +0000 UTC",
        power: 1826,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
      {
        blockNumber: 9719523,
        blockTimestamp: "2022-04-14 18:29:50.718 +0000 UTC",
        power: 1826,
        reason: "missing_signature",
        jailed: "injvalcons1e9pzmnyfuahqmdq42zd0rguvaacwkcusdmvdjj",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "POSTCAPITALIST🎩",
    operator_address: "injvaloper1get4y2tqyp047ftym76c4avl4remldtrrj9knt",
    consensus_address: "injvalcons1fdavckupqs9wcvrdw94tc6hdc78zctrspwnk3k",
    jailed: true,
    status: 1,
    tokens: "2875126491730881747",
    delegator_shares: "2886050055485318115.605810617443949798",
    description: {
      moniker: "POSTCAPITALIST🎩",
      identity: "CBFDCD0D9180878B",
      website: "https://blog.postcapitalist.io",
      security_contact: "",
      details:
        "Validator without Capital 💸 |  GIVE MORE 🔄 TAKE LESS  | 🪐 Our missions - https://blog.postcapitalist.io/tagged/postcapitalist-charity",
      imageURL: "",
    },
    unbonding_height: 12607731,
    unbonding_time: "2022-07-28 02:17:57.183 +0000 UTC",
    commission_rate: "0.080000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-04-01 13:41:17.113 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 9634201,
        blockTimestamp: "2022-04-12 06:42:10.343 +0000 UTC",
        power: 542,
        reason: "missing_signature",
        jailed: "injvalcons1fdavckupqs9wcvrdw94tc6hdc78zctrspwnk3k",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/96c9aa6f70b9d9a4321158808bbed305_360_360.jpg",
  },
  {
    id: "",
    moniker: "WolfEdge",
    operator_address: "injvaloper1dqryh824u0w7p6ajk2gsr29tgj6d0nkfeka457",
    consensus_address: "injvalcons1jmj0q57lsehcxr04jss6jzn30cahm09d0l6a77",
    jailed: true,
    status: 1,
    tokens: "2886851600000000000000",
    delegator_shares: "2893500199985410957049.172718600362221932",
    description: {
      moniker: "WolfEdge",
      identity: "",
      website: "https://wolfedge.capital",
      security_contact: "pawan.sharma@wolfedge.capital",
      details:
        "WolfEdge is leading blockchain validation service on proof of stake chains with cutting edge infrastructure & expertise.",
      imageURL: "",
    },
    unbonding_height: 11410358,
    unbonding_time: "2022-06-24 16:29:51.567 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-05-05 17:20:16.39 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 10604069,
        blockTimestamp: "2022-05-11 09:33:32.127 +0000 UTC",
        power: 2891,
        reason: "missing_signature",
        jailed: "injvalcons1jmj0q57lsehcxr04jss6jzn30cahm09d0l6a77",
      },
      {
        blockNumber: 10693690,
        blockTimestamp: "2022-05-14 02:45:06.437 +0000 UTC",
        power: 2893,
        reason: "missing_signature",
        jailed: "injvalcons1jmj0q57lsehcxr04jss6jzn30cahm09d0l6a77",
      },
      {
        blockNumber: 11058443,
        blockTimestamp: "2022-05-24 17:05:10.509 +0000 UTC",
        power: 2890,
        reason: "missing_signature",
        jailed: "injvalcons1jmj0q57lsehcxr04jss6jzn30cahm09d0l6a77",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Injective Node 3",
    operator_address: "injvaloper1m3jmau90vepl2nyvhljrkgl7sq78algxmew74t",
    consensus_address: "injvalcons1d8als5uv78vsgf6qhyku0wcgcssp47mepfxuuy",
    jailed: true,
    status: 1,
    tokens: "8007139209342398680",
    delegator_shares: "8007940002860234255.977481435146399016",
    description: {
      moniker: "Injective Node 3",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 15760468,
    unbonding_time: "2022-10-15 07:16:35.932 +0000 UTC",
    commission_rate: "0.070000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.500000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 12589205,
        blockTimestamp: "2022-07-06 14:28:02.859 +0000 UTC",
        power: 1510460,
        reason: "missing_signature",
        jailed: "injvalcons1d8als5uv78vsgf6qhyku0wcgcssp47mepfxuuy",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Open DeFi",
    operator_address: "injvaloper185jhauhyttg2d5j535hksj234dn0hg0zy6q5el",
    consensus_address: "injvalcons10h235hdqvl56jhac9cpdtfq62xkarm6xerkp9u",
    jailed: false,
    status: 3,
    tokens: "273817405076058142572872",
    delegator_shares: "274118907355761497778667.260521578931532449",
    description: {
      moniker: "Open DeFi",
      identity: "",
      website: "https://www.opendefi.xyz",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 24305586,
    unbonding_time: "2023-02-09 21:28:57.395 +0000 UTC",
    commission_rate: "1.000000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.500000000000000000",
    commission_update_time: "2022-12-22 11:27:08.939 +0000 UTC",
    proposed: 10339,
    signed: 1756782,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 12589350,
        blockTimestamp: "2022-07-06 14:36:00.57 +0000 UTC",
        power: 1516040,
        reason: "missing_signature",
        jailed: "injvalcons10h235hdqvl56jhac9cpdtfq62xkarm6xerkp9u",
      },
    ],
    uptime_percentage: 98.91391794046662,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Injective Node 1",
    operator_address: "injvaloper1jpnuzes6hd3kjye9f8fsrum8dhj8dylwq5gdcc",
    consensus_address: "injvalcons1w2fuj7kes56r4n0fjqsvaf8andlkx5ryelaud8",
    jailed: true,
    status: 1,
    tokens: "1198766235464682388547",
    delegator_shares: "1198886124003296547585.156498130858011233",
    description: {
      moniker: "Injective Node 1",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 15760450,
    unbonding_time: "2022-10-15 07:16:02.666 +0000 UTC",
    commission_rate: "0.070000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.500000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 12589359,
        blockTimestamp: "2022-07-06 14:36:37.438 +0000 UTC",
        power: 1559818,
        reason: "missing_signature",
        jailed: "injvalcons1w2fuj7kes56r4n0fjqsvaf8andlkx5ryelaud8",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Stakewolle.com | Auto-compound",
    operator_address: "injvaloper1kpfxtqt5cmlew46dem32fqlk5g6k4wyueh4szu",
    consensus_address: "injvalcons1xldh2zm5qf439ux5jceljkyp4rhc4erucgn90q",
    jailed: false,
    status: 3,
    tokens: "670925676512398698805670",
    delegator_shares: "675167472085670459100187.858107517548965584",
    description: {
      moniker: "Stakewolle.com | Auto-compound",
      identity: "6386E8C1B6217AC2",
      website: "https://stakewolle.com/",
      security_contact: "",
      details:
        "🚀 Professional Cosmos Validator & Engineer 🔁Auto-compound with REStakeapp 🛡Slash insurance 🎁 All Airdrops for delegators 🔗 Twitter, Telegram and Youtube | Stakewolle.com",
      imageURL: "",
    },
    unbonding_height: 49996027,
    unbonding_time: "2023-11-22 23:41:02.653 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-01-28 11:00:32.151 +0000 UTC",
    proposed: 25039,
    signed: 1685296,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 13945031,
        blockTimestamp: "2022-08-12 06:22:11.868 +0000 UTC",
        power: 58164,
        reason: "missing_signature",
        jailed: "injvalcons1xldh2zm5qf439ux5jceljkyp4rhc4erucgn90q",
      },
      {
        blockNumber: 22063529,
        blockTimestamp: "2022-12-21 02:56:16.078 +0000 UTC",
        power: 714853,
        reason: "missing_signature",
        jailed: "injvalcons1xldh2zm5qf439ux5jceljkyp4rhc4erucgn90q",
      },
      {
        blockNumber: 27952615,
        blockTimestamp: "2023-03-06 11:57:55.951 +0000 UTC",
        power: 912649,
        reason: "missing_signature",
        jailed: "injvalcons1xldh2zm5qf439ux5jceljkyp4rhc4erucgn90q",
      },
    ],
    uptime_percentage: 95.51860488695124,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/1dbbcb3590eb95b16521c13a42921605_360_360.jpg",
  },
  {
    id: "",
    moniker: " Crypteloid",
    operator_address: "injvaloper1xd0pgs5r04whkkpw45wrl757rvd3qre4rjzp5z",
    consensus_address: "injvalcons1060m9mv85zr57up69ntwdh4u2n0vgdeqymegzs",
    jailed: true,
    status: 1,
    tokens: "1100010000000002",
    delegator_shares: "1101221167000682.521300009992169030",
    description: {
      moniker: " Crypteloid",
      identity: "C6C9099DE1462F3B",
      website: "https://stake.crypteloid.com",
      security_contact: "",
      details:
        "Highly secure and reliable. Subscribe to our Twitter https://twitter.com/iCrypteloid and Telegram https://t.me/icrypteloid",
      imageURL: "",
    },
    unbonding_height: 14603348,
    unbonding_time: "2022-09-19 09:09:29.165 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.020000000000000000",
    commission_update_time: "2022-08-21 22:21:35.125 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 14603348,
        blockTimestamp: "2022-08-29 09:09:29.165 +0000 UTC",
        power: 812,
        reason: "missing_signature",
        jailed: "injvalcons1060m9mv85zr57up69ntwdh4u2n0vgdeqymegzs",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/26addac6681b7994a18e38ab41fb8205_360_360.jpg",
  },
  {
    id: "",
    moniker: "Staked",
    operator_address: "injvaloper1w3psm8a9td2qz06s46cxss03mz5umxaxegvhhs",
    consensus_address: "injvalcons1n2kq0y8l7a85q5xmhw64a06ehuf5cspc8qs437",
    jailed: true,
    status: 1,
    tokens: "11777223154585945657476",
    delegator_shares: "11780757018863693576670.492746646447503957",
    description: {
      moniker: "Staked",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 32483015,
    unbonding_time: "2023-05-23 15:28:36.265 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 14750913,
        blockTimestamp: "2022-09-02 02:46:20.441 +0000 UTC",
        power: 351296,
        reason: "missing_signature",
        jailed: "injvalcons1n2kq0y8l7a85q5xmhw64a06ehuf5cspc8qs437",
      },
      {
        blockNumber: 15892493,
        blockTimestamp: "2022-09-27 06:09:10.659 +0000 UTC",
        power: 350463,
        reason: "missing_signature",
        jailed: "injvalcons1n2kq0y8l7a85q5xmhw64a06ehuf5cspc8qs437",
      },
      {
        blockNumber: 32483015,
        blockTimestamp: "2023-05-02 15:28:36.265 +0000 UTC",
        power: 63236,
        reason: "missing_signature",
        jailed: "injvalcons1n2kq0y8l7a85q5xmhw64a06ehuf5cspc8qs437",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Copper",
    operator_address: "injvaloper1p5w2pquxj0plswe4jwczxj86tzuupqpydg3n64",
    consensus_address: "injvalcons1xsm2yvceu9x26zsjdefgl606jlgx5avn64v6y0",
    jailed: true,
    status: 1,
    tokens: "83209656583824581490",
    delegator_shares: "83384659437826366570.757735368605318571",
    description: {
      moniker: "Copper",
      identity: "",
      website: "https://copper.co",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 33900258,
    unbonding_time: "2023-06-11 03:17:33.129 +0000 UTC",
    commission_rate: "0.088000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2022-04-21 13:11:19.71 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 14942242,
        blockTimestamp: "2022-09-06 07:31:59.849 +0000 UTC",
        power: 35381,
        reason: "missing_signature",
        jailed: "injvalcons1xsm2yvceu9x26zsjdefgl606jlgx5avn64v6y0",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "0% Fee to 2024 | Octopus",
    operator_address: "injvaloper1w0as8yvc4se5s8alux2wy8kj6qt3n5p34d0x6f",
    consensus_address: "injvalcons1qskjgedkwhzfwfku94g6mwqmph42kjq8n5w3zs",
    jailed: true,
    status: 1,
    tokens: "9000000000000001",
    delegator_shares: "9000900090009000.900090009000900091",
    description: {
      moniker: "0% Fee to 2024 | Octopus",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 15513212,
    unbonding_time: "2022-10-09 20:48:10.775 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-09-12 19:37:21.641 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 15513212,
        blockTimestamp: "2022-09-18 20:48:10.775 +0000 UTC",
        power: 10,
        reason: "missing_signature",
        jailed: "injvalcons1qskjgedkwhzfwfku94g6mwqmph42kjq8n5w3zs",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "QWERTY.EXCHANGE",
    operator_address: "injvaloper1qndvee93f30q3ra2hncasphm22k97vpr89zusa",
    consensus_address: "injvalcons1mpt6fw5hg565m87pm7g6stxms7382caxcu5um8",
    jailed: false,
    status: 3,
    tokens: "602681922179091268377030",
    delegator_shares: "603405881792934955251225.433790130512041646",
    description: {
      moniker: "QWERTY.EXCHANGE",
      identity: "",
      website: "https://qwerty.exchange",
      security_contact: "validator@qwerty.exchange",
      details: "",
      imageURL: "",
    },
    unbonding_height: 57446663,
    unbonding_time: "2024-02-05 13:42:31.16 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-08-30 14:11:09.091 +0000 UTC",
    proposed: 22505,
    signed: 1636214,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 15892242,
        blockTimestamp: "2022-09-27 06:00:55.49 +0000 UTC",
        power: 100801,
        reason: "missing_signature",
        jailed: "injvalcons1mpt6fw5hg565m87pm7g6stxms7382caxcu5um8",
      },
      {
        blockNumber: 25038049,
        blockTimestamp: "2023-01-29 13:48:50.26 +0000 UTC",
        power: 336151,
        reason: "missing_signature",
        jailed: "injvalcons1mpt6fw5hg565m87pm7g6stxms7382caxcu5um8",
      },
    ],
    uptime_percentage: 94.75549255846917,
    imageURL: "",
  },
  {
    id: "",
    moniker: "inactive",
    operator_address: "injvaloper19vhp7hgxetnglngyfuft0342lezvutyd4jk8nd",
    consensus_address: "injvalcons15sldqfslputjhrmdesvj8n0an7mfjvws00ussx",
    jailed: true,
    status: 1,
    tokens: "20583364955957885",
    delegator_shares: "21666699067198293.185306005214595178",
    description: {
      moniker: "inactive",
      identity: "",
      website: "",
      security_contact: "",
      details: "inactive - please redelegate",
      imageURL: "",
    },
    unbonding_height: 16523806,
    unbonding_time: "2022-10-31 00:06:48.456 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-08-16 22:15:45.1 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 16523806,
        blockTimestamp: "2022-10-10 00:06:48.456 +0000 UTC",
        power: 205830,
        reason: "double_sign",
      },
      {
        blockNumber: 16523806,
        blockTimestamp: "2022-10-10 00:06:48.456 +0000 UTC",
        jailed: "injvalcons15sldqfslputjhrmdesvj8n0an7mfjvws00ussx",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Please Re-delegate",
    operator_address: "injvaloper197m0n2yxtfhz0uv4ehhddqrsjeg6xsp250nh0y",
    consensus_address: "injvalcons1uykwqng8hefrstwdcvsewhteg6phdmxwa34sph",
    jailed: true,
    status: 1,
    tokens: "26361737560409000872",
    delegator_shares: "27807557030924869137.237021924985733614",
    description: {
      moniker: "Please Re-delegate",
      identity: "",
      website: "https://ping.pub",
      security_contact: "",
      details:
        "Dear Delegators, thanks for delegations. very sorry for inactive. Welcome to connect us!",
      imageURL: "",
    },
    unbonding_height: 31446932,
    unbonding_time: "2023-05-10 09:09:16.769 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.500000000000000000",
    commission_max_change_rate: "0.500000000000000000",
    commission_update_time: "2022-01-31 02:03:10.363 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 19209448,
        blockTimestamp: "2022-11-14 19:33:29.893 +0000 UTC",
        power: 414404,
        reason: "missing_signature",
        jailed: "injvalcons1uykwqng8hefrstwdcvsewhteg6phdmxwa34sph",
      },
      {
        blockNumber: 31446932,
        blockTimestamp: "2023-04-19 09:09:16.769 +0000 UTC",
        power: 425491,
        reason: "double_sign",
      },
      {
        blockNumber: 31446932,
        blockTimestamp: "2023-04-19 09:09:16.769 +0000 UTC",
        jailed: "injvalcons1uykwqng8hefrstwdcvsewhteg6phdmxwa34sph",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Dora Factory",
    operator_address: "injvaloper1dknnk0nzap8h07elxays2ecldxq9elu3dzqvqr",
    consensus_address: "injvalcons1cp7u6w66h6zh8w4xzmua6r3lek8vran3cmnwcc",
    jailed: false,
    status: 3,
    tokens: "401321806949986624730461",
    delegator_shares: "401361943142963092534700.621385328470778616",
    description: {
      moniker: "Dora Factory",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 19630934,
    unbonding_time: "2022-12-10 23:27:28.453 +0000 UTC",
    commission_rate: "1.000000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2022-11-15 05:37:44.381 +0000 UTC",
    proposed: 15153,
    signed: 1708892,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 19630934,
        blockTimestamp: "2022-11-19 23:27:28.453 +0000 UTC",
        power: 300010,
        reason: "missing_signature",
        jailed: "injvalcons1cp7u6w66h6zh8w4xzmua6r3lek8vran3cmnwcc",
      },
    ],
    uptime_percentage: 98.98164952611414,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Matrixed.Link",
    operator_address: "injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp",
    consensus_address: "injvalcons1hczu528gc6egx55lvp6fps9knwhzz2u97sxycp",
    jailed: true,
    status: 1,
    tokens: "8174627625882188459",
    delegator_shares: "8200017952002648268.986911233177923707",
    description: {
      moniker: "Matrixed.Link",
      identity: "E903E2F1EA108F5D",
      website: "https://matrixedlink.io",
      security_contact: "validator@matrixed.link",
      details: "Your trusted blockchain infrastructure provider.",
      imageURL: "",
    },
    unbonding_height: 36866024,
    unbonding_time: "2023-07-15 21:56:57.844 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-02-06 10:00:22.889 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 24383346,
        blockTimestamp: "2023-01-20 22:09:23.681 +0000 UTC",
        power: 40102,
        reason: "missing_signature",
        jailed: "injvalcons1hczu528gc6egx55lvp6fps9knwhzz2u97sxycp",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/dfd087438eac6485febc3477699aa605_360_360.jpg",
  },
  {
    id: "",
    moniker: "HashQuark",
    operator_address: "injvaloper1zaahxq8my6nqvp64flacugxdyxp6tmm8st3hrz",
    consensus_address: "injvalcons1ffgu65c0n6c3zxh6tpf0unna2a3mw6ffla2nw6",
    jailed: true,
    status: 1,
    tokens: "558115326694734612077",
    delegator_shares: "558226966232732345703.843241280191153215",
    description: {
      moniker: "HashQuark",
      identity: "",
      website: "https://www.hashquark.io",
      security_contact: "",
      details: "Staking Made Easier",
      imageURL: "",
    },
    unbonding_height: 32568190,
    unbonding_time: "2023-05-24 17:17:37.779 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 24447603,
        blockTimestamp: "2023-01-21 19:07:39.565 +0000 UTC",
        power: 203091,
        reason: "missing_signature",
        jailed: "injvalcons1ffgu65c0n6c3zxh6tpf0unna2a3mw6ffla2nw6",
      },
      {
        blockNumber: 24601605,
        blockTimestamp: "2023-01-23 19:32:28.78 +0000 UTC",
        power: 203071,
        reason: "missing_signature",
        jailed: "injvalcons1ffgu65c0n6c3zxh6tpf0unna2a3mw6ffla2nw6",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Swiss Staking | Bare Metal | Bunker",
    operator_address: "injvaloper1r4lvwh0pmkmjewa76rem7ndr0z8zg3pseyfpux",
    consensus_address: "injvalcons1zsd39h38vysfgzzhpedakrr33n2574yw9eyjve",
    jailed: false,
    status: 3,
    tokens: "262817297356977077338311",
    delegator_shares: "263106688284379924579319.753915774271130840",
    description: {
      moniker: "Swiss Staking | Bare Metal | Bunker",
      identity: "165F85FC0194320D",
      website: "https://swiss-staking.ch",
      security_contact: "",
      details:
        "Our Injective bare metal validator is running in a former military building in Switzerland. No cloud provider. Top-notch protection and reliability!",
      imageURL: "",
    },
    unbonding_height: 35593780,
    unbonding_time: "2023-07-02 00:28:30.959 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.250000000000000000",
    commission_max_change_rate: "0.250000000000000000",
    commission_update_time: "2023-01-30 09:13:50.559 +0000 UTC",
    proposed: 9829,
    signed: 1636868,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 24758537,
        blockTimestamp: "2023-01-25 21:07:26.863 +0000 UTC",
        power: 202963,
        reason: "missing_signature",
        jailed: "injvalcons1zsd39h38vysfgzzhpedakrr33n2574yw9eyjve",
      },
    ],
    uptime_percentage: 92.49019804966322,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/ff6f58998b7612c5ff3c808aed1af005_360_360.jpg",
  },
  {
    id: "",
    moniker: "KingSuper",
    operator_address: "injvaloper1d2x0t42a6anth3lt38ld5997m6e5ya5frn7u8h",
    consensus_address: "injvalcons15uhn40g97ds8kmt0dvyx5vcuesqd0w89rehwvr",
    jailed: true,
    status: 1,
    tokens: "22005829760904654",
    delegator_shares: "22010002617717205.099069199539616777",
    description: {
      moniker: "KingSuper",
      identity: "C8992BB62C009B9F",
      website: "",
      security_contact: "",
      details:
        "KingSuper has highly qualified skills to run Validators Node And Our Commission Rate is lower than Others, which gives More Profit to our delegators",
      imageURL: "",
    },
    unbonding_height: 25044509,
    unbonding_time: "2023-02-19 15:50:55.316 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.400000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-03-22 18:34:39.921 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 24859446,
        blockTimestamp: "2023-01-27 04:40:16.409 +0000 UTC",
        power: 3,
        reason: "missing_signature",
        jailed: "injvalcons15uhn40g97ds8kmt0dvyx5vcuesqd0w89rehwvr",
      },
      {
        blockNumber: 24901902,
        blockTimestamp: "2023-01-27 18:03:25.815 +0000 UTC",
        power: 2,
        reason: "missing_signature",
        jailed: "injvalcons15uhn40g97ds8kmt0dvyx5vcuesqd0w89rehwvr",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/0ef4ba226a2717dca5f367e657e2d405_360_360.jpg",
  },
  {
    id: "",
    moniker: "Cosmology",
    operator_address: "injvaloper1njnkzz20mlmxff9vs42wgx22lcqtmsqjxr46ua",
    consensus_address: "injvalcons1876nzyd6732zgv6ykjm2q4v3t84u8qr262yqlm",
    jailed: true,
    status: 1,
    tokens: "2012599702262280403",
    delegator_shares: "2013002282536517900.375604748861119642",
    description: {
      moniker: "Cosmology",
      identity: "cosmology_fi",
      website: "https://cosmology.tech",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 28806599,
    unbonding_time: "2023-04-06 23:20:38.484 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-01-27 07:27:15.459 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 25325836,
        blockTimestamp: "2023-02-02 06:24:28.073 +0000 UTC",
        power: 50000,
        reason: "missing_signature",
        jailed: "injvalcons1876nzyd6732zgv6ykjm2q4v3t84u8qr262yqlm",
      },
      {
        blockNumber: 26920212,
        blockTimestamp: "2023-02-21 19:23:19.476 +0000 UTC",
        power: 50089,
        reason: "missing_signature",
        jailed: "injvalcons1876nzyd6732zgv6ykjm2q4v3t84u8qr262yqlm",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Informal Systems",
    operator_address: "injvaloper10xhy8xurfwts9ckjkq0ga92mrjz9txyygymqzp",
    consensus_address: "injvalcons1hwmt06edw48v99tu54s4m4dt09q4kzc66ulnmp",
    jailed: false,
    status: 3,
    tokens: "309066512858554831205706",
    delegator_shares: "309406829040229435214057.151616699162567115",
    description: {
      moniker: "Informal Systems",
      identity: "6408AA029ADBE364",
      website: "https://informal.systems",
      security_contact: "validator@informal.systems",
      details:
        "Informal Systems x Cephalopod Equipment - infrastructure for decentralized intelligence",
      imageURL: "",
    },
    unbonding_height: 25487585,
    unbonding_time: "2023-02-25 07:36:39.376 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.420000000000000000",
    commission_max_change_rate: "0.011800000000000000",
    commission_update_time: "2024-01-08 18:40:33.894 +0000 UTC",
    proposed: 10884,
    signed: 1631310,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 25336771,
        blockTimestamp: "2023-02-02 09:42:59.798 +0000 UTC",
        power: 310916,
        reason: "missing_signature",
        jailed: "injvalcons1hwmt06edw48v99tu54s4m4dt09q4kzc66ulnmp",
      },
    ],
    uptime_percentage: 98.00744691556808,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/3a6087c347a6ee0d02f82b4fe2081f05_360_360.jpg",
  },
  {
    id: "",
    moniker: "BlockHunters 🎯",
    operator_address: "injvaloper10gt552f5sw80lmd9a43rkvvgf6wj0nk3r7cwzf",
    consensus_address: "injvalcons13zs6ah79f7d35nnhay9h9rvvvpn583hmdp5r3q",
    jailed: true,
    status: 1,
    tokens: "103702095363176703170",
    delegator_shares: "103920111441046290700.104213119483350486",
    description: {
      moniker: "BlockHunters 🎯",
      identity: "BEAC09B6FE7F908B",
      website: "https://blockhunters.org/",
      security_contact: "blockhunters@pm.me",
      details: "Hunt for the best stake. 🏹",
      imageURL: "",
    },
    unbonding_height: 27978268,
    unbonding_time: "2023-03-27 19:19:33.897 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-01-20 12:42:35.742 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 25354308,
        blockTimestamp: "2023-02-02 15:05:12.769 +0000 UTC",
        power: 879,
        reason: "missing_signature",
        jailed: "injvalcons13zs6ah79f7d35nnhay9h9rvvvpn583hmdp5r3q",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/bec2c55ae55d417f928a323fd8815105_360_360.jpg",
  },
  {
    id: "",
    moniker: " Lavender.Five Nodes 🐝",
    operator_address: "injvaloper155yk4wfn0xqye80exlsr6hu4qdfsvsgwg3jckk",
    consensus_address: "injvalcons1ycsqvhadmyw9up40yk3m07ryfqa6rsaedgfde5",
    jailed: false,
    status: 3,
    tokens: "541795342522852373255990",
    delegator_shares: "543343381492647352843759.270830773866312916",
    description: {
      moniker: " Lavender.Five Nodes 🐝",
      identity: "F87ADDB700C0CC94",
      website: "https://www.lavenderfive.com/",
      security_contact: "hello@lavenderfive.com",
      details:
        "100% soft slash protected. We strive to make the cosmos a more holistically wholesome place. Come say Hi! https://linktr.ee/lavenderfive",
      imageURL: "",
    },
    unbonding_height: 52589910,
    unbonding_time: "2023-12-18 17:30:11.669 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-01-20 20:11:53.061 +0000 UTC",
    proposed: 20268,
    signed: 1674168,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 25455733,
        blockTimestamp: "2023-02-03 21:56:18.211 +0000 UTC",
        power: 100004,
        reason: "missing_signature",
        jailed: "injvalcons1ycsqvhadmyw9up40yk3m07ryfqa6rsaedgfde5",
      },
    ],
    uptime_percentage: 93.60906057235312,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/831756ce9a9a9e938788b766d3cc5305_360_360.jpg",
  },
  {
    id: "",
    moniker: "Psycho Node",
    operator_address: "injvaloper1ejnw6efrt7f6v6n0xhqlpxunlxmpllnusy2gf3",
    consensus_address: "injvalcons13pyllr0kaul7mmf5dkudgkzrqj643sqng50zfy",
    jailed: true,
    status: 1,
    tokens: "35928900110000000034",
    delegator_shares: "35968461773242946371.050319558736581592",
    description: {
      moniker: "Psycho Node",
      identity: "31B22D63A091E1DF",
      website: "",
      security_contact: "",
      details: "https://twitter.com/AltcoinPsycho",
      imageURL: "",
    },
    unbonding_height: 44835373,
    unbonding_time: "2023-10-03 21:09:48.556 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-01-27 04:26:38.731 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 26823375,
        blockTimestamp: "2023-02-20 14:55:30.414 +0000 UTC",
        power: 75000,
        reason: "missing_signature",
        jailed: "injvalcons13pyllr0kaul7mmf5dkudgkzrqj643sqng50zfy",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/397b1440e0d53f105c53715e62a8db05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Redelegate to Galaxy",
    operator_address: "injvaloper1c0g0fxq5mqgd8v6fc5z4kdtnqnae9trfreeah7",
    consensus_address: "injvalcons10putcl8vt50tfkujmtw9am5flwt5j08jedfuut",
    jailed: true,
    status: 1,
    tokens: "998801420000000000",
    delegator_shares: "1001002208593606867.108802393026917157",
    description: {
      moniker: "Redelegate to Galaxy",
      identity: "1718CFB46A0EC7FA",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 28916329,
    unbonding_time: "2023-04-08 07:13:10.737 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-02-21 19:13:21.529 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 28814136,
        blockTimestamp: "2023-03-17 01:30:20.082 +0000 UTC",
        power: 144831,
        reason: "missing_signature",
        jailed: "injvalcons10putcl8vt50tfkujmtw9am5flwt5j08jedfuut",
      },
      {
        blockNumber: 28916329,
        blockTimestamp: "2023-03-18 07:13:10.737 +0000 UTC",
        power: 144817,
        reason: "missing_signature",
        jailed: "injvalcons10putcl8vt50tfkujmtw9am5flwt5j08jedfuut",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/09f6187142c890cf9fb7b6bc0811e505_360_360.jpg",
  },
  {
    id: "",
    moniker: "Galaxy",
    operator_address: "injvaloper10er2rpmfux7dwyrqfgre90e4dqmsj5n06twg4l",
    consensus_address: "injvalcons1m0xku293exk0dvfljkangv4cjt28yyqct85pkq",
    jailed: true,
    status: 1,
    tokens: "11999862537836461",
    delegator_shares: "12631434084196822.963373435195430052",
    description: {
      moniker: "Galaxy",
      identity: "1718CFB46A0EC7FA",
      website: "www.galaxy.com",
      security_contact: "bci@galaxy.com",
      details:
        "Galaxy is a digital asset and blockchain leader helping institutions, startups, and qualified individuals shape a changing economy. We provide platform solutions custom-made for a digitally native ecosystem.",
      imageURL: "",
    },
    unbonding_height: 29445803,
    unbonding_time: "2023-04-14 20:33:56.296 +0000 UTC",
    commission_rate: "0.070000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-03-20 16:07:09.662 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 29445803,
        blockTimestamp: "2023-03-24 20:33:56.296 +0000 UTC",
        power: 40011,
        reason: "double_sign",
      },
      {
        blockNumber: 29445803,
        blockTimestamp: "2023-03-24 20:33:56.296 +0000 UTC",
        jailed: "injvalcons1m0xku293exk0dvfljkangv4cjt28yyqct85pkq",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/09f6187142c890cf9fb7b6bc0811e505_360_360.jpg",
  },
  {
    id: "",
    moniker: "DOUBLETOP",
    operator_address: "injvaloper1n5yluh6k9zqv74jw35r5s5zy808xpzfq4ea8jf",
    consensus_address: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
    jailed: true,
    status: 2,
    tokens: "10565743567780119390368",
    delegator_shares: "10737313955323594305887.146602761490090115",
    description: {
      moniker: "DOUBLETOP",
      identity: "48E37DA1F442F1F0",
      website: "https://doubletop.io",
      security_contact: "https://t.me/razumv",
      details: "",
      imageURL: "",
    },
    unbonding_height: 57071477,
    unbonding_time: "2024-02-01 16:13:28.867 +0000 UTC",
    commission_rate: "0.070000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-04-02 22:46:08.603 +0000 UTC",
    proposed: 0,
    signed: 4515,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 30804825,
        blockTimestamp: "2023-04-11 04:02:40.255 +0000 UTC",
        power: 20002,
        reason: "missing_signature",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/8d4df92e7b5f7ad654b3dd8a593dcf05_360_360.jpg",
  },
  {
    id: "",
    moniker: "SCV-Security",
    operator_address: "injvaloper19a77dzm2lrxt2gehqca3nyzq077kq7qsgvmrp4",
    consensus_address: "injvalcons1d3tqsnsulge3v2xgavw6d7r9jgj902t4mv9u5l",
    jailed: false,
    status: 3,
    tokens: "1679228708731503766092116",
    delegator_shares: "1679396648091448822737848.230297859118175097",
    description: {
      moniker: "SCV-Security",
      identity: "135E966C9A604B66",
      website: "https://scv.services",
      security_contact: "contact@scv.services",
      details:
        "Trusted security provider for projects building on Cosmos and CosmWasm. We combine security research with a realistic approach to whether minimize risks and increase security awareness.",
      imageURL: "",
    },
    unbonding_height: 33878902,
    unbonding_time: "2023-06-10 20:35:58.272 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-05-14 03:21:03.35 +0000 UTC",
    proposed: 60752,
    signed: 1750503,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 33878902,
        blockTimestamp: "2023-05-20 20:35:58.272 +0000 UTC",
        power: 321974,
        reason: "missing_signature",
        jailed: "injvalcons1d3tqsnsulge3v2xgavw6d7r9jgj902t4mv9u5l",
      },
    ],
    uptime_percentage: 99.80327189894388,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/a4d0c0f90c4ced2cf14c029323c76605_360_360.jpg",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1r4lvwh0pmkmjewa76rem7ndr0z8zg3pseyfpux",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 35593779,
        blockTimestamp: "2023-06-11 00:28:29.39 +0000 UTC",
        power: 282955,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1zsd39h38vysfgzzhpedakrr33n2574yw9eyjve",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1zy5ufk2aluhyup57j0y3ugm68s3lz9nfw8v30r",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 35634223,
        blockTimestamp: "2023-06-11 10:46:03.79 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-07T06:44:42.695298915Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: jD7I5JX1hx979Wp23bTSGNovk0FcFE/1iBflvO81Cho=\ndelegator_shares: "58831120841601962483270.769561771004748859"\ndescription:\n  details: Atomic Wallet is a decentralized non custodial app to manage, exchange\n    and stake over 300 blockchains and tokens. We have over 5,000,000 users across\n    various devices and support entire blockchain ecosystem since 2017\n  identity: E73AFD8985423B14\n  moniker: Atomic Nodes\n  security_contact: ""\n  website: atomicwallet.io\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1zy5ufk2aluhyup57j0y3ugm68s3lz9nfw8v30r\nstatus: BOND_STATUS_UNBONDING\ntokens: "58772289950936370340598"\nunbonding_height: "35609222"\nunbonding_ids:\n- "494"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-02T04:23:16.564006711Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Finoa-Consensus-Services",
    operator_address: "injvaloper18kcu6w92u4mq4dsudpcrufdfmtgu7q2r753t72",
    consensus_address: "injvalcons1r7kysfjcqkk4l4p2qvujspsque2jzhmk2kpe4g",
    jailed: false,
    status: 3,
    tokens: "300014795108943342193830",
    delegator_shares: "300645788785459720503465.823022460253094818",
    description: {
      moniker: "Finoa-Consensus-Services",
      identity: "0FA38A5EDF5FA362",
      website: "https://www.finoa.io/staking-delegation",
      security_contact: "",
      details:
        "Stake and grow your assets using Finoa's institutional-grade infrastructure.",
      imageURL: "",
    },
    unbonding_height: 54108220,
    unbonding_time: "2024-01-02 07:06:10.273 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-01-11 12:31:22.963 +0000 UTC",
    proposed: 11304,
    signed: 1613078,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36815249,
        blockTimestamp: "2023-06-24 08:43:32.797 +0000 UTC",
        power: 300147,
        reason: "missing_signature",
        jailed: "injvalcons1r7kysfjcqkk4l4p2qvujspsque2jzhmk2kpe4g",
      },
    ],
    uptime_percentage: 96.50870308884194,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/43e3a6846acfd91e9f2b002acf5af005_360_360.jpg",
  },
  {
    id: "",
    moniker: "White Whale",
    operator_address: "injvaloper16gdnrnl224ylje5z9vd0vn0msym7p58f00qauj",
    consensus_address: "injvalcons1zl4hkzrnenvrafm6vtp5l7z2jyx9708q48gl4l",
    jailed: false,
    status: 3,
    tokens: "534739645279188964494211",
    delegator_shares: "536400676927908755134651.715902356147866295",
    description: {
      moniker: "White Whale",
      identity: "CE2C5C02D96391AA",
      website: "https://whitewhale.money",
      security_contact: "info@whitewhale.money",
      details: "Interchain Liquidity Protocol",
      imageURL: "",
    },
    unbonding_height: 36816020,
    unbonding_time: "2023-07-15 08:57:04.059 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-01-10 18:11:29.52 +0000 UTC",
    proposed: 20069,
    signed: 1762485,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36816020,
        blockTimestamp: "2023-06-24 08:57:04.059 +0000 UTC",
        power: 574465,
        reason: "missing_signature",
        jailed: "injvalcons1zl4hkzrnenvrafm6vtp5l7z2jyx9708q48gl4l",
      },
    ],
    uptime_percentage: 99.29228591648973,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/513a589365eb3545b67d70c44eecb605_360_360.jpg",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1lsuqpgm8kgwpq96ewyew26xnfwyn3lh3ncrkrk",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36840250,
        blockTimestamp: "2023-06-24 15:14:54.597 +0000 UTC",
        power: 3136852,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1893nm5rlsl3pdcx26q0qhcskughcn2el3w335z",
      },
      {
        blockNumber: 36841021,
        blockTimestamp: "2023-06-24 15:26:45.568 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2021-06-30T13:00:00Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: fk3eHNOJCT5zrJEiuI2TglSe/vZsV2Y/gMwl5JANQPM=\ndelegator_shares: "3143449810127027529701662.417383899527863010"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Binance Staking\n  security_contact: poolvip@binance.com\n  website: https://www.binance.com/en/earn\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1lsuqpgm8kgwpq96ewyew26xnfwyn3lh3ncrkrk\nstatus: BOND_STATUS_UNBONDING\ntokens: "3133715589824183061778647"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1154"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        power: 3133716,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1893nm5rlsl3pdcx26q0qhcskughcn2el3w335z",
      },
      {
        blockNumber: 54201238,
        blockTimestamp: "2023-12-13 04:59:38.376 +0000 UTC",
        power: 4199070,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1893nm5rlsl3pdcx26q0qhcskughcn2el3w335z",
      },
      {
        blockNumber: 54202488,
        blockTimestamp: "2023-12-13 05:16:27.255 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2021-06-30T13:00:00Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: fk3eHNOJCT5zrJEiuI2TglSe/vZsV2Y/gMwl5JANQPM=\ndelegator_shares: "4216072841846103499905743.343816843164767082"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Binance Staking\n  security_contact: poolvip@binance.com\n  website: https://www.binance.com/en/earn\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1lsuqpgm8kgwpq96ewyew26xnfwyn3lh3ncrkrk\nstatus: BOND_STATUS_UNBONDING\ntokens: "4194615233845193484680189"\nunbonding_height: "54201239"\nunbonding_ids:\n- "1154"\n- "1183"\n- "23553"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-03T04:59:39.185971209Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1n5yluh6k9zqv74jw35r5s5zy808xpzfq4ea8jf",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36840250,
        blockTimestamp: "2023-06-24 15:14:54.597 +0000 UTC",
        power: 194866,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 36841021,
        blockTimestamp: "2023-06-24 15:26:45.568 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-04-02T22:46:08.603428210Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8jlHSQP50GWjo7ZlYvPPKRbGoFs8G/MsPF/veJAEwTI=\ndelegator_shares: "195471462714819013704834.197851523632478535"\ndescription:\n  details: ""\n  identity: 48E37DA1F442F1F0\n  moniker: DOUBLETOP\n  security_contact: https://t.me/razumv\n  website: https://doubletop.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1n5yluh6k9zqv74jw35r5s5zy808xpzfq4ea8jf\nstatus: BOND_STATUS_UNBONDING\ntokens: "194671289800000000000001"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1152"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 37185063,
        blockTimestamp: "2023-06-28 07:55:41.314 +0000 UTC",
        power: 194671,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 37232515,
        blockTimestamp: "2023-06-28 20:18:32.024 +0000 UTC",
        power: 194476,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 37477413,
        blockTimestamp: "2023-07-01 14:42:51.74 +0000 UTC",
        power: 194282,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 44668840,
        blockTimestamp: "2023-09-11 06:15:00.565 +0000 UTC",
        power: 194129,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 44670802,
        blockTimestamp: "2023-09-11 06:43:38.631 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-04-02T22:46:08.603428210Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8jlHSQP50GWjo7ZlYvPPKRbGoFs8G/MsPF/veJAEwTI=\ndelegator_shares: "195513168311270784019131.839901309041999293"\ndescription:\n  details: ""\n  identity: 48E37DA1F442F1F0\n  moniker: DOUBLETOP\n  security_contact: https://t.me/razumv\n  website: https://doubletop.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1n5yluh6k9zqv74jw35r5s5zy808xpzfq4ea8jf\nstatus: BOND_STATUS_UNBONDING\ntokens: "193935142192526307675197"\nunbonding_height: "44668841"\nunbonding_ids:\n- "1152"\n- "1372"\n- "1410"\n- "1553"\n- "6138"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-02T06:15:01.231055349Z"\n',
      },
      {
        blockNumber: 44718844,
        blockTimestamp: "2023-09-11 18:00:01.79 +0000 UTC",
        power: 193935,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 44768848,
        blockTimestamp: "2023-09-12 05:37:33.399 +0000 UTC",
        power: 193741,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 44818852,
        blockTimestamp: "2023-09-12 17:20:39.459 +0000 UTC",
        power: 193547,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 44860374,
        blockTimestamp: "2023-09-13 02:54:57.297 +0000 UTC",
        power: 193353,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 49896746,
        blockTimestamp: "2023-11-01 00:25:13.021 +0000 UTC",
        power: 193462,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 49942126,
        blockTimestamp: "2023-11-01 10:50:12.036 +0000 UTC",
        power: 193269,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 49943933,
        blockTimestamp: "2023-11-01 11:14:37.484 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-04-02T22:46:08.603428210Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8jlHSQP50GWjo7ZlYvPPKRbGoFs8G/MsPF/veJAEwTI=\ndelegator_shares: "195818974990485367572553.942852393878793320"\ndescription:\n  details: ""\n  identity: 48E37DA1F442F1F0\n  moniker: DOUBLETOP\n  security_contact: https://t.me/razumv\n  website: https://doubletop.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1n5yluh6k9zqv74jw35r5s5zy808xpzfq4ea8jf\nstatus: BOND_STATUS_UNBONDING\ntokens: "193075962112225659804140"\nunbonding_height: "49942127"\nunbonding_ids:\n- "1152"\n- "1372"\n- "1410"\n- "1553"\n- "6138"\n- "6163"\n- "6184"\n- "6211"\n- "6227"\n- "9542"\n- "9601"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T10:50:12.686250394Z"\n',
      },
      {
        blockNumber: 49970776,
        blockTimestamp: "2023-11-01 17:38:29.065 +0000 UTC",
        power: 193085,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 54024717,
        blockTimestamp: "2023-12-11 08:32:14.263 +0000 UTC",
        power: 194884,
        reason: "missing_valset_confirm",
        jailed: "injvalcons17v7v49lvvwn9gswdx6xnk2rml453q0vge5jzye",
      },
      {
        blockNumber: 54049719,
        blockTimestamp: "2023-12-11 14:14:35.044 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-04-02T22:46:08.603428210Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8jlHSQP50GWjo7ZlYvPPKRbGoFs8G/MsPF/veJAEwTI=\ndelegator_shares: "197838859346517170679958.994168884738969706"\ndescription:\n  details: ""\n  identity: 48E37DA1F442F1F0\n  moniker: DOUBLETOP\n  security_contact: https://t.me/razumv\n  website: https://doubletop.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1n5yluh6k9zqv74jw35r5s5zy808xpzfq4ea8jf\nstatus: BOND_STATUS_UNBONDING\ntokens: "194677613441770966928435"\nunbonding_height: "54024718"\nunbonding_ids:\n- "1152"\n- "1372"\n- "1410"\n- "1553"\n- "6138"\n- "6163"\n- "6184"\n- "6211"\n- "6227"\n- "9542"\n- "9601"\n- "9683"\n- "19163"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-01T08:32:14.980735176Z"\n',
      },
      {
        blockNumber: 54053053,
        blockTimestamp: "2023-12-11 15:07:53.34 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-04-02T22:46:08.603428210Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8jlHSQP50GWjo7ZlYvPPKRbGoFs8G/MsPF/veJAEwTI=\ndelegator_shares: "197838859346517170679958.994168884738969706"\ndescription:\n  details: ""\n  identity: 48E37DA1F442F1F0\n  moniker: DOUBLETOP\n  security_contact: https://t.me/razumv\n  website: https://doubletop.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1n5yluh6k9zqv74jw35r5s5zy808xpzfq4ea8jf\nstatus: BOND_STATUS_UNBONDING\ntokens: "194677613441770966928435"\nunbonding_height: "54024718"\nunbonding_ids:\n- "1152"\n- "1372"\n- "1410"\n- "1553"\n- "6138"\n- "6163"\n- "6184"\n- "6211"\n- "6227"\n- "9542"\n- "9601"\n- "9683"\n- "19163"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-01T08:32:14.980735176Z"\n',
      },
      {
        blockNumber: 57096478,
        blockTimestamp: "2024-01-11 22:40:57.246 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-04-02T22:46:08.603428210Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8jlHSQP50GWjo7ZlYvPPKRbGoFs8G/MsPF/veJAEwTI=\ndelegator_shares: "12987795924520680447752.801748961727763418"\ndescription:\n  details: ""\n  identity: 48E37DA1F442F1F0\n  moniker: DOUBLETOP\n  security_contact: https://t.me/razumv\n  website: https://doubletop.io\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1n5yluh6k9zqv74jw35r5s5zy808xpzfq4ea8jf\nstatus: BOND_STATUS_UNBONDING\ntokens: "12780265327075425666869"\nunbonding_height: "57071477"\nunbonding_ids:\n- "1152"\n- "1372"\n- "1410"\n- "1553"\n- "6138"\n- "6163"\n- "6184"\n- "6211"\n- "6227"\n- "9542"\n- "9601"\n- "9683"\n- "19163"\n- "57681"\n- "62322"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-02-01T16:13:28.867003056Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36840250,
        blockTimestamp: "2023-06-24 15:14:54.597 +0000 UTC",
        power: 126761,
        reason: "missing_valset_confirm",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 36841021,
        blockTimestamp: "2023-06-24 15:26:45.568 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36859585,
        blockTimestamp: "2023-06-24 20:17:53.829 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36865251,
        blockTimestamp: "2023-06-24 21:45:14.969 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36865252,
        blockTimestamp: "2023-06-24 21:45:15.774 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36866023,
        blockTimestamp: "2023-06-24 21:56:57.016 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36866225,
        blockTimestamp: "2023-06-24 22:00:03.187 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36867213,
        blockTimestamp: "2023-06-24 22:15:24.498 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36879664,
        blockTimestamp: "2023-06-25 01:29:08.868 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36881096,
        blockTimestamp: "2023-06-25 01:51:19.859 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36881709,
        blockTimestamp: "2023-06-25 02:00:51.784 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127639903648238876424557.722188910896475293"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126635189943834760247157"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1150"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 38092133,
        blockTimestamp: "2023-07-08 04:30:43.258 +0000 UTC",
        power: 126489,
        reason: "missing_valset_confirm",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 38094019,
        blockTimestamp: "2023-07-08 04:59:46.39 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127492776133793655516272.364873663020720133"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126362731539242594430765"\nunbonding_height: "38092134"\nunbonding_ids:\n- "1150"\n- "1915"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-29T04:30:44.036326087Z"\n',
      },
      {
        blockNumber: 38117135,
        blockTimestamp: "2023-07-08 10:59:46.797 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127492776133793655516272.364873663020720133"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126362731539242594430765"\nunbonding_height: "38092134"\nunbonding_ids:\n- "1150"\n- "1915"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-29T04:30:44.036326087Z"\n',
      },
      {
        blockNumber: 42777127,
        blockTimestamp: "2023-08-23 23:57:12.33 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127905364895148786913663.544801300899238862"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126746310377295467246640"\nunbonding_height: "42745324"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-13T16:41:46.634992823Z"\n',
      },
      {
        blockNumber: 42777742,
        blockTimestamp: "2023-08-24 00:05:30.653 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127905364895148786913663.544801300899238862"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126746310377295467246640"\nunbonding_height: "42745324"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-13T16:41:46.634992823Z"\n',
      },
      {
        blockNumber: 42779882,
        blockTimestamp: "2023-08-24 00:34:25.069 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127905364895148786913663.544801300899238862"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126746310377295467246640"\nunbonding_height: "42745324"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-13T16:41:46.634992823Z"\n',
      },
      {
        blockNumber: 44668840,
        blockTimestamp: "2023-09-11 06:15:00.565 +0000 UTC",
        power: 126752,
        reason: "missing_valset_confirm",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 44670802,
        blockTimestamp: "2023-09-11 06:43:38.631 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127911431872968185295168.931678542926240095"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126625570377295467246640"\nunbonding_height: "44668841"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-02T06:15:01.231055349Z"\n',
      },
      {
        blockNumber: 44693842,
        blockTimestamp: "2023-09-11 12:08:00.023 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127911431872968185295168.931678542926240095"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126625570377295467246640"\nunbonding_height: "44668841"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-02T06:15:01.231055349Z"\n',
      },
      {
        blockNumber: 44704005,
        blockTimestamp: "2023-09-11 14:30:40.746 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "127911431872968185295168.931678542926240095"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126625570377295467246640"\nunbonding_height: "44668841"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-02T06:15:01.231055349Z"\n',
      },
      {
        blockNumber: 46474094,
        blockTimestamp: "2023-09-28 14:52:36.476 +0000 UTC",
        power: 102135,
        reason: "missing_valset_confirm",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 46482839,
        blockTimestamp: "2023-09-28 16:56:23.534 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "103172759013286868222913.846260518490390224"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "102033454181983130491565"\nunbonding_height: "46474095"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-19T14:52:37.218848888Z"\n',
      },
      {
        blockNumber: 46499096,
        blockTimestamp: "2023-09-28 20:40:20.059 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "103172759013286868222913.846260518490390224"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "102033454181983130491565"\nunbonding_height: "46474095"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-19T14:52:37.218848888Z"\n',
      },
      {
        blockNumber: 46515751,
        blockTimestamp: "2023-09-29 00:27:53.034 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "103172759013286868222913.846260518490390224"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "102033454181983130491565"\nunbonding_height: "46474095"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-19T14:52:37.218848888Z"\n',
      },
      {
        blockNumber: 51226210,
        blockTimestamp: "2023-11-14 01:37:39.836 +0000 UTC",
        power: 127969,
        reason: "missing_valset_confirm",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 51227577,
        blockTimestamp: "2023-11-14 01:56:15.472 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "129398196718588596581837.568713743567138391"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "127841323499166745236055"\nunbonding_height: "51226211"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-05T01:37:40.529787056Z"\n',
      },
      {
        blockNumber: 51251212,
        blockTimestamp: "2023-11-14 07:17:52.206 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "129398196718588596581837.568713743567138391"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "127841323499166745236055"\nunbonding_height: "51226211"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-05T01:37:40.529787056Z"\n',
      },
      {
        blockNumber: 52768442,
        blockTimestamp: "2023-11-29 10:51:39.309 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "129230282795819290901261.470002448604276339"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "127675429857175355889914"\nunbonding_height: "52743441"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\n- "13772"\n- "13796"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-20T05:07:21.850966492Z"\n',
      },
      {
        blockNumber: 53295826,
        blockTimestamp: "2023-12-04 10:00:42.575 +0000 UTC",
        power: 127732,
        reason: "missing_valset_confirm",
        jailed: "injvalcons142jknlfh5v34aknskngqs0pfjxqtsqczujvq9w",
      },
      {
        blockNumber: 53296274,
        blockTimestamp: "2023-12-04 10:06:41.07 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "129287552556948569719480.036337880382369016"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "127604278568846095752681"\nunbonding_height: "53295827"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\n- "13772"\n- "13796"\n- "14582"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-25T10:00:43.254775225Z"\n',
      },
      {
        blockNumber: 53320828,
        blockTimestamp: "2023-12-04 15:49:59.979 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "129287552556948569719480.036337880382369016"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "127604278568846095752681"\nunbonding_height: "53295827"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\n- "13772"\n- "13796"\n- "14582"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-25T10:00:43.254775225Z"\n',
      },
      {
        blockNumber: 54782173,
        blockTimestamp: "2023-12-18 17:31:31.612 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "129272720429886949829147.192595683182149649"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "127576880650347888786979"\nunbonding_height: "54757172"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\n- "13772"\n- "13796"\n- "14582"\n- "31650"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-08T11:45:23.854687585Z"\n',
      },
      {
        blockNumber: 54927299,
        blockTimestamp: "2023-12-20 06:01:27.331 +0000 UTC",
        power: 126926,
        reason: "missing_batch_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "128613757252240443484186.430035176054792882"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_BONDED\ntokens: "126926561956751754860551"\nunbonding_height: "54757172"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\n- "13772"\n- "13796"\n- "14582"\n- "31650"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-08T11:45:23.854687585Z"\n',
      },
      {
        blockNumber: 54952301,
        blockTimestamp: "2023-12-20 12:17:53.514 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "128592977244104239220686.848217252659780439"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126779149054548272733440"\nunbonding_height: "54927300"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\n- "13772"\n- "13796"\n- "14582"\n- "31650"\n- "34575"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-10T06:01:28.857405645Z"\n',
      },
      {
        blockNumber: 54972117,
        blockTimestamp: "2023-12-20 17:16:22.568 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "128592977244104239220686.848217252659780439"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126779149054548272733440"\nunbonding_height: "54927300"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\n- "13772"\n- "13796"\n- "14582"\n- "31650"\n- "34575"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-10T06:01:28.857405645Z"\n',
      },
      {
        blockNumber: 54972350,
        blockTimestamp: "2023-12-20 17:19:53.507 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-02-26T20:36:59.043660533Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Jpfj6fN84IuZrMScLeG86SKUvILuMcOkrkgEaxQlKfs=\ndelegator_shares: "128592977244104239220686.848217252659780439"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Innovating Capital\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1rqqpyuka5dxulzjslnzjld2ltcw5095rr0jz07\nstatus: BOND_STATUS_UNBONDING\ntokens: "126779149054548272733440"\nunbonding_height: "54927300"\nunbonding_ids:\n- "1150"\n- "1915"\n- "4565"\n- "4900"\n- "6137"\n- "6826"\n- "6850"\n- "12013"\n- "12117"\n- "13772"\n- "13796"\n- "14582"\n- "31650"\n- "34575"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-10T06:01:28.857405645Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36840250,
        blockTimestamp: "2023-06-24 15:14:54.597 +0000 UTC",
        power: 102726,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1wuy03c925yr93zmthf9f0spla8hxks4w8yctr4",
      },
      {
        blockNumber: 36841021,
        blockTimestamp: "2023-06-24 15:26:45.568 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726226663078698712386.000000000000000000"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102623500663078698712386"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1153"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        power: 102623,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1wuy03c925yr93zmthf9f0spla8hxks4w8yctr4",
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726228854966300510386.945817816290660529"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879852774417744939"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726228854966300510386.945817816290660529"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879852774417744939"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726228854966300510386.945817816290660529"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879852774417744939"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36879664,
        blockTimestamp: "2023-06-25 01:29:08.868 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36881096,
        blockTimestamp: "2023-06-25 01:51:19.859 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36881709,
        blockTimestamp: "2023-06-25 02:00:51.784 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36891025,
        blockTimestamp: "2023-06-25 04:25:30.693 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36893445,
        blockTimestamp: "2023-06-25 05:03:11.109 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36894292,
        blockTimestamp: "2023-06-25 05:16:00.778 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36894293,
        blockTimestamp: "2023-06-25 05:16:02.4 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36894688,
        blockTimestamp: "2023-06-25 05:22:05.107 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726229001756090920837.706937558711544623"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879999270776406471"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 36904372,
        blockTimestamp: "2023-06-25 07:53:27.905 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-04-27T16:28:17.145861747Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: HWSVoEwWeQVbRI2aCeEwoCIKsAbRHPvui7D4BSbWBgw=\ndelegator_shares: "102726228001756090920838.196851944436533428"\ndescription:\n  details: "WhisperNode operates robust, high up-time validators across the Cosmos\n    ecosystem. Auto-compound your rewards with our REStake app. Join our Discord:\n    https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode \\U0001F426"\n  identity: 9C7571030BEF5157\n  moniker: " WhisperNode\\U0001F910"\n  security_contact: security@whispernode.com\n  website: https://REStake.WhisperNode.com/injective\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564\nstatus: BOND_STATUS_UNBONDING\ntokens: "102520879001269769307125"\nunbonding_height: "36868444"\nunbonding_ids:\n- "1153"\n- "1181"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:34:42.021315023Z"\n',
      },
      {
        blockNumber: 51299075,
        blockTimestamp: "2023-11-14 18:56:06.257 +0000 UTC",
        power: 177338,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1wuy03c925yr93zmthf9f0spla8hxks4w8yctr4",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36840250,
        blockTimestamp: "2023-06-24 15:14:54.597 +0000 UTC",
        power: 100354,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1p44qwx5ru3sfga2txh37gt3h0qxps47ntnrsuf",
      },
      {
        blockNumber: 36841021,
        blockTimestamp: "2023-06-24 15:26:45.568 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36859585,
        blockTimestamp: "2023-06-24 20:17:53.829 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36865251,
        blockTimestamp: "2023-06-24 21:45:14.969 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36865252,
        blockTimestamp: "2023-06-24 21:45:15.774 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36866023,
        blockTimestamp: "2023-06-24 21:56:57.016 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36866225,
        blockTimestamp: "2023-06-24 22:00:03.187 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36867213,
        blockTimestamp: "2023-06-24 22:15:24.498 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36879664,
        blockTimestamp: "2023-06-25 01:29:08.868 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36881096,
        blockTimestamp: "2023-06-25 01:51:19.859 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 36881709,
        blockTimestamp: "2023-06-25 02:00:51.784 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "100454808009195594555392.475116616302063972"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "100254074000000999999999"\nunbonding_height: "36840251"\nunbonding_ids:\n- "1151"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:14:55.423094749Z"\n',
      },
      {
        blockNumber: 46449092,
        blockTimestamp: "2023-09-28 09:06:02.414 +0000 UTC",
        power: 125272,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1p44qwx5ru3sfga2txh37gt3h0qxps47ntnrsuf",
      },
      {
        blockNumber: 46474094,
        blockTimestamp: "2023-09-28 14:52:36.476 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "125535509936989454106756.750868697733158500"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "125146857930237978659429"\nunbonding_height: "46449093"\nunbonding_ids:\n- "1151"\n- "2223"\n- "4891"\n- "6129"\n- "6521"\n- "6841"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-19T09:06:03.130571948Z"\n',
      },
      {
        blockNumber: 46482839,
        blockTimestamp: "2023-09-28 16:56:23.534 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "125535509936989454106756.750868697733158500"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "125146857930237978659429"\nunbonding_height: "46449093"\nunbonding_ids:\n- "1151"\n- "2223"\n- "4891"\n- "6129"\n- "6521"\n- "6841"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-19T09:06:03.130571948Z"\n',
      },
      {
        blockNumber: 52615463,
        blockTimestamp: "2023-11-27 23:29:48.274 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "25303349181600037750.284443856387848998"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "25225011208209892787"\nunbonding_height: "52590462"\nunbonding_ids:\n- "1151"\n- "2223"\n- "4891"\n- "6129"\n- "6521"\n- "6841"\n- "7276"\n- "10929"\n- "12076"\n- "13544"\n- "13620"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T17:38:15.798945011Z"\n',
      },
      {
        blockNumber: 52615603,
        blockTimestamp: "2023-11-27 23:31:45.072 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "25303349181600037750.284443856387848998"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "25225011208209892787"\nunbonding_height: "52590462"\nunbonding_ids:\n- "1151"\n- "2223"\n- "4891"\n- "6129"\n- "6521"\n- "6841"\n- "7276"\n- "10929"\n- "12076"\n- "13544"\n- "13620"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T17:38:15.798945011Z"\n',
      },
      {
        blockNumber: 52639912,
        blockTimestamp: "2023-11-28 05:09:24.944 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2023-03-03T10:17:02.817377340Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: hJgKwhceKRluZpE7N8SJ+NCc8cDqqf0isnN4UV6CtS4=\ndelegator_shares: "25303349181600037750.284443856387848998"\ndescription:\n  details: Safe and secure validator in the interchain\n  identity: F7E5A47BC1D9F95B\n  moniker: nysa.network\n  security_contact: security@nysa.network\n  website: https://nysa.network\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp\nstatus: BOND_STATUS_UNBONDING\ntokens: "25225011208209892787"\nunbonding_height: "52590462"\nunbonding_ids:\n- "1151"\n- "2223"\n- "4891"\n- "6129"\n- "6521"\n- "6841"\n- "7276"\n- "10929"\n- "12076"\n- "13544"\n- "13620"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T17:38:15.798945011Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper16gdnrnl224ylje5z9vd0vn0msym7p58f00qauj",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36840250,
        blockTimestamp: "2023-06-24 15:14:54.597 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-01-10T18:11:29.520165253Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: r3UliOfRj6OCAdtJvnAH/XF3KSrRepEMqKGlM0PbtKQ=\ndelegator_shares: "576155777583469804226699.083868973978455028"\ndescription:\n  details: Interchain Liquidity Protocol\n  identity: CE2C5C02D96391AA\n  moniker: White Whale\n  security_contact: info@whitewhale.money\n  website: https://whitewhale.money\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16gdnrnl224ylje5z9vd0vn0msym7p58f00qauj\nstatus: BOND_STATUS_UNBONDING\ntokens: "574371639303406588362381"\nunbonding_height: "36816020"\nunbonding_ids:\n- "1140"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T08:57:04.059454614Z"\n',
      },
      {
        blockNumber: 36841021,
        blockTimestamp: "2023-06-24 15:26:45.568 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-01-10T18:11:29.520165253Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: r3UliOfRj6OCAdtJvnAH/XF3KSrRepEMqKGlM0PbtKQ=\ndelegator_shares: "576155777583469804226699.083868973978455028"\ndescription:\n  details: Interchain Liquidity Protocol\n  identity: CE2C5C02D96391AA\n  moniker: White Whale\n  security_contact: info@whitewhale.money\n  website: https://whitewhale.money\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16gdnrnl224ylje5z9vd0vn0msym7p58f00qauj\nstatus: BOND_STATUS_UNBONDING\ntokens: "574371639303406588362381"\nunbonding_height: "36816020"\nunbonding_ids:\n- "1140"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T08:57:04.059454614Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36841021,
        blockTimestamp: "2023-06-24 15:26:45.568 +0000 UTC",
        power: 15619,
        reason: "missing_valset_confirm",
        jailed: "injvalcons10kmmtmd85u78typdnsk72tn0s56y69lvcwpdpu",
      },
      {
        blockNumber: 36859585,
        blockTimestamp: "2023-06-24 20:17:53.829 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36865251,
        blockTimestamp: "2023-06-24 21:45:14.969 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36865252,
        blockTimestamp: "2023-06-24 21:45:15.774 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36866023,
        blockTimestamp: "2023-06-24 21:56:57.016 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36866225,
        blockTimestamp: "2023-06-24 22:00:03.187 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36867213,
        blockTimestamp: "2023-06-24 22:15:24.498 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15653353213490068647654.931298876926701818"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15606441997890907469919"\nunbonding_height: "36841022"\nunbonding_ids:\n- "1156"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:26:46.384211844Z"\n',
      },
      {
        blockNumber: 36966227,
        blockTimestamp: "2023-06-25 23:44:56.643 +0000 UTC",
        power: 15610,
        reason: "missing_valset_confirm",
        jailed: "injvalcons10kmmtmd85u78typdnsk72tn0s56y69lvcwpdpu",
      },
      {
        blockNumber: 36982439,
        blockTimestamp: "2023-06-26 03:51:09.539 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15657702572681756595279.232513130316977245"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15595168322576571262264"\nunbonding_height: "36966228"\nunbonding_ids:\n- "1156"\n- "1232"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T23:44:57.446315046Z"\n',
      },
      {
        blockNumber: 36982784,
        blockTimestamp: "2023-06-26 03:56:14.631 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15657702572681756595279.232513130316977245"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15595168322576571262264"\nunbonding_height: "36966228"\nunbonding_ids:\n- "1156"\n- "1232"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T23:44:57.446315046Z"\n',
      },
      {
        blockNumber: 36984829,
        blockTimestamp: "2023-06-26 04:26:38.677 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15657702572681756595279.232513130316977245"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15595168322576571262264"\nunbonding_height: "36966228"\nunbonding_ids:\n- "1156"\n- "1232"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T23:44:57.446315046Z"\n',
      },
      {
        blockNumber: 36991229,
        blockTimestamp: "2023-06-26 06:01:34.896 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-30T03:56:09.711469098Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: O1WgDtw1ZeXVQufSv1tt6eyJ/EpWy7I3JsGFragpicw=\ndelegator_shares: "15657702572681756595279.232513130316977245"\ndescription:\n  details: Vault Staking is a trusted validator serving the Cosmos community. Our\n    team members are located around the world, always available to answer questions\n    and keep the highest uptime.\n  identity: 1F3068FC9F81343C\n  moniker: Vault Staking\n  security_contact: hello@vaultstaking.com\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh\nstatus: BOND_STATUS_UNBONDING\ntokens: "15595168322576571262264"\nunbonding_height: "36966228"\nunbonding_ids:\n- "1156"\n- "1232"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T23:44:57.446315046Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Forbole",
    operator_address: "injvaloper12s9d7l53ef2c8avrn7pgd6dfeeg2yzel58ztfx",
    consensus_address: "injvalcons1ylv842shhp402hr7dmz0kcmexll088aj6rdcyg",
    jailed: false,
    status: 3,
    tokens: "312004433746039601668767",
    delegator_shares: "312347984586589580585799.564932871718654062",
    description: {
      moniker: "Forbole",
      identity: "2861F5EE06627224",
      website: "https://forbole.com",
      security_contact: "",
      details: "Cobuilding the Interchain",
      imageURL: "",
    },
    unbonding_height: 46424091,
    unbonding_time: "2023-10-19 03:23:44.064 +0000 UTC",
    commission_rate: "0.095000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 11715,
    signed: 1680789,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36854663,
        blockTimestamp: "2023-06-24 19:01:02.336 +0000 UTC",
        power: 306138,
        reason: "missing_signature",
        jailed: "injvalcons1ylv842shhp402hr7dmz0kcmexll088aj6rdcyg",
      },
    ],
    uptime_percentage: 96.5180638019523,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/f5b0771af36b2e3d6a196a29751e1f05_360_360.jpeg",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36866023,
        blockTimestamp: "2023-06-24 21:56:57.016 +0000 UTC",
        power: 671,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1hczu528gc6egx55lvp6fps9knwhzz2u97sxycp",
      },
      {
        blockNumber: 36866225,
        blockTimestamp: "2023-06-24 22:00:03.187 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36867213,
        blockTimestamp: "2023-06-24 22:15:24.498 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36879664,
        blockTimestamp: "2023-06-25 01:29:08.868 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36881096,
        blockTimestamp: "2023-06-25 01:51:19.859 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36881709,
        blockTimestamp: "2023-06-25 02:00:51.784 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36891025,
        blockTimestamp: "2023-06-25 04:25:30.693 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36893445,
        blockTimestamp: "2023-06-25 05:03:11.109 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36894292,
        blockTimestamp: "2023-06-25 05:16:00.778 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36894293,
        blockTimestamp: "2023-06-25 05:16:02.4 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36894688,
        blockTimestamp: "2023-06-25 05:22:05.107 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
      {
        blockNumber: 36904372,
        blockTimestamp: "2023-06-25 07:53:27.905 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-06T10:00:22.889934167Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: okOdNNQBwwCx/GZTcA3bgAH5cfUqmhhVXYppMPkK+v4=\ndelegator_shares: "672615121550151255805.349954494412280502"\ndescription:\n  details: Your trusted blockchain infrastructure provider.\n  identity: E903E2F1EA108F5D\n  moniker: Matrixed.Link\n  security_contact: validator@matrixed.link\n  website: https://matrixedlink.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1cwzl3q4h5v8yvzlp64h7fwnduzut3f4nst2gdp\nstatus: BOND_STATUS_UNBONDING\ntokens: "670532453269462894088"\nunbonding_height: "36866024"\nunbonding_ids:\n- "1148"\n- "1180"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T21:56:57.844665338Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36866023,
        blockTimestamp: "2023-06-24 21:56:57.016 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36866225,
        blockTimestamp: "2023-06-24 22:00:03.187 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36867213,
        blockTimestamp: "2023-06-24 22:15:24.498 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36879664,
        blockTimestamp: "2023-06-25 01:29:08.868 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36881096,
        blockTimestamp: "2023-06-25 01:51:19.859 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36881709,
        blockTimestamp: "2023-06-25 02:00:51.784 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
      {
        blockNumber: 36891025,
        blockTimestamp: "2023-06-25 04:25:30.693 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T06:01:15.786498057Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: JuqxFrTR6iVzdiQ7YMSK7kSg/5JJy/eaclVBiOrHGCM=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: 5307DE5B18FEA0AA\n  moniker: NFTSwitch.xyz w/ Reality Flux\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36842212"\nunbonding_ids:\n- "1161"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T15:45:13.941417499Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36866023,
        blockTimestamp: "2023-06-24 21:56:57.016 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36866225,
        blockTimestamp: "2023-06-24 22:00:03.187 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36867213,
        blockTimestamp: "2023-06-24 22:15:24.498 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36879664,
        blockTimestamp: "2023-06-25 01:29:08.868 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36881096,
        blockTimestamp: "2023-06-25 01:51:19.859 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36881709,
        blockTimestamp: "2023-06-25 02:00:51.784 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 36891025,
        blockTimestamp: "2023-06-25 04:25:30.693 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2000000000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000001000000"\nunbonding_height: "36843442"\nunbonding_ids:\n- "1164"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:04:51.551231607Z"\n',
      },
      {
        blockNumber: 37657928,
        blockTimestamp: "2023-07-03 14:35:46.809 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "38490100000001000000.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "38490100000001000000"\nunbonding_height: "37632927"\nunbonding_ids:\n- "1164"\n- "1644"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-24T08:21:13.233343818Z"\n',
      },
      {
        blockNumber: 37815761,
        blockTimestamp: "2023-07-05 06:05:13.461 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "1488983837014868212202.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "1488983837014868212202"\nunbonding_height: "37790760"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-25T23:53:50.994251156Z"\n',
      },
      {
        blockNumber: 37916021,
        blockTimestamp: "2023-07-06 07:14:01.356 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "2523253837014868212202.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "2523253837014868212202"\nunbonding_height: "37891020"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-27T01:00:08.717943521Z"\n',
      },
      {
        blockNumber: 38644697,
        blockTimestamp: "2023-07-14 00:14:13.011 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "7206479382701204945205.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "7206479382701204945205"\nunbonding_height: "38604635"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-08-03T13:43:23.575673963Z"\n',
      },
      {
        blockNumber: 42342342,
        blockTimestamp: "2023-08-19 20:21:43.494 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "6766005746129079089657.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "6766005746129079089657"\nunbonding_height: "42317341"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-09T14:23:14.860325896Z"\n',
      },
      {
        blockNumber: 44793850,
        blockTimestamp: "2023-09-12 11:26:52.028 +0000 UTC",
        power: 6622,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1czqysjwd5px0v4p9lcwr4dteej8rhyrwt32rt5",
      },
      {
        blockNumber: 44802370,
        blockTimestamp: "2023-09-12 13:26:12.861 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "6622005836129079089658.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "6615383836129079089658"\nunbonding_height: "44793851"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-03T11:26:52.959938828Z"\n',
      },
      {
        blockNumber: 44818852,
        blockTimestamp: "2023-09-12 17:20:39.459 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "6622005836129079089658.000000000000000000"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "6615383836129079089658"\nunbonding_height: "44793851"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-03T11:26:52.959938828Z"\n',
      },
      {
        blockNumber: 44885376,
        blockTimestamp: "2023-09-13 08:34:03.666 +0000 UTC",
        power: 6615,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1czqysjwd5px0v4p9lcwr4dteej8rhyrwt32rt5",
      },
      {
        blockNumber: 49923403,
        blockTimestamp: "2023-11-01 06:33:28.175 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "246156636543497868464.336862484751187687"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "245664594319190688102"\nunbonding_height: "49898402"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\n- "6231"\n- "6302"\n- "9545"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T00:47:59.424485342Z"\n',
      },
      {
        blockNumber: 49942126,
        blockTimestamp: "2023-11-01 10:50:12.036 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "246156636543497868464.336862484751187687"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "245664594319190688102"\nunbonding_height: "49898402"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\n- "6231"\n- "6302"\n- "9545"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T00:47:59.424485342Z"\n',
      },
      {
        blockNumber: 49943933,
        blockTimestamp: "2023-11-01 11:14:37.484 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "246156636543497868464.336862484751187687"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "245664594319190688102"\nunbonding_height: "49898402"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\n- "6231"\n- "6302"\n- "9545"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T00:47:59.424485342Z"\n',
      },
      {
        blockNumber: 49946750,
        blockTimestamp: "2023-11-01 11:52:50.525 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "246156636543497868464.336862484751187687"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "245664594319190688102"\nunbonding_height: "49898402"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\n- "6231"\n- "6302"\n- "9545"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T00:47:59.424485342Z"\n',
      },
      {
        blockNumber: 50063266,
        blockTimestamp: "2023-11-02 15:58:44.733 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "246186696630572158201.735005941278391776"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "245694594319190688102"\nunbonding_height: "50038265"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\n- "6231"\n- "6302"\n- "9545"\n- "9831"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-23T09:46:05.501857182Z"\n',
      },
      {
        blockNumber: 52704067,
        blockTimestamp: "2023-11-28 20:11:40.336 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "273700445848408652887.941629628102701315"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "273153346334618699474"\nunbonding_height: "52679066"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\n- "6231"\n- "6302"\n- "9545"\n- "9831"\n- "13621"\n- "13675"\n- "13732"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-19T14:21:07.192265652Z"\n',
      },
      {
        blockNumber: 57473439,
        blockTimestamp: "2024-01-15 20:31:20.435 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "606859007656140908387.380875366121625066"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "605645957867352416042"\nunbonding_height: "57448438"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\n- "6231"\n- "6302"\n- "9545"\n- "9831"\n- "13621"\n- "13675"\n- "13732"\n- "36333"\n- "66600"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-02-05T14:08:51.015130100Z"\n',
      },
      {
        blockNumber: 57474456,
        blockTimestamp: "2024-01-15 20:45:38.454 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-31T12:48:54.375251416Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W5naMaQvcUJJXx/GW+GSHuI2xHbtD+QgrgwPtDB2Plw=\ndelegator_shares: "606859007656140908387.380875366121625066"\ndescription:\n  details: Secure & Robust Validator. Autocompound available on Restake, support builders,\n    support us.\n  identity: EC771B7A05CDF1D4\n  moniker: \'5.0 Validator | Airdrop \'\n  security_contact: 5.0validator@protonmail.com\n  website: https://twitter.com/5_0Validator\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz\nstatus: BOND_STATUS_UNBONDING\ntokens: "605645957867352416042"\nunbonding_height: "57448438"\nunbonding_ids:\n- "1164"\n- "1644"\n- "1781"\n- "1821"\n- "1886"\n- "1911"\n- "2190"\n- "4594"\n- "6164"\n- "6194"\n- "6231"\n- "6302"\n- "9545"\n- "9831"\n- "13621"\n- "13675"\n- "13732"\n- "36333"\n- "66600"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-02-05T14:08:51.015130100Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36866023,
        blockTimestamp: "2023-06-24 21:56:57.016 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36866225,
        blockTimestamp: "2023-06-24 22:00:03.187 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36867213,
        blockTimestamp: "2023-06-24 22:15:24.498 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36879664,
        blockTimestamp: "2023-06-25 01:29:08.868 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36881096,
        blockTimestamp: "2023-06-25 01:51:19.859 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36881709,
        blockTimestamp: "2023-06-25 02:00:51.784 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36891025,
        blockTimestamp: "2023-06-25 04:25:30.693 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
      {
        blockNumber: 36893445,
        blockTimestamp: "2023-06-25 05:03:11.109 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-07T15:48:28.319948151Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 7UrUjcNIJH2cuYFTj0ylbTXCjWLGT+WTwpZV2cmbCkU=\ndelegator_shares: "3100000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: ContributionDAO\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "100000000000000000"\noperator_address: injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na\nstatus: BOND_STATUS_UNBONDING\ntokens: "3100000000000000000"\nunbonding_height: "36843844"\nunbonding_ids:\n- "1165"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:11:27.486645868Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36866023,
        blockTimestamp: "2023-06-24 21:56:57.016 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36866225,
        blockTimestamp: "2023-06-24 22:00:03.187 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36867213,
        blockTimestamp: "2023-06-24 22:15:24.498 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36868443,
        blockTimestamp: "2023-06-24 22:34:41.225 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36868845,
        blockTimestamp: "2023-06-24 22:40:47.882 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36869291,
        blockTimestamp: "2023-06-24 22:47:38.619 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36869725,
        blockTimestamp: "2023-06-24 22:54:15.239 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36870534,
        blockTimestamp: "2023-06-24 23:06:42.029 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36870820,
        blockTimestamp: "2023-06-24 23:11:06.652 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36878294,
        blockTimestamp: "2023-06-25 01:07:29.6 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36879664,
        blockTimestamp: "2023-06-25 01:29:08.868 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36881096,
        blockTimestamp: "2023-06-25 01:51:19.859 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36881709,
        blockTimestamp: "2023-06-25 02:00:51.784 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36891025,
        blockTimestamp: "2023-06-25 04:25:30.693 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36893445,
        blockTimestamp: "2023-06-25 05:03:11.109 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36894292,
        blockTimestamp: "2023-06-25 05:16:00.778 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36894293,
        blockTimestamp: "2023-06-25 05:16:02.4 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 36894688,
        blockTimestamp: "2023-06-25 05:22:05.107 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "93058116563501073121.716899729136883156"\ndescription:\n  details: ""\n  identity: 4835993E8ED0B96A\n  moniker: Experimental Validator\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "93002299975800403238"\nunbonding_height: "36845819"\nunbonding_ids:\n- "1171"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T16:42:38.528444457Z"\n',
      },
      {
        blockNumber: 37502713,
        blockTimestamp: "2023-07-01 21:52:33.067 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "671545095100823935322.548172091938730846"\ndescription:\n  details: ""\n  identity: CE33078D690BAC6A\n  moniker: Node X\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "671142299975800403238"\nunbonding_height: "37479603"\nunbonding_ids:\n- "1171"\n- "1401"\n- "1404"\n- "1543"\n- "1557"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-22T15:21:24.990976817Z"\n',
      },
      {
        blockNumber: 37504604,
        blockTimestamp: "2023-07-01 22:22:13.071 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "671545095100823935322.548172091938730846"\ndescription:\n  details: ""\n  identity: CE33078D690BAC6A\n  moniker: Node X\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "671142299975800403238"\nunbonding_height: "37479603"\nunbonding_ids:\n- "1171"\n- "1401"\n- "1404"\n- "1543"\n- "1557"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-22T15:21:24.990976817Z"\n',
      },
      {
        blockNumber: 49969172,
        blockTimestamp: "2023-11-01 17:16:08.153 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "170204385196758574419.020590390589493195"\ndescription:\n  details: ""\n  identity: CE33078D690BAC6A\n  moniker: Node X\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "170102296004066922419"\nunbonding_height: "49944171"\nunbonding_ids:\n- "1171"\n- "1401"\n- "1404"\n- "1543"\n- "1557"\n- "9603"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T11:17:48.358849046Z"\n',
      },
      {
        blockNumber: 49970776,
        blockTimestamp: "2023-11-01 17:38:29.065 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-09-20T08:18:23.666339195Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: zrs8XTydF2YHouJep/aKP4XEyRExK1HzKaKJ6TbdD1Y=\ndelegator_shares: "170204385196758574419.020590390589493195"\ndescription:\n  details: ""\n  identity: CE33078D690BAC6A\n  moniker: Node X\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1zjjw8p4llg4uee4hqwe04kat4haf734wegcr24\nstatus: BOND_STATUS_UNBONDING\ntokens: "170102296004066922419"\nunbonding_height: "49944171"\nunbonding_ids:\n- "1171"\n- "1401"\n- "1404"\n- "1543"\n- "1557"\n- "9603"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T11:17:48.358849046Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1wh9a7f05yf4nqkmh7d9lyletzhefaa53txynrj",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36894292,
        blockTimestamp: "2023-06-25 05:16:00.778 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "1.000000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "1.000000000000000000"\n  update_time: "2023-05-11T10:32:21.967091817Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: CHqpLFZ9TBYabsMpKJ+PRKTnJisQT5c6Q/k/9ZezIms=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: E83C7B8E58DC11E0\n  moniker: Hype INFRA\n  security_contact: ""\n  website: https://www.hy.pe/infra\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1wh9a7f05yf4nqkmh7d9lyletzhefaa53txynrj\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36879371"\nunbonding_ids:\n- "1187"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T01:24:31.330279363Z"\n',
      },
      {
        blockNumber: 36894293,
        blockTimestamp: "2023-06-25 05:16:02.4 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "1.000000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "1.000000000000000000"\n  update_time: "2023-05-11T10:32:21.967091817Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: CHqpLFZ9TBYabsMpKJ+PRKTnJisQT5c6Q/k/9ZezIms=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: E83C7B8E58DC11E0\n  moniker: Hype INFRA\n  security_contact: ""\n  website: https://www.hy.pe/infra\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1wh9a7f05yf4nqkmh7d9lyletzhefaa53txynrj\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36879371"\nunbonding_ids:\n- "1187"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T01:24:31.330279363Z"\n',
      },
      {
        blockNumber: 36894688,
        blockTimestamp: "2023-06-25 05:22:05.107 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "1.000000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "1.000000000000000000"\n  update_time: "2023-05-11T10:32:21.967091817Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: CHqpLFZ9TBYabsMpKJ+PRKTnJisQT5c6Q/k/9ZezIms=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: E83C7B8E58DC11E0\n  moniker: Hype INFRA\n  security_contact: ""\n  website: https://www.hy.pe/infra\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1wh9a7f05yf4nqkmh7d9lyletzhefaa53txynrj\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36879371"\nunbonding_ids:\n- "1187"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T01:24:31.330279363Z"\n',
      },
      {
        blockNumber: 36904372,
        blockTimestamp: "2023-06-25 07:53:27.905 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "1.000000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "1.000000000000000000"\n  update_time: "2023-05-11T10:32:21.967091817Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: CHqpLFZ9TBYabsMpKJ+PRKTnJisQT5c6Q/k/9ZezIms=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: E83C7B8E58DC11E0\n  moniker: Hype INFRA\n  security_contact: ""\n  website: https://www.hy.pe/infra\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1wh9a7f05yf4nqkmh7d9lyletzhefaa53txynrj\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36879371"\nunbonding_ids:\n- "1187"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T01:24:31.330279363Z"\n',
      },
      {
        blockNumber: 50034963,
        blockTimestamp: "2023-11-02 08:57:12.172 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "1.000000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "1.000000000000000000"\n  update_time: "2023-05-11T10:32:21.967091817Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: CHqpLFZ9TBYabsMpKJ+PRKTnJisQT5c6Q/k/9ZezIms=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: E83C7B8E58DC11E0\n  moniker: Hype INFRA\n  security_contact: ""\n  website: https://www.hy.pe/infra\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1wh9a7f05yf4nqkmh7d9lyletzhefaa53txynrj\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "50009962"\nunbonding_ids:\n- "1187"\n- "9778"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-23T02:57:34.645322578Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1e8gk2jyfn0vwr3ewk02lfjluvxhxsd7k9qn7nx",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36894688,
        blockTimestamp: "2023-06-25 05:22:05.107 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-21T17:52:17.608879245Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: MxvfF4XCqd29/pQ+pZv7WCI+Y9vSKRerOlj46y7aQu8=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: AzureNode\n  identity: 5048B115DCE4F4BB\n  moniker: AzureNode\n  security_contact: jeff@azurenode.xyz\n  website: https://azurenode.xyz\njailed: false\nmin_self_delegation: "10000000000000000"\noperator_address: injvaloper1e8gk2jyfn0vwr3ewk02lfjluvxhxsd7k9qn7nx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36869687"\nunbonding_ids:\n- "1184"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:53:40.535745640Z"\n',
      },
      {
        blockNumber: 36904372,
        blockTimestamp: "2023-06-25 07:53:27.905 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-06-21T17:52:17.608879245Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: MxvfF4XCqd29/pQ+pZv7WCI+Y9vSKRerOlj46y7aQu8=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: AzureNode\n  identity: 5048B115DCE4F4BB\n  moniker: AzureNode\n  security_contact: jeff@azurenode.xyz\n  website: https://azurenode.xyz\njailed: true\nmin_self_delegation: "10000000000000000"\noperator_address: injvaloper1e8gk2jyfn0vwr3ewk02lfjluvxhxsd7k9qn7nx\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "36869687"\nunbonding_ids:\n- "1184"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-15T22:53:40.535745640Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1dz66p5x62hrs23xq5v30g7zxevc2amdjl2mjfx",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36982784,
        blockTimestamp: "2023-06-26 03:56:14.631 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-09T00:27:23.320894274Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: KlEcJuoNmdLrbehtiRer80IYLP2VuD/ZGdhrlT0ojf0=\ndelegator_shares: "126780000000000000000.000000000000000000"\ndescription:\n  details: Community centered validator run by the Cosmos Spaces team. Community.\n    Support. Alpha.\n  identity: 06B033BAC39DA21C\n  moniker: Cosmos Spaces\n  security_contact: ""\n  website: https://twitter.com/Cosmos_Spaces\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1dz66p5x62hrs23xq5v30g7zxevc2amdjl2mjfx\nstatus: BOND_STATUS_UNBONDING\ntokens: "126780000000000000000"\nunbonding_height: "36957783"\nunbonding_ids:\n- "1166"\n- "1169"\n- "1179"\n- "1215"\n- "1228"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T21:35:35.948862661Z"\n',
      },
      {
        blockNumber: 36984829,
        blockTimestamp: "2023-06-26 04:26:38.677 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-09T00:27:23.320894274Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: KlEcJuoNmdLrbehtiRer80IYLP2VuD/ZGdhrlT0ojf0=\ndelegator_shares: "126780000000000000000.000000000000000000"\ndescription:\n  details: Community centered validator run by the Cosmos Spaces team. Community.\n    Support. Alpha.\n  identity: 06B033BAC39DA21C\n  moniker: Cosmos Spaces\n  security_contact: ""\n  website: https://twitter.com/Cosmos_Spaces\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1dz66p5x62hrs23xq5v30g7zxevc2amdjl2mjfx\nstatus: BOND_STATUS_UNBONDING\ntokens: "126780000000000000000"\nunbonding_height: "36957783"\nunbonding_ids:\n- "1166"\n- "1169"\n- "1179"\n- "1215"\n- "1228"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T21:35:35.948862661Z"\n',
      },
      {
        blockNumber: 36991229,
        blockTimestamp: "2023-06-26 06:01:34.896 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-09T00:27:23.320894274Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: KlEcJuoNmdLrbehtiRer80IYLP2VuD/ZGdhrlT0ojf0=\ndelegator_shares: "126780000000000000000.000000000000000000"\ndescription:\n  details: Community centered validator run by the Cosmos Spaces team. Community.\n    Support. Alpha.\n  identity: 06B033BAC39DA21C\n  moniker: Cosmos Spaces\n  security_contact: ""\n  website: https://twitter.com/Cosmos_Spaces\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1dz66p5x62hrs23xq5v30g7zxevc2amdjl2mjfx\nstatus: BOND_STATUS_UNBONDING\ntokens: "126780000000000000000"\nunbonding_height: "36957783"\nunbonding_ids:\n- "1166"\n- "1169"\n- "1179"\n- "1215"\n- "1228"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-16T21:35:35.948862661Z"\n',
      },
      {
        blockNumber: 37056409,
        blockTimestamp: "2023-06-26 22:26:00.128 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-09T00:27:23.320894274Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: KlEcJuoNmdLrbehtiRer80IYLP2VuD/ZGdhrlT0ojf0=\ndelegator_shares: "126780000000000000000.000000000000000000"\ndescription:\n  details: Community centered validator run by the Cosmos Spaces team. Community.\n    Support. Alpha.\n  identity: 06B033BAC39DA21C\n  moniker: Cosmos Spaces\n  security_contact: ""\n  website: https://twitter.com/Cosmos_Spaces\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1dz66p5x62hrs23xq5v30g7zxevc2amdjl2mjfx\nstatus: BOND_STATUS_UNBONDING\ntokens: "126780000000000000000"\nunbonding_height: "37031408"\nunbonding_ids:\n- "1166"\n- "1169"\n- "1179"\n- "1215"\n- "1228"\n- "1267"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-17T16:03:38.557285832Z"\n',
      },
      {
        blockNumber: 37210960,
        blockTimestamp: "2023-06-28 14:45:38.433 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-09T00:27:23.320894274Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: KlEcJuoNmdLrbehtiRer80IYLP2VuD/ZGdhrlT0ojf0=\ndelegator_shares: "141780000000000000000.000000000000000000"\ndescription:\n  details: dont stake here\n  identity: ""\n  moniker: xxx\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1dz66p5x62hrs23xq5v30g7zxevc2amdjl2mjfx\nstatus: BOND_STATUS_UNBONDING\ntokens: "141780000000000000000"\nunbonding_height: "37185959"\nunbonding_ids:\n- "1166"\n- "1169"\n- "1179"\n- "1215"\n- "1228"\n- "1267"\n- "1316"\n- "1361"\n- "1373"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-19T08:09:58.477707594Z"\n',
      },
      {
        blockNumber: 37232515,
        blockTimestamp: "2023-06-28 20:18:32.024 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-05-09T00:27:23.320894274Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: KlEcJuoNmdLrbehtiRer80IYLP2VuD/ZGdhrlT0ojf0=\ndelegator_shares: "0.000000000000000000"\ndescription:\n  details: dont stake here\n  identity: ""\n  moniker: xxx\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1dz66p5x62hrs23xq5v30g7zxevc2amdjl2mjfx\nstatus: BOND_STATUS_UNBONDING\ntokens: "0"\nunbonding_height: "37185959"\nunbonding_ids:\n- "1166"\n- "1169"\n- "1179"\n- "1215"\n- "1228"\n- "1267"\n- "1316"\n- "1361"\n- "1373"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-19T08:09:58.477707594Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper14ncezrfu3hxauehjntf6pdpme7l5etqsep2qp6",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 36991229,
        blockTimestamp: "2023-06-26 06:01:34.896 +0000 UTC",
        power: 425760,
        reason: "missing_valset_confirm",
        jailed: "injvalcons167mfy60y2ln679pecsu5f232eq5l6l9cq7mp6q",
      },
      {
        blockNumber: 37016231,
        blockTimestamp: "2023-06-26 12:14:08.703 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-04-10T20:43:43.290589604Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: trpFI4MgwlYdYIoHDy5gLOgnx6zwi9vjNmQu6Sf1jkU=\ndelegator_shares: "429334229026116072953987.190303405498876674"\ndescription:\n  details: Validators hosted by nerds - highly available architecture with redundant\n    failover nodes across multiple datacenters. Featuring a transparent, state-of-the-art\n    monitoring system, publicly available 24/7 at orman.xyz/monitor\n  identity: ""\n  moniker: Orman.xyz\n  security_contact: staking@orman.xyz\n  website: https://orman.xyz\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper14ncezrfu3hxauehjntf6pdpme7l5etqsep2qp6\nstatus: BOND_STATUS_UNBONDING\ntokens: "425230417019579543658334"\nunbonding_height: "36991230"\nunbonding_ids:\n- "1240"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-17T06:01:35.658112795Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper162w4wq4wenfznjkd62kesq9vsmdla3f22s629d",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 37032461,
        blockTimestamp: "2023-06-26 16:20:06.733 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-29T17:21:04.922500190Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Y33I2l5vEFo2OdWrW1/5Pc7vqFBt6ksUs6hlRttV7xs=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: "The First Community Governed Validator in the Cosmos \\U0001FA90"\n  identity: AD6C05DA12E42B70\n  moniker: Kleomedes\n  security_contact: ""\n  website: https://www.kleomed.es\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper162w4wq4wenfznjkd62kesq9vsmdla3f22s629d\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "37007460"\nunbonding_ids:\n- "1245"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-17T10:02:49.158325545Z"\n',
      },
      {
        blockNumber: 37056409,
        blockTimestamp: "2023-06-26 22:26:00.128 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-29T17:21:04.922500190Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Y33I2l5vEFo2OdWrW1/5Pc7vqFBt6ksUs6hlRttV7xs=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: "The First Community Governed Validator in the Cosmos \\U0001FA90"\n  identity: AD6C05DA12E42B70\n  moniker: Kleomedes\n  security_contact: ""\n  website: https://www.kleomed.es\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper162w4wq4wenfznjkd62kesq9vsmdla3f22s629d\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "37007460"\nunbonding_ids:\n- "1245"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-17T10:02:49.158325545Z"\n',
      },
      {
        blockNumber: 54912681,
        blockTimestamp: "2023-12-20 02:18:45.158 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-29T17:21:04.922500190Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Y33I2l5vEFo2OdWrW1/5Pc7vqFBt6ksUs6hlRttV7xs=\ndelegator_shares: "125138578768659399911.000000000000000000"\ndescription:\n  details: "The First Community Governed Validator in the Cosmos \\U0001FA90"\n  identity: AD6C05DA12E42B70\n  moniker: Kleomedes\n  security_contact: ""\n  website: https://www.kleomed.es\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper162w4wq4wenfznjkd62kesq9vsmdla3f22s629d\nstatus: BOND_STATUS_UNBONDING\ntokens: "125138578768659399911"\nunbonding_height: "54887680"\nunbonding_ids:\n- "1245"\n- "34005"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-09T19:19:37.189211674Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1m4pj2y9qrm77m9gsa8uu7xj6xjyy7du5hg2t2d",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 37125687,
        blockTimestamp: "2023-06-27 16:28:41.529 +0000 UTC",
        power: 328695,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1kmxsj4ak8kmnazkqu5wdphjn4aq2c37hkz63u6",
      },
      {
        blockNumber: 37126015,
        blockTimestamp: "2023-06-27 16:33:49.117 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-12T17:42:17.767940386Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: qG6vY3Qr7Zg6MUh8LW5J53xaZWTu4OY9Zi/Y9KycQ/Q=\ndelegator_shares: "330674888388852438952443.269713831426918198"\ndescription:\n  details: Your friendly validator for cosmos chains\n  identity: C47845226662AF47\n  moniker: "Frens (\\U0001F91D,\\U0001F91D)"\n  security_contact: marc@frens.army\n  website: https://frens.army\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1m4pj2y9qrm77m9gsa8uu7xj6xjyy7du5hg2t2d\nstatus: BOND_STATUS_UNBONDING\ntokens: "328367100854412284580111"\nunbonding_height: "37125688"\nunbonding_ids:\n- "1334"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-18T16:28:42.348568251Z"\n',
      },
      {
        blockNumber: 37150689,
        blockTimestamp: "2023-06-27 22:57:47.366 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-12T17:42:17.767940386Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: qG6vY3Qr7Zg6MUh8LW5J53xaZWTu4OY9Zi/Y9KycQ/Q=\ndelegator_shares: "330565091087885747693021.840329145409153407"\ndescription:\n  details: Your friendly validator for cosmos chains\n  identity: C47845226662AF47\n  moniker: "Frens (\\U0001F91D,\\U0001F91D)"\n  security_contact: marc@frens.army\n  website: https://frens.army\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1m4pj2y9qrm77m9gsa8uu7xj6xjyy7du5hg2t2d\nstatus: BOND_STATUS_UNBONDING\ntokens: "328258069831295479928922"\nunbonding_height: "37125688"\nunbonding_ids:\n- "1334"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-18T16:28:42.348568251Z"\n',
      },
      {
        blockNumber: 49921748,
        blockTimestamp: "2023-11-01 06:10:36.372 +0000 UTC",
        power: 378962,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1kmxsj4ak8kmnazkqu5wdphjn4aq2c37hkz63u6",
      },
      {
        blockNumber: 49923403,
        blockTimestamp: "2023-11-01 06:33:28.175 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-12T17:42:17.767940386Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: qG6vY3Qr7Zg6MUh8LW5J53xaZWTu4OY9Zi/Y9KycQ/Q=\ndelegator_shares: "381625965382586467388960.893141439146223814"\ndescription:\n  details: Your friendly validator for cosmos chains\n  identity: C47845226662AF47\n  moniker: "Frens (\\U0001F91D,\\U0001F91D)"\n  security_contact: marc@frens.army\n  website: https://frens.army\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1m4pj2y9qrm77m9gsa8uu7xj6xjyy7du5hg2t2d\nstatus: BOND_STATUS_UNBONDING\ntokens: "378583627127982693016779"\nunbonding_height: "49921749"\nunbonding_ids:\n- "1334"\n- "9575"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T06:10:37.018762781Z"\n',
      },
      {
        blockNumber: 49942126,
        blockTimestamp: "2023-11-01 10:50:12.036 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-12T17:42:17.767940386Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: qG6vY3Qr7Zg6MUh8LW5J53xaZWTu4OY9Zi/Y9KycQ/Q=\ndelegator_shares: "381537448056987248904481.490524892483645054"\ndescription:\n  details: Your friendly validator for cosmos chains\n  identity: C47845226662AF47\n  moniker: "Frens (\\U0001F91D,\\U0001F91D)"\n  security_contact: marc@frens.army\n  website: https://frens.army\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1m4pj2y9qrm77m9gsa8uu7xj6xjyy7du5hg2t2d\nstatus: BOND_STATUS_UNBONDING\ntokens: "378495815466227903311992"\nunbonding_height: "49921749"\nunbonding_ids:\n- "1334"\n- "9575"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T06:10:37.018762781Z"\n',
      },
      {
        blockNumber: 49943933,
        blockTimestamp: "2023-11-01 11:14:37.484 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.500000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-12T17:42:17.767940386Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: qG6vY3Qr7Zg6MUh8LW5J53xaZWTu4OY9Zi/Y9KycQ/Q=\ndelegator_shares: "381537448056987248904481.490524892483645054"\ndescription:\n  details: Your friendly validator for cosmos chains\n  identity: C47845226662AF47\n  moniker: "Frens (\\U0001F91D,\\U0001F91D)"\n  security_contact: marc@frens.army\n  website: https://frens.army\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1m4pj2y9qrm77m9gsa8uu7xj6xjyy7du5hg2t2d\nstatus: BOND_STATUS_UNBONDING\ntokens: "378495815466227903311992"\nunbonding_height: "49921749"\nunbonding_ids:\n- "1334"\n- "9575"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T06:10:37.018762781Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1gzycxekfxq2hvu9jt255aajvlakay9nvs4at6q",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 37249195,
        blockTimestamp: "2023-06-29 00:34:52.325 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-27T04:44:59.766895668Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: +mNBLdPk1Z7rXmp8QKFFSHd2zNUe1J/5b2UJHITr/Zc=\ndelegator_shares: "150009999999999999999.000000000000000000"\ndescription:\n  details: Baremetal validator and IBC relayer operator\n  identity: AB730C3A241BB718\n  moniker: Architect Nodes\n  security_contact: \' \'\n  website: ""\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1gzycxekfxq2hvu9jt255aajvlakay9nvs4at6q\nstatus: BOND_STATUS_UNBONDING\ntokens: "150009999999999999999"\nunbonding_height: "37207514"\nunbonding_ids:\n- "1157"\n- "1168"\n- "1176"\n- "1178"\n- "1227"\n- "1230"\n- "1314"\n- "1393"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-19T13:51:55.437321610Z"\n',
      },
      {
        blockNumber: 37249536,
        blockTimestamp: "2023-06-29 00:40:02.864 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-27T04:44:59.766895668Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: +mNBLdPk1Z7rXmp8QKFFSHd2zNUe1J/5b2UJHITr/Zc=\ndelegator_shares: "150009999999999999999.000000000000000000"\ndescription:\n  details: Baremetal validator and IBC relayer operator\n  identity: AB730C3A241BB718\n  moniker: Architect Nodes\n  security_contact: \' \'\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1gzycxekfxq2hvu9jt255aajvlakay9nvs4at6q\nstatus: BOND_STATUS_UNBONDING\ntokens: "150009999999999999999"\nunbonding_height: "37207514"\nunbonding_ids:\n- "1157"\n- "1168"\n- "1176"\n- "1178"\n- "1227"\n- "1230"\n- "1314"\n- "1393"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-19T13:51:55.437321610Z"\n',
      },
      {
        blockNumber: 37250375,
        blockTimestamp: "2023-06-29 00:52:59.74 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-27T04:44:59.766895668Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: +mNBLdPk1Z7rXmp8QKFFSHd2zNUe1J/5b2UJHITr/Zc=\ndelegator_shares: "150009999999999999999.000000000000000000"\ndescription:\n  details: Baremetal validator and IBC relayer operator\n  identity: AB730C3A241BB718\n  moniker: Architect Nodes\n  security_contact: \' \'\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1gzycxekfxq2hvu9jt255aajvlakay9nvs4at6q\nstatus: BOND_STATUS_UNBONDING\ntokens: "150009999999999999999"\nunbonding_height: "37207514"\nunbonding_ids:\n- "1157"\n- "1168"\n- "1176"\n- "1178"\n- "1227"\n- "1230"\n- "1314"\n- "1393"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-19T13:51:55.437321610Z"\n',
      },
      {
        blockNumber: 37250611,
        blockTimestamp: "2023-06-29 00:56:46.48 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-02-27T04:44:59.766895668Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: +mNBLdPk1Z7rXmp8QKFFSHd2zNUe1J/5b2UJHITr/Zc=\ndelegator_shares: "150009999999999999999.000000000000000000"\ndescription:\n  details: Baremetal validator and IBC relayer operator\n  identity: AB730C3A241BB718\n  moniker: Architect Nodes\n  security_contact: \' \'\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1gzycxekfxq2hvu9jt255aajvlakay9nvs4at6q\nstatus: BOND_STATUS_UNBONDING\ntokens: "150009999999999999999"\nunbonding_height: "37207514"\nunbonding_ids:\n- "1157"\n- "1168"\n- "1176"\n- "1178"\n- "1227"\n- "1230"\n- "1314"\n- "1393"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-19T13:51:55.437321610Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1sgcawjmfm7q7rmfdwfuaxxpk806jq96kqswv83",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 37258389,
        blockTimestamp: "2023-06-29 02:56:44.468 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.300000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-06-28T14:03:10.392887401Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Anz5R5cnvz5PHikOUejivx49ypBy1rFdBFnAMvIoYwg=\ndelegator_shares: "168360000000000000000.000000000000000000"\ndescription:\n  details: Community centered validator run by the Cosmos Spaces team. Community.\n    Support. Alpha.\n  identity: 06B033BAC39DA21C\n  moniker: Cosmos Spaces\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1sgcawjmfm7q7rmfdwfuaxxpk806jq96kqswv83\nstatus: BOND_STATUS_UNBONDING\ntokens: "168360000000000000000"\nunbonding_height: "37233388"\nunbonding_ids:\n- "1403"\n- "1405"\n- "1411"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-19T20:32:02.240118955Z"\n',
      },
      {
        blockNumber: 37502713,
        blockTimestamp: "2023-07-01 21:52:33.067 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.300000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-06-28T14:03:10.392887401Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Anz5R5cnvz5PHikOUejivx49ypBy1rFdBFnAMvIoYwg=\ndelegator_shares: "168360000000000000000.000000000000000000"\ndescription:\n  details: Community centered validator run by the Cosmos Spaces team. Community.\n    Support. Alpha.\n  identity: 06B033BAC39DA21C\n  moniker: Cosmos Spaces\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1sgcawjmfm7q7rmfdwfuaxxpk806jq96kqswv83\nstatus: BOND_STATUS_UNBONDING\ntokens: "168360000000000000000"\nunbonding_height: "37477712"\nunbonding_ids:\n- "1403"\n- "1405"\n- "1411"\n- "1555"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-22T14:48:03.653439056Z"\n',
      },
      {
        blockNumber: 37504604,
        blockTimestamp: "2023-07-01 22:22:13.071 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.300000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-06-28T14:03:10.392887401Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: Anz5R5cnvz5PHikOUejivx49ypBy1rFdBFnAMvIoYwg=\ndelegator_shares: "168360000000000000000.000000000000000000"\ndescription:\n  details: Community centered validator run by the Cosmos Spaces team. Community.\n    Support. Alpha.\n  identity: 06B033BAC39DA21C\n  moniker: Cosmos Spaces\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1sgcawjmfm7q7rmfdwfuaxxpk806jq96kqswv83\nstatus: BOND_STATUS_UNBONDING\ntokens: "168360000000000000000"\nunbonding_height: "37477712"\nunbonding_ids:\n- "1403"\n- "1405"\n- "1411"\n- "1555"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-22T14:48:03.653439056Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper16eg6wf2k6v0lzwu2vsrhxhe0tcycgr7jm98nyz",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 37477413,
        blockTimestamp: "2023-07-01 14:42:51.74 +0000 UTC",
        power: 567802,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1ejxertnje4s4d3gxg5jtf0dde96qthx8yk6mx8",
      },
      {
        blockNumber: 52589909,
        blockTimestamp: "2023-11-27 17:30:10.844 +0000 UTC",
        power: 553724,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1ejxertnje4s4d3gxg5jtf0dde96qthx8yk6mx8",
      },
      {
        blockNumber: 52614910,
        blockTimestamp: "2023-11-27 23:22:03.193 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.096900000000000000"\n  update_time: "2022-08-04T11:48:06.427510697Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lbeaMF2E1BQmo4hvivZYcbSMH9NTj/0KvBkufHoDRnc=\ndelegator_shares: "555387226581968900268493.580558603789567033"\ndescription:\n  details: Stake with us on https://notional.ventures/#Delegate\n  identity: 0E480E2B83B23D80\n  moniker: Notional\n  security_contact: contact@notional.ventures\n  website: https://notional.ventures\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16eg6wf2k6v0lzwu2vsrhxhe0tcycgr7jm98nyz\nstatus: BOND_STATUS_UNBONDING\ntokens: "553169009650116820617595"\nunbonding_height: "52589910"\nunbonding_ids:\n- "1554"\n- "13617"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T17:30:11.669679748Z"\n',
      },
      {
        blockNumber: 52614911,
        blockTimestamp: "2023-11-27 23:22:04.372 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.096900000000000000"\n  update_time: "2022-08-04T11:48:06.427510697Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lbeaMF2E1BQmo4hvivZYcbSMH9NTj/0KvBkufHoDRnc=\ndelegator_shares: "555387226581968900268493.580558603789567033"\ndescription:\n  details: Stake with us on https://notional.ventures/#Delegate\n  identity: 0E480E2B83B23D80\n  moniker: Notional\n  security_contact: contact@notional.ventures\n  website: https://notional.ventures\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16eg6wf2k6v0lzwu2vsrhxhe0tcycgr7jm98nyz\nstatus: BOND_STATUS_UNBONDING\ntokens: "553169009650116820617595"\nunbonding_height: "52589910"\nunbonding_ids:\n- "1554"\n- "13617"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T17:30:11.669679748Z"\n',
      },
      {
        blockNumber: 52615463,
        blockTimestamp: "2023-11-27 23:29:48.274 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.096900000000000000"\n  update_time: "2022-08-04T11:48:06.427510697Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lbeaMF2E1BQmo4hvivZYcbSMH9NTj/0KvBkufHoDRnc=\ndelegator_shares: "555387226581968900268493.580558603789567033"\ndescription:\n  details: Stake with us on https://notional.ventures/#Delegate\n  identity: 0E480E2B83B23D80\n  moniker: Notional\n  security_contact: contact@notional.ventures\n  website: https://notional.ventures\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16eg6wf2k6v0lzwu2vsrhxhe0tcycgr7jm98nyz\nstatus: BOND_STATUS_UNBONDING\ntokens: "553169009650116820617595"\nunbonding_height: "52589910"\nunbonding_ids:\n- "1554"\n- "13617"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T17:30:11.669679748Z"\n',
      },
      {
        blockNumber: 52615603,
        blockTimestamp: "2023-11-27 23:31:45.072 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.096900000000000000"\n  update_time: "2022-08-04T11:48:06.427510697Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lbeaMF2E1BQmo4hvivZYcbSMH9NTj/0KvBkufHoDRnc=\ndelegator_shares: "555387226581968900268493.580558603789567033"\ndescription:\n  details: Stake with us on https://notional.ventures/#Delegate\n  identity: 0E480E2B83B23D80\n  moniker: Notional\n  security_contact: contact@notional.ventures\n  website: https://notional.ventures\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16eg6wf2k6v0lzwu2vsrhxhe0tcycgr7jm98nyz\nstatus: BOND_STATUS_UNBONDING\ntokens: "553169009650116820617595"\nunbonding_height: "52589910"\nunbonding_ids:\n- "1554"\n- "13617"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T17:30:11.669679748Z"\n',
      },
      {
        blockNumber: 53119091,
        blockTimestamp: "2023-12-02 18:54:01.51 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.096900000000000000"\n  update_time: "2022-08-04T11:48:06.427510697Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lbeaMF2E1BQmo4hvivZYcbSMH9NTj/0KvBkufHoDRnc=\ndelegator_shares: "1656959295359126192637.113530265719913845"\ndescription:\n  details: Stake with us on https://notional.ventures/#Delegate\n  identity: 0E480E2B83B23D80\n  moniker: Notional\n  security_contact: contact@notional.ventures\n  website: https://notional.ventures\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16eg6wf2k6v0lzwu2vsrhxhe0tcycgr7jm98nyz\nstatus: BOND_STATUS_UNBONDING\ntokens: "1650341398892591517937"\nunbonding_height: "53094090"\nunbonding_ids:\n- "1554"\n- "13617"\n- "14129"\n- "14264"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-23T13:24:52.778071662Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper14n4kqm2gvghk2v8f7r9jgk4hc2mujaqua0lp3n",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 37563054,
        blockTimestamp: "2023-07-02 13:38:05.607 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-01T03:43:33.220828566Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: PXao+GkOuX5OIIgszKIAw2otBC3Kb0do0YcOB+q+yXs=\ndelegator_shares: "0.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Abandon\n  security_contact: staking@coinpayu.com\n  website: https://www.coinpayu.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper14n4kqm2gvghk2v8f7r9jgk4hc2mujaqua0lp3n\nstatus: BOND_STATUS_UNBONDING\ntokens: "0"\nunbonding_height: "37538053"\nunbonding_ids:\n- "1596"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-23T07:07:05.086029500Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "StakeLab.zone",
    operator_address: "injvaloper1r3lgsyq49zvl36cnevjx3q6u2ep897rws9hauk",
    consensus_address: "injvalcons1psa697zujyq5djsnw9schu2cce44ggf5ptu3vu",
    jailed: false,
    status: 3,
    tokens: "518405409489759389252013",
    delegator_shares: "521578912995685105073355.929600668635449976",
    description: {
      moniker: "StakeLab.zone",
      identity: "F12B081334CBE0C6",
      website: "https://www.stakelab.zone",
      security_contact: "securite@stakelab.fr",
      details: "Grow your assets - Staking & Relaying Hub for Cosmos ecosystem",
      imageURL: "",
    },
    unbonding_height: 54947116,
    unbonding_time: "2024-01-10 11:00:05.52 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-18 10:46:34.061 +0000 UTC",
    proposed: 19339,
    signed: 1761023,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 37626646,
        blockTimestamp: "2023-07-03 06:46:27.247 +0000 UTC",
        power: 562596,
        reason: "missing_signature",
        jailed: "injvalcons1psa697zujyq5djsnw9schu2cce44ggf5ptu3vu",
      },
    ],
    uptime_percentage: 97.46310895492218,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/63585765d299338807f158d6aadd2e05_360_360.jpg",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1p66ez0ywzssuq26f7056mx3ydgvkfqw8lf93e8",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 38067131,
        blockTimestamp: "2023-07-07 21:58:33.777 +0000 UTC",
        power: 669296,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1qveaytw2xqj580x37ks5gnxgqwydexaxjxavvs",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 38067131,
        blockTimestamp: "2023-07-07 21:58:33.777 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-02T01:52:07.419155777Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8L2Z8CEzdSkVXD0JAkIMcXkSMdhkd5fdZHc6ostfj4o=\ndelegator_shares: "3216460000000000000000.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://www.coinpayu.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq\nstatus: BOND_STATUS_UNBONDING\ntokens: "3216460000000000000000"\nunbonding_height: "38042130"\nunbonding_ids:\n- "1722"\n- "1820"\n- "1885"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-28T15:26:56.196893973Z"\n',
      },
      {
        blockNumber: 38071837,
        blockTimestamp: "2023-07-07 23:13:00.916 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-02T01:52:07.419155777Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8L2Z8CEzdSkVXD0JAkIMcXkSMdhkd5fdZHc6ostfj4o=\ndelegator_shares: "3216460000000000000000.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://www.coinpayu.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq\nstatus: BOND_STATUS_UNBONDING\ntokens: "3216460000000000000000"\nunbonding_height: "38042130"\nunbonding_ids:\n- "1722"\n- "1820"\n- "1885"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-07-28T15:26:56.196893973Z"\n',
      },
      {
        blockNumber: 44914264,
        blockTimestamp: "2023-09-13 15:14:04.514 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-02T01:52:07.419155777Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8L2Z8CEzdSkVXD0JAkIMcXkSMdhkd5fdZHc6ostfj4o=\ndelegator_shares: "495298371222485398084.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://www.coinpayu.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq\nstatus: BOND_STATUS_UNBONDING\ntokens: "495298371222485398084"\nunbonding_height: "44889263"\nunbonding_ids:\n- "1722"\n- "1820"\n- "1885"\n- "6229"\n- "6237"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-04T09:27:51.372683608Z"\n',
      },
      {
        blockNumber: 49923403,
        blockTimestamp: "2023-11-01 06:33:28.175 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-02T01:52:07.419155777Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8L2Z8CEzdSkVXD0JAkIMcXkSMdhkd5fdZHc6ostfj4o=\ndelegator_shares: "495945745953214835710.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://www.coinpayu.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq\nstatus: BOND_STATUS_UNBONDING\ntokens: "495945745953214835710"\nunbonding_height: "49917125"\nunbonding_ids:\n- "1722"\n- "1820"\n- "1885"\n- "6229"\n- "6237"\n- "9569"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T05:06:32.606789694Z"\n',
      },
      {
        blockNumber: 49942126,
        blockTimestamp: "2023-11-01 10:50:12.036 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-02T01:52:07.419155777Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8L2Z8CEzdSkVXD0JAkIMcXkSMdhkd5fdZHc6ostfj4o=\ndelegator_shares: "495945745953214835710.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://www.coinpayu.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq\nstatus: BOND_STATUS_UNBONDING\ntokens: "495945745953214835710"\nunbonding_height: "49917125"\nunbonding_ids:\n- "1722"\n- "1820"\n- "1885"\n- "6229"\n- "6237"\n- "9569"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T05:06:32.606789694Z"\n',
      },
      {
        blockNumber: 49943933,
        blockTimestamp: "2023-11-01 11:14:37.484 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-02T01:52:07.419155777Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8L2Z8CEzdSkVXD0JAkIMcXkSMdhkd5fdZHc6ostfj4o=\ndelegator_shares: "495945745953214835710.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://www.coinpayu.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq\nstatus: BOND_STATUS_UNBONDING\ntokens: "495945745953214835710"\nunbonding_height: "49917125"\nunbonding_ids:\n- "1722"\n- "1820"\n- "1885"\n- "6229"\n- "6237"\n- "9569"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T05:06:32.606789694Z"\n',
      },
      {
        blockNumber: 49946750,
        blockTimestamp: "2023-11-01 11:52:50.525 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-02T01:52:07.419155777Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8L2Z8CEzdSkVXD0JAkIMcXkSMdhkd5fdZHc6ostfj4o=\ndelegator_shares: "495945745953214835710.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://www.coinpayu.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq\nstatus: BOND_STATUS_UNBONDING\ntokens: "495945745953214835710"\nunbonding_height: "49917125"\nunbonding_ids:\n- "1722"\n- "1820"\n- "1885"\n- "6229"\n- "6237"\n- "9569"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T05:06:32.606789694Z"\n',
      },
      {
        blockNumber: 52615463,
        blockTimestamp: "2023-11-27 23:29:48.274 +0000 UTC",
        power: 481,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1a6y6d9wj8ccwztt56xq8532ykdu53z7mspddy4",
      },
      {
        blockNumber: 52615603,
        blockTimestamp: "2023-11-27 23:31:45.072 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-07-02T01:52:07.419155777Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 8L2Z8CEzdSkVXD0JAkIMcXkSMdhkd5fdZHc6ostfj4o=\ndelegator_shares: "481345745953214835710.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://staking.coinpayu.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq\nstatus: BOND_STATUS_UNBONDING\ntokens: "480864745953214835710"\nunbonding_height: "52615464"\nunbonding_ids:\n- "1722"\n- "1820"\n- "1885"\n- "6229"\n- "6237"\n- "9569"\n- "13673"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T23:29:49.682824285Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "nysa.network",
    operator_address: "injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp",
    consensus_address: "injvalcons1p44qwx5ru3sfga2txh37gt3h0qxps47ntnrsuf",
    jailed: true,
    status: 1,
    tokens: "8544167564302432868",
    delegator_shares: "8570702052860971875.335357917867189480",
    description: {
      moniker: "nysa.network",
      identity: "F7E5A47BC1D9F95B",
      website: "https://nysa.network",
      security_contact: "security@nysa.network",
      details: "Safe and secure validator in the interchain",
      imageURL: "",
    },
    unbonding_height: 52590462,
    unbonding_time: "2023-12-18 17:38:15.798 +0000 UTC",
    commission_rate: "0.070000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-03-03 10:17:02.817 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 38670139,
        blockTimestamp: "2023-07-14 06:46:32.391 +0000 UTC",
        power: 100254,
        reason: "missing_signature",
        jailed: "injvalcons1p44qwx5ru3sfga2txh37gt3h0qxps47ntnrsuf",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/2d9056551af97b92f6c759c6af125905_360_360.jpg",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1xwazl8ftks4gn00y5x3c47auquc62ssucyym4v",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 41635891,
        blockTimestamp: "2023-08-13 00:37:12.884 +0000 UTC",
        power: 127527,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1d7nl32r8uhc6hhs0n00jqkd0wp6zjt3tzxcvgx",
      },
      {
        blockNumber: 49967128,
        blockTimestamp: "2023-11-01 16:47:44.433 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-05-30T19:50:34.393609047Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W2xg5sLtTzz9f7CM3z+kXdLAGFgPKl1ZmkQxct/FwJ0=\ndelegator_shares: "109685930287111695633109.551421805864282655"\ndescription:\n  details: just another dad in the cosmos\n  identity: FA260EE7A0113432\n  moniker: jabbey\n  security_contact: ""\n  website: https://jabbey.io\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1xwazl8ftks4gn00y5x3c47auquc62ssucyym4v\nstatus: BOND_STATUS_UNBONDING\ntokens: "109346266485218496014752"\nunbonding_height: "49945775"\nunbonding_ids:\n- "3849"\n- "6906"\n- "9509"\n- "9574"\n- "9604"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T11:39:31.126397935Z"\n',
      },
      {
        blockNumber: 49969172,
        blockTimestamp: "2023-11-01 17:16:08.153 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-05-30T19:50:34.393609047Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W2xg5sLtTzz9f7CM3z+kXdLAGFgPKl1ZmkQxct/FwJ0=\ndelegator_shares: "109685930287111695633109.551421805864282655"\ndescription:\n  details: just another dad in the cosmos\n  identity: FA260EE7A0113432\n  moniker: jabbey\n  security_contact: ""\n  website: https://jabbey.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1xwazl8ftks4gn00y5x3c47auquc62ssucyym4v\nstatus: BOND_STATUS_UNBONDING\ntokens: "109346266485218496014752"\nunbonding_height: "49945775"\nunbonding_ids:\n- "3849"\n- "6906"\n- "9509"\n- "9574"\n- "9604"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T11:39:31.126397935Z"\n',
      },
      {
        blockNumber: 49970776,
        blockTimestamp: "2023-11-01 17:38:29.065 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-05-30T19:50:34.393609047Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: W2xg5sLtTzz9f7CM3z+kXdLAGFgPKl1ZmkQxct/FwJ0=\ndelegator_shares: "109685930287111695633109.551421805864282655"\ndescription:\n  details: just another dad in the cosmos\n  identity: FA260EE7A0113432\n  moniker: jabbey\n  security_contact: ""\n  website: https://jabbey.io\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1xwazl8ftks4gn00y5x3c47auquc62ssucyym4v\nstatus: BOND_STATUS_UNBONDING\ntokens: "109346266485218496014752"\nunbonding_height: "49945775"\nunbonding_ids:\n- "3849"\n- "6906"\n- "9509"\n- "9574"\n- "9604"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T11:39:31.126397935Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1f2kdg34689x93cvw2y59z7y46dvz2fk8lhddfz",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 41660893,
        blockTimestamp: "2023-08-13 06:31:04.662 +0000 UTC",
        power: 655639,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1mrdfewvpvfn4a80t8z8wzlpkp4cz6kukepxuxc",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper17y9pmvrwcmytrvsurduj7ts0s8unzr55a6ffa3",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 41686382,
        blockTimestamp: "2023-08-13 12:23:05.361 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-08T11:23:40.616405444Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: ElUQ9799RTQtwNx9lwIQ5bXL0+nZfNnRAsm86SIqcHQ=\ndelegator_shares: "93644728825014516456058.904507130174805504"\ndescription:\n  details: 01node Professional Staking Services\n  identity: 7BDD4C2E94392626\n  moniker: 01node\n  security_contact: secops@01node.com\n  website: https://01node.com\njailed: false\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper17y9pmvrwcmytrvsurduj7ts0s8unzr55a6ffa3\nstatus: BOND_STATUS_UNBONDING\ntokens: "93364088445816707469781"\nunbonding_height: "41661381"\nunbonding_ids:\n- "3831"\n- "3857"\n- "3886"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-03T06:37:52.481090166Z"\n',
      },
      {
        blockNumber: 42839944,
        blockTimestamp: "2023-08-24 14:07:22.28 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-08T11:23:40.616405444Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: ElUQ9799RTQtwNx9lwIQ5bXL0+nZfNnRAsm86SIqcHQ=\ndelegator_shares: "93138193265862007923920.281959084398099505"\ndescription:\n  details: 01node Professional Staking Services\n  identity: 7BDD4C2E94392626\n  moniker: 01node\n  security_contact: secops@01node.com\n  website: https://01node.com\njailed: false\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper17y9pmvrwcmytrvsurduj7ts0s8unzr55a6ffa3\nstatus: BOND_STATUS_UNBONDING\ntokens: "92859070904102888575099"\nunbonding_height: "42814943"\nunbonding_ids:\n- "3831"\n- "3857"\n- "3886"\n- "4754"\n- "4904"\n- "4934"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-14T08:29:46.476648969Z"\n',
      },
      {
        blockNumber: 44743846,
        blockTimestamp: "2023-09-11 23:49:06.476 +0000 UTC",
        power: 16231,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1dflwep4n9kmekqvtuu66ef3m5hj06u56kr2q8c",
      },
      {
        blockNumber: 44744914,
        blockTimestamp: "2023-09-12 00:03:55.54 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-08T11:23:40.616405444Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: ElUQ9799RTQtwNx9lwIQ5bXL0+nZfNnRAsm86SIqcHQ=\ndelegator_shares: "16279935732374445304934.531824454322553228"\ndescription:\n  details: 01node Professional Staking Services\n  identity: 7BDD4C2E94392626\n  moniker: 01node\n  security_contact: secops@01node.com\n  website: https://01node.com\njailed: true\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper17y9pmvrwcmytrvsurduj7ts0s8unzr55a6ffa3\nstatus: BOND_STATUS_UNBONDING\ntokens: "16214916002943802776248"\nunbonding_height: "44743847"\nunbonding_ids:\n- "3831"\n- "3857"\n- "3886"\n- "4754"\n- "4904"\n- "4934"\n- "6141"\n- "6173"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-02T23:49:07.326923002Z"\n',
      },
      {
        blockNumber: 44768848,
        blockTimestamp: "2023-09-12 05:37:33.399 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-08T11:23:40.616405444Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: ElUQ9799RTQtwNx9lwIQ5bXL0+nZfNnRAsm86SIqcHQ=\ndelegator_shares: "16279935732374445304934.531824454322553228"\ndescription:\n  details: 01node Professional Staking Services\n  identity: 7BDD4C2E94392626\n  moniker: 01node\n  security_contact: secops@01node.com\n  website: https://01node.com\njailed: true\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper17y9pmvrwcmytrvsurduj7ts0s8unzr55a6ffa3\nstatus: BOND_STATUS_UNBONDING\ntokens: "16214916002943802776248"\nunbonding_height: "44743847"\nunbonding_ids:\n- "3831"\n- "3857"\n- "3886"\n- "4754"\n- "4904"\n- "4934"\n- "6141"\n- "6173"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-02T23:49:07.326923002Z"\n',
      },
      {
        blockNumber: 46515751,
        blockTimestamp: "2023-09-29 00:27:53.034 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-08T11:23:40.616405444Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: ElUQ9799RTQtwNx9lwIQ5bXL0+nZfNnRAsm86SIqcHQ=\ndelegator_shares: "16245333815732705111383.138816132034844269"\ndescription:\n  details: 01node Professional Staking Services\n  identity: 7BDD4C2E94392626\n  moniker: 01node\n  security_contact: secops@01node.com\n  website: https://01node.com\njailed: false\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper17y9pmvrwcmytrvsurduj7ts0s8unzr55a6ffa3\nstatus: BOND_STATUS_UNBONDING\ntokens: "16180452281396614068231"\nunbonding_height: "46490750"\nunbonding_ids:\n- "3831"\n- "3857"\n- "3886"\n- "4754"\n- "4904"\n- "4934"\n- "6141"\n- "6173"\n- "6457"\n- "6846"\n- "6855"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-19T18:45:03.938250717Z"\n',
      },
      {
        blockNumber: 46609765,
        blockTimestamp: "2023-09-29 22:09:03.177 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-08T11:23:40.616405444Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: ElUQ9799RTQtwNx9lwIQ5bXL0+nZfNnRAsm86SIqcHQ=\ndelegator_shares: "16245514544322181657281.442546507922111779"\ndescription:\n  details: 01node Professional Staking Services\n  identity: 7BDD4C2E94392626\n  moniker: 01node\n  security_contact: secops@01node.com\n  website: https://01node.com\njailed: false\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper17y9pmvrwcmytrvsurduj7ts0s8unzr55a6ffa3\nstatus: BOND_STATUS_UNBONDING\ntokens: "16180632288182013416289"\nunbonding_height: "46584764"\nunbonding_ids:\n- "3831"\n- "3857"\n- "3886"\n- "4754"\n- "4904"\n- "4934"\n- "6141"\n- "6173"\n- "6457"\n- "6846"\n- "6855"\n- "6908"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-20T16:26:33.782380795Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1dyntafvjynns5zyltwgyktej0y5zt84y3npkt4",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 42670324,
        blockTimestamp: "2023-08-22 23:30:11.497 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-08-14T15:02:50.415508918Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: sr3cYN5N539oQf4RwnG2NV4m5WKIAgio8in03p0gOEk=\ndelegator_shares: "100001000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Trust Nodes\n  security_contact: ""\n  website: https://trustwallet.com/\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1dyntafvjynns5zyltwgyktej0y5zt84y3npkt4\nstatus: BOND_STATUS_UNBONDING\ntokens: "100001000000000000000000"\nunbonding_height: "42645323"\nunbonding_ids:\n- "4841"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-12T17:41:14.293815438Z"\n',
      },
      {
        blockNumber: 42754880,
        blockTimestamp: "2023-08-23 18:53:37.148 +0000 UTC",
        power: 150001,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1uejre3lrwhlc3842t8mlewflfh34gq7mfv0m5p",
      },
      {
        blockNumber: 42770325,
        blockTimestamp: "2023-08-23 22:25:11.971 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-08-14T15:02:50.415508918Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: sr3cYN5N539oQf4RwnG2NV4m5WKIAgio8in03p0gOEk=\ndelegator_shares: "150001000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Trust Nodes\n  security_contact: ""\n  website: https://trustwallet.com/\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1dyntafvjynns5zyltwgyktej0y5zt84y3npkt4\nstatus: BOND_STATUS_UNBONDING\ntokens: "149850999000000000000000"\nunbonding_height: "42754881"\nunbonding_ids:\n- "4841"\n- "4908"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-13T18:53:37.879409455Z"\n',
      },
      {
        blockNumber: 42777127,
        blockTimestamp: "2023-08-23 23:57:12.33 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-08-14T15:02:50.415508918Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: sr3cYN5N539oQf4RwnG2NV4m5WKIAgio8in03p0gOEk=\ndelegator_shares: "150001000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Trust Nodes\n  security_contact: ""\n  website: https://trustwallet.com/\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1dyntafvjynns5zyltwgyktej0y5zt84y3npkt4\nstatus: BOND_STATUS_UNBONDING\ntokens: "149850999000000000000000"\nunbonding_height: "42754881"\nunbonding_ids:\n- "4841"\n- "4908"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-13T18:53:37.879409455Z"\n',
      },
      {
        blockNumber: 42777742,
        blockTimestamp: "2023-08-24 00:05:30.653 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-08-14T15:02:50.415508918Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: sr3cYN5N539oQf4RwnG2NV4m5WKIAgio8in03p0gOEk=\ndelegator_shares: "150001000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Trust Nodes\n  security_contact: ""\n  website: https://trustwallet.com/\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1dyntafvjynns5zyltwgyktej0y5zt84y3npkt4\nstatus: BOND_STATUS_UNBONDING\ntokens: "149850999000000000000000"\nunbonding_height: "42754881"\nunbonding_ids:\n- "4841"\n- "4908"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-13T18:53:37.879409455Z"\n',
      },
      {
        blockNumber: 42779882,
        blockTimestamp: "2023-08-24 00:34:25.069 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-08-14T15:02:50.415508918Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: sr3cYN5N539oQf4RwnG2NV4m5WKIAgio8in03p0gOEk=\ndelegator_shares: "150001000000000000000000.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Trust Nodes\n  security_contact: ""\n  website: https://trustwallet.com/\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1dyntafvjynns5zyltwgyktej0y5zt84y3npkt4\nstatus: BOND_STATUS_UNBONDING\ntokens: "149850999000000000000000"\nunbonding_height: "42754881"\nunbonding_ids:\n- "4841"\n- "4908"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-09-13T18:53:37.879409455Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1mwerm24kce7dcl59t6n0caw7c8zxgkrjj9svwj",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 42752125,
        blockTimestamp: "2023-08-23 18:16:00.777 +0000 UTC",
        power: 887211,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1688g0wlgndehf92pd6xyfw04353h94w3cca4p3",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper16gquuvkj7yreyvmmmha4uke9cjtd6t3nh5r76d",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 44693842,
        blockTimestamp: "2023-09-11 12:08:00.023 +0000 UTC",
        power: 189881,
        reason: "missing_valset_confirm",
        jailed: "injvalcons19u74jddypfwvug04y0hzs54507cdnegrqlqyee",
      },
      {
        blockNumber: 44704005,
        blockTimestamp: "2023-09-11 14:30:40.746 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2021-06-30T13:00:00Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: WnpY6PXSjoinEL8Dm+63RyuwvTzgcePgNO2yBj8ZdsY=\ndelegator_shares: "190090372138535771689523.481758811006394178"\ndescription:\n  details: Validators of today, Auditors of tomorrow\n  identity: 5736C325251A8046\n  moniker: AUDIT.one\n  security_contact: hello@audit.one\n  website: https://audit.one\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16gquuvkj7yreyvmmmha4uke9cjtd6t3nh5r76d\nstatus: BOND_STATUS_UNBONDING\ntokens: "189691412709045085015810"\nunbonding_height: "44693843"\nunbonding_ids:\n- "6127"\n- "6145"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-02T12:08:05.185824322Z"\n',
      },
      {
        blockNumber: 44718844,
        blockTimestamp: "2023-09-11 18:00:01.79 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.070000000000000000"\n  update_time: "2021-06-30T13:00:00Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: WnpY6PXSjoinEL8Dm+63RyuwvTzgcePgNO2yBj8ZdsY=\ndelegator_shares: "190090458706094147404729.937450419333287589"\ndescription:\n  details: Validators of today, Auditors of tomorrow\n  identity: 5736C325251A8046\n  moniker: AUDIT.one\n  security_contact: hello@audit.one\n  website: https://audit.one\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper16gquuvkj7yreyvmmmha4uke9cjtd6t3nh5r76d\nstatus: BOND_STATUS_UNBONDING\ntokens: "189691499094916490931164"\nunbonding_height: "44693843"\nunbonding_ids:\n- "6127"\n- "6145"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-02T12:08:05.185824322Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1ejnw6efrt7f6v6n0xhqlpxunlxmpllnusy2gf3",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 44843854,
        blockTimestamp: "2023-09-12 23:06:25.615 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-27T04:26:38.731102719Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 6gkZlX+LvttIvvSdhvu7cYLXS8jKf6arhlmOooWeyJI=\ndelegator_shares: "929951230023904263941.717440452058587970"\ndescription:\n  details: https://twitter.com/AltcoinPsycho\n  identity: 31B22D63A091E1DF\n  moniker: Psycho Node\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1ejnw6efrt7f6v6n0xhqlpxunlxmpllnusy2gf3\nstatus: BOND_STATUS_UNBONDING\ntokens: "928928377903440832300"\nunbonding_height: "44835373"\nunbonding_ids:\n- "2232"\n- "2338"\n- "6190"\n- "6217"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-03T21:09:48.556295226Z"\n',
      },
      {
        blockNumber: 44844563,
        blockTimestamp: "2023-09-12 23:16:22.48 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-27T04:26:38.731102719Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 6gkZlX+LvttIvvSdhvu7cYLXS8jKf6arhlmOooWeyJI=\ndelegator_shares: "929951230023904263941.717440452058587970"\ndescription:\n  details: https://twitter.com/AltcoinPsycho\n  identity: 31B22D63A091E1DF\n  moniker: Psycho Node\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1ejnw6efrt7f6v6n0xhqlpxunlxmpllnusy2gf3\nstatus: BOND_STATUS_UNBONDING\ntokens: "928928377903440832300"\nunbonding_height: "44835373"\nunbonding_ids:\n- "2232"\n- "2338"\n- "6190"\n- "6217"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-03T21:09:48.556295226Z"\n',
      },
      {
        blockNumber: 44860374,
        blockTimestamp: "2023-09-13 02:54:57.297 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-27T04:26:38.731102719Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: 6gkZlX+LvttIvvSdhvu7cYLXS8jKf6arhlmOooWeyJI=\ndelegator_shares: "929951230023904263941.717440452058587970"\ndescription:\n  details: https://twitter.com/AltcoinPsycho\n  identity: 31B22D63A091E1DF\n  moniker: Psycho Node\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1ejnw6efrt7f6v6n0xhqlpxunlxmpllnusy2gf3\nstatus: BOND_STATUS_UNBONDING\ntokens: "928928377903440832300"\nunbonding_height: "44835373"\nunbonding_ids:\n- "2232"\n- "2338"\n- "6190"\n- "6217"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-03T21:09:48.556295226Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper158m0v02cahrec02q0mlzwwztv95avdh9ldadne",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 44844563,
        blockTimestamp: "2023-09-12 23:16:22.48 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-08-17T04:52:09.629101450Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: b7fpJ3ZRW2OLuPgVZRKho3GnWKx4OHLB+FZ+aw7bN+k=\ndelegator_shares: "679040278636476521323.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Nebula\n  security_contact: ""\n  website: ""\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper158m0v02cahrec02q0mlzwwztv95avdh9ldadne\nstatus: BOND_STATUS_UNBONDING\ntokens: "679040278636476521323"\nunbonding_height: "44819562"\nunbonding_ids:\n- "6212"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-03T17:30:27.886225163Z"\n',
      },
      {
        blockNumber: 44860374,
        blockTimestamp: "2023-09-13 02:54:57.297 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-08-17T04:52:09.629101450Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: b7fpJ3ZRW2OLuPgVZRKho3GnWKx4OHLB+FZ+aw7bN+k=\ndelegator_shares: "679040278636476521323.000000000000000000"\ndescription:\n  details: ""\n  identity: ""\n  moniker: Nebula\n  security_contact: ""\n  website: ""\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper158m0v02cahrec02q0mlzwwztv95avdh9ldadne\nstatus: BOND_STATUS_UNBONDING\ntokens: "679040278636476521323"\nunbonding_height: "44819562"\nunbonding_ids:\n- "6212"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-03T17:30:27.886225163Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper12s9d7l53ef2c8avrn7pgd6dfeeg2yzel58ztfx",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 46424090,
        blockTimestamp: "2023-09-28 03:23:43.297 +0000 UTC",
        power: 303013,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1ylv842shhp402hr7dmz0kcmexll088aj6rdcyg",
      },
      {
        blockNumber: 46449092,
        blockTimestamp: "2023-09-28 09:06:02.414 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "1.000000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "0.095000000000000000"\n  update_time: "2021-06-30T13:00:00Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: gECPoQq1GN1e9xaf90Y8DyUMgcCi1YU3eBgKvpEDzQM=\ndelegator_shares: "303043945013647135708649.397735488482003347"\ndescription:\n  details: Cobuilding the Interchain\n  identity: 2861F5EE06627224\n  moniker: Forbole\n  security_contact: ""\n  website: https://forbole.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper12s9d7l53ef2c8avrn7pgd6dfeeg2yzel58ztfx\nstatus: BOND_STATUS_UNBONDING\ntokens: "302710627665142976379780"\nunbonding_height: "46424091"\nunbonding_ids:\n- "1177"\n- "6833"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-10-19T03:23:44.064922179Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "jabbey",
    operator_address: "injvaloper1xwazl8ftks4gn00y5x3c47auquc62ssucyym4v",
    consensus_address: "injvalcons1d7nl32r8uhc6hhs0n00jqkd0wp6zjt3tzxcvgx",
    jailed: true,
    status: 1,
    tokens: "2209730047405840977569",
    delegator_shares: "2216594162049947002970.958082738883565142",
    description: {
      moniker: "jabbey",
      identity: "FA260EE7A0113432",
      website: "https://jabbey.io",
      security_contact: "",
      details: "just another dad in the cosmos",
      imageURL: "",
    },
    unbonding_height: 49945775,
    unbonding_time: "2023-11-22 11:39:31.126 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-05-30 19:50:34.393 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 46579886,
        blockTimestamp: "2023-09-29 15:17:37.552 +0000 UTC",
        power: 128513,
        reason: "missing_signature",
        jailed: "injvalcons1d7nl32r8uhc6hhs0n00jqkd0wp6zjt3tzxcvgx",
      },
    ],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/af106005e2439232a6f19bcb75c52605_360_360.jpg",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1r3lgsyq49zvl36cnevjx3q6u2ep897rws9hauk",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 49896746,
        blockTimestamp: "2023-11-01 00:25:13.021 +0000 UTC",
        power: 514557,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1psa697zujyq5djsnw9schu2cce44ggf5ptu3vu",
      },
      {
        blockNumber: 51200302,
        blockTimestamp: "2023-11-13 19:32:38.502 +0000 UTC",
        power: 514972,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1psa697zujyq5djsnw9schu2cce44ggf5ptu3vu",
      },
      {
        blockNumber: 54133221,
        blockTimestamp: "2023-12-12 12:47:43.276 +0000 UTC",
        power: 497191,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1psa697zujyq5djsnw9schu2cce44ggf5ptu3vu",
      },
      {
        blockNumber: 54135280,
        blockTimestamp: "2023-12-12 13:16:08.013 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T10:46:34.061278827Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: SAZI/xUE9McIi0JmVDnUclrz6Ykku2B6gLKNv6lkqcg=\ndelegator_shares: "498634448522900958433758.496828889407018320"\ndescription:\n  details: Grow your assets - Staking & Relaying Hub for Cosmos ecosystem\n  identity: F12B081334CBE0C6\n  moniker: StakeLab\n  security_contact: securite@stakelab.fr\n  website: https://www.stakelab.zone\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r3lgsyq49zvl36cnevjx3q6u2ep897rws9hauk\nstatus: BOND_STATUS_UNBONDING\ntokens: "496593237951392790868479"\nunbonding_height: "54133222"\nunbonding_ids:\n- "1635"\n- "9540"\n- "12062"\n- "21827"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-02T12:47:43.957871746Z"\n',
      },
      {
        blockNumber: 54176236,
        blockTimestamp: "2023-12-12 23:08:18.256 +0000 UTC",
        power: 495866,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1psa697zujyq5djsnw9schu2cce44ggf5ptu3vu",
      },
      {
        blockNumber: 54947115,
        blockTimestamp: "2023-12-20 11:00:04.666 +0000 UTC",
        power: 487153,
        reason: "missing_batch_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-03-18T10:46:34.061278827Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: SAZI/xUE9McIi0JmVDnUclrz6Ykku2B6gLKNv6lkqcg=\ndelegator_shares: "489645118946984000820843.861933688163362015"\ndescription:\n  details: Grow your assets - Staking & Relaying Hub for Cosmos ecosystem\n  identity: F12B081334CBE0C6\n  moniker: StakeLab\n  security_contact: securite@stakelab.fr\n  website: https://www.stakelab.zone\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1r3lgsyq49zvl36cnevjx3q6u2ep897rws9hauk\nstatus: BOND_STATUS_BONDED\ntokens: "487153066954372417181310"\nunbonding_height: "54176237"\nunbonding_ids:\n- "1635"\n- "9540"\n- "12062"\n- "21827"\n- "23168"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-02T23:08:19.189502406Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper18kcu6w92u4mq4dsudpcrufdfmtgu7q2r753t72",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 49896746,
        blockTimestamp: "2023-11-01 00:25:13.021 +0000 UTC",
        power: 300225,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1r7kysfjcqkk4l4p2qvujspsque2jzhmk2kpe4g",
      },
      {
        blockNumber: 54108219,
        blockTimestamp: "2023-12-12 07:06:09.32 +0000 UTC",
        power: 299992,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1r7kysfjcqkk4l4p2qvujspsque2jzhmk2kpe4g",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1kpfxtqt5cmlew46dem32fqlk5g6k4wyueh4szu",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 49996026,
        blockTimestamp: "2023-11-01 23:41:01.823 +0000 UTC",
        power: 1160946,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1xldh2zm5qf439ux5jceljkyp4rhc4erucgn90q",
      },
      {
        blockNumber: 50021027,
        blockTimestamp: "2023-11-02 05:32:46.582 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-28T11:00:32.151366501Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: N4jcxF2vegPEqKTXAMkTXUOibxFImRavlzfslAAPqCA=\ndelegator_shares: "656506027937698007675921.959080229726811701"\ndescription:\n  details: "\\U0001F680 Professional Cosmos Validator & Engineer \\U0001F501Auto-compound\n    with REStakeapp \\U0001F6E1Slash insurance \\U0001F381 All Airdrops for delegators\n    \\U0001F517 Twitter, Telegram and Youtube | Stakewolle.com"\n  identity: 6386E8C1B6217AC2\n  moniker: Stakewolle.com | Auto-compound\n  security_contact: ""\n  website: https://stakewolle.com/\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1kpfxtqt5cmlew46dem32fqlk5g6k4wyueh4szu\nstatus: BOND_STATUS_UNBONDING\ntokens: "652381474433172834685708"\nunbonding_height: "49996027"\nunbonding_ids:\n- "9765"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T23:41:02.653682164Z"\n',
      },
      {
        blockNumber: 50021028,
        blockTimestamp: "2023-11-02 05:32:47.303 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-28T11:00:32.151366501Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: N4jcxF2vegPEqKTXAMkTXUOibxFImRavlzfslAAPqCA=\ndelegator_shares: "656506027937698007675921.959080229726811701"\ndescription:\n  details: "\\U0001F680 Professional Cosmos Validator & Engineer \\U0001F501Auto-compound\n    with REStakeapp \\U0001F6E1Slash insurance \\U0001F381 All Airdrops for delegators\n    \\U0001F517 Twitter, Telegram and Youtube | Stakewolle.com"\n  identity: 6386E8C1B6217AC2\n  moniker: Stakewolle.com | Auto-compound\n  security_contact: ""\n  website: https://stakewolle.com/\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1kpfxtqt5cmlew46dem32fqlk5g6k4wyueh4szu\nstatus: BOND_STATUS_UNBONDING\ntokens: "652381474433172834685708"\nunbonding_height: "49996027"\nunbonding_ids:\n- "9765"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T23:41:02.653682164Z"\n',
      },
      {
        blockNumber: 50034963,
        blockTimestamp: "2023-11-02 08:57:12.172 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-01-28T11:00:32.151366501Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: N4jcxF2vegPEqKTXAMkTXUOibxFImRavlzfslAAPqCA=\ndelegator_shares: "656316090335819927574251.076835593756093894"\ndescription:\n  details: "\\U0001F680 Professional Cosmos Validator & Engineer \\U0001F501Auto-compound\n    with REStakeapp \\U0001F6E1Slash insurance \\U0001F381 All Airdrops for delegators\n    \\U0001F517 Twitter, Telegram and Youtube | Stakewolle.com"\n  identity: 6386E8C1B6217AC2\n  moniker: Stakewolle.com | Auto-compound\n  security_contact: ""\n  website: https://stakewolle.com/\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1kpfxtqt5cmlew46dem32fqlk5g6k4wyueh4szu\nstatus: BOND_STATUS_UNBONDING\ntokens: "652192730130012708910650"\nunbonding_height: "49996027"\nunbonding_ids:\n- "9765"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T23:41:02.653682164Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1y4pfpkwpy6myskp7pne256k6smh2rjta694yq5",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 49996026,
        blockTimestamp: "2023-11-01 23:41:01.823 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-10-26T06:04:11.705707022Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: PFRgT80ZWMxR1etpU9TAAht6OOFiAfvgMRjllwTPb6M=\ndelegator_shares: "1000000000000000000.000000000000000000"\ndescription:\n  details: We are operating a secure, stable Validator Node for 16 mainnet chains.\n    We provide infrastructure support - public RPC, LCD endpoints, daily snapshots,\n    operate multiple IBC Relayers, Blockchain Explorers, Youtube tutorials, etc. All\n    info on our website.\n  identity: AF9D7EF7CC70CE24\n  moniker: Synergy Nodes\n  security_contact: contact@synergynodes.com\n  website: https://www.synergynodes.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1y4pfpkwpy6myskp7pne256k6smh2rjta694yq5\nstatus: BOND_STATUS_UNBONDING\ntokens: "1000000000000000000"\nunbonding_height: "49971025"\nunbonding_ids:\n- "9687"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-11-22T17:42:02.108946651Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1444m5prz36s4t4gr7kca5lgc682h94yutqrgpg",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 51200302,
        blockTimestamp: "2023-11-13 19:32:38.502 +0000 UTC",
        power: 320893,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1lzt3tzhath5argv9yxfsfcsklc8gz2jx0kvluk",
      },
      {
        blockNumber: 51225304,
        blockTimestamp: "2023-11-14 01:25:07.991 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-08-15T18:30:55.281029063Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: moKKwdFTbPhEGKnYTs4b3aI6IC+dhRkRhhel7IV4tmM=\ndelegator_shares: "321214708072192952266372.937416896783464840"\ndescription:\n  details: Interchain infra. Apoapsis to the Periapsis.\n  identity: 55AE6F8C61BF4749\n  moniker: Blockapsis\n  security_contact: node-operator@chainapsis.com\n  website: https://www.chainapsis.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1444m5prz36s4t4gr7kca5lgc682h94yutqrgpg\nstatus: BOND_STATUS_UNBONDING\ntokens: "320572600374158624522702"\nunbonding_height: "51200303"\nunbonding_ids:\n- "12063"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-04T19:32:39.974623037Z"\n',
      },
      {
        blockNumber: 51226210,
        blockTimestamp: "2023-11-14 01:37:39.836 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-08-15T18:30:55.281029063Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: moKKwdFTbPhEGKnYTs4b3aI6IC+dhRkRhhel7IV4tmM=\ndelegator_shares: "321214708072192952266372.937416896783464840"\ndescription:\n  details: Interchain infra. Apoapsis to the Periapsis.\n  identity: 55AE6F8C61BF4749\n  moniker: Blockapsis\n  security_contact: node-operator@chainapsis.com\n  website: https://www.chainapsis.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1444m5prz36s4t4gr7kca5lgc682h94yutqrgpg\nstatus: BOND_STATUS_UNBONDING\ntokens: "320572600374158624522702"\nunbonding_height: "51200303"\nunbonding_ids:\n- "12063"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-04T19:32:39.974623037Z"\n',
      },
      {
        blockNumber: 51227577,
        blockTimestamp: "2023-11-14 01:56:15.472 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "1.000000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-08-15T18:30:55.281029063Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: moKKwdFTbPhEGKnYTs4b3aI6IC+dhRkRhhel7IV4tmM=\ndelegator_shares: "321214708072192952266372.937416896783464840"\ndescription:\n  details: Interchain infra. Apoapsis to the Periapsis.\n  identity: 55AE6F8C61BF4749\n  moniker: Blockapsis\n  security_contact: node-operator@chainapsis.com\n  website: https://www.chainapsis.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1444m5prz36s4t4gr7kca5lgc682h94yutqrgpg\nstatus: BOND_STATUS_UNBONDING\ntokens: "320572600374158624522702"\nunbonding_height: "51200303"\nunbonding_ids:\n- "12063"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-04T19:32:39.974623037Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper155yk4wfn0xqye80exlsr6hu4qdfsvsgwg3jckk",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 52589909,
        blockTimestamp: "2023-11-27 17:30:10.844 +0000 UTC",
        power: 361050,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1ycsqvhadmyw9up40yk3m07ryfqa6rsaedgfde5",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1vqz7mgm47xhx25xu5g9qagnz48naks6pk6fmg2",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 52589909,
        blockTimestamp: "2023-11-27 17:30:10.844 +0000 UTC",
        power: 183348,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1598m7akaux9qz63k2akhaxzpfdu0n6cr49agyw",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper15vlkdnu2c0k0gaclgycnyjm7c5f3hsde034f5p",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 52614910,
        blockTimestamp: "2023-11-27 23:22:03.193 +0000 UTC",
        power: 577226,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1a9efq9vresqrcnpc3mewda2hp2ls6km4qsyzm8",
      },
      {
        blockNumber: 52614911,
        blockTimestamp: "2023-11-27 23:22:04.372 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-05-18T22:47:18.061181490Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: LHzyDzStV6XcLnE5wTKzQtJI5AX8ZLER8sa53t67RaE=\ndelegator_shares: "579714559260955082500762.144832777462161696"\ndescription:\n  details: "PFC - the  Panamanian Flower Company.  \\nTechnical & Community validator,\\nproviding\n    IBC services and https://validator.tools/"\n  identity: 86AC709C230079D0\n  moniker: PFC\n  security_contact: pfc-validator@protonmail.com\n  website: https://pfc.zone/\njailed: true\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper15vlkdnu2c0k0gaclgycnyjm7c5f3hsde034f5p\nstatus: BOND_STATUS_UNBONDING\ntokens: "576648938525837594506649"\nunbonding_height: "52614911"\nunbonding_ids:\n- "2323"\n- "13672"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T23:22:04.372698423Z"\n',
      },
      {
        blockNumber: 52615463,
        blockTimestamp: "2023-11-27 23:29:48.274 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-05-18T22:47:18.061181490Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: LHzyDzStV6XcLnE5wTKzQtJI5AX8ZLER8sa53t67RaE=\ndelegator_shares: "579714559260955082500762.144832777462161696"\ndescription:\n  details: "PFC - the  Panamanian Flower Company.  \\nTechnical & Community validator,\\nproviding\n    IBC services and https://validator.tools/"\n  identity: 86AC709C230079D0\n  moniker: PFC\n  security_contact: pfc-validator@protonmail.com\n  website: https://pfc.zone/\njailed: true\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper15vlkdnu2c0k0gaclgycnyjm7c5f3hsde034f5p\nstatus: BOND_STATUS_UNBONDING\ntokens: "576648938525837594506649"\nunbonding_height: "52614911"\nunbonding_ids:\n- "2323"\n- "13672"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T23:22:04.372698423Z"\n',
      },
      {
        blockNumber: 52615603,
        blockTimestamp: "2023-11-27 23:31:45.072 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2022-05-18T22:47:18.061181490Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: LHzyDzStV6XcLnE5wTKzQtJI5AX8ZLER8sa53t67RaE=\ndelegator_shares: "579714559260955082500762.144832777462161696"\ndescription:\n  details: "PFC - the  Panamanian Flower Company.  \\nTechnical & Community validator,\\nproviding\n    IBC services and https://validator.tools/"\n  identity: 86AC709C230079D0\n  moniker: PFC\n  security_contact: pfc-validator@protonmail.com\n  website: https://pfc.zone/\njailed: true\nmin_self_delegation: "10000000000000000000"\noperator_address: injvaloper15vlkdnu2c0k0gaclgycnyjm7c5f3hsde034f5p\nstatus: BOND_STATUS_UNBONDING\ntokens: "576648938525837594506649"\nunbonding_height: "52614911"\nunbonding_ids:\n- "2323"\n- "13672"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-18T23:22:04.372698423Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Offline",
    operator_address: "injvaloper13gq4msx4z03ye5gef35yjz9nqtg6ja2yqj6csq",
    consensus_address: "injvalcons1a6y6d9wj8ccwztt56xq8532ykdu53z7mspddy4",
    jailed: true,
    status: 1,
    tokens: "2274687081178668744",
    delegator_shares: "2288383750653908504.092467030600936731",
    description: {
      moniker: "Offline",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 52623736,
    unbonding_time: "2023-12-19 01:25:18.58 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-07-02 01:52:07.419 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 52623736,
        blockTimestamp: "2023-11-28 01:25:18.58 +0000 UTC",
        power: 480,
        reason: "double_sign",
      },
      {
        blockNumber: 52623736,
        blockTimestamp: "2023-11-28 01:25:18.58 +0000 UTC",
        jailed: "injvalcons1a6y6d9wj8ccwztt56xq8532ykdu53z7mspddy4",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1nc9ppgynj4fxqlsxq62sh4zfvf59la6rkjy734",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 52643636,
        blockTimestamp: "2023-11-28 06:02:37.607 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-11-24T13:04:52.761106044Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: RL4hNj2kl1IroZybngMglgyRTnYenP+J/covOzi8z98=\ndelegator_shares: "2301583342500000000.000000000000000000"\ndescription:\n  details: Trusted Proof-of-Stake validator across Cosmos universe. Active ecosystem\n    contributor, IBC relayer and chain service provider since 2023.\n  identity: 6CB6AC3E672AAB9D\n  moniker: Node & Validator\n  security_contact: ""\n  website: https://validatorvn.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1nc9ppgynj4fxqlsxq62sh4zfvf59la6rkjy734\nstatus: BOND_STATUS_UNBONDING\ntokens: "2301583342500000000"\nunbonding_height: "52618635"\nunbonding_ids:\n- "13674"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-19T00:14:07.253355317Z"\n',
      },
      {
        blockNumber: 52644717,
        blockTimestamp: "2023-11-28 06:18:23.305 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-11-24T13:04:52.761106044Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: RL4hNj2kl1IroZybngMglgyRTnYenP+J/covOzi8z98=\ndelegator_shares: "2301583342500000000.000000000000000000"\ndescription:\n  details: Trusted Proof-of-Stake validator across Cosmos universe. Active ecosystem\n    contributor, IBC relayer and chain service provider since 2023.\n  identity: 6CB6AC3E672AAB9D\n  moniker: Node & Validator\n  security_contact: ""\n  website: https://validatorvn.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1nc9ppgynj4fxqlsxq62sh4zfvf59la6rkjy734\nstatus: BOND_STATUS_UNBONDING\ntokens: "2301583342500000000"\nunbonding_height: "52618635"\nunbonding_ids:\n- "13674"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-19T00:14:07.253355317Z"\n',
      },
      {
        blockNumber: 52648737,
        blockTimestamp: "2023-11-28 07:15:56.828 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-11-24T13:04:52.761106044Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: RL4hNj2kl1IroZybngMglgyRTnYenP+J/covOzi8z98=\ndelegator_shares: "2301583342500000000.000000000000000000"\ndescription:\n  details: Trusted Proof-of-Stake validator across Cosmos universe. Active ecosystem\n    contributor, IBC relayer and chain service provider since 2023.\n  identity: 6CB6AC3E672AAB9D\n  moniker: Node & Validator\n  security_contact: ""\n  website: https://validatorvn.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1nc9ppgynj4fxqlsxq62sh4zfvf59la6rkjy734\nstatus: BOND_STATUS_UNBONDING\ntokens: "2301583342500000000"\nunbonding_height: "52618635"\nunbonding_ids:\n- "13674"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-19T00:14:07.253355317Z"\n',
      },
      {
        blockNumber: 54108219,
        blockTimestamp: "2023-12-12 07:06:09.32 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-11-24T13:04:52.761106044Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: RL4hNj2kl1IroZybngMglgyRTnYenP+J/covOzi8z98=\ndelegator_shares: "2681123973000000000.000000000000000000"\ndescription:\n  details: Trusted Proof-of-Stake validator across Cosmos universe. Active ecosystem\n    contributor, IBC relayer and chain service provider since 2023.\n  identity: 6CB6AC3E672AAB9D\n  moniker: Node & Validator\n  security_contact: ""\n  website: https://validatorvn.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1nc9ppgynj4fxqlsxq62sh4zfvf59la6rkjy734\nstatus: BOND_STATUS_UNBONDING\ntokens: "2681123973000000000"\nunbonding_height: "54083218"\nunbonding_ids:\n- "13674"\n- "20694"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-02T00:01:34.949983459Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1n6z54tf5rezqurq7vn4xy6gfj9u7m5axen0ycc",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 52733466,
        blockTimestamp: "2023-11-29 02:52:26.422 +0000 UTC",
        power: 325053,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1s0ehetrj5swz0scqtd3r7r2dawwwtgswrxygy7",
      },
      {
        blockNumber: 52736242,
        blockTimestamp: "2023-11-29 03:29:57.237 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.010000000000000000"\n    max_rate: "0.200000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-03-17T08:58:34.354402349Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: nKqj1AHk+by2rDscxWZrfBx1gwNKNqk1Xi4Si3ycEpA=\ndelegator_shares: "325704314951780029786534.577753568935318884"\ndescription:\n  details: Guaranteed availability and up-time backed by a professional blockchain\n    infrastructure team.\n  identity: E83A08BEEE7A70BD\n  moniker: BwareLabs\n  security_contact: validators@bwarelabs.com\n  website: https://bwarelabs.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1n6z54tf5rezqurq7vn4xy6gfj9u7m5axen0ycc\nstatus: BOND_STATUS_UNBONDING\ntokens: "324728211206525720588217"\nunbonding_height: "52733467"\nunbonding_ids:\n- "13792"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-20T02:52:27.146168157Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1f566hkhdhf9s3hskd43nggj7qsc7g0xxtqylr7",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 53270824,
        blockTimestamp: "2023-12-04 04:22:19.412 +0000 UTC",
        power: 144979,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1wgalsmptyz6v7mljaxd7vpcl3u5mget5wvqkeg",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1z8rcledwadkcyykvn20zxr032fnv787vv46hlg",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 53391153,
        blockTimestamp: "2023-12-05 07:56:43.885 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-12-01T04:54:42.668368590Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lADmTwRuaX9bFDWl739xhZu8rUGCT+ZER25ySH5gD7o=\ndelegator_shares: "206994469143917511787.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://staking.coinpayu.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1z8rcledwadkcyykvn20zxr032fnv787vv46hlg\nstatus: BOND_STATUS_UNBONDING\ntokens: "206994469143917511787"\nunbonding_height: "53366152"\nunbonding_ids:\n- "14495"\n- "14535"\n- "14699"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2023-12-26T02:10:20.761684761Z"\n',
      },
      {
        blockNumber: 54053053,
        blockTimestamp: "2023-12-11 15:07:53.34 +0000 UTC",
        power: 212,
        reason: "missing_valset_confirm",
        jailed: "injvalcons16m2l2sal0wn0cv4733ey30kvfrjr8lfv2c837t",
      },
      {
        blockNumber: 54078055,
        blockTimestamp: "2023-12-11 22:35:21.925 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-12-01T04:54:42.668368590Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lADmTwRuaX9bFDWl739xhZu8rUGCT+ZER25ySH5gD7o=\ndelegator_shares: "212704469143917511787.000000000000000000"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://staking.coinpayu.com\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1z8rcledwadkcyykvn20zxr032fnv787vv46hlg\nstatus: BOND_STATUS_UNBONDING\ntokens: "212492469143917511787"\nunbonding_height: "54053054"\nunbonding_ids:\n- "14495"\n- "14535"\n- "14699"\n- "19799"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-01T15:07:54.152618679Z"\n',
      },
      {
        blockNumber: 54248767,
        blockTimestamp: "2023-12-13 15:57:09.96 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-12-01T04:54:42.668368590Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lADmTwRuaX9bFDWl739xhZu8rUGCT+ZER25ySH5gD7o=\ndelegator_shares: "257773140185227670383.466944041934902426"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://staking.coinpayu.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1z8rcledwadkcyykvn20zxr032fnv787vv46hlg\nstatus: BOND_STATUS_UNBONDING\ntokens: "257516220779917511789"\nunbonding_height: "54223766"\nunbonding_ids:\n- "14495"\n- "14535"\n- "14699"\n- "19799"\n- "21621"\n- "21988"\n- "22580"\n- "23194"\n- "23884"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-03T10:07:45.632853955Z"\n',
      },
      {
        blockNumber: 54912681,
        blockTimestamp: "2023-12-20 02:18:45.158 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-12-01T04:54:42.668368590Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lADmTwRuaX9bFDWl739xhZu8rUGCT+ZER25ySH5gD7o=\ndelegator_shares: "215570890836511196595.381836475753966424"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://staking.coinpayu.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1z8rcledwadkcyykvn20zxr032fnv787vv46hlg\nstatus: BOND_STATUS_UNBONDING\ntokens: "215356033908298660082"\nunbonding_height: "54881372"\nunbonding_ids:\n- "14495"\n- "14535"\n- "14699"\n- "19799"\n- "21621"\n- "21988"\n- "22580"\n- "23194"\n- "23884"\n- "33867"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-09T17:29:00.856704117Z"\n',
      },
      {
        blockNumber: 55081900,
        blockTimestamp: "2023-12-21 22:41:43.746 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-12-01T04:54:42.668368590Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: lADmTwRuaX9bFDWl739xhZu8rUGCT+ZER25ySH5gD7o=\ndelegator_shares: "515875230579657548220.571247767413938698"\ndescription:\n  details: A leading staking service platform. Build a sustainable energy future with\n    us!\n  identity: B2F549EFA7FCDA85\n  moniker: Coinpayu\n  security_contact: staking@coinpayu.com\n  website: https://staking.coinpayu.com\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper1z8rcledwadkcyykvn20zxr032fnv787vv46hlg\nstatus: BOND_STATUS_UNBONDING\ntokens: "515361063908298660082"\nunbonding_height: "55056899"\nunbonding_ids:\n- "14495"\n- "14535"\n- "14699"\n- "19799"\n- "21621"\n- "21988"\n- "22580"\n- "23194"\n- "23884"\n- "33867"\n- "37050"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-11T15:47:39.156885991Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1ltu4jgw850x7kg9kl7g8hjtuzatvzfpy0svplt",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 54024717,
        blockTimestamp: "2023-12-11 08:32:14.263 +0000 UTC",
        power: 138079,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1j2ner0vsvch7a0t27r6uh4w44yt80c803dtx3e",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1m5lqvd8kr2s7lx3j2c7cfa33hxrqa3cth9pd56",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 54053053,
        blockTimestamp: "2023-12-11 15:07:53.34 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-11-30T14:22:10.356456358Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: WuZgeZYUg0aHZe1lsXTXBW6o35JGrNw3c0X3UwMDxHU=\ndelegator_shares: "5000000000000000000.000000000000000000"\ndescription:\n  details: Tané is a professional validator, operated by a group of crypto-native\n    product builders, backed by Japanese enterprises. Delegate your tokens, secure\n    the network and earn staking rewards with us!\n  identity: A8709BB7ABF23A73\n  moniker: Tané\n  security_contact: ""\n  website: https://tané.com\njailed: false\nmin_self_delegation: "5000000000000000000"\noperator_address: injvaloper1m5lqvd8kr2s7lx3j2c7cfa33hxrqa3cth9pd56\nstatus: BOND_STATUS_UNBONDING\ntokens: "5000000000000000000"\nunbonding_height: "54028052"\nunbonding_ids:\n- "19211"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-01T09:16:20.016595152Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1lshp7qy8mzlc3fpqjservcxfwg3j52a2cv26n2",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 54135280,
        blockTimestamp: "2023-12-12 13:16:08.013 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.300000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-12-05T15:39:41.117525426Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: kt7jOEslWxCH5C2hw01Mk1L10K65rPQ9neEsLvQGZOM=\ndelegator_shares: "2000000000000000000.000000000000000000"\ndescription:\n  details: Web3 developer and content creator.\n  identity: 65CC7CB0CC69040D\n  moniker: Alessandro Mazza\n  security_contact: ""\n  website: alessandromazza.com\njailed: false\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1lshp7qy8mzlc3fpqjservcxfwg3j52a2cv26n2\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000000000000"\nunbonding_height: "54110279"\nunbonding_ids:\n- "21308"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-02T07:34:45.469852186Z"\n',
      },
      {
        blockNumber: 54151234,
        blockTimestamp: "2023-12-12 17:06:40.741 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.300000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-12-05T15:39:41.117525426Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: kt7jOEslWxCH5C2hw01Mk1L10K65rPQ9neEsLvQGZOM=\ndelegator_shares: "2000000000000000000.000000000000000000"\ndescription:\n  details: Web3 developer and content creator.\n  identity: 65CC7CB0CC69040D\n  moniker: Alessandro Mazza\n  security_contact: ""\n  website: alessandromazza.com\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1lshp7qy8mzlc3fpqjservcxfwg3j52a2cv26n2\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000000000000"\nunbonding_height: "54110279"\nunbonding_ids:\n- "21308"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-02T07:34:45.469852186Z"\n',
      },
      {
        blockNumber: 54158223,
        blockTimestamp: "2023-12-12 18:51:35.592 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.100000000000000000"\n    max_rate: "0.300000000000000000"\n    rate: "0.100000000000000000"\n  update_time: "2023-12-05T15:39:41.117525426Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: kt7jOEslWxCH5C2hw01Mk1L10K65rPQ9neEsLvQGZOM=\ndelegator_shares: "2000000000000000000.000000000000000000"\ndescription:\n  details: Web3 developer and content creator.\n  identity: 65CC7CB0CC69040D\n  moniker: Alessandro Mazza\n  security_contact: ""\n  website: alessandromazza.com\njailed: true\nmin_self_delegation: "1000000000000000000"\noperator_address: injvaloper1lshp7qy8mzlc3fpqjservcxfwg3j52a2cv26n2\nstatus: BOND_STATUS_UNBONDING\ntokens: "2000000000000000000"\nunbonding_height: "54110279"\nunbonding_ids:\n- "21308"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-02T07:34:45.469852186Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1p90whzaz28ayhmqqw0ur8283tsu8ad3jfqz0s0",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 54151234,
        blockTimestamp: "2023-12-12 17:06:40.741 +0000 UTC",
        power: 459637,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1s3suvfquuc8arhsxx3th7qfqq57s7ft4x2z4r2",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper17dt0hpykufxjrc7fh8rxwgv6z9kx8vlgg0qqrs",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 54972350,
        blockTimestamp: "2023-12-20 17:19:53.507 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.100000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2023-12-14T15:32:51.418248787Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: irI+gJ017iZ0Jmhk0LxSqtruUuw9e54emSdqGIHhr8U=\ndelegator_shares: "86372504761041898981.000000000000000000"\ndescription:\n  details: Ninja Garden is a first of its kind Social-Fi X LSD platform. Holders of\n    our $KUNAI token will get to participate in fee sharing and project launchpools\n    across our ecosystem.\n  identity: CDD5B1ED519FFBE4\n  moniker: Ninja Garden | Delegate for Airdrop\n  security_contact: dev@ninja.garden\n  website: https://ninja.garden\njailed: false\nmin_self_delegation: "1"\noperator_address: injvaloper17dt0hpykufxjrc7fh8rxwgv6z9kx8vlgg0qqrs\nstatus: BOND_STATUS_UNBONDING\ntokens: "86372504761041898981"\nunbonding_height: "54947349"\nunbonding_ids:\n- "34990"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-01-10T11:03:29.185902316Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Notional",
    operator_address: "injvaloper16eg6wf2k6v0lzwu2vsrhxhe0tcycgr7jm98nyz",
    consensus_address: "injvalcons1ejxertnje4s4d3gxg5jtf0dde96qthx8yk6mx8",
    jailed: true,
    status: 1,
    tokens: "238164491904312757527",
    delegator_shares: "239119535479227136375.962636237871340774",
    description: {
      moniker: "Notional",
      identity: "0E480E2B83B23D80",
      website: "https://notional.ventures",
      security_contact: "contact@notional.ventures",
      details: "Stake with us on https://notional.ventures/#Delegate",
      imageURL: "",
    },
    unbonding_height: 53094090,
    unbonding_time: "2023-12-23 13:24:52.778 +0000 UTC",
    commission_rate: "0.096900000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2022-08-04 11:48:06.427 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/6ce44a0b3bbd2a99933ccb10a4a46305_360_360.jpg",
  },
  {
    id: "",
    moniker: "Cosmostation",
    operator_address: "injvaloper1rvqzf9u2uxttmshn302anlknfgsatrh5v7fl7e",
    consensus_address: "injvalcons1w7ql594dc4lpp8mrh50znu20n5up0c22v79wz8",
    jailed: false,
    status: 3,
    tokens: "692145772937542020840885",
    delegator_shares: "692838610877673577071555.274321244218889412",
    description: {
      moniker: "Cosmostation",
      identity: "AE4C403A6E7AA1AC",
      website: "https://www.cosmostation.io",
      security_contact: "admin@stamper.network",
      details:
        "Cosmostation validator node. Delegate your tokens and Start Earning Staking Rewards",
      imageURL: "",
    },
    unbonding_height: 25462584,
    unbonding_time: "2023-02-25 00:01:54.261 +0000 UTC",
    commission_rate: "0.089000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.200000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 26098,
    signed: 1714733,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 96.63251851100517,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/909034c1d36c1d1f3e9191f668007805_360_360.jpeg",
  },
  {
    id: "",
    moniker: "Injective Node 0",
    operator_address: "injvaloper1yqdrkyp37w46aant2u80v2rapuuz7eg3rl4arg",
    consensus_address: "injvalcons1wtgxscgespkxn9krwt5uxsr5ff5tv9tycrff52",
    jailed: true,
    status: 1,
    tokens: "50961422179229952718",
    delegator_shares: "51012434597831677316.801907980703245510",
    description: {
      moniker: "Injective Node 0",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 15760397,
    unbonding_time: "2022-10-15 07:14:23.617 +0000 UTC",
    commission_rate: "0.070000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.500000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "StakeWithUs",
    operator_address: "injvaloper1v2xylfeyry808ltt0hwxggzt0fns6uwh74mku3",
    consensus_address: "injvalcons1jsj5jdp99ny02h0cl09dssc7429w7eja6ve35c",
    jailed: false,
    status: 3,
    tokens: "321693652647434507478881",
    delegator_shares: "322338005015845033917503.080464189624786533",
    description: {
      moniker: "StakeWithUs",
      identity: "609F83752053AD57",
      website: "https://stakewith.us",
      security_contact: "node@stakewith.us",
      details:
        "Secured Staking Made Easy. Put Your Crypto to Work - Hassle Free. Disclaimer: Delegators should understand that delegation comes with slashing risk. By delegating to StakeWithUs Pte Ltd, you acknowledge that StakeWithUs Pte Ltd is not liable for any losses on your investment.",
      imageURL: "",
    },
    unbonding_height: 34265240,
    unbonding_time: "2023-06-15 23:31:58.662 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-03-11 08:09:22.396 +0000 UTC",
    proposed: 12081,
    signed: 1654346,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 93.96074484146955,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/3074ffa8484e5d89597d53356bf9d905_360_360.jpg",
  },
  {
    id: "",
    moniker: "Imperator.co",
    operator_address: "injvaloper1esud09zs5754g5nlkmrgxsfdj276xm64cgmd3w",
    consensus_address: "injvalcons1gau93e4l0cm2mdsx953y5zcxk76wtsnwsmswhz",
    jailed: false,
    status: 3,
    tokens: "1655183743606526444131855",
    delegator_shares: "1656840584190717161291530.355448782048697853",
    description: {
      moniker: "Imperator.co",
      identity: "0878BA6BE556C132",
      website: "https://imperator.co/",
      security_contact: "contact@imperator.co",
      details:
        "100% refund on downtime slashing -- Professional Delegated Proof-of-Stake Network Validator",
      imageURL: "",
    },
    unbonding_height: 14775991,
    unbonding_time: "2022-09-23 15:38:37.844 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-12-06 11:46:54.002 +0000 UTC",
    proposed: 62635,
    signed: 1710177,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.6131235768992,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/1855362ac6629cbc7158012eb363e405_360_360.jpg",
  },
  {
    id: "",
    moniker: "KiKi Finance",
    operator_address: "injvaloper12t9868urj32x458n9cjc09zpdxmc4svt3945kk",
    consensus_address: "injvalcons1w84ku3lfqckc27jjp50klwrmsfrppmehgllrvh",
    jailed: true,
    status: 1,
    tokens: "999001000000000000",
    delegator_shares: "1000001000000000000.000000000000000000",
    description: {
      moniker: "KiKi Finance",
      identity: "F224E3F2FF449873",
      website: "https://kiki.finance/",
      security_contact: "",
      details: "https://kiki.finance/",
      imageURL: "",
    },
    unbonding_height: 10888278,
    unbonding_time: "2022-06-09 22:27:46.895 +0000 UTC",
    commission_rate: "1.000000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2022-03-16 16:23:58.276 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/bf7afa09358c3ade451ad89c2ed73505_360_360.jpg",
  },
  {
    id: "",
    moniker: "Citadel.one",
    operator_address: "injvaloper1hsxaln75wjs033t3spd8a0gawl4jvxawn6v849",
    consensus_address: "injvalcons1m5kf3ze83efxsuxajltm5a0w4gh7h42awccxtt",
    jailed: false,
    status: 3,
    tokens: "2572815991982278590523317",
    delegator_shares: "2572815991982278590523317.000000000000000000",
    description: {
      moniker: "Citadel.one",
      identity: "EBB03EB4BB4CFCA7",
      website: "https://citadel.one",
      security_contact: "",
      details:
        "Citadel.one is a multi-asset non-custodial staking platform that lets anyone become a part of decentralized infrastructure and earn passive income. Stake with our nodes or any other validator across multiple networks in a few clicks",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.400000000000000000",
    commission_max_change_rate: "0.030000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 99149,
    signed: 1660608,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 96.58047493403694,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/2826e38259411adafb416505fb948c05_360_360.jpg",
  },
  {
    id: "",
    moniker: "DSRV",
    operator_address: "injvaloper1p90whzaz28ayhmqqw0ur8283tsu8ad3jfqz0s0",
    consensus_address: "injvalcons1s3suvfquuc8arhsxx3th7qfqq57s7ft4x2z4r2",
    jailed: false,
    status: 3,
    tokens: "452148959825312949839398",
    delegator_shares: "452601560486005015242778.302126556964953055",
    description: {
      moniker: "DSRV",
      identity: "CC434B6FE536F51B",
      website: "https://dsrvlabs.com",
      security_contact: "validator@dsrvlabs.com",
      details:
        "DSRV is an integrated blockchain solutions company with the mission of enriching the crypto ecosystem via stronger connectivity. We strive to be your gateway to a suite of all-comprehensive blockchain services. Everything distributed, served complete.",
      imageURL: "",
    },
    unbonding_height: 54151235,
    unbonding_time: "2024-01-02 17:06:41.508 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-10-04 18:04:25.993 +0000 UTC",
    proposed: 17087,
    signed: 1778208,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.23294307630198,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/8349f3ab6852a8419c1987ad9096c605_360_360.jpg",
  },
  {
    id: "",
    moniker: "BitNordic",
    operator_address: "injvaloper1v93fqstgfkqnw080mv8sv5p5fq7n2h7e4kuz5r",
    consensus_address: "injvalcons176jdgr4rdngueud25nhsgdcy688z6gfnq2hawv",
    jailed: false,
    status: 3,
    tokens: "204855318137040708578892",
    delegator_shares: "204855318137040708578892.000000000000000000",
    description: {
      moniker: "BitNordic",
      identity: "8620B2B38D72599B",
      website: "https://bitnordic.com/",
      security_contact: "info@bitnordic.com",
      details: "Your partner in staking & web3",
      imageURL: "",
    },
    unbonding_height: 23983333,
    unbonding_time: "2023-02-05 12:33:46.552 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-09-01 17:30:38.197 +0000 UTC",
    proposed: 7730,
    signed: 1787002,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 96.95722032536654,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/fb775aa6dceba975b4dfed512cf33105_360_360.jpg",
  },
  {
    id: "",
    moniker: "Figment",
    operator_address: "injvaloper1g4d6dmvnpg7w7yugy6kplndp7jpfmf3krtschp",
    consensus_address: "injvalcons1vzrkqls72mmwu7f54whktq6vjttpsyzvfu0l8l",
    jailed: false,
    status: 3,
    tokens: "801648345530307212477597",
    delegator_shares: "806475112926531121475520.311323110404127417",
    description: {
      moniker: "Figment",
      identity: "E5F274B870BDA01D",
      website: "https://figment.io",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 58652976,
    unbonding_time: "2024-02-17 06:24:57.209 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.250000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 30107,
    signed: 1548087,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 88.59292395282635,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/bd5fb87f241bd78a9c4bceaaa849ca05_360_360.jpg",
  },
  {
    id: "",
    moniker: "BitStack-0",
    operator_address: "injvaloper1jnj27uu7c3un75z38tv5x6hcpxsw0f80uqx3a7",
    consensus_address: "injvalcons1up03weqnj6rr5wahjvxw33vgpgxgnnj5xht58q",
    jailed: false,
    status: 1,
    tokens: "999000000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "BitStack-0",
      identity: "",
      website: "https://bitstack.com",
      security_contact: "",
      details: "BitStack makes professional staking.",
      imageURL: "",
    },
    unbonding_height: 12614352,
    unbonding_time: "2022-07-28 06:25:08.3 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-06-01 04:31:32.587 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "redelegate to StakeLab",
    operator_address: "injvaloper1ackww60mtpc24d3wccxz90wyx5gxq0ggwtnw6c",
    consensus_address: "injvalcons19u55v70rk7quuwp7e7ddqyz3jqvlp0pkd3dha4",
    jailed: true,
    status: 1,
    tokens: "263993624663412006045",
    delegator_shares: "265583111249776913143.317076412643147858",
    description: {
      moniker: "redelegate to StakeLab",
      identity: "F12B081334CBE0C6",
      website: "https://www.stakelab.fr",
      security_contact: "",
      details: "Staking Hub for Cosmos ecosystem",
      imageURL: "",
    },
    unbonding_height: 18410012,
    unbonding_time: "2022-11-26 02:43:34.8 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-10-20 08:26:48.302 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/63585765d299338807f158d6aadd2e05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Stakecito",
    operator_address: "injvaloper1ee7xnhczhvu064utmdn48sh0wx7zq3pgy6frgm",
    consensus_address: "injvalcons1q0s2z2rmy43fkdkk4g579c0secqpzstz7ujpmn",
    jailed: false,
    status: 3,
    tokens: "461630425483668986561966",
    delegator_shares: "461630425483668986561966.000000000000000000",
    description: {
      moniker: "Stakecito",
      identity: "D16E26E5C8154E17",
      website: "",
      security_contact: "",
      details: "Securing & Decentralizing PoS Networks.",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.020000000000000000",
    commission_update_time: "2022-02-14 15:39:01.501 +0000 UTC",
    proposed: 16560,
    signed: 1683718,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 94.9323642237028,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/d8d03bfdc35339c30a502b73b9ab6d05_360_360.jpg",
  },
  {
    id: "",
    moniker: "✅ CryptoCrew Validators",
    operator_address: "injvaloper1nq37nq79w2j76xj8qhjzcn6vh0wlx0qk2r7zm6",
    consensus_address: "injvalcons1nh7v9c6ygv7t7td9x058rm0hvh3gld6weyclq9",
    jailed: false,
    status: 3,
    tokens: "834554620307346088711950",
    delegator_shares: "835390009726280251805027.020414889981348802",
    description: {
      moniker: "✅ CryptoCrew Validators",
      identity: "9AE70F9E3EDA8956",
      website: "https://ccvalidators.com",
      security_contact: "support@ccvalidators.com",
      details:
        "CryptoCrew validator & multi-chain IBC-relayer service for INJECTIVE. Based in Europe. t.me/cryptocrew_validators",
      imageURL: "",
    },
    unbonding_height: 17433347,
    unbonding_time: "2022-11-14 05:02:09.517 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-08-17 16:03:30.512 +0000 UTC",
    proposed: 30444,
    signed: 1720431,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.1383519837233,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/7f4b384472efeccffd7685227f698405_360_360.jpg",
  },
  {
    id: "",
    moniker: "[NODERS]TEAM",
    operator_address: "injvaloper156anrjzys4tnkawruadm4cakrk9vxrd567myrl",
    consensus_address: "injvalcons1kd3n59ts59um7tmyf9s8k839zvuqz58fgyyqz3",
    jailed: true,
    status: 2,
    tokens: "0",
    delegator_shares: "0.000000000000000000",
    description: {
      moniker: "[NODERS]TEAM",
      identity: "B38EBF2F38B998F4",
      website: "https://noders-stake.com/",
      security_contact: "dudava45@gmailc.com",
      details: "Tell me what node you set, and I will tell you who you are",
      imageURL: "",
    },
    unbonding_height: 17445515,
    unbonding_time: "2022-11-14 08:52:02.968 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-10-08 09:27:15.192 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-19 07:32:19.048 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/ddd2f2bfbde48c51d2c38424e1cdcc05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Ubik Capital",
    operator_address: "injvaloper1h4t5dqenq86znza06thc64pzyxtme7zltyeg8k",
    consensus_address: "injvalcons1yhgm6mh5mjytxzj8zzpzs8s9j5fsxcrwvt9gjg",
    jailed: false,
    status: 3,
    tokens: "696053860037884724311370",
    delegator_shares: "696053860037884724311370.000000000000000000",
    description: {
      moniker: "Ubik Capital",
      identity: "8265DEAF50B61DF7",
      website: "https://www.ubik.capital",
      security_contact: "contact@ubik.capital",
      details:
        "Ubik Capital secures major proof of stake networks and is a trusted staking provider with years of industry experience. By delegating to us, you agree to the Terms of Service at: https://ubik.capital",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-05-30 13:04:01.854 +0000 UTC",
    proposed: 25536,
    signed: 1689185,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 84.9969567863664,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/b3273b5e67fbb587d207293e478aab05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Do Not Delegate",
    operator_address: "injvaloper14v0kl7aaylnz2wchm4mlfnyk6xdvcatgcn4f2x",
    consensus_address: "injvalcons102z0j2emsly4ns2msp2enawtdsyyg59a7swzhr",
    jailed: true,
    status: 1,
    tokens: "324031777835313565",
    delegator_shares: "327945425258263425.534667251796285716",
    description: {
      moniker: "Do Not Delegate",
      identity: "0000000000000000",
      website: "",
      security_contact: "",
      details: "Do Not Delegate",
      imageURL: "",
    },
    unbonding_height: 27977617,
    unbonding_time: "2023-03-27 19:08:19.87 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.500000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-10-09 14:58:19.966 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Stakely.io",
    operator_address: "injvaloper1mwerm24kce7dcl59t6n0caw7c8zxgkrjj9svwj",
    consensus_address: "injvalcons1688g0wlgndehf92pd6xyfw04353h94w3cca4p3",
    jailed: false,
    status: 3,
    tokens: "1177954181286518716810835",
    delegator_shares: "1180313594340513280432311.974739794616586805",
    description: {
      moniker: "Stakely.io",
      identity: "55A5F88B4ED52D3E",
      website: "https://stakely.io/",
      security_contact: "admin@stakely.io",
      details:
        "🔥 Professional validator highly experienced in PoS 🔥 Slashing protection & Eligible for airdrops | Learn with our staking guides, video tutorials and FAQs | Part of the commission of our nodes will go to our Multicoin Faucet funds and other tools",
      imageURL: "",
    },
    unbonding_height: 42752126,
    unbonding_time: "2023-09-13 18:16:01.501 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-06-14 15:17:55.076 +0000 UTC",
    proposed: 44201,
    signed: 1638556,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 93.65713700174199,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/4c88923608ce72dd4d6ebb8b93e5d005_360_360.jpg",
  },
  {
    id: "",
    moniker: "Smart Stake 📈📊",
    operator_address: "injvaloper1xwsnq88kc8wcrp34qenxf3dvhl5n02yj93u755",
    consensus_address: "injvalcons105keds2xx2rpxda2vtqw7n3u5ztvu9n9z9duxt",
    jailed: false,
    status: 3,
    tokens: "1110123457485698965029835",
    delegator_shares: "1110123457485698965029835.000000000000000000",
    description: {
      moniker: "Smart Stake 📈📊",
      identity: "DD06F013A474ACA3",
      website: "analytics.smartstake.io/injective",
      security_contact: "info@smartstake.io",
      details:
        "Your hub for validator, network, and decentralization analytics. Auto compound via Restake. Running IBC relayers. Support @ t.me/SmartStake. Like our services? Delegate on any of the networks @ smartstake.io",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2022-05-12 13:20:22.37 +0000 UTC",
    proposed: 41680,
    signed: 1703877,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 96.85901370984244,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/2d924fd3a739f81b89e55343c40f9b05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Chorus One",
    operator_address: "injvaloper14yeq3lkajldaggj28hmq8xng9xux7x5g46hezv",
    consensus_address: "injvalcons1l64y9vxrtqk0n6lyh4fqs2usvdkghua9drdha0",
    jailed: false,
    status: 3,
    tokens: "543077650153049343592965",
    delegator_shares: "544710144657341729445595.755213575131174300",
    description: {
      moniker: "Chorus One",
      identity: "00B79D689B7DC1CE",
      website: "https://chorus.one",
      security_contact: "security@chorus.one",
      details:
        "Secure Injective and shape its future by delegating to Chorus One, a highly secure and stable validator. By delegating, you agree to the terms of service at: https://chorus.one/tos",
      imageURL: "",
    },
    unbonding_height: 20110503,
    unbonding_time: "2022-12-16 20:36:01.505 +0000 UTC",
    commission_rate: "0.075000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2021-06-30 13:00:00 +0000 UTC",
    proposed: 20413,
    signed: 1757124,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 97.60339771463242,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/3a844f583b686ec5285403694b738a05_360_360.jpg",
  },
  {
    id: "",
    moniker: "INJ",
    operator_address: "injvaloper173h9qv2vqvnjd58wel9y8rf33vpzfklhhj8lg6",
    consensus_address: "injvalcons12fgc00xxvh7lnj7c4kgq7s9deltasnwec2ve55",
    jailed: true,
    status: 1,
    tokens: "1010999999999999999",
    delegator_shares: "1010999999999999999.000000000000000000",
    description: {
      moniker: "INJ",
      identity: "",
      website: "injdojo.exchange",
      security_contact: "injdojo_validator@gmail.com",
      details: "",
      imageURL: "",
    },
    unbonding_height: 10908947,
    unbonding_time: "2022-06-10 12:15:24.032 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-04-28 17:56:05.776 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Crosnest",
    operator_address: "injvaloper1fqrdtx7pyps6eytn3356j9cs4f8zl0eevlt3rt",
    consensus_address: "injvalcons1m002pepun6crws3dpcnu8lldlp5ja8yhcycag7",
    jailed: false,
    status: 3,
    tokens: "765664793355841769646166",
    delegator_shares: "765664793355841769646166.000000000000000000",
    description: {
      moniker: "Crosnest",
      identity: "5F1D6AC7EA588676",
      website: "https://www.cros-nest.com",
      security_contact: "chainmaster@cros-nest.com",
      details:
        "We operate secure and redundant validator infrastructure for PoS blockchains and actively participate in IBC relaying with over $350M Total Relayed Value",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.150000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-08-18 15:39:59.217 +0000 UTC",
    proposed: 28925,
    signed: 1784881,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.82730597318164,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/4e8a6ee78ed698828e6c2baec569d305_360_360.jpg",
  },
  {
    id: "",
    moniker: "B-Harvest",
    operator_address: "injvaloper1zpy3qf7us3m0pxpqkp72gzjv55t70huy33t47x",
    consensus_address: "injvalcons1ajt4ahdfyd65c6axq3xuvpqmxht6myk0xrqard",
    jailed: false,
    status: 3,
    tokens: "557956142507920263377353",
    delegator_shares: "558514657165085348725532.973645327161650377",
    description: {
      moniker: "B-Harvest",
      identity: "8957C5091FBF4192",
      website: "https://bharvest.io",
      security_contact: "contact@bharvest.io",
      details: "Provides secure validation services for dPoS networks",
      imageURL: "",
    },
    unbonding_height: 18726699,
    unbonding_time: "2022-11-30 00:31:25.423 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2022-09-24 00:37:39.609 +0000 UTC",
    proposed: 21085,
    signed: 1761194,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.50293344123003,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/3d5a3bd02e0c30db7949a371bbc4d705_360_360.jpg",
  },
  {
    id: "",
    moniker: " AutoStake.com",
    operator_address: "injvaloper1acgud5qpn3frwzjrayqcdsdr9vkl3p6hrz34ts",
    consensus_address: "injvalcons17wdljvtvqlj0ylhrr9glwgrc83vdp8q3q5pvas",
    jailed: false,
    status: 3,
    tokens: "2728350121118344623321811",
    delegator_shares: "2731081201760006108520491.797006840821176253",
    description: {
      moniker: " AutoStake.com",
      identity: "4D3303E20A4D2C32",
      website: "https://autostake.com",
      security_contact: "support@autostake.com",
      details:
        "Soft slash insurance backed by a SAFU fund 💸 Auto-compound your rewards to maximize your INJ here: https://wallet.autostake.com/injective 💸 Connect with us at: https://linktr.ee/autostake",
      imageURL: "",
    },
    unbonding_height: 15128530,
    unbonding_time: "2022-10-01 07:29:09.035 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2021-10-12 08:42:22.385 +0000 UTC",
    proposed: 105067,
    signed: 1691957,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.40709370037057,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/051cdbd6d051d3cafcdb45377c6b5c05_360_360.jpg",
  },
  {
    id: "",
    moniker: "test-node",
    operator_address: "injvaloper1xwvvcg6j2gvfsfg5h9cgp57057n9mm0kryfcj2",
    consensus_address: "injvalcons1ewwwp7nvjkw7un9xf8amqpglvc0yp9883hx4ya",
    jailed: true,
    status: 1,
    tokens: "5495000000000000001",
    delegator_shares: "5500000000000000000.000000000000000001",
    description: {
      moniker: "test-node",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 24849568,
    unbonding_time: "2023-02-17 01:37:38.521 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-10-25 10:09:24.571 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "danku_zone w DAIC",
    operator_address: "injvaloper1ltu4jgw850x7kg9kl7g8hjtuzatvzfpy0svplt",
    consensus_address: "injvalcons1j2ner0vsvch7a0t27r6uh4w44yt80c803dtx3e",
    jailed: false,
    status: 3,
    tokens: "199016622607877830147088",
    delegator_shares: "199215838253344800738883.685612178571233321",
    description: {
      moniker: "danku_zone w DAIC",
      identity: "C92C6965D89F07A3",
      website: "https://daic.capital/danku_zone/",
      security_contact: "office@daic.capital",
      details:
        "The official validator node from danku_r (YouTube, Twitter, Medium) run by DAIC (https://t.me/validator_danku_DAIC)",
      imageURL: "",
    },
    unbonding_height: 54024718,
    unbonding_time: "2024-01-01 08:32:14.98 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2022-11-01 18:48:17.088 +0000 UTC",
    proposed: 7432,
    signed: 1653742,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 93.35274625966463,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/b16bcadbedcfc61763187a5b2657c205_360_360.jpg",
  },
  {
    id: "",
    moniker: " Oni ⛩️",
    operator_address: "injvaloper1f566hkhdhf9s3hskd43nggj7qsc7g0xxtqylr7",
    consensus_address: "injvalcons1wgalsmptyz6v7mljaxd7vpcl3u5mget5wvqkeg",
    jailed: false,
    status: 3,
    tokens: "146786185698605461780285",
    delegator_shares: "146933117949547806821147.579650359795774547",
    description: {
      moniker: " Oni ⛩️",
      identity: "5A8AB49CF5CAAF3C",
      website: "www.onivalidator.com",
      security_contact: "onivalidator@protonmail.com",
      details:
        "The Oni Protectorate ⚛️ Validator for the Cosmos. Friend to the Cosmonaut.",
      imageURL: "",
    },
    unbonding_height: 53270825,
    unbonding_time: "2023-12-25 04:22:20.127 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2024-01-29 04:49:04.044 +0000 UTC",
    proposed: 5488,
    signed: 1706917,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.05435763714773,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/a0696b953ceca5ca4fa36f3b00e91705_360_360.jpg",
  },
  {
    id: "",
    moniker: "Do not delegate to this validator",
    operator_address: "injvaloper18lmmdf27kx24ya8hvg4qvs3u8hhx87ekymvyjk",
    consensus_address: "injvalcons1gqdw5nq4uqwrcv9smaws0ktg7k0nfkxsjqzgfy",
    jailed: false,
    status: 1,
    tokens: "10000000000000000000",
    delegator_shares: "10000000000000000000.000000000000000000",
    description: {
      moniker: "Do not delegate to this validator",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2022-11-28 05:46:46.295 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Blockapsis",
    operator_address: "injvaloper1444m5prz36s4t4gr7kca5lgc682h94yutqrgpg",
    consensus_address: "injvalcons1lzt3tzhath5argv9yxfsfcsklc8gz2jx0kvluk",
    jailed: false,
    status: 3,
    tokens: "321343038462659288099035",
    delegator_shares: "321986689350058233190366.243857138685080989",
    description: {
      moniker: "Blockapsis",
      identity: "55AE6F8C61BF4749",
      website: "https://infra.keplr.app",
      security_contact: "node-operator@chainapsis.com",
      details: "Interchain infra. Apoapsis to the Periapsis.",
      imageURL: "",
    },
    unbonding_height: 51200303,
    unbonding_time: "2023-12-04 19:32:39.974 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-08-15 18:30:55.281 +0000 UTC",
    proposed: 12122,
    signed: 1686858,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.14435272800483,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/f7f5e725a63944b1ccd27b5ab99da405_360_360.jpg",
  },
  {
    id: "",
    moniker: "preprodtestingval",
    operator_address: "injvaloper1ysmsl693cu5urzyp689t26a3efra98pt82qc6c",
    consensus_address: "injvalcons1n8zmdcunyrg3k9fylxw5velpn665qp73ezkenl",
    jailed: true,
    status: 1,
    tokens: "999000534713928049",
    delegator_shares: "1000000000000000000.506450499482259251",
    description: {
      moniker: "preprodtestingval",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 24882460,
    unbonding_time: "2023-02-17 11:53:10.636 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-01-10 07:59:14.039 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Kiln",
    operator_address: "injvaloper1rf0fczrw2gnhuju86kmjtku4dvf9dcc2mpe7pe",
    consensus_address: "injvalcons1xl88rtvv6nwqrkre3cgr5uyhskghpqtfy3aaxf",
    jailed: false,
    status: 3,
    tokens: "639401850459857542195285",
    delegator_shares: "639401850459857542195285.000000000000000000",
    description: {
      moniker: "Kiln",
      identity: "9B74364A5E526BED",
      website: "https://kiln.fi",
      security_contact: "",
      details: "Enterprise-grade staking made easy",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-01-24 08:53:52.873 +0000 UTC",
    proposed: 19970,
    signed: 1730680,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.26747720364742,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/e976400f2c6613037aa555dd11394305_360_360.jpg",
  },
  {
    id: "",
    moniker: "Frens (🤝,🤝)",
    operator_address: "injvaloper1m4pj2y9qrm77m9gsa8uu7xj6xjyy7du5hg2t2d",
    consensus_address: "injvalcons1kmxsj4ak8kmnazkqu5wdphjn4aq2c37hkz63u6",
    jailed: false,
    status: 3,
    tokens: "451705185493423608921656",
    delegator_shares: "455335136360699138610258.924758739499130359",
    description: {
      moniker: "Frens (🤝,🤝)",
      identity: "C47845226662AF47",
      website: "https://frens.army",
      security_contact: "marc@frens.army",
      details: "Your friendly validator for cosmos chains",
      imageURL: "",
    },
    unbonding_height: 49921749,
    unbonding_time: "2023-11-22 06:10:37.018 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.500000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-01-12 17:42:17.767 +0000 UTC",
    proposed: 16835,
    signed: 1765113,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.25393621316108,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/41a14755f6dcc57604ebfe75e3e4ed05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Everstake",
    operator_address: "injvaloper134dct56cq5v7uerxcy2cn4m06mqf4dxrlgpp24",
    consensus_address: "injvalcons175ctmkculqfkwzn0du09w3r56ny38p04w8fen0",
    jailed: false,
    status: 3,
    tokens: "2026865971313876430364918",
    delegator_shares: "2037030769110475855199520.547737675384555717",
    description: {
      moniker: "Everstake",
      identity: "EF5AC70C00BECEDC",
      website: "https://everstake.one",
      security_contact: "",
      details:
        "Everstake is a staking-as-a-service company. We help institutional investors and regular token holders to profit off their crypto assets. Choose the most promising projects, delegate with Everstake, and make a stable passive income.",
      imageURL: "",
    },
    unbonding_height: 24872182,
    unbonding_time: "2023-02-17 08:41:44.623 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-01-19 11:42:48.05 +0000 UTC",
    proposed: 76219,
    signed: 1708983,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.23719620319183,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/fa01e6109b3fd4579c4bdd445d75ad05_360_360.jpg",
  },
  {
    id: "",
    moniker: "please redelegate - shutting down",
    operator_address: "injvaloper125j4jg5d5hs3xvgfn0me69kgdlv9xe9zknvcz4",
    consensus_address: "injvalcons1a98m2mn0pwcq8pf7v5wnd9xfv8xu82xllq0w2v",
    jailed: true,
    status: 1,
    tokens: "1014740691543456699671",
    delegator_shares: "1015756446838854420387.839154027481817692",
    description: {
      moniker: "please redelegate - shutting down",
      identity: "D75509198CE782A6",
      website: "https://blockpane.com",
      security_contact: "security@blockpane.com",
      details: "🔥 Fast, datacenter-hosted, bare-metal validators 🔥",
      imageURL: "",
    },
    unbonding_height: 51309691,
    unbonding_time: "2023-12-05 22:41:06.527 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-01-19 17:59:17.225 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/c5b1dfddf88bb3a757a8a9c66651e105_360_360.jpg",
  },
  {
    id: "",
    moniker: "Tedcrypto.io 🧸 | TedLotto",
    operator_address: "injvaloper1cpvpauaevandec0vvy6sq4axu7pz0yxtt0eswy",
    consensus_address: "injvalcons1u9yx0lpxl42ezcelt5nqrehzm3dyv3c8athr3m",
    jailed: true,
    status: 2,
    tokens: "0",
    delegator_shares: "0.000000000000000000",
    description: {
      moniker: "Tedcrypto.io 🧸 | TedLotto",
      identity: "6D5F63F1DDCF0404",
      website: "https://www.tedcrypto.io/",
      security_contact: "support@tedcrypto.io",
      details:
        "Bringing fun to staking. Delegate and grab your free lotto tickets here: https://lotto.tedcrypto.io",
      imageURL: "",
    },
    unbonding_height: 24896580,
    unbonding_time: "2023-02-17 16:23:39.33 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-01-20 11:55:15.803 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/84110931964a58bc811be5b8e19e7605_360_360.jpg",
  },
  {
    id: "",
    moniker: "Golden Ratio Staking",
    operator_address: "injvaloper1agu7gu9ay39jkaccsfnt0ykjce6daycjuzyg2a",
    consensus_address: "injvalcons1udfutjylkcxja07z7gu0rnq3vu7fsrj95khtjf",
    jailed: false,
    status: 3,
    tokens: "260358648208422961297417",
    delegator_shares: "260358648208422961297417.000000000000000000",
    description: {
      moniker: "Golden Ratio Staking",
      identity: "1C32EF4035953E8B",
      website: "https://www.goldenstaking.com",
      security_contact: "info@goldenstaking.com",
      details:
        "Interchain Relayer and Validator. Powered By The Most Beautiful Number In The Cosmos!",
      imageURL: "",
    },
    unbonding_height: 26849905,
    unbonding_time: "2023-03-13 22:42:35.219 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-01-20 14:01:43.882 +0000 UTC",
    proposed: 9727,
    signed: 1676179,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 94.76223987131799,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/a9c3a52a07f0c9d7ec2ef0714eda1305_360_360.jpg",
  },
  {
    id: "",
    moniker: "CosmosPug 🍒",
    operator_address: "injvaloper13aanu4acwtjh68a6ec5gm200nx6zqcyqz8r0jq",
    consensus_address: "injvalcons1c0tk0epdxdw9yvceg6ua9vr539nkcqaut2fuks",
    jailed: true,
    status: 1,
    tokens: "4526000000000000000",
    delegator_shares: "4530530530530530530.530530530530530530",
    description: {
      moniker: "CosmosPug 🍒",
      identity: "E831E23101940CAC",
      website: "https://cosmospug.com",
      security_contact: "contact@cosmospug.com",
      details: "CosmosPug validator",
      imageURL: "",
    },
    unbonding_height: 24881943,
    unbonding_time: "2023-02-17 11:43:33.517 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-01-20 16:07:22.053 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/b4616d40e299a8626b20a7e1a2577905_360_360.jpg",
  },
  {
    id: "",
    moniker: "SmartNodes",
    operator_address: "injvaloper1vwzmwq3vsu7pg24v5pxqvgrp82acl6fxl23ent",
    consensus_address: "injvalcons1l7f6km876mas6zudvraduc7wldmpmh3j3vg44q",
    jailed: true,
    status: 1,
    tokens: "10000000005997459",
    delegator_shares: "10020009504150290.422653076371291876",
    description: {
      moniker: "SmartNodes",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 25751828,
    unbonding_time: "2023-02-28 10:59:53.408 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-01-20 19:55:37.67 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "metaONE",
    operator_address: "injvaloper1aqhzfz4ssqj5h95ntwgfhtsd65knp0e7tltszk",
    consensus_address: "injvalcons1h6dmt4lcsjlvjxary0pn2rlwzqgn9d8zxelhwv",
    jailed: true,
    status: 1,
    tokens: "66751432294868333",
    delegator_shares: "66818137066682419.160927597136438195",
    description: {
      moniker: "metaONE",
      identity: "",
      website: "https:\\\\metaone.solutions",
      security_contact: "metaoneklas@gmail.com",
      details: "",
      imageURL: "",
    },
    unbonding_height: 28838906,
    unbonding_time: "2023-04-07 08:43:21.397 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.150000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-02-04 09:58:44.379 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "POSTHUMAN ꝏ DVS",
    operator_address: "injvaloper1e84fr6cxgcflv3fc9ey6n8425au7zx6wsztrle",
    consensus_address: "injvalcons1qjjrwp3vgz0628kf3fxavjfef0gchkkh76f804",
    jailed: false,
    status: 3,
    tokens: "401255397219568305932717",
    delegator_shares: "401657049344284088547521.266931362027917789",
    description: {
      moniker: "POSTHUMAN ꝏ DVS",
      identity: "8A9FC930E1A980D6",
      website: "https://posthuman.digital",
      security_contact: "validator@posthuman.digital",
      details:
        "100% refund in case of slashing ⚛️ MEꝏWE 🤲 Part of the profits goes to charity",
      imageURL: "",
    },
    unbonding_height: 29067800,
    unbonding_time: "2023-04-10 02:24:12.132 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-02-15 18:59:47.758 +0000 UTC",
    proposed: 14819,
    signed: 1753171,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 97.74148013712443,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/a36cb442d15672ecd180b29c698a2505_360_360.jpg",
  },
  {
    id: "",
    moniker: "w3coins",
    operator_address: "injvaloper1tly62qxj2t8wz2zw4d7p37jcysvgasqa0zjtlk",
    consensus_address: "injvalcons1rq3jrrc4xxagcjj9k5as3jymlr3xakvrz88hye",
    jailed: false,
    status: 2,
    tokens: "62072122099768168624223",
    delegator_shares: "62508367066824064314905.645615378334295349",
    description: {
      moniker: "w3coins",
      identity: "63C62007AB5345E3",
      website: "https://www.w3coins.io",
      security_contact: "info@w3coins.io",
      details:
        "w3coins is a Professional Validator and web3 Venture Capital. Stake Your Cryptocurrency with us to Maximize Your Earnings.",
      imageURL: "",
    },
    unbonding_height: 58887458,
    unbonding_time: "2024-02-19 13:16:03.994 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-02-27 08:18:33.328 +0000 UTC",
    proposed: 4150,
    signed: 1462638,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/1b01fcc940b47fdbe4d48a0fa192b205_360_360.jpg",
  },
  {
    id: "",
    moniker: "Architect Nodes",
    operator_address: "injvaloper1gzycxekfxq2hvu9jt255aajvlakay9nvs4at6q",
    consensus_address: "injvalcons1d2dupu6ydkf8pfjd9s3wnwd59al7yfsw6z08rn",
    jailed: true,
    status: 1,
    tokens: "9999999999999999",
    delegator_shares: "9999999999999999.000000000000000000",
    description: {
      moniker: "Architect Nodes",
      identity: "AB730C3A241BB718",
      website: "",
      security_contact: " ",
      details: "Baremetal validator and IBC relayer operator",
      imageURL: "",
    },
    unbonding_height: 37207514,
    unbonding_time: "2023-07-19 13:51:55.437 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-02-27 04:44:59.766 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/447b443109bfb69adee7f285e95c0b05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Stake.tips",
    operator_address: "injvaloper1hz3svgdhmv67lsqlduu0tcnd3f75c0xrzcsgtk",
    consensus_address: "injvalcons145v7xgzhh9e3k49ty2vfa548mtmjnfwps9er4r",
    jailed: true,
    status: 1,
    tokens: "1451696734965279447",
    delegator_shares: "1453149097117197730.529152496068815570",
    description: {
      moniker: "Stake.tips",
      identity: "",
      website: "https://stake.tips",
      security_contact: "hello@stake.tips",
      details:
        "Stake.tips is committed to supporting unique projects and talented authors. By choosing our platform, you can earn rewards as well as help these projects and individuals succeed and achieve their goals. Find out more: Stake.tips",
      imageURL: "",
    },
    unbonding_height: 28807939,
    unbonding_time: "2023-04-06 23:43:19.895 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-02-27 19:01:27.159 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "ECO Stake 🌱",
    operator_address: "injvaloper1vqz7mgm47xhx25xu5g9qagnz48naks6pk6fmg2",
    consensus_address: "injvalcons1598m7akaux9qz63k2akhaxzpfdu0n6cr49agyw",
    jailed: false,
    status: 3,
    tokens: "185072180784284066572297",
    delegator_shares: "185257438093120090626007.598969085612186308",
    description: {
      moniker: "ECO Stake 🌱",
      identity: "5992A6D423A406D6",
      website: "https://ecostake.com",
      security_contact: "support@ecostake.com",
      details:
        "ECO Stake is a climate positive validator 🌱 Carbon neutral and 10% of commission is donated to climate causes 🌍 Check out REStake.app on our website and let us auto-compound your staking rewards for you 🔄",
      imageURL: "",
    },
    unbonding_height: 52589910,
    unbonding_time: "2023-12-18 17:30:11.669 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-02-27 22:41:24.917 +0000 UTC",
    proposed: 6892,
    signed: 1635892,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 94.25818108813492,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/9d337c16fa39ef101c37131dbec2cf05_360_360.jpg",
  },
  {
    id: "",
    moniker: "BwareLabs",
    operator_address: "injvaloper1n6z54tf5rezqurq7vn4xy6gfj9u7m5axen0ycc",
    consensus_address: "injvalcons1s0ehetrj5swz0scqtd3r7r2dawwwtgswrxygy7",
    jailed: false,
    status: 3,
    tokens: "326034207539769082729188",
    delegator_shares: "327014236992332377413595.927921925733460626",
    description: {
      moniker: "BwareLabs",
      identity: "E83A08BEEE7A70BD",
      website: "https://bwarelabs.com",
      security_contact: "validators@bwarelabs.com",
      details:
        "Guaranteed availability and up-time backed by a professional blockchain infrastructure team.",
      imageURL: "",
    },
    unbonding_height: 52733467,
    unbonding_time: "2023-12-20 02:52:27.146 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-17 08:58:34.354 +0000 UTC",
    proposed: 12317,
    signed: 1782435,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.95298499949662,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/a7d03c9b2155a6821070eeecb4c24705_360_360.jpg",
  },
  {
    id: "",
    moniker: "Chill Validation",
    operator_address: "injvaloper1etursa3ccgkrp587449tyw8rcfgrzfwkc6a7pc",
    consensus_address: "injvalcons1wp0e99d8mqrkxdtgaltjnj8gpqk85ukdl2c8ps",
    jailed: true,
    status: 1,
    tokens: "119880000000000000000",
    delegator_shares: "120000000000000000000.000000000000000000",
    description: {
      moniker: "Chill Validation",
      identity: "3820B9E30B3BD99E",
      website: "www.chillvalidation.com",
      security_contact: "",
      details:
        "Chill Validation Services - We take care of the Validation so that you can Chill :)",
      imageURL: "",
    },
    unbonding_height: 27978268,
    unbonding_time: "2023-03-27 19:19:33.897 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-03-01 14:28:10.565 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/f0238d018c8256e54e8109406786e505_360_360.jpg",
  },
  {
    id: "",
    moniker: "Daily ExtraRewards | Nodeof Cosmonauts",
    operator_address: "injvaloper1d7uejj7he40g6g999dlpfg5egeq9jvkmdzs066",
    consensus_address: "injvalcons18lk4yph2lfhe05jrxr5qnxm8edput626a9uhcn",
    jailed: true,
    status: 1,
    tokens: "17756835730508460",
    delegator_shares: "17810122201254418.588897353682991257",
    description: {
      moniker: "Daily ExtraRewards | Nodeof Cosmonauts",
      identity: "EDEA30F3AD071CD2",
      website: "https://linktr.ee/NodeOfCosmonauts",
      security_contact: "Telegram t.me/pastuono",
      details:
        "Injective Chain Validator - Daily Extra AutoRewards Promo Available! - Join and Ask us how on Telegram https://t.me/NodeOfCosmonauts - Built with ❤️ by NodeOfCosmonauts Team - Slash Protected",
      imageURL: "",
    },
    unbonding_height: 32593192,
    unbonding_time: "2023-05-25 01:01:10.717 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2023-03-21 09:45:23.625 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/ed8fd11c2a19472bf4871d4dc66c8405_360_360.jpg",
  },
  {
    id: "",
    moniker: "moonshot | 100% MEV Return to Stakers",
    operator_address: "injvaloper18lftv08vsplm39ht2xysp7wsauch03nwfqckxh",
    consensus_address: "injvalcons1hcwkpfhct63wsukxq3hafwu4kuzuandfh7z4ym",
    jailed: true,
    status: 1,
    tokens: "2000000000000000000",
    delegator_shares: "2000000000000000000.000000000000000000",
    description: {
      moniker: "moonshot | 100% MEV Return to Stakers",
      identity: "CEF65DBA51D8A57D",
      website: "https://www.moonshot.army",
      security_contact: "info@moonshot.army",
      details:
        "moonshot provides highly available secure infrastructure hosted on dedicated servers for Injective staking with 24/7 monitoring",
      imageURL: "",
    },
    unbonding_height: 33890998,
    unbonding_time: "2023-06-11 00:24:08.645 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-12 22:12:13.259 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/7d42b794d3c19239b2b261f8a6344505_360_360.jpg",
  },
  {
    id: "",
    moniker: "x",
    operator_address: "injvaloper1dxnsuxezqxnnuxnkhktd46p7nkcvdaxnjt5xu0",
    consensus_address: "injvalcons19ayf3rc69uqg6k79fxj9fjy7ak503ernhsfvgc",
    jailed: true,
    status: 1,
    tokens: "9990000000000000000",
    delegator_shares: "10000000000000000000.000000000000000000",
    description: {
      moniker: "x",
      identity: "x",
      website: "",
      security_contact: "",
      details: "x",
      imageURL: "",
    },
    unbonding_height: 28007472,
    unbonding_time: "2023-03-28 03:44:51.064 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-04 16:30:51.311 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Terran One",
    operator_address: "injvaloper175x4lvxpwmmwt5m76yqz0ace8dwkjyjjj7sxdj",
    consensus_address: "injvalcons1m8shfyz7cu7w0umgwgmhk59nmatzlvu7g56t6a",
    jailed: true,
    status: 1,
    tokens: "398000043607455703",
    delegator_shares: "398398434073879607.128541057493400115",
    description: {
      moniker: "Terran One",
      identity: "BA19D70D1099E80E",
      website: "https://www.terran.one/",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 42915029,
    unbonding_time: "2023-09-15 07:18:51.004 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.025000000000000000",
    commission_update_time: "2023-03-07 05:04:37.397 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/f0b5d1c943bf458d140ca363ed14c605_360_360.jpg",
  },
  {
    id: "",
    moniker: "Vault Staking",
    operator_address: "injvaloper1r5sv0r4t2uu3hxrg4ectp2k75u7vn2g9u64lvh",
    consensus_address: "injvalcons10kmmtmd85u78typdnsk72tn0s56y69lvcwpdpu",
    jailed: true,
    status: 1,
    tokens: "457670384877892625299",
    delegator_shares: "459505573426146914008.476209216276303140",
    description: {
      moniker: "Vault Staking",
      identity: "1F3068FC9F81343C",
      website: "",
      security_contact: "hello@vaultstaking.com",
      details:
        "Vault Staking is a trusted validator serving the Cosmos community. Our team members are located around the world, always available to answer questions and keep the highest uptime.",
      imageURL: "",
    },
    unbonding_height: 36966228,
    unbonding_time: "2023-07-16 23:44:57.446 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.500000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-04-30 03:56:09.711 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/cde7b736fdc7e7af4805ece83eb05705_360_360.jpg",
  },
  {
    id: "",
    moniker: "01node",
    operator_address: "injvaloper17y9pmvrwcmytrvsurduj7ts0s8unzr55a6ffa3",
    consensus_address: "injvalcons1dflwep4n9kmekqvtuu66ef3m5hj06u56kr2q8c",
    jailed: true,
    status: 1,
    tokens: "1626111896953767804576",
    delegator_shares: "1632632396692692618130.255364321154220106",
    description: {
      moniker: "01node",
      identity: "7BDD4C2E94392626",
      website: "https://01node.com",
      security_contact: "secops@01node.com",
      details: "01node Professional Staking Services",
      imageURL: "",
    },
    unbonding_height: 46584764,
    unbonding_time: "2023-10-20 16:26:33.782 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-08 11:23:40.616 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/82c0a77a4fa7eadcc7c19db426535e05_360_360.jpg",
  },
  {
    id: "",
    moniker: "WhisperNode 🤐",
    operator_address: "injvaloper1cutynk8xve9lqmwt4nn575x95v9849zj6p9564",
    consensus_address: "injvalcons1wuy03c925yr93zmthf9f0spla8hxks4w8yctr4",
    jailed: false,
    status: 3,
    tokens: "278289337672587145655196",
    delegator_shares: "279125876072232049902004.656220718614230294",
    description: {
      moniker: "WhisperNode 🤐",
      identity: "C99B3A6836BF0CB8",
      website: "https://REStake.WhisperNode.com/injective",
      security_contact: "security@whispernode.com",
      details:
        "WhisperNode operates robust, high up-time validators across the Cosmos ecosystem. Auto-compound your rewards with our REStake app. Join our Discord: https://discord.gg/4E5KZsRtjE / tweet @ https://twitter.com/WhisperNode 🐦",
      imageURL: "",
    },
    unbonding_height: 51299076,
    unbonding_time: "2023-12-05 18:56:07.018 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-04-27 16:28:17.145 +0000 UTC",
    proposed: 10530,
    signed: 1723812,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 95.967417538214,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/ab66c0f83e60450dd0272d45c0517105_360_360.jpg",
  },
  {
    id: "",
    moniker: "Artifact",
    operator_address: "injvaloper1sdd8krauuydtauk8je6zexcw5llj8aunyhlz89",
    consensus_address: "injvalcons16an9kkselmsttv20mxvd9sm7plw0lu7xas729z",
    jailed: true,
    status: 1,
    tokens: "129000000000000000000",
    delegator_shares: "129000000000000000000.000000000000000000",
    description: {
      moniker: "Artifact",
      identity: "70C162B0473634FD",
      website: "https://artifact-staking.io",
      security_contact: "hello@artifact-capital.com",
      details: "",
      imageURL: "",
    },
    unbonding_height: 32566796,
    unbonding_time: "2023-05-24 16:51:55.693 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2023-03-14 03:57:41.137 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/7457583aa6c1316335719ec6cd1ba905_360_360.jpg",
  },
  {
    id: "",
    moniker: "Talis Protocol",
    operator_address: "injvaloper1h37jxecva2xgm78ejw3683vrw932j6nuzgdxgj",
    consensus_address: "injvalcons1wlfl79dd3phfxp5vp867t3jr2d63rga9a70ar4",
    jailed: false,
    status: 3,
    tokens: "1498708365860481498876296",
    delegator_shares: "1503213476934129978347873.624680347538829521",
    description: {
      moniker: "Talis Protocol",
      identity: "5EBAD9ADF2300554",
      website: "https://talis.art",
      security_contact: "eloi@talis.art",
      details: "",
      imageURL: "",
    },
    unbonding_height: 34265240,
    unbonding_time: "2023-06-15 23:31:58.662 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-14 22:06:37.203 +0000 UTC",
    proposed: 57624,
    signed: 1661911,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 96.71962038374252,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/625c0c6cadbbfea16be8bb9961643305_360_360.jpg",
  },
  {
    id: "",
    moniker: "NFTSwitch.xyz w/ Reality Flux",
    operator_address: "injvaloper1x7le0d537tsl3jnmmj0klhtv887p5shtn2pfyx",
    consensus_address: "injvalcons1gaz5skcykvpm96dtjreslkp8tdkc36draq0yg4",
    jailed: true,
    status: 1,
    tokens: "1000000000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "NFTSwitch.xyz w/ Reality Flux",
      identity: "5307DE5B18FEA0AA",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 36842212,
    unbonding_time: "2023-07-15 15:45:13.941 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-18 06:01:15.786 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/87b819597dbaf78dad19b84a26d5aa05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Bro_n_Bro",
    operator_address: "injvaloper1sa9d42qyqqf3zmgvwwslxgfdlcya6s4a7msz8j",
    consensus_address: "injvalcons1qc6g45ntg7tk9pn9ts0wepmkuxmlq2rmwmfa2q",
    jailed: false,
    status: 1,
    tokens: "10429607826662574087",
    delegator_shares: "10429607826662574087.000000000000000000",
    description: {
      moniker: "Bro_n_Bro",
      identity: "",
      website: "bronbro.io",
      security_contact: "",
      details:
        "We are a community-driven bare-metal operated validator and interchain relayer fighting for transparency and decentralization on the Great Web. Visit our portfolio management at app.bronbro.io, validator scoring at score.bronbro.io, and live chains stats at monitor.bronbro.io.",
      imageURL: "",
    },
    unbonding_height: 32595439,
    unbonding_time: "2023-05-25 01:42:43.065 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-21 16:56:59.173 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Allnodes",
    operator_address: "injvaloper14w4qu60azpz5zkw5yrcvmaxqh6xg5sny7qm6rm",
    consensus_address: "injvalcons1usgjwlwd3hhj8m2megl3lmqknld3pmmm790gw0",
    jailed: false,
    status: 3,
    tokens: "958533430189711105524047",
    delegator_shares: "959492894000196107976402.825873686387611096",
    description: {
      moniker: "Allnodes",
      identity: "3999DA33020A4DBC",
      website: "https://www.allnodes.com/inj",
      security_contact: "support@allnodes.com",
      details:
        "Allnodes leads non-custodial infrastructure for Injective, offering auto-compounding rewards, seamless node and governance management across all supported Cosmos-based chains from one portfolio page. Fast, reliable, and free RPC endpoints: https://injective.publicnode.com",
      imageURL: "",
    },
    unbonding_height: 30075854,
    unbonding_time: "2023-04-22 20:35:41.46 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-04-01 13:55:39.343 +0000 UTC",
    proposed: 23168,
    signed: 1772126,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.3064055487556,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/c1bfe4c1d4f6c8f8d66baa152d50e805_360_360.jpg",
  },
  {
    id: "",
    moniker: "Kleomedes",
    operator_address: "injvaloper162w4wq4wenfznjkd62kesq9vsmdla3f22s629d",
    consensus_address: "injvalcons1m5sfcfrczfqkct5jl9epmgu0sldsmm42smjl6h",
    jailed: true,
    status: 1,
    tokens: "138568768659398318",
    delegator_shares: "138568768659398318.000000000000000000",
    description: {
      moniker: "Kleomedes",
      identity: "AD6C05DA12E42B70",
      website: "https://www.kleomed.es",
      security_contact: "",
      details: "The First Community Governed Validator in the Cosmos 🪐",
      imageURL: "",
    },
    unbonding_height: 54887680,
    unbonding_time: "2024-01-09 19:19:37.189 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-03-29 17:21:04.922 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/7757c06ab563563da07d5423d7675705_360_360.jpg",
  },
  {
    id: "",
    moniker: "Atomic Nodes",
    operator_address: "injvaloper1zy5ufk2aluhyup57j0y3ugm68s3lz9nfw8v30r",
    consensus_address: "injvalcons1uh6f4wgkrpj2ew24s0kascp4vwe780vrk93qtk",
    jailed: true,
    status: 1,
    tokens: "24828567178724852754684",
    delegator_shares: "24853420501988150230671.690401407925146287",
    description: {
      moniker: "Atomic Nodes",
      identity: "E73AFD8985423B14",
      website: "atomicwallet.io",
      security_contact: "",
      details:
        "Atomic Wallet is a decentralized non custodial app to manage, exchange and stake over 300 blockchains and tokens. We have over 5,000,000 users across various devices and support entire blockchain ecosystem since 2017",
      imageURL: "",
    },
    unbonding_height: 35609222,
    unbonding_time: "2023-07-02 04:23:16.564 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-04-07 06:44:42.695 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/128efcca8fffd52fed0cae7aa9f25d05_360_360.jpg",
  },
  {
    id: "",
    moniker: "MCF",
    operator_address: "injvaloper1nxahyr4v2hzemdfwxv90djs9vwgpenc2zzxelk",
    consensus_address: "injvalcons14jphwpqw6tkwkml87zuhh45vd245p3etgu3haf",
    jailed: true,
    status: 1,
    tokens: "5000000000000000000",
    delegator_shares: "5000000000000000000.000000000000000000",
    description: {
      moniker: "MCF",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 33925096,
    unbonding_time: "2023-06-11 11:03:31.625 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-04-15 23:08:56.825 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Liquify",
    operator_address: "injvaloper1zjpmmu8yr30z477dmdt9k9u85am0k2wtsdzxlv",
    consensus_address: "injvalcons1pyd2t9phpg387a6zf9hu8uk3rafxya9huf55cj",
    jailed: true,
    status: 1,
    tokens: "1201000000000000000",
    delegator_shares: "1201000000000000000.000000000000000000",
    description: {
      moniker: "Liquify",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 33917972,
    unbonding_time: "2023-06-11 08:50:18.73 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-04-25 14:01:02.946 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "xxx",
    operator_address: "injvaloper1dz66p5x62hrs23xq5v30g7zxevc2amdjl2mjfx",
    consensus_address: "injvalcons1ts2rve8cyfugf69g2accqwfqc4s04y3mklyzxe",
    jailed: true,
    status: 2,
    tokens: "0",
    delegator_shares: "0.000000000000000000",
    description: {
      moniker: "xxx",
      identity: "",
      website: "",
      security_contact: "",
      details: "dont stake here",
      imageURL: "",
    },
    unbonding_height: 37185959,
    unbonding_time: "2023-07-19 08:09:58.477 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-05-09 00:27:23.32 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "undelegate",
    operator_address: "injvaloper1x5y65hgngh77sxta6s2vpjnfjep3ltllkh44le",
    consensus_address: "injvalcons1yw33t76gnhj8uh4f7yd0v8y5a7pj4u6rv57vyz",
    jailed: true,
    status: 1,
    tokens: "1000002986016357",
    delegator_shares: "1001003978326521.431182569534518152",
    description: {
      moniker: "undelegate",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 34010458,
    unbonding_time: "2023-06-12 13:40:36.141 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2023-05-10 03:17:36.603 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Hype INFRA",
    operator_address: "injvaloper1wh9a7f05yf4nqkmh7d9lyletzhefaa53txynrj",
    consensus_address: "injvalcons1csrvwsezmnhln2eaj3z46mu7q9e2fl53aug20d",
    jailed: true,
    status: 1,
    tokens: "1000000000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "Hype INFRA",
      identity: "E83C7B8E58DC11E0",
      website: "https://www.hy.pe/infra",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 50009962,
    unbonding_time: "2023-11-23 02:57:34.645 +0000 UTC",
    commission_rate: "1.000000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2023-05-11 10:32:21.967 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/95744180896c7f4c92259f2488994605_360_360.jpg",
  },
  {
    id: "",
    moniker: "PartnerStaking",
    operator_address: "injvaloper1hkjsxadg0a0dj5pwdyfnf8qm0az6wcxcwhdnry",
    consensus_address: "injvalcons1rq6w3ghau3jynx0w6k75hxd54x3zcs63r0d6zm",
    jailed: false,
    status: 3,
    tokens: "933092796548471702560112",
    delegator_shares: "934961784107742055898177.072197924711689972",
    description: {
      moniker: "PartnerStaking",
      identity: "8AB00A2EC2EB71BD",
      website: "https://partnerstaking.com",
      security_contact: "contact@partnerstaking.com",
      details:
        "Your stake and our tech knowledge makes for a great partnership",
      imageURL: "",
    },
    unbonding_height: 33950098,
    unbonding_time: "2023-06-11 18:56:50.764 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-05-16 17:34:27.369 +0000 UTC",
    proposed: 37272,
    signed: 1696575,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.72474258334182,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/7b3dafcf4fac57bd1b94da8d92eeab05_360_360.jpg",
  },
  {
    id: "",
    moniker: "kingnodes 👑",
    operator_address: "injvaloper1p66ez0ywzssuq26f7056mx3ydgvkfqw8lf93e8",
    consensus_address: "injvalcons1qveaytw2xqj580x37ks5gnxgqwydexaxjxavvs",
    jailed: false,
    status: 3,
    tokens: "831020323468246636462536",
    delegator_shares: "831852174681470335739490.784033063295801282",
    description: {
      moniker: "kingnodes 👑",
      identity: "30E6CD38D9721222",
      website: "https://kingnodes.com",
      security_contact: "security@kingnodes.com",
      details:
        "Professional PoS validator securing only the best interchain networks. Join our community of delegators Telegram: t.me/kingnodes Twitter: https://twitter.com/kingnodes",
      imageURL: "",
    },
    unbonding_height: 38067132,
    unbonding_time: "2023-07-28 21:58:34.633 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2023-05-24 11:12:30.634 +0000 UTC",
    proposed: 31141,
    signed: 1775609,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.39283897874071,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/c9fbb1676edede4bf0b3c787aefaa205_360_360.jpg",
  },
  {
    id: "",
    moniker: "5.0 Validator | Airdrop ",
    operator_address: "injvaloper1x2k4l6k57c2rqhyzdef6y9galutn7psegeqylz",
    consensus_address: "injvalcons1czqysjwd5px0v4p9lcwr4dteej8rhyrwt32rt5",
    jailed: false,
    status: 2,
    tokens: "696408944344822596769",
    delegator_shares: "697803783543985428496.213650460114646537",
    description: {
      moniker: "5.0 Validator | Airdrop ",
      identity: "EC771B7A05CDF1D4",
      website: "https://twitter.com/5_0Validator",
      security_contact: "5.0validator@protonmail.com",
      details:
        "Secure & Robust Validator. Autocompound available on Restake, support builders, support us.",
      imageURL: "",
    },
    unbonding_height: 57448438,
    unbonding_time: "2024-02-05 14:08:51.015 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-05-31 12:48:54.375 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/22d564bd8287141afbf59961df853205_360_360.jpg",
  },
  {
    id: "",
    moniker: "AzureNode",
    operator_address: "injvaloper1e8gk2jyfn0vwr3ewk02lfjluvxhxsd7k9qn7nx",
    consensus_address: "injvalcons14gshaf2z34veutm33hm0z9lxw8ft0hjvuyj0uu",
    jailed: true,
    status: 1,
    tokens: "1001000000000000000",
    delegator_shares: "1001000000000000000.000000000000000000",
    description: {
      moniker: "AzureNode",
      identity: "5048B115DCE4F4BB",
      website: "https://azurenode.xyz",
      security_contact: "jeff@azurenode.xyz",
      details: "AzureNode",
      imageURL: "",
    },
    unbonding_height: 36869687,
    unbonding_time: "2023-07-15 22:53:40.535 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-06-21 17:52:17.608 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/43982dc02443978ef0ca31333afdd705_360_360.jpg",
  },
  {
    id: "",
    moniker: "Cosmos Spaces",
    operator_address: "injvaloper1sgcawjmfm7q7rmfdwfuaxxpk806jq96kqswv83",
    consensus_address: "injvalcons1yk33ftkl8rl2nfs5rz9zcm36gktm3snqrqdg3a",
    jailed: true,
    status: 1,
    tokens: "1202302585669201960",
    delegator_shares: "1202302585669201960.000000000000000000",
    description: {
      moniker: "Cosmos Spaces",
      identity: "06B033BAC39DA21C",
      website: "",
      security_contact: "",
      details:
        "Community centered validator run by the Cosmos Spaces team. Community. Support. Alpha.",
      imageURL: "",
    },
    unbonding_height: 37477712,
    unbonding_time: "2023-07-22 14:48:03.653 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.300000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-06-28 14:03:10.392 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/6dc14362803c12b4f06ebc58e9958705_360_360.jpg",
  },
  {
    id: "",
    moniker: "Abandon",
    operator_address: "injvaloper14n4kqm2gvghk2v8f7r9jgk4hc2mujaqua0lp3n",
    consensus_address: "injvalcons1g2j223a0metce8kjygvs862hsxyfr8vqg7uesr",
    jailed: true,
    status: 2,
    tokens: "0",
    delegator_shares: "0.000000000000000000",
    description: {
      moniker: "Abandon",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 37538053,
    unbonding_time: "2023-07-23 07:07:05.086 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-07-01 03:43:33.22 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Fusion Trade",
    operator_address: "injvaloper1hq35mlvs2z6j9vz743fyj8krk4rfm929mus55p",
    consensus_address: "injvalcons1a3aymzjcfzvhr2n2avjap2jn9l683hyy6dfqwx",
    jailed: false,
    status: 1,
    tokens: "1000000000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "Fusion Trade",
      identity: "BA5E4C0CE9E92145",
      website: "https://fusion.trade",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-07-14 12:16:04.951 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/12496396c3ebda6f8c9b1a8ff474e705_360_360.jpg",
  },
  {
    id: "",
    moniker: "Black Panther | Delegate for Claimdrop",
    operator_address: "injvaloper10pe4avat38u38yzj5hvnw235uecfff6c73s8fn",
    consensus_address: "injvalcons123tk3uwy78hrulhe8h5wt8740fgxem5jdax0p3",
    jailed: false,
    status: 3,
    tokens: "4610807084076312801375222",
    delegator_shares: "4610807084076312801375222.000000000000000000",
    description: {
      moniker: "Black Panther | Delegate for Claimdrop",
      identity: "18BEBD4F265E2994",
      website: "https://blackpanther.fi/",
      security_contact: "",
      details:
        "Black Panther is an asset management protocol that helps you achieve superior returns.",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-08-10 11:47:49.479 +0000 UTC",
    proposed: 169119,
    signed: 1642301,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.90066225165563,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/4282ca3103a8d692095071209d940e05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Trust Nodes",
    operator_address: "injvaloper1dyntafvjynns5zyltwgyktej0y5zt84y3npkt4",
    consensus_address: "injvalcons1uejre3lrwhlc3842t8mlewflfh34gq7mfv0m5p",
    jailed: false,
    status: 3,
    tokens: "248931386013415103372429",
    delegator_shares: "249180566579995098470295.838779930738890405",
    description: {
      moniker: "Trust Nodes",
      identity: "",
      website: "https://trustwallet.com/",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 42754881,
    unbonding_time: "2023-09-13 18:53:37.879 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-08-14 15:02:50.415 +0000 UTC",
    proposed: 9170,
    signed: 1769953,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 97.59774851743894,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Nebula",
    operator_address: "injvaloper158m0v02cahrec02q0mlzwwztv95avdh9ldadne",
    consensus_address: "injvalcons1m6e0667wqekvzjeeqna9src9v7wkmnu3c7u9a3",
    jailed: true,
    status: 1,
    tokens: "63110000000000000000",
    delegator_shares: "63110000000000000000.000000000000000000",
    description: {
      moniker: "Nebula",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 44819562,
    unbonding_time: "2023-10-03 17:30:27.886 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-08-17 04:52:09.629 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "Contribution DAO",
    operator_address: "injvaloper1axd525a65ly3p878g3hcqw7ykhas0rmk8ws3na",
    consensus_address: "injvalcons18dq3ucszzyfv2jjxgtr7p6cect6wq80acx4xva",
    jailed: false,
    status: 3,
    tokens: "201418972080576238632878",
    delegator_shares: "201418972080576238632878.000000000000000000",
    description: {
      moniker: "Contribution DAO",
      identity: "C5AD0831E7EB6B27",
      website: "https://contributiondao.com",
      security_contact: "contacts@contributiondao.com",
      details:
        "Bootstrapping Quality Talents and Onboarding Users to the Projects for making opportunities in Web 3.0 accessible to everyone.",
      imageURL: "",
    },
    unbonding_height: 38673746,
    unbonding_time: "2023-08-04 07:43:17.169 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-06-07 15:48:28.319 +0000 UTC",
    proposed: 7624,
    signed: 1784134,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.09629480871574,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/23024e4d97e61c75d09d3db621931605_360_360.jpg",
  },
  {
    id: "",
    moniker: "undelegate",
    operator_address: "injvaloper1mjwqdezqw0m3nalrc8380t29z54wx5emy9whun",
    consensus_address: "injvalcons13zvusdsl9uy8atd0uryls788uzsgc46ujh434z",
    jailed: true,
    status: 1,
    tokens: "10000000000000000",
    delegator_shares: "10000000000000000.000000000000000000",
    description: {
      moniker: "undelegate",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-06-29 14:35:07.027 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "TienThuatToan ⚡ Auto-compound & coinback",
    operator_address: "injvaloper1an6hyf4h2au53aa3tuy6yjjr4edsd2xvg5kc2p",
    consensus_address: "injvalcons10f4fmmna3ua64qljcwsp08t5a52g0yevrkppml",
    jailed: false,
    status: 3,
    tokens: "302271148540363449782548",
    delegator_shares: "302271148540363449782548.000000000000000000",
    description: {
      moniker: "TienThuatToan ⚡ Auto-compound & coinback",
      identity: "E46A1AC121BEAD77",
      website: "https://tienthuattoan.ventures",
      security_contact: "hello@tienthuattoan.ventures",
      details:
        "Coinback https://tienthuattoan.com/coinback ⚡ Auto-compound https://restake.app/injective ⚡ Cosmos Ecosystem Vietnam https://fb.com/groups/cosmosvn ⚡ Youtube https://youtube.com/c/tienthuattoan ⚡ X https://x.com/TTTcapital ⚡ See more at https://linktr.ee/tienthuattoan",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2023-10-16 01:09:34.891 +0000 UTC",
    proposed: 11390,
    signed: 1763329,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.82281919710232,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/78c9d0b4af22277a7f88e249cb949c05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Ledger by Chorus One",
    operator_address: "injvaloper1ntn4j2lsu3k60g8xj9pqshqvdj2q5tygyvczpy",
    consensus_address: "injvalcons1wk7zq7yplyulwyk88ux76h42kq5lrxev74swkd",
    jailed: false,
    status: 3,
    tokens: "716258086033861696978380",
    delegator_shares: "716258086033861696978380.000000000000000000",
    description: {
      moniker: "Ledger by Chorus One",
      identity: "38491F730E86F0C5",
      website: "https://www.ledger.com/staking",
      security_contact: "developer-ecosystem@ledger.fr",
      details:
        "Ledger, the world leader in critical digital asset security, and Chorus One, one of the world's leading staking and blockchain infrastructure providers, join forces to offer the most secure and seamless staking experience.",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.080000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2024-01-21 23:50:39.567 +0000 UTC",
    proposed: 26472,
    signed: 1772270,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.5534355018776,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/02e6f77b267b8c9f222e8b4e3b325305_360_360.jpg",
  },
  {
    id: "",
    moniker: "Synergy Nodes",
    operator_address: "injvaloper1y4pfpkwpy6myskp7pne256k6smh2rjta694yq5",
    consensus_address: "injvalcons1pyycyh7z4cwtw5px8f6twhpak9ah952f27227a",
    jailed: true,
    status: 1,
    tokens: "1051000000000000000",
    delegator_shares: "1051000000000000000.000000000000000000",
    description: {
      moniker: "Synergy Nodes",
      identity: "AF9D7EF7CC70CE24",
      website: "https://www.synergynodes.com",
      security_contact: "contact@synergynodes.com",
      details:
        "We are operating a secure, stable Validator Node for 16 mainnet chains. We provide infrastructure support - public RPC, LCD endpoints, daily snapshots, operate multiple IBC Relayers, Blockchain Explorers, Youtube tutorials, etc. All info on our website.",
      imageURL: "",
    },
    unbonding_height: 49971025,
    unbonding_time: "2023-11-22 17:42:02.108 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-10-26 06:04:11.705 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/61a3437ae2315c13eafec83cd2482b05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Cosmic Validator | Top Uptime & Auto-compound",
    operator_address: "injvaloper1fa558ytfnekl2zlmv7zpupam7zrcj2wkpqt90x",
    consensus_address: "injvalcons1rqpvgd73tel9fq3mmq4lt9zf7yj9up3lq8gzyf",
    jailed: false,
    status: 3,
    tokens: "239712380359842769256017",
    delegator_shares: "239712380359842769256017.000000000000000000",
    description: {
      moniker: "Cosmic Validator | Top Uptime & Auto-compound",
      identity: "FF4B91B50B71CEDA",
      website: "https://www.youtube.com/@cosmicvalidator",
      security_contact: "info@cosmicvalidator.com",
      details:
        "Top Uptime Institutional-Grade Infrastructure | Rewards auto-compounding enabled to maximize your INJ yield: https://restake.app/injective | Selected by Stride & ICF in the Cosmos Hub | Follow our Injective educational content: https://www.youtube.com/@cosmicvalidator",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.099000000000000000",
    commission_max_rate: "0.250000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2023-11-10 17:55:30.288 +0000 UTC",
    proposed: 9050,
    signed: 1780188,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.25635614511104,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/77f05c9f4479b689156a691b2640f305_360_360.jpg",
  },
  {
    id: "",
    moniker: "Twinstake",
    operator_address: "injvaloper13p7suhhxrd6rrj6kka39sadklartjpekm9hes8",
    consensus_address: "injvalcons1pk0aatucmxkaec3z447r90dwuuxruc8tsyj83w",
    jailed: false,
    status: 1,
    tokens: "2000000000000000001",
    delegator_shares: "2000000000000000001.000000000000000000",
    description: {
      moniker: "Twinstake",
      identity: "A7823F4D765E18AB",
      website: "https://twinstake.io",
      security_contact: "hello@twinstake.io",
      details:
        "Twinstake is an institutional-grade, non-custodial staking provider designed and built in collaboration with leaders in the digital assets space. The platform offers industry leading performance, institutional-grade security and uncompromising Know Your Client (KYC) procedures.",
      imageURL: "",
    },
    unbonding_height: 52997392,
    unbonding_time: "2023-12-22 15:57:54.469 +0000 UTC",
    commission_rate: "0.080000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2023-11-13 15:52:00.701 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/f5b880eab74b1134cca3ae371b4fab05_360_360.jpg",
  },
  {
    id: "",
    moniker: "NODEJUMPER",
    operator_address: "injvaloper1kywsemgukghp6gah7yw6hmly9zc03z0479mzdn",
    consensus_address: "injvalcons1pxcezre69pvdhqpjjsa9js78kyntrxd3fhgrc2",
    jailed: false,
    status: 1,
    tokens: "1000000000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "NODEJUMPER",
      identity: "FFB0AA51A2DF5954",
      website: "https://app.nodejumper.io/injective",
      security_contact: "validator@nodejumper.io",
      details:
        "Professional POS Validator services, Cosmos contributors and supporters, Restake.app compatible. Making the blockchain world a better place.",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-11-22 10:11:39.077 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/8506223c573a1bee45e9182de7684805_360_360.jpg",
  },
  {
    id: "",
    moniker: "Node & Validator",
    operator_address: "injvaloper1nc9ppgynj4fxqlsxq62sh4zfvf59la6rkjy734",
    consensus_address: "injvalcons1mqxe4c6y0myxzhuhda9yljepvhejnnz6w7f4uh",
    jailed: false,
    status: 1,
    tokens: "2491583342500000000",
    delegator_shares: "2491583342500000000.000000000000000000",
    description: {
      moniker: "Node & Validator",
      identity: "6CB6AC3E672AAB9D",
      website: "https://validatorvn.com",
      security_contact: "",
      details:
        "Trusted Proof-of-Stake validator across Cosmos universe. Active ecosystem contributor, IBC relayer and chain service provider since 2023.",
      imageURL: "",
    },
    unbonding_height: 54083218,
    unbonding_time: "2024-01-02 00:01:34.949 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-11-24 13:04:52.761 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/37fbb90531ed92d4ed5fecd185874705_360_360.jpg",
  },
  {
    id: "",
    moniker: "Twinstake",
    operator_address: "injvaloper18cyc7wxru3g9de3emwy9pdh5x0vevxp3p2623y",
    consensus_address: "injvalcons1x7elnerzjel32r6mlx985d2lx5cmtrgj62fu7y",
    jailed: false,
    status: 3,
    tokens: "1008738856774789493734347",
    delegator_shares: "1008738856774789493734347.000000000000000000",
    description: {
      moniker: "Twinstake",
      identity: "A7823F4D765E18AB",
      website: "https://twinstake.io",
      security_contact: "security@twinstake.io",
      details:
        "Twinstake is an institutional-grade, non-custodial staking provider designed and built in collaboration with leaders in the digital assets space. The platform offers industry leading performance, institutional-grade security and uncompromising Know Your Client (KYC) procedures.",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.080000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2023-11-30 11:01:38.482 +0000 UTC",
    proposed: 37647,
    signed: 1737335,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 99.74474167857872,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/f5b880eab74b1134cca3ae371b4fab05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Tané",
    operator_address: "injvaloper1m5lqvd8kr2s7lx3j2c7cfa33hxrqa3cth9pd56",
    consensus_address: "injvalcons1xvmk2pyjj46n99emyxnlkjd9n2qwzspdv48qvc",
    jailed: false,
    status: 1,
    tokens: "5000000000000000000",
    delegator_shares: "5000000000000000000.000000000000000000",
    description: {
      moniker: "Tané",
      identity: "A8709BB7ABF23A73",
      website: "https://tané.com",
      security_contact: "",
      details:
        "Tané is a professional validator, operated by a group of crypto-native product builders, backed by Japanese enterprises. Delegate your tokens, secure the network and earn staking rewards with us!",
      imageURL: "",
    },
    unbonding_height: 54028052,
    unbonding_time: "2024-01-01 09:16:20.016 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-11-30 14:22:10.356 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/f497798ec6e2834d65cf5232120f7c05_360_360.jpg",
  },
  {
    id: "",
    moniker: "redelegate_please",
    operator_address: "injvaloper1z8rcledwadkcyykvn20zxr032fnv787vv46hlg",
    consensus_address: "injvalcons16m2l2sal0wn0cv4733ey30kvfrjr8lfv2c837t",
    jailed: true,
    status: 1,
    tokens: "10094046559810584920",
    delegator_shares: "10104117212569721058.834911778041596561",
    description: {
      moniker: "redelegate_please",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 55056899,
    unbonding_time: "2024-01-11 15:47:39.156 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-12-01 04:54:42.668 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/99fafbe934c042c03ae1c4c32a7dcd05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Alessandro Mazza",
    operator_address: "injvaloper1lshp7qy8mzlc3fpqjservcxfwg3j52a2cv26n2",
    consensus_address: "injvalcons1u3cqavnssntc4yaegpkpqmjhd49u6dp20a65en",
    jailed: false,
    status: 1,
    tokens: "2000000000000000000",
    delegator_shares: "2000000000000000000.000000000000000000",
    description: {
      moniker: "Alessandro Mazza",
      identity: "65CC7CB0CC69040D",
      website: "alessandromazza.com",
      security_contact: "",
      details: "Web3 developer and content creator.",
      imageURL: "",
    },
    unbonding_height: 54110279,
    unbonding_time: "2024-01-02 07:34:45.469 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.300000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-12-05 15:39:41.117 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/d796c528771e547a2a8057d1eb52f205_360_360.jpg",
  },
  {
    id: "",
    moniker: "🍀LuckyFriday.io🍀",
    operator_address: "injvaloper17z69jk7e36zqjday7llt3z26c2lhaj4qt3c208",
    consensus_address: "injvalcons1tr59g7axlq6hezljt79nea06gxz6vddqcnuhw0",
    jailed: false,
    status: 1,
    tokens: "1100000000000000",
    delegator_shares: "1100000000000000.000000000000000000",
    description: {
      moniker: "🍀LuckyFriday.io🍀",
      identity: "798382F32C66759F",
      website: "https://luckyfriday.io",
      security_contact: "info@luckyfriday.io",
      details:
        "With decades of infrastructure experience, LuckyFriday's enterprise-grade Web3 data centers, equipment, and protocols are specifically engineered to secure Proof-of-Stake networks. We believe that if you are going to do something, then do it right. Stay Lucky! 🍀",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-12-13 20:34:15.002 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/1f9840e52c2f114c4d3e244c3c70dd05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Dojo",
    operator_address: "injvaloper17dt0hpykufxjrc7fh8rxwgv6z9kx8vlgg0qqrs",
    consensus_address: "injvalcons1vm2upy2ykes5ayspazm7pycmf5zjs4qmn9ne0c",
    jailed: false,
    status: 2,
    tokens: "141248483628289517594352",
    delegator_shares: "141248483628289517594352.000000000000000000",
    description: {
      moniker: "Dojo",
      identity: "32A2E49B377D032D",
      website: "https://dojo.trading",
      security_contact: "",
      details:
        "Dojoswap is the number 1 all-in-one DeFi protocol on Injective. With our AMM DEX and Liquid Staking Derivatives protocol powering our DOJO token, we are confident of becoming the number 1 DeFi protocol in cosmos and more!",
      imageURL: "",
    },
    unbonding_height: 58899007,
    unbonding_time: "2024-02-19 16:04:00.916 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2023-12-14 15:32:51.418 +0000 UTC",
    proposed: 1902,
    signed: 714366,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/707e90be50e4367d105549853a3b5d05_360_360.jpg",
  },
  {
    id: "",
    moniker: "ARCANSECURITY",
    operator_address: "injvaloper1cmc57netnw7mt9psccfr0wx6ahhr9tstatstv6",
    consensus_address: "injvalcons1z9dhfwr2ejfee205w9ev7hheqtmr4pcxwjwa0a",
    jailed: false,
    status: 1,
    tokens: "1100000000000000000",
    delegator_shares: "1100000000000000000.000000000000000000",
    description: {
      moniker: "ARCANSECURITY",
      identity: "",
      website: "https://arcansecurity.com",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2023-12-22 09:36:25.136 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "mykk",
    operator_address: "injvaloper1lq4ceva4xqm4l6ukhtq3c8akxyms8au36pnter",
    consensus_address: "injvalcons1cm7k8z0vmxrldcuyrssf3pyyxchffh6dzk0dxk",
    jailed: false,
    status: 1,
    tokens: "10300000000000000",
    delegator_shares: "10300000000000000.000000000000000000",
    description: {
      moniker: "mykk",
      identity: "8999OKTOMOON8889",
      website: "1",
      security_contact: "yun1@crypto.com",
      details: "1",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.060000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2024-01-02 15:39:09.303 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "coinhall.org 📈🔄",
    operator_address: "injvaloper1ka5clr8wmdr50cs8dcrnfsszyunlxs72r36j5e",
    consensus_address: "injvalcons1ewq8dyw9pgaj3k4mh046k9p69umv03hrrdlh58",
    jailed: false,
    status: 3,
    tokens: "200968148129817281285926",
    delegator_shares: "201169317291135102777704.544952902897682549",
    description: {
      moniker: "coinhall.org 📈🔄",
      identity: "0B5217ACAE18F4C9",
      website: "coinhall.org",
      security_contact: "gm@coinhall.org",
      details:
        "Coinhall is a community first platform providing real time price charts & analytics, and aggregating DEXes across the Cosmos ecosystem.",
      imageURL: "",
    },
    unbonding_height: 58771517,
    unbonding_time: "2024-02-18 09:56:31.656 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2024-01-08 07:37:17.857 +0000 UTC",
    proposed: 6835,
    signed: 1731908,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 98.37333065568832,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/d56a8afc0d99e63498f82e7b89d89b05_360_360.jpg",
  },
  {
    id: "",
    moniker: "this_is_moniker",
    operator_address: "injvaloper1u5m8vf5yw49gut3lrk3trje0zdvlnpdu9vhevd",
    consensus_address: "injvalcons1gtmkygnqw90q4vs0w3egrph0fxlesjajq4mrg5",
    jailed: false,
    status: 1,
    tokens: "2000000000000000",
    delegator_shares: "2000000000000000.000000000000000000",
    description: {
      moniker: "this_is_moniker",
      identity: "this_is_identity",
      website: "https://www.okx.com",
      security_contact: "this_is_security_content",
      details: "this_is_detail",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2024-01-03 03:07:44.87 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "undelegate",
    operator_address: "injvaloper1tjsc590u7m8pets0c8c3mym4ph6hyjgq4cc99q",
    consensus_address: "injvalcons1cu23s6y745spxs0uym4suulpypzxqmnf2zmgt8",
    jailed: false,
    status: 1,
    tokens: "1000000000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "undelegate",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2024-01-05 16:57:52.807 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "$MAX injective",
    operator_address: "injvaloper1z4z0erxx80cex9kgrk5ynk6gczs0qvale6ae48",
    consensus_address: "injvalcons1c3rutmu6nrr0zkdtfns8qva08wy3chq8hj9y56",
    jailed: false,
    status: 1,
    tokens: "35070000000000000000",
    delegator_shares: "35070000000000000000.000000000000000000",
    description: {
      moniker: "$MAX injective",
      identity: "F0E1AD996922183A",
      website: "maxinjective.com",
      security_contact: "Maxoninjective@gmail.com",
      details:
        "The MAX Validator, in honor of the co-founders best friend and beloved canine MAX, provides users with the highest tier of APY rewards while returning commissions to the MAX community.",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2024-01-05 17:52:13.76 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/f0c3ca119bc9468b0e88676de2c5b005_360_360.jpg",
  },
  {
    id: "",
    moniker: "Injective Finance",
    operator_address: "injvaloper1s28qvpfpq3525ep0surypnmwkfy8d2n237pnc4",
    consensus_address: "injvalcons1kgl0p35dpflx08enft55zqhl9x6gwgp3pxx4tc",
    jailed: false,
    status: 3,
    tokens: "1012336930410732374700160",
    delegator_shares: "1012336930410732374700160.000000000000000000",
    description: {
      moniker: "Injective Finance",
      identity: "54EEBB62B7D58830",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.100000000000000000",
    commission_update_time: "2024-01-09 07:29:12.364 +0000 UTC",
    proposed: 18560,
    signed: 778665,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 95.85460486011844,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/36b6bfcfc494101fde47a65eca378605_360_360.jpg",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1qndvee93f30q3ra2hncasphm22k97vpr89zusa",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 57446662,
        blockTimestamp: "2024-01-15 13:42:30.415 +0000 UTC",
        power: 602922,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1mpt6fw5hg565m87pm7g6stxms7382caxcu5um8",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1tly62qxj2t8wz2zw4d7p37jcysvgasqa0zjtlk",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 57446662,
        blockTimestamp: "2024-01-15 13:42:30.415 +0000 UTC",
        power: 130077,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1rq3jrrc4xxagcjj9k5as3jymlr3xakvrz88hye",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "OKX Earn",
    operator_address: "injvaloper195htudzwkmgn64wvyp7e474ul9t62ws9sr3rhn",
    consensus_address: "injvalcons1mvlglkqg0swdw02w2aarm0lut57m9j7am7kc0g",
    jailed: false,
    status: 1,
    tokens: "1000000000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "OKX Earn",
      identity: "FBBCF611AE6F6B77",
      website: "https://www.okx.com",
      security_contact: "earn@okx.com",
      details:
        "OKX Earn serves as a one-stop shop for all the decentralised earning options available on the OKX platform and we are the leading staking service provider for blockchain projects",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.100000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2024-01-16 10:42:01.002 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/23fc374bb258fcb7dc2889b8733ea005_360_360.jpg",
  },
  {
    id: "",
    moniker: "NINJA",
    operator_address: "injvaloper1kvlepclamftkwx5qqu4463jznsvru24n472cy7",
    consensus_address: "injvalcons1uewmwhr9wucwngnqtzlys9ygqq9zz4ztfjr56e",
    jailed: false,
    status: 1,
    tokens: "2151010000000000000000",
    delegator_shares: "2151010000000000000000.000000000000000000",
    description: {
      moniker: "NINJA",
      identity: "",
      website: "--keyring-backend=file",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.050000000000000000",
    commission_max_change_rate: "0.050000000000000000",
    commission_update_time: "2024-01-18 10:51:48.649 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "none",
    operator_address: "injvaloper156anrjzys4tnkawruadm4cakrk9vxrd567myrl",
    consensus_address: "injvalcons17fpm502dsxxlc79pw3lpddppnttu4jctm7l6cd",
    jailed: false,
    status: 1,
    tokens: "1000000",
    delegator_shares: "1000000.000000000000000000",
    description: {
      moniker: "none",
      identity: "",
      website: "",
      security_contact: "office@noders.team",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2024-01-23 10:31:04.422 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "PRYZM | StakeDrop",
    operator_address: "injvaloper1v2wjf06nutm9phvh5hgc3twpst9q50y4f8ckd5",
    consensus_address: "injvalcons1m3jaft2mw4xkg0rnx3cde3ayfw0mfh99a06lzy",
    jailed: false,
    status: 1,
    tokens: "6820000000000000000",
    delegator_shares: "6820000000000000000.000000000000000000",
    description: {
      moniker: "PRYZM | StakeDrop",
      identity: "3CE640138108BAAE",
      website: "pryzm.zone",
      security_contact: "hello@refractedlabs.com",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2024-01-19 09:55:21.941 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/93dfc4e344b5908d72537b3d4ef34a05_360_360.jpg",
  },
  {
    id: "",
    moniker: "[NODERS]TEAM",
    operator_address: "injvaloper17ucaxqrwmzak0g98ly3r6h30zm3r5ph4c68n6k",
    consensus_address: "injvalcons13sl05g4jppagh8xg2qxqqkjelr23pw2gvvrtg2",
    jailed: false,
    status: 1,
    tokens: "1000000000000000000",
    delegator_shares: "1000000000000000000.000000000000000000",
    description: {
      moniker: "[NODERS]TEAM",
      identity: "B38EBF2F38B998F4",
      website: "https://nodersteam.com",
      security_contact: "office@noders.team",
      details: "Trusted blockchain validator and web3 developer team",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.100000000000000000",
    commission_max_rate: "0.200000000000000000",
    commission_max_change_rate: "0.010000000000000000",
    commission_update_time: "2024-01-23 10:25:20.161 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/ddd2f2bfbde48c51d2c38424e1cdcc05_360_360.jpg",
  },
  {
    id: "",
    moniker: "Decentrio",
    operator_address: "injvaloper1mlsg82x0mnw88u2teg0kceautjktgcl08tqfs4",
    consensus_address: "injvalcons1v3sggjevs8587032zpn80pamwcqvl2tsjzl7dj",
    jailed: false,
    status: 1,
    tokens: "77339389032827585774",
    delegator_shares: "77339389032827585774.000000000000000000",
    description: {
      moniker: "Decentrio",
      identity: "66B724BE21C8CA3D",
      website: "https://decentrio.ventures",
      security_contact: "contact@decentrio.ventures",
      details: "Cosmos software engineering and infrastructure provision.",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "1970-01-01 00:00:00 +0000 UTC",
    commission_rate: "0.050000000000000000",
    commission_max_rate: "1.000000000000000000",
    commission_max_change_rate: "1.000000000000000000",
    commission_update_time: "2024-01-24 12:40:38.581 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "2024-01-31 06:57:42.899 +0000 UTC",
    uptimes: null,
    slashingEvents: [],
    uptime_percentage: 0,
    imageURL:
      "https://s3.amazonaws.com/keybase_processed_uploads/27dd542af46a2c9f0cb0a716e17a4605_360_360.jpg",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1g4d6dmvnpg7w7yugy6kplndp7jpfmf3krtschp",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 58652975,
        blockTimestamp: "2024-01-27 06:24:56.493 +0000 UTC",
        power: 805417,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1vzrkqls72mmwu7f54whktq6vjttpsyzvfu0l8l",
      },
      {
        blockNumber: 58658542,
        blockTimestamp: "2024-01-27 07:41:12.671 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.250000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-06-30T13:00:00Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: znxWqbUit5j6l/bhgMU+pL+vVfRZighyEGrGqL35uyU=\ndelegator_shares: "809375621131634526102392.258514130830903458"\ndescription:\n  details: ""\n  identity: E5F274B870BDA01D\n  moniker: Figment\n  security_contact: ""\n  website: https://figment.io\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1g4d6dmvnpg7w7yugy6kplndp7jpfmf3krtschp\nstatus: BOND_STATUS_UNBONDING\ntokens: "804531494144007889672896"\nunbonding_height: "58652976"\nunbonding_ids:\n- "77223"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-02-17T06:24:57.209477585Z"\n',
      },
      {
        blockNumber: 58677977,
        blockTimestamp: "2024-01-27 12:07:57.371 +0000 UTC",
        reason: "missing_valset_confirm",
        jailed:
          'commission:\n  commission_rates:\n    max_change_rate: "0.050000000000000000"\n    max_rate: "0.250000000000000000"\n    rate: "0.050000000000000000"\n  update_time: "2021-06-30T13:00:00Z"\nconsensus_pubkey:\n  \'@type\': /cosmos.crypto.ed25519.PubKey\n  key: znxWqbUit5j6l/bhgMU+pL+vVfRZighyEGrGqL35uyU=\ndelegator_shares: "806797162730780407345720.018940654554291504"\ndescription:\n  details: ""\n  identity: E5F274B870BDA01D\n  moniker: Figment\n  security_contact: ""\n  website: https://figment.io\njailed: true\nmin_self_delegation: "1"\noperator_address: injvaloper1g4d6dmvnpg7w7yugy6kplndp7jpfmf3krtschp\nstatus: BOND_STATUS_UNBONDING\ntokens: "801968467860949197641805"\nunbonding_height: "58652976"\nunbonding_ids:\n- "77223"\nunbonding_on_hold_ref_count: "0"\nunbonding_time: "2024-02-17T06:24:57.209477585Z"\n',
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
  {
    id: "",
    moniker: "",
    operator_address: "",
    consensus_address: "injvaloper1ka5clr8wmdr50cs8dcrnfsszyunlxs72r36j5e",
    jailed: false,
    status: 0,
    tokens: "",
    delegator_shares: "",
    description: {
      moniker: "",
      identity: "",
      website: "",
      security_contact: "",
      details: "",
      imageURL: "",
    },
    unbonding_height: 0,
    unbonding_time: "0001-01-01 00:00:00 +0000 UTC",
    commission_rate: "",
    commission_max_rate: "",
    commission_max_change_rate: "",
    commission_update_time: "0001-01-01 00:00:00 +0000 UTC",
    proposed: 0,
    signed: 0,
    missed: 0,
    timestamp: "0001-01-01 00:00:00 +0000 UTC",
    uptimes: null,
    slashingEvents: [
      {
        blockNumber: 58771516,
        blockTimestamp: "2024-01-28 09:56:30.848 +0000 UTC",
        power: 200953,
        reason: "missing_valset_confirm",
        jailed: "injvalcons1ewq8dyw9pgaj3k4mh046k9p69umv03hrrdlh58",
      },
    ],
    uptime_percentage: 0,
    imageURL: "",
  },
];
