import Modal from "./Modal";
import classNames from "classnames";
import { CHAIN_ID } from "../libs/services";
import { ChainId } from "@injectivelabs/ts-types";
import { TxStage, TxState } from "../store/interfaces/txState.interface";
import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as NinjaVaultWhiteIcon } from "../images/NinjaVaultWhiteIcon.svg";

interface Props {
  txState: TxState;
  isOpen: boolean;
  onClose: () => void;
}

const TxResultModal = (props: Props) => {
  const { txState, isOpen, onClose } = props;
  const isMainnet = CHAIN_ID === ChainId.Mainnet;

  return (
    <Modal
      isOpen={isOpen}
      close={onClose}
      open={() => {}}
      shouldCloseOnOverlayClick={false}
    >
      <div
        className={
          "flex flex-col bg-primary-100 border border-primary-300 my-0 p-3 rounded-xl border-solid mx-4 sm:mx-2"
        }
      >
        {/* <p className={styles.message}>Please sign transaction in your wallet</p> */}

        {txState.txStage === TxStage.SIGNING && (
          <div className="flex flex-col justify-center items-center">
            <div
              className={
                "text-xl font-bold not-italic leading-[normal] tracking-[normal] text-center text-primary-900 mt-2.5 mb-[30px]"
              }
            >
              Signing Transaction
            </div>
            <NinjaVaultWhiteIcon className="w-14 h-14 animate-pulse" />

            <p
              className={
                "text-lg text-center text-primary-900/50 mb-[30px] mt-5"
              }
            >
              Please sign transaction in your wallet
            </p>
          </div>
        )}

        {txState.txStage === TxStage.LOADING && (
          <div className="flex flex-col justify-center items-center">
            <div
              className={
                "text-xl font-bold not-italic leading-[normal] tracking-[normal] text-center text-primary-900 mt-2.5 mb-[30px]"
              }
            >
              Sending Transaction
            </div>
            <NinjaVaultWhiteIcon className="w-14 h-14 animate-pulse" />
            <p
              className={
                "text-lg text-center text-primary-900/50 mb-[30px] mt-5"
              }
            >
              Give the blockchain a moment...
            </p>
          </div>
        )}

        {txState.txStage === TxStage.RESULT && (
          <div className="flex flex-col justify-center items-center">
            <div
              className={
                "text-xl font-bold not-italic leading-[normal] tracking-[normal] text-center text-primary-900 mt-2.5 mb-[30px]"
              }
            >
              Transaction Completed
            </div>
            <CheckCircleIcon className="w-14 h-14 text-green-500" />
            <p
              className={
                "text-lg text-center text-primary-900/50 mb-[30px] mt-5"
              }
            >
              {txState.summary}
            </p>
          </div>
        )}

        {txState.txStage === TxStage.FAILED && (
          <div className="flex flex-col justify-center items-center">
            <div
              className={
                "text-xl font-bold not-italic leading-[normal] tracking-[normal] text-center text-primary-900 mt-2.5 mb-[30px]"
              }
            >
              Transaction Failed
            </div>
            <XCircleIcon className="w-14 h-14 text-red-500" />
            <p
              className={
                "text-lg text-center text-primary-900/50 mb-[30px] mt-5"
              }
            >
              {txState.summary}
            </p>
          </div>
        )}

        {txState.txStage === TxStage.RESULT && txState?.txResult?.txHash && (
          <a
            href={`https://${
              !isMainnet ? "testnet." : ""
            }explorer.injective.network/transaction/${
              txState?.txResult?.txHash
            }`}
            target="_blank"
            rel="noreferrer"
            className="mb-3 w-full"
          >
            <button className="font-medium py-2 rounded-lg bg-primary-700 hover:opacity-80 cursor-pointer w-full">
              <span className="mr-2 text-primary-100">
                View on Injective Explorer
              </span>{" "}
              <ArrowTopRightOnSquareIcon className="-mt-1 w-5 h-5 text-primary-100 inline-block" />
            </button>
          </a>
        )}

        <button
          className="bg-primary-900 border-solid hover:opacity-80 cursor-pointer w-full rounded-lg text-primary-100 py-2 font-medium mb-2"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default TxResultModal;
