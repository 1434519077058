import { create, StoreApi, UseBoundStore } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { walletSlice } from "./slices/walletSlice";
import { darkModeSlice } from "./slices/darkModeSlice";
import { LocalStore } from "./interfaces/localStore.interface";

let useLocalStore: UseBoundStore<StoreApi<LocalStore>>;

useLocalStore = create<LocalStore>()(
  persist(
    (...a) => ({
      ...walletSlice(...a),
      ...darkModeSlice(...a),
    }),
    {
      // TODO: clear users' localstorage from keys above
      name: "ninjavault-10", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useLocalStore;
