import { useCallback, useState } from "react";
import BN from "bignumber.js";
import Modal from "./Modal";
import { getFormattedValue } from "../libs/getFormattedValue";
import { VaultData } from "../store/interfaces/vaults.interface";
import useSendTransaction from "../hooks/useSendTransaction";
import useWallet from "../hooks/useWallet";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface Props {
  vault?: VaultData;
  isOpen: boolean;
  onClose: () => void;
  onWithdraw: () => void;
}

const WithdrawModal = (props: Props) => {
  const tokenDecimals = Math.pow(10, props?.vault?.stakingToken?.decimals);
  const [value, setValue] = useState("");
  const { wallet } = useWallet();
  const { loading, sendTransaction } = useSendTransaction();

  const submit = useCallback(async () => {
    const apr = props?.vault?.apr;
    const data = {
      sender: wallet.address,
      contractAddress: props?.vault?.vaultAddress,
      msg: {
        withdraw_underlying: {
          amount: new BN(value).times(tokenDecimals).toFixed(),
        },
      },
    };

    let additionalData = undefined;
    if(apr !== "NaN") {
      additionalData = {
        sender: wallet.address,
        contractAddress: props?.vault?.vaultAddress,
        msg: {
          harvest: {},
        },
      };
    }

    await sendTransaction({
      wallet,
      txType: "withdraw-vault",
      data,
      additionalData:
        wallet.address !== "metamask" ? additionalData : undefined,
      successMessage: `Withdrew ${getFormattedValue(
        value
      )} tokens successfully`,
      callback: () => {
        setValue("");
        props.onClose();
      },
      gasMultiplier: props.vault.gasMultiplier ?? 1.5,
      // hideResultModal: true,
    });

    // toast.error(`Failed to withdraw tokens: ${e.message}`)

    props.onWithdraw();
  }, [
    wallet.address,
    props?.vault?.stakingToken.address,
    value,
    tokenDecimals,
  ]);

  return (
    <Modal
      isOpen={props.isOpen}
      close={() => {
        setValue("");
        props.onClose();
      }}
      open={() => {}}
      isCloseBtn
    >
      <div
        className={
          "px-4 sm:px-6 flex flex-col bg-primary-100 border border-primary-300 my-0 p-3 rounded-xl border-solid mx-4 sm:mx-2"
        }
      >
        <h1
          className={
            "text-xl font-bold not-italic leading-[normal] tracking-[normal] text-center text-primary-900 mt-2.5 mb-[30px]"
          }
        >
          Withdraw
        </h1>

        <div className={"mt-2 mb-1 flex items-center justify-between mx-1"}>
          <div className={"text-primary-900 shrink-0"}>
            Staked :{" "}
            {getFormattedValue(new BN(props?.vault?.balance).toNumber())}{" "}
            {props?.vault?.stakingToken.name}
          </div>
          <div className="flex justify-end">
            <div
              className="font-medium text-sm text-primary-100 bg-primary-700 rounded-lg px-3 hover:opacity-80 cursor-pointer"
              onClick={() => {
                if (!isNaN(parseFloat(props?.vault?.balance))) {
                  setValue(new BN(props?.vault?.balance).toString());
                }
              }}
            >
              Max
            </div>
          </div>
        </div>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={"Enter Amount"}
          className="text-primary-900 rounded-xl bg-primary-200/50 border border-primary-500 px-4 py-2 focus:outline-none"
        />
        {/* 
        {dayjs().diff(
          dayjs(props?.vault?.data?.user?.deposited_at * 1000),
          "hours"
        ) <= 72 && (
          <div className="mt-4 text-sm text-red-500 text-center">
            Your last deposit is{" "}
            {dayjs().diff(
              dayjs(props?.vault?.data?.user?.deposited_at * 1000),
              "hours"
            )}{" "}
            hours ago. You will be charged a 0.5% fee if you withdraw within 72
            hours of depositing.
          </div>
        )} */}

        <button
          className={`my-4 py-2 text-white font-bold rounded-xl bg-primary-700 pointer-events-auto w-full ${
            new BN(props?.vault?.balance).lte(new BN(0)) ||
            loading ||
            !value ||
            new BN(value).lte(new BN(0)) ||
            new BN(value).gt(new BN(props?.vault?.balance))
              ? "opacity-50 hover:opacity-50 cursor-default"
              : "hover:opacity-80 cursor-pointer"
          }`}
          disabled={
            new BN(props?.vault?.balance).lte(new BN(0)) ||
            loading ||
            !value ||
            new BN(value).lte(new BN(0)) ||
            new BN(value).gt(new BN(props?.vault?.balance))
          }
          onClick={() => submit()}
        >
          Withdraw
        </button>
      </div>
    </Modal>
  );
};

export default WithdrawModal;
