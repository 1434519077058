import classNames from "classnames";

interface Props {
  bg?: string;
  width?: string;
  className?: string;
}

const Skeleton: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames(
        "animate-pulse rounded-full aspect-square",
        props.bg ?? "bg-primary-900/20",
        props.width ?? "w-20",
        props.className
      )}
    />
  );
};

export default Skeleton;
