import classNames from "classnames";

const InjectiveLogo = ({ className }: { className?: string }) => {
  return (
    <div className={classNames(className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1000 207.195208"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient
            x1="0.000287984588%"
            y1="50.0042469%"
            x2="169.198084%"
            y2="50.0042469%"
            id="linearGradient-3-j_qmknxf-1">
            <stop stopColor="#0082FA" offset="0%"></stop>
            <stop stopColor="#00F2FE" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="-69.1804623%"
            y1="50.0042017%"
            x2="100.005993%"
            y2="50.0042017%"
            id="linearGradient-3-j_qmknxf-2">
            <stop stopColor="#0082FA" offset="0%"></stop>
            <stop stopColor="#00F2FE" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fillRule="nonzero">
            <path
              d="M278.670798,155.959008 L278.670798,70.4689444 C278.670798,61.681553 285.785084,54.567702 294.572662,54.567702 L295.734773,54.567702 L295.734773,155.959008 L278.670798,155.959008 Z"
              fill="currentColor"></path>
            <path
              d="M320.47701,155.959008 L320.47701,54.567702 L338.439743,54.567702 L379.542228,128.154659 L379.542228,54.567702 L379.96024,54.567702 C388.747818,54.567702 395.862104,61.681553 395.862104,70.4689444 L395.862104,155.959008 L377.899371,155.959008 L336.796886,82.3720501 L336.796886,155.959008 L320.47701,155.959008 Z"
              fill="currentColor"></path>
            <path
              d="M449.680117,158.423604 C439.094403,158.423604 430.833533,155.184473 424.898129,148.721119 C418.962104,142.242858 415.986328,133.656523 415.986328,122.931678 L433.050303,122.931678 C433.050303,129.208697 434.429806,134.230436 437.203719,137.996274 C440.0708,141.669566 444.224837,143.498138 449.664589,143.498138 C455.104341,143.498138 459.258378,141.669566 462.125459,137.996274 C465.101235,134.230436 466.573285,129.255281 466.573285,123.07143 L466.573285,70.4689444 C466.573285,61.681553 473.687571,54.567702 482.475148,54.567702 L483.63726,54.567702 L483.63726,123.086958 C483.63726,133.811802 480.6149,142.398138 474.586329,148.876399 C468.650304,155.245964 460.34285,158.439132 449.649061,158.439132 L449.680117,158.423604 Z"
              fill="currentColor"></path>
            <path
              d="M506.827323,54.5691926 L566.791299,54.5691926 C566.791299,62.8142859 560.111174,69.4944102 551.865833,69.4944102 L523.891298,69.4944102 L523.891298,98.1664603 L566.775771,98.1664603 L566.775771,113.091926 L523.891298,113.091926 L523.891298,141.050933 L566.775771,141.050933 L566.775771,155.976399 L506.811795,155.976399 L506.811795,54.5691926 L506.827323,54.5691926 Z"
              fill="currentColor"></path>
            <path
              d="M940.037266,54.5691926 L1000,54.5691926 C1000,62.8142859 993.322981,69.4944102 985.074534,69.4944102 L957.105589,69.4944102 L957.105589,98.1664603 L999.987578,98.1664603 L999.987578,113.091926 L957.105589,113.091926 L957.105589,141.050933 L999.987578,141.050933 L999.987578,155.976399 L940.024844,155.976399 L940.024844,54.5691926 L940.037266,54.5691926 Z"
              fill="currentColor"></path>
            <path
              d="M636.55279,158.413045 C621.751548,158.413045 610.035399,153.484473 601.387572,143.642858 C592.754653,133.801243 588.430429,120.99938 588.430429,105.252796 C588.430429,89.5062117 592.754653,76.7198762 601.387572,66.8633543 C610.019871,57.0214908 621.751548,52.0929193 636.55279,52.0929193 C648.981362,52.0929193 659.198753,55.5645964 667.223598,62.5236027 C675.236021,69.3739133 679.962729,78.3167706 681.372667,89.3204974 L677.565213,89.3204974 C670.07453,89.3204974 664.093163,83.6633545 659.664592,77.634783 C658.372666,75.8987581 656.900617,74.3335407 655.211176,72.9540376 C650.453412,69.0018636 644.28571,67.0180127 636.70807,67.0180127 C626.571424,67.0180127 618.637262,70.4894413 612.918007,77.4484476 C607.277013,84.2987582 604.455895,93.5670814 604.455895,105.252796 C604.455895,116.939131 607.277013,126.253417 612.918007,133.212423 C618.65279,140.062734 626.571424,143.487579 636.70807,143.487579 C644.28571,143.487579 650.453412,141.504349 655.211176,137.552175 C656.900617,136.172672 658.3913,134.622983 659.664592,132.87143 C664.111797,126.842237 670.07453,121.185715 677.565213,121.185715 L681.372667,121.185715 C679.962729,132.189442 675.254654,141.178883 667.223598,148.12174 C659.198753,154.972051 648.981362,158.413045 636.55279,158.413045 Z"
              fill="currentColor"></path>
            <path
              d="M725.44099,155.959008 L725.44099,70.2055904 L695.310555,70.2055904 C695.310555,61.5730437 702.316766,54.567702 710.950307,54.567702 L772.776395,54.567702 L772.776395,70.2055904 L742.509313,70.2055904 L742.509313,155.959008 L725.44099,155.959008 Z"
              fill="currentColor"></path>
            <path
              d="M794.459625,155.959008 L794.459625,70.4689444 C794.459625,61.681553 801.571426,54.567702 810.360246,54.567702 L811.521737,54.567702 L811.521737,155.959008 L794.459625,155.959008 Z"
              fill="currentColor"></path>
            <path
              d="M867.602483,155.959008 L833.1677,54.567702 L840.105588,54.567702 C847.018632,54.567702 853.142855,59.0313045 855.248445,65.6180127 L875.757762,129.735405 L896.260868,65.6180127 C898.366458,59.0313045 904.490682,54.567702 911.403726,54.567702 L918.347825,54.567702 L883.906831,155.959008 L867.59006,155.959008 L867.602483,155.959008 Z"
              fill="currentColor"></path>
            <path
              d="M25.1234655,35.7947825 C26.409863,34.1829812 27.773714,32.6175774 29.137627,31.0677638 C29.1996146,30.9902482 29.3391177,30.9747824 29.4011053,30.8972669 C29.5250804,30.7422979 29.7265711,30.6493166 29.8505463,30.4942855 L29.9745214,30.3393165 C30.9199252,29.4559004 31.9428445,28.5104966 33.1052668,27.6735401 C37.2278756,24.5428568 41.5054533,22.1560866 46.0310434,20.590745 C60.5066958,15.5072046 76.6254537,18.6378878 89.2720378,30.571801 C106.924833,47.1242236 105.344088,73.76646 91.2558887,91.481367 C73.4478139,117.890684 42.8538384,154.730436 85.2111681,187.742238 C92.8366341,193.678263 98.4782491,198.575778 122.469554,205.503729 C106.769554,208.401865 92.2322862,207.503108 76.0360127,203.365219 C64.5825964,196.902486 46.5734658,183.062114 40.4360744,164.355281 C31.1679376,135.993169 56.7405466,93.6043484 69.0931555,77.2844724 C86.0639632,54.6875156 58.6003603,30.2308072 38.3902358,57.5392548 C27.8202357,71.7670811 9.34594975,112.047827 15.7623473,141.913045 C19.5130306,158.853418 24.5190555,171.205592 44.3726583,188.160871 C40.6995526,185.991306 37.1193663,183.52671 33.6476892,180.768325 C-12.4604481,137.806212 -7.09796351,71.3950314 25.1234655,35.7947825 Z"
              fill="url(#linearGradient-3-j_qmknxf-1)"></path>
            <path
              d="M182.091294,171.400623 C180.804958,173.012424 179.440983,174.577641 178.077629,176.127331 C178.015517,176.204971 177.875766,176.220499 177.813654,176.298138 C177.690051,176.452797 177.488188,176.545964 177.364585,176.701244 L177.240362,176.855902 C176.29502,177.739753 175.272039,178.685095 174.109927,179.521741 C169.986946,182.652797 165.693778,185.039132 161.18384,186.60435 C146.708063,191.688201 130.590051,188.557145 117.942846,176.623604 C100.29005,160.070809 101.870796,133.429194 115.958995,115.714287 C133.767069,89.3049695 164.36148,52.4647206 121.988187,19.4527947 C114.378249,13.516894 108.721727,8.61931626 84.7298017,1.69148387 C100.429802,-1.20674598 114.967069,-0.307830439 131.163343,3.83027893 C142.616759,10.2931672 160.626076,24.1488817 166.763343,42.8401242 C176.031666,71.2024848 150.458995,113.590684 138.106821,129.911181 C121.136013,152.508076 148.599368,176.964598 168.809306,149.655902 C179.379492,135.428573 197.853406,95.1478267 191.437256,65.2819878 C187.686325,48.3421118 182.680735,35.9897514 162.826697,19.0343475 C166.499989,21.2041611 170.080113,23.6684469 173.551542,26.4271426 C219.660238,69.3739133 214.313033,135.800622 182.091294,171.400623 Z"
              fill="url(#linearGradient-3-j_qmknxf-2)"></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default InjectiveLogo;
