import { DexScreenerSlice } from "store/interfaces/dexScreener.interface"
import { DexScreenerPair } from "types/dexScreener"
import { StateCreator } from "zustand"

export const dexScreenerSlice: StateCreator<
  DexScreenerSlice,
  [],
  [],
  DexScreenerSlice
> = (set) => ({
  dexScreenerData: undefined,
  setDexScreenerData: (data: DexScreenerPair[]) =>
    set((state) => ({
      ...state,
      dexScreenerData: data,
    })),
})
