import useEphemeralStore from "../store/ephemeralStore"
import useDashboardAPI from "rest/useDashboardAPI.ts"
import BN from "bignumber.js"

export const FETCH_PRICES_FREQUENCY_MS = 30000

export const usePrices = () => {
  const setPricesBySymbol = useEphemeralStore((s) => s.setPricesBySymbol)
  const setPricesByTokenAddress = useEphemeralStore(
    (s) => s.setPricesByTokenAddress,
  )
  const { api } = useDashboardAPI()

  // ------------------
  // EXECUTION
  // ------------------
  const fetchPrices = async () => {
    const priceData = await api.prices.list()
    const _pricesBySymbol = {}
    const _pricesByTokenAddress = {}
    priceData?.map((price) => {
      _pricesBySymbol[price.symbol] = price.price
      _pricesByTokenAddress[price.contractAddress] = price.price
    })
    // console.log({
    //   _pricesBySymbol,
    //   _pricesByTokenAddress,
    // })
    setPricesBySymbol(_pricesBySymbol)
    setPricesByTokenAddress(_pricesByTokenAddress)
  }

  return { fetchPrices }
}

// getting prices from Dex Screener API
export const useDexScreenerPrices = () => {
  const dexScreenerData = useEphemeralStore((s) => s.dexScreenerData)

  function getPriceOfToken({
    symbol,
    address,
  }: {
    symbol?: string
    address?: string
  }) {
    const token = dexScreenerData?.find((item) => {
      if (symbol) {
        return item?.baseToken?.symbol === symbol
      } else if (address) {
        return item?.baseToken?.address === address
      }
    })
    if (token) {
      return new BN(token?.priceUsd).toNumber()
    }
    return undefined
  }

  return { getPriceOfToken }
}
