import { DEFAULT_TOKEN_IMAGE_URL } from "constants/constants";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { Img } from "react-image";
import useEphemeralStore from "../store/ephemeralStore";
import useLocalStore from "../store/localStore";
import { NETWORK } from "libs/services";
import { Network } from "@injectivelabs/networks";
import { MarketingInfo } from "constants/tokens";

type AssetIconProps = {
  className?: string;
  logo?: string;
  address?: string;
  alt?: string;
  style?: CSSProperties;
  isLP?: boolean;
  noRounded?: boolean;
  marketingInfo?: MarketingInfo;
};

const AssetIcon: React.FC<AssetIconProps> = ({
  className,
  logo,
  address,
  alt,
  style,
  isLP,
  marketingInfo,
  noRounded,
}) => {
  const [icon, setIcon] = useState("");
  const tokensAddressMap = useEphemeralStore(
    (s) => s.jsonData?.tokensAddressMap
  );

  useEffect(() => {
    if (logo) {
      setIcon(logo);
    } else {
      let name = address?.includes("ibc/")
        ? address?.replace("ibc/", "")
        : address;

      if (tokensAddressMap?.[address]?.icon) {
        setIcon(tokensAddressMap[address].icon);
      } else {
        if (name) {
          // removes all forward slashes from name
          name = name.split("/").join("");

          setIcon(`/images/tokens/${name?.toLowerCase()}.svg`);
        } else {
          setIcon(DEFAULT_TOKEN_IMAGE_URL);
        }
      }
    }
  }, [address, tokensAddressMap]);

  // const srcs = useMemo(() => {

  // }, [icon])

  const _srcs = [];
  if (icon.includes("ipfs://")) {
    _srcs.push(icon.replace("ipfs://", "https://gateway.pinata.cloud/ipfs/"));
    _srcs.push(icon.replace("ipfs://", "https://ipfs.io/ipfs/"));
    _srcs.push(icon.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/"));
  } else {
    _srcs.push(icon);
  }
  if (icon.includes(".svg")) {
    _srcs.push(icon.replace(".svg", ".png"));
  }
  if (marketingInfo?.logo?.url) {
    _srcs.push(marketingInfo?.logo?.url);
  }

  return (
    <Img
      className={className}
      alt={alt}
      src={[..._srcs, DEFAULT_TOKEN_IMAGE_URL]}
      style={{
        width: "auto",
        height: "25px",
        position: "relative",
        display: "inline-block",
        verticalAlign: "middle",
        borderRadius: isLP || noRounded ? "0" : "50%",
        ...style,
      }}
    />
  );
};

export default AssetIcon;
