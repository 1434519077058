import { memo, useCallback } from "react";
import { PublicVaultData } from "../constants/vaults";
import useSendTransaction from "../hooks/useSendTransaction";
import useWallet from "../hooks/useWallet";

interface Props {
  vault?: PublicVaultData;
  fetchVault: (name: string, walletAddress: string) => void;
}

const HarvestButton = (props: Props) => {
  const earned = props?.vault?.data?.earned ?? "0";
  const { wallet, isConnected } = useWallet();

  const { loading, sendTransaction } = useSendTransaction();

  const submit = useCallback(async () => {
    const data = {
      sender: wallet.address,
      contractAddress: props?.vault?.vaultAddress,
      msg: {
        get_reward: {},
      },
    };
    // const additionalData = {
    //   sender: wallet.address,
    //   contractAddress: props?.vault?.vaultAddress,
    //   msg: {
    //     harvest: {},
    //   },
    // };
    await sendTransaction({
      wallet,
      txType: "harvest-vault",
      data,
      // additionalData:
      //   wallet.address !== "metamask" ? additionalData : undefined,
      successMessage: `Harvested rewards successfully`,
      gasMultiplier: props.vault.gasMultiplier ?? 1.5,
    });
    props.fetchVault(props?.vault?.name, wallet.address);
  }, [wallet.address, props?.vault?.vaultAddress, earned]);

  return (
    <>
      <button
        onClick={() => submit()}
        disabled={loading || earned === "0" || !isConnected}
        className="text-primary-100 disabled:text-primary-500/60 dark:disabled:text-white/60 rounded-xl text-sm font-bold tracking-wider disabled:border disabled:border-primary-500 disabled:border-primary-500/30 dark:disabled:border-white/40 hover:opacity-80 disabled:bg-white/10 disabled:opacity-60 cursor-pointer disabled:cursor-not-allowed bg-primary-700 px-3 py-2.5"
      >
        Get Reward
      </button>
    </>
  );
};

export default memo(HarvestButton);
