import { Presale, PresalesSlice } from "store/interfaces/presales.interface"
import { StateCreator } from "zustand"

export const presalesSlice: StateCreator<
  PresalesSlice,
  [],
  [],
  PresalesSlice
> = (set) => ({
  presales: undefined,
  setPresales: (data: Presale[]) => {
    set((state) => ({
      ...state,
      presales: data,
    }))
  },
})
