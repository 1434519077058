import { TransactionResult } from "../../types/txHistoryContext";

export enum TxStage {
  SIGNING,
  LOADING,
  RESULT,
  FAILED,
}

export interface TxState {
  txType: string | undefined;
  txStage: TxStage | undefined;
  txResult: TransactionResult | undefined;
  summary: string | undefined;
}

export interface TxStateSlice {
  txState: TxState;
  resetTxState: () => void;
  updateTxStage: (stage: TxStage) => void;
  updateTxType: (type: string) => void;
  updateTxResult: (result: TransactionResult) => void;
  updateTxSummary: (summary: string) => void;
}
