import {
  JsonSlice,
  TokensAddressMap,
  TokensSymbolMap,
} from "store/interfaces/json.interface"
import { StateCreator } from "zustand"

export const jsonSlice: StateCreator<JsonSlice, [], [], JsonSlice> = (set) => ({
  jsonData: {
    isLoading: true,
    vaults: undefined,
    tokensSymbolMap: {
      POINT: {
        address: "inj1l73x8hh6du0h8upp65r7ltzpj5twadtp5490n0",
        decimals: 18,
        name: "POINT Token",
        symbol: "POINT",
        type: "cw20",
        icon: "/images/tokens/inj1l73x8hh6du0h8upp65r7ltzpj5twadtp5490n0.svg",
        verified: true,
      },
      DOJO: {
        address: "inj1zdj9kqnknztl2xclm5ssv25yre09f8908d4923",
        decimals: 18,
        name: "Dojo Token",
        symbol: "DOJO",
        type: "cw20",
        icon: "/images/tokens/dojo.svg",
        verified: true,
      },
      INJ: {
        address: "inj",
        decimals: 18,
        name: "Injective",
        symbol: "INJ",
        type: "native",
        icon: "injective-v3.svg",
        verified: true,
      },
      dINJ: {
        address: "inj134wfjutywny9qnyux2xgdmm0hfj7mwpl39r3r9",
        decimals: 18,
        name: "Dojo Staked Injective",
        symbol: "dINJ",
        type: "cw20",
        icon: "/images/tokens/dinj.svg",
        verified: true,
      },
      USDT: {
        address: "peggy0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimals: 6,
        name: "USD Tether",
        symbol: "USDT",
        type: "native",
        icon: "usdt.svg",
        verified: true,
      },
      WETH: {
        address: "peggy0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        decimals: 18,
        name: "Wrapped Ether",
        symbol: "WETH",
        type: "native",
        icon: "ethereum.svg",
        verified: true,
      },
      WBTC: {
        address: "peggy0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
        decimals: 8,
        name: "Wrapped BTC",
        symbol: "WBTC",
        type: "native",
        icon: "wbtc.svg",
        verified: true,
      },
      USDC: {
        address: "peggy0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        decimals: 6,
        name: "USD Coin",
        symbol: "USDC",
        type: "native",
        icon: "usdc.svg",
        verified: true,
      },
    },
    tokensAddressMap: undefined,
    farmFees: undefined,
    farmsV1: undefined,
    farms: undefined,
    pools: undefined,
    feeApr: undefined
  },
  setJsonData: ({
    vaults,
    tokensSymbolMap,
    tokensAddressMap,
    farmFees,
    farmsV1,
    farms,
    pools,
    feeApr,
  }: {
    vaults?: any
    tokensSymbolMap?: TokensSymbolMap
    tokensAddressMap?: TokensAddressMap
    farmFees?: any
    farmsV1?: any
    farms?: any
    pools?: any
    feeApr?: any
  }) => {
    set((state) => ({
      ...state,
      jsonData: {
        ...state.jsonData,
        isLoading: false,
        vaults: vaults ?? state?.jsonData?.vaults,
        tokensSymbolMap: tokensSymbolMap ?? state?.jsonData?.tokensSymbolMap,
        tokensAddressMap: tokensAddressMap ?? state?.jsonData?.tokensAddressMap,
        farmFees: farmFees ?? state?.jsonData?.farmFees,
        farmsV1: farmsV1 ?? state?.jsonData?.farmsV1,
        farms: farms ?? state?.jsonData?.farms,
        pools: pools ?? state?.jsonData?.pools,
        feeApr: feeApr ?? state?.jsonData?.feeApr,
      },
    }))
  },
  setTokenAddressMap: (tokens: TokensAddressMap) => {
    set((state) => ({
      ...state,
      jsonData: {
        ...state.jsonData,
        tokensAddressMap: tokens,
      },
    }))
  },
})
