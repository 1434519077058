import Card from "./Card";
import Modal from "./Modal";
import ConnectList from "./ConnectList";
import useWallet from "../hooks/useWallet";
import { truncate } from "libs/text";
import useLocalStore from "../store/localStore";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { bgGradient, buttonGradient } from "constants/gradient";

const ConnectListModal = (modal: Modal) => {
  const size = { width: 30, height: "auto" };
  const disconnect = useLocalStore((s) => s.disconnect);

  const { isConnected, wallet } = useWallet();
  return (
    <Modal {...modal} className={"w-[90vw] max-w-[432px] sm:mx-auto my-0"}>
      {isConnected ? (
        <Card className="bg-primary-100">
          <h1
            className={
              "text-primary-900 text-base font-bold mb-[18px] border-b border-primary-500 pb-4 text-center"
            }>
            Your Wallet Details
          </h1>
          <div className={" text-primary-900"}>
            <div className="h-full">
              <div className="flex justify-between mb-1">
                <div className="flex items-center">
                  <div className="font-bold mr-2 flex">Injective Address</div>
                </div>
              </div>
              <div className="flex justify-between pb-1">
                <div className="flex items-center">
                  <div className="flex justify-between">
                    <div className="leading-7 text-sm sm:text-base bg-gradient-to-r from-blue-600 via-sky-500 to-purple-600 text-transparent bg-clip-text hover:brightness-75">
                      <a
                        href={`https://explorer.injective.network/account/${wallet.address}`}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer">
                        {wallet.address}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-primary-700">
                <div className="text-primary-900 mt-3 font-semibold flex">
                  Ethereum Address
                </div>
                <div className="flex justify-between">
                  <div className="leading-7 text-sm sm:text-base bg-gradient-to-r from-blue-600 via-sky-500 to-purple-600 text-transparent bg-clip-text hover:brightness-75">
                    <a
                      href={`https://etherscan.io/address/${wallet.ethereumAddress}`}
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer">
                      {wallet.ethereumAddress}
                    </a>
                  </div>
                </div>
              </div>
              <button
                onClick={disconnect}
                className={classNames(
                  buttonGradient,
                  "cursor-pointer text-primary-100 px-4 py-1 rounded-lg mt-6 hover:brightness-90 w-full"
                )}>
                Disconnect wallet
              </button>
            </div>
          </div>
        </Card>
      ) : (
        <Card className="bg-primary-100">
          <h1
            className={
              "text-primary-900 text-base font-bold mb-[18px] border-b border-primary-500 pb-4 text-center"
            }>
            Select a Wallet to Connect
          </h1>
          <ConnectList />
        </Card>
      )}
    </Modal>
  );
};

export default ConnectListModal;
