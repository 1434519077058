import { StateCreator } from "zustand";
import {
  MoneyMarketObject,
  MoneyMarketSlice,
} from "store/interfaces/moneyMarket.interface";
import { INJECTIVE_BURN_WALLET } from "constants/constants";

export const moneyMarketSlice: StateCreator<
  MoneyMarketSlice,
  [],
  [],
  MoneyMarketSlice
> = (set) => ({
  moneyMarketWallet: undefined,
  setMoneyMarketWallet: (wallet: string) =>
    set((state) => ({
      ...state,
      moneyMarketWallet: wallet,
    })),
  isMoneyMarketLoading: false,
  setIsMoneyMarketLoading: (isLoading: boolean) =>
    set(() => ({
      isMoneyMarketLoading: isLoading,
    })),
  supplyBySymbol: undefined,
  borrowBySymbol: undefined,
  setSupplyBySymbol: (supply: MoneyMarketObject[], wallet: string) => {
    const supplyWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET;
    set((s) => ({
      ...s,
      supplyBySymbol: {
        ...s.supplyBySymbol,
        [supplyWallet]: supply,
      },
    }));
  },
  setBorrowBySymbol: (borrow: MoneyMarketObject[], wallet: string) => {
    const borrowWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET;
    set((s) => ({
      ...s,
      borrowBySymbol: {
        ...s.borrowBySymbol,
        [borrowWallet]: borrow,
      },
    }));
  },
});
