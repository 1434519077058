import { useConnectModal } from "../hooks/useConnectModal";
import useLocalStore from "../store/localStore";

const size = { width: 30, height: "auto" };

const ConnectList = () => {
  const connectModal = useConnectModal();

  const [connectMetamask, connectKeplr, connectLeap, connectTrustWallet] =
    useLocalStore((s) => [
      s.connectMetamask,
      s.connectKeplr,
      s.connectLeap,
      s.connectTrustWallet,
    ]);

  const handleConnectClick = () => {
    connectModal.close();
  };

  return (
    <article className={""}>
      <section className="font-bold">
        <div
          className="hover:bg-primary-200 p-4 cursor-pointer rounded-lg"
          onClick={() => {
            connectMetamask()
              .then(() => {
                console.info("connect metamask success");
                handleConnectClick();
              })
              .catch((e) => console.error(e));
          }}
        >
          <button className={"text-primary-900 flex"}>
            <img src="/images/wallets/metamask.svg" alt="Metamask" {...size} />
            &nbsp;&nbsp; Metamask
          </button>
        </div>
        <div
          className="hover:bg-primary-200 p-4 cursor-pointer rounded-lg"
          onClick={() => {
            connectKeplr()
              .then(() => {
                console.info("connect keplr success");
                handleConnectClick();
              })
              .catch((e) => console.error(e));
          }}
        >
          <button className={"text-primary-900 flex"}>
            <img src="/images/wallets/keplr.svg" alt="Keplr" {...size} />
            &nbsp;&nbsp; Keplr
          </button>
        </div>
        <div
          className="hover:bg-primary-200 p-4 cursor-pointer rounded-lg"
          onClick={() => {
            connectLeap()
              .then(() => {
                console.info("connect leap success");
                handleConnectClick();
              })
              .catch((e) => console.error(e));
          }}
        >
          <button className={"text-primary-900 flex"}>
            <img src="/images/wallets/leap.svg" alt="Leap" {...size} />
            &nbsp;&nbsp; Leap
          </button>
        </div>
        <div
          className="hover:bg-primary-200 p-4 cursor-pointer rounded-lg"
          onClick={() => {
            connectTrustWallet()
              .then(() => {
                console.info("connect trust success");
                handleConnectClick();
              })
              .catch((e) => console.error(e));
          }}
        >
          <button className={"text-primary-900 flex"}>
            <img src="/images/wallets/trust-wallet.svg" alt="trust" {...size} />
            &nbsp;&nbsp; TrustWallet
          </button>
        </div>
      </section>
    </article>
  );
};

export default ConnectList;
