import { Network } from "@injectivelabs/networks";
import { NETWORK } from "../libs/services";
import {
  DOJO,
  DOJO_ROLL,
  FarmToken,
  KAGE,
  ROLL,
  SUSHI,
  SUSHI_DOJO,
  TokenType,
  serializeToken,
} from "./tokens";
export interface VaultConfig {
  name: string;
  stakingToken: FarmToken;
  earningToken: FarmToken;
  yieldToken?: FarmToken; // If LP to LP compounding vault, might need specify price of underlying token
  vaultAddress: string;
  farmAddress: string;
  lpTokens?: [FarmToken, FarmToken];
  baseApr?: number;
  hidden?: boolean;
  isMaximiser: boolean;
  isAutoCompound: boolean;
  gasMultiplier?: number;
}

export interface PublicVaultData extends VaultConfig {
  data: {
    balance: string;
    totalSupply: string;
    farmConfig: {
      distribution_schedule: [number, number, string][];
      dojo_token: string;
      staking_token: string;
    };
    farmState: {
      global_reward_index: string;
      last_distributed: number;
      total_bond_amount: string;
    };
    config: {
      admin: string;
      pair: string;
      protocol_token: string;
      reward_pool: string;
      reward_token: string;
      staking_pool: string;
      staking_token: string;
    };
    user: {
      shares: string;
      principal: string;
      deposited_at: number;
    };
    earned: string;
    balanceOf: string;
    walletBalance: string;
  };
}

const test = {
  dojoToken: "inj1wphuc5rz6t5qd6gs65zmxjnjzr7dd9q6ak6k25",
  autoToken: "inj12andm9zhj3eht8xx7d77zc7u2n6an484fr7s2n",
  dojoSingleStake: "inj1k7lqp87gxrh6hc8r3us7q9a5xkf5vnsx7tlmn6",
  autoSingleStake: "inj1g8pqxxv4waj6dyyg6zlpaylz49grt59vyutr03",
  dojoAutoLp: "inj16m779w3g5w59n2mnz5pc9vh4rprwvs8zs29axm",
  autoVault: "inj1nzgf89lswkf8474u7c3agz5vtczsaezvq46lkr",
  dojoToAutoVault: "inj166t2c4zsua7wn7shhwg05rnxrcwx0lh4n07vh6",
};
export const vaults: { mainnet: VaultConfig[]; testnet: VaultConfig[] } = {
  //TODO: Replace with correct addresses
  mainnet: [
    {
      name: "Stake Dojo, Earn Dojo",
      stakingToken: DOJO,
      earningToken: DOJO,
      vaultAddress: "inj102zs4nvdq4lyztqntp4l7nsvvqkpcvec4eeryw",
      farmAddress: "inj1p0vntlcq7t3uksx56dny7wn334vrtpuwcj884z",
      isAutoCompound: true,
      isMaximiser: false,
    },
    {
      name: "Stake ROLL, Earn ROLL",
      stakingToken: ROLL,
      earningToken: ROLL,
      vaultAddress: "inj1kkv8phfx6v22tndqk7vj3nxzvgzhjmz2jx74v7",
      farmAddress: "inj1fuf8u3d8fk2p34anz3f72tct6q8sr5hvxxv4x4",
      isAutoCompound: true,
      isMaximiser: false,
    },
    {
      name: "Stake KAGE, Earn KAGE",
      stakingToken: KAGE,
      earningToken: KAGE,
      vaultAddress: "inj1g6nvwwr6uhpcxxstvlql09zcny0xggzne6l2ev",
      farmAddress: "inj19lwej7yvqjk90ju4ztr79vz8gjdg2zy2gc73rm",
      isAutoCompound: true,
      isMaximiser: false,
    },
    {
      name: "Stake SUSHI-DOJO, Earn LP",
      stakingToken: SUSHI_DOJO,
      earningToken: SUSHI_DOJO,
      yieldToken: DOJO,
      lpTokens: [SUSHI, DOJO],
      vaultAddress: "inj1pf4r60ac7lznwu8exp4k7kqjxv8zq922wgjq3r",
      farmAddress: "inj1yqtcds4gpvhcdlpjh9u45xjx9lxwame7fa265x",
      isAutoCompound: true,
      isMaximiser: false,
      gasMultiplier: 4,
    },
    {
      name: "Stake DOJO-ROLL, Earn LP",
      stakingToken: DOJO_ROLL,
      earningToken: DOJO_ROLL,
      yieldToken: DOJO,
      lpTokens: [DOJO, ROLL],
      vaultAddress: "inj1s77m7rk5kdtcuaveyjgmp4uclnrnhjm46fck2x",
      farmAddress: "inj1zmqyp2rh92t8vsgr6z4jmgahddufgpk8za00vu",
      isAutoCompound: true,
      isMaximiser: false,
      gasMultiplier: 4,
    },
    // {
    //   "name": "Stake hINJ, Earn Dojo",
    //   "stakingToken": "inj18luqttqyckgpddndh8hvaq25d5nfwjc78m56lc",
    //   "earningToken": "inj1zdj9kqnknztl2xclm5ssv25yre09f8908d4923",
    //   "vaultAddress": "inj1rtznpr8pvmn24nka4mgh45hdf3qg8rlm5dnlu8",
    //   "farmAddress": "inj1p0vntlcq7t3uksx56dny7wn334vrtpuwcj884z",
    //   "isAutoCompound": true,
    //   "isMaximiser": true
    // },
    // {
    //   name: "Stake hINJ, Earn Dojo",
    //   stakingToken: hINJ,
    //   earningToken: DOJO,
    //   vaultAddress: "inj1rtznpr8pvmn24nka4mgh45hdf3qg8rlm5dnlu8",
    //   farmAddress: "inj1p0vntlcq7t3uksx56dny7wn334vrtpuwcj884z",
    //   isAutoCompound: true,
    //   isMaximiser: true,
    //   baseApr: 16,
    //   gasMultiplier: 3,
    // },
    // {
    //   name: "Auto Vault",
    //   stakingToken: TOKENS.AUTO,
    //   earningToken: TOKENS.AUTO,
    //   vaultAddress: "inj1c26pqz9xh408cp6e7v5w5hk25wx3y9pq5px8lt",
    //   farmAddress: "inj12wl5qplmq8jzn7lha5xnzgv0sfn39m5zhr9t5w",
    // },
    // {
    //   name: "DOJO to AUTO Vault",
    //   stakingToken: DOJO,
    //   earningToken: DOJO,
    //   isMaximiser: true,
    //   vaultAddress: "inj17emt6d900f55md3kp7tum0tq0e2g62sdpuujpq",
    //   farmAddress: "inj1jcfp2c46gw5myy838fthmp6e5rg3mtekhyjxu2",
    // },
  ],
  testnet: [
    {
      name: "Stake Vault, Earn Vault",
      stakingToken: serializeToken(
        "VAULT",
        "Vault Token",
        test.autoToken,
        18,
        TokenType.CW20
      ),
      earningToken: serializeToken(
        "VAULT",
        "Vault Token",
        test.autoToken,
        18,
        TokenType.CW20
      ),
      vaultAddress: test.autoVault,
      farmAddress: test.autoSingleStake,
      isAutoCompound: true,
      isMaximiser: false,
    },
    {
      name: "Stake Dojo, Earn Vault",
      stakingToken: serializeToken(
        "DOJO",
        "Dojo Token",
        test.dojoToken,
        18,
        TokenType.CW20
      ),
      earningToken: serializeToken(
        "VAULT",
        "Vault Token",
        test.autoToken,
        18,
        TokenType.CW20
      ),
      isMaximiser: true,
      isAutoCompound: true,
      vaultAddress: test.dojoToAutoVault,
      farmAddress: test.dojoSingleStake,
    },
  ],
};

export const getPoolByTokenAddress = (address: string) => {
  const _pools = NETWORK === Network.Mainnet ? vaults.mainnet : vaults.testnet;
  return _pools.find((item) => item.stakingToken.address === address);
};
