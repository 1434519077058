// import { IS_DEVELOPMENT } from "libs/services";

import Announcements from "./Icons/Announcements";
import Gitbook from "./Icons/Gitbook";
import Telegram from "./Icons/Telegram";
import Twitter from "./Icons/Twitter";

const Footer = () => {
  return (
    <footer className="flex gap-x-8 justify-center text-primary-900 w-full text-sm py-5">
      <a
        className=" hover:opacity-75 cursor-pointer"
        href="http://ninjavault.gitbook.io/"
        target="_blank"
        rel="noreferrer">
        <Twitter className="w-5 h-5 text-black dark:text-white" />
      </a>
      <a
        className=" hover:opacity-75 cursor-pointer"
        href="https://t.me/ninjavaultchatgroup"
        target="_blank"
        rel="noreferrer">
        <Telegram className="w-5 h-5 text-black dark:text-white" />
      </a>
      <a
        className=" hover:opacity-75 cursor-pointer"
        href="https://t.me/ninjavaultanngroup"
        target="_blank"
        rel="noreferrer">
        <Announcements className="w-5 h-5 text-black dark:text-white" />
      </a>
      <a
        className=" hover:opacity-75 cursor-pointer"
        href="http://ninjavault.gitbook.io/"
        target="_blank"
        rel="noreferrer">
        <Gitbook className="w-5 h-5 text-black dark:text-white" />
      </a>
      {/* {IS_DEVELOPMENT && (
        <button className="cursor-default bg-primary-900 text-primary-100 px-4 py-1 rounded-2xl font-semibold text-sm tracking-wider">
          TESTNET
        </button>
      )} */}
    </footer>
  );
};

export default Footer;
