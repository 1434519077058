import classNames from "classnames";

interface Props {
  bg?: string;
  width?: string;
  height?: string;
  className?: string;
}

const Skeleton: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames(
        "animate-pulse rounded-md",
        props.bg ?? "bg-white/20",
        props.height ?? "h-[24px]",
        props.width ?? "w-20",
        props.className
      )}
    />
  );
};

export default Skeleton;
