import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { BeakerIcon } from "@heroicons/react/24/solid";
import Connect from "./Connect";
import { ReactComponent as Icon } from "../images/NinjaVaultIcon.svg";
import useWallet from "../hooks/useWallet";
import useLocalStore from "../store/localStore";
import Sun from "./Icons/Sun";
import Moon from "./Icons/Moon";

const Header = () => {
  const { pathname } = useLocation();
  const darkMode = useLocalStore((s) => s.darkMode);
  const toggleDarkMode = useLocalStore((s) => s.toggleDarkMode);
  // const { isConnected, wallet } = useWallet();

  const isActive = (_pathname: string) => {
    if (_pathname === "/") {
      return pathname === "/";
    }
    if (pathname.includes(_pathname)) {
      return true;
    }
    return false;
  };

  return (
    <header className="text-white w-full h-auto fixed z-[5000] transition-[padding] duration-[0.125s] ease-[ease-in-out] overflow-visible mb-0 px-2 sm:px-4 py-4 left-0 top-0  after:bg-white after:opacity-[0.15] after:left-0 after:bottom-0 backdrop-blur-sm">
      <div className="flex justify-between max-w-7xl mx-auto">
        <section
          className={"wrapper flex items-center gap-x-0 sm:gap-x-3 lg:gap-x-4"}>
          <Link to="/" className="font-black flex items-center">
            <Icon className="h-10 w-10 inline-block mr-2" />
            <div className="hidden sm:inline-block text-2xl text-primary-900 dark:text-white">
              NinjaVault
            </div>
          </Link>
          {/* <div className="sm:-mt-0.5 inline-block items-start cursor-default text-primary-100 text-xs px-3 sm:px-4 py-1 rounded-full font-bold bg-primary-500">
              Beta
            </div> */}
        </section>

        <section className="flex">
          <div className="flex items-center text-white gap-x-5 sm:gap-x-6 md:gap-x-10 text-sm sm:text-base">
            <Link
              to="/"
              className={classNames(
                "",
                isActive("/")
                  ? "text-primary-700 dark:text-dark-700 cursor-default font-black"
                  : "text-primary-900 dark:text-white font-medium"
              )}>
              Dashboard
            </Link>
            <Link
              to="/vault"
              className={classNames(
                "",
                isActive("/vault")
                  ? "text-primary-700 dark:text-dark-700 cursor-default font-black"
                  : "text-primary-900 dark:text-white font-medium"
              )}>
              Vaults
            </Link>
            <a
              href="https://dojo.trading/swap"
              rel="noreferrer"
              target="_blank"
              className={classNames(
                "font-medium rounded-lg",
                "text-primary-900 dark:text-dark-900 hover:opacity-75"
              )}>
              Exchange
            </a>
            <div
              onClick={toggleDarkMode}
              className="cursor-pointer hover:scale-110 transition-scale duration-100">
              {darkMode ? (
                <Sun className="w-5 h-5" />
              ) : (
                <Moon className="w-5 h-5 text-black" />
              )}
            </div>
          </div>
        </section>
      </div>
    </header>
  );
};

export default Header;
