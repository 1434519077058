import {
  memo,
  useMemo,
  useState,
  Fragment,
  useCallback,
  useRef,
  useEffect,
} from "react";
import classNames from "classnames";
import AssetIcon from "./AssetIcon";
import BN from "bignumber.js";
import DepositModal from "./DepositModal";
import HarvestButton from "./HarvestButton";
import WithdrawModal from "./WithdrawModal";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { useDebounce } from "@uidotdev/usehooks";
import { getFormattedValue } from "../libs/getFormattedValue";
import { Menu, Transition } from "@headlessui/react";
import Skeleton from "./Skeleton";
import SkeletonCircle from "./SkeletonCircle";
import { initialVaults, useVaults } from "../hooks/useVaults";
import {
  ArrowPathIcon,
  ChevronDownIcon,
  MinusIcon,
  PlusIcon,
  WalletIcon,
  CheckCircleIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import {
  DocumentDuplicateIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "react-query";

import TxResultModal from "./TxResultModal";
import useEphemeralStore from "../store/ephemeralStore";
import useLocalStore from "../store/localStore";
import { vaults as VAULTS } from "../constants/vaults";
import { NETWORK } from "../libs/services";
import { Network } from "@injectivelabs/networks";
import useWallet from "../hooks/useWallet";
import { useConnectModal } from "hooks/useConnectModal";
import { ReactComponent as NinjaVaultWhiteIcon } from "../images/NinjaVaultWhiteIcon.svg";
import { truncate } from "libs/text";
import { Tooltip } from "react-tooltip";
import { textGradient, vaultCardGradient } from "constants/gradient";
import useDashboardAPI from "rest/useDashboardAPI";

dayjs.extend(relative);

enum SortKeys {
  DEPOSITED = "Deposited",
  TVL = "TVL",
  EMISSIONS = "Emissions",
  EARNED = "Earned",
}

const Vault = () => {
  const { isConnected, actualWallet } = useWallet();
  const connectModal = useConnectModal();

  const handleClick = () => connectModal.open();
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const handleCopy = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (timer?.current) {
        clearTimeout(timer.current);
      }
      setIsCopied(true);
      if (navigator?.clipboard?.writeText) {
        navigator?.clipboard?.writeText(inputRef?.current?.innerHTML || "");
      } else {
        inputRef?.current?.focus();
        inputRef?.current?.select();
        document.execCommand("copy");
      }

      if (event.currentTarget) {
        event.currentTarget.blur();
      }

      timer.current = setTimeout(() => {
        setIsCopied(false);
      }, 1200);
    },
    []
  );

  const { vaults, fetchVault, fetchVaults } = useVaults();
  const vaultWallet = useEphemeralStore((s) => s.vaultWallet);
  const selectedVault = useEphemeralStore((s) => s.selectedVault);
  const setSelectedVault = useEphemeralStore((s) => s.setSelectedVault);
  const txState = useEphemeralStore((s) => s.txState);
  const resetTxState = useEphemeralStore((s) => s.resetTxState);
  const [modalType, setModalType] = useState("");

  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 300);

  const [sortedBy, setSortedBy] = useState(SortKeys.DEPOSITED);
  const setAllVaults = useEphemeralStore((s) => s.setAllVaults);
  const setVaultWallet = useEphemeralStore((s) => s.setVaultWallet);
  const setMockWallet = useLocalStore((s) => s.setMockWallet);
  const { api } = useDashboardAPI();
  const { data: marketingTokens } = useQuery(`marketing-all`, () =>
    api.tokens.allMarketingInfo()
  );
  useEffect(() => {
    if (actualWallet?.address) {
      setMockWallet("");
    }
  }, [actualWallet.address]);

  useEffect(() => {
    if (actualWallet.address !== "" && vaultWallet !== actualWallet.address) {
      console.log("refreshing all vaults due to wallet change");
      const cached = vaults ?? initialVaults();
      let _vaults = {};
      Object.keys(cached).map((key) => {
        const _vault = cached[key];
        _vaults[key] = {
          ..._vault,
          loading: true,
          lastFetched: undefined,
        };
      });
      setAllVaults(_vaults, actualWallet.address);
      fetchVaults(actualWallet.address, true);
      setVaultWallet(actualWallet.address);
    }
  }, [actualWallet.address]);

  const sortedVaults = useMemo(() => {
    const _vaults = vaults ?? initialVaults();
    const _filteredvaults = Object.keys(_vaults)
      .map((key) => _vaults[key])
      .filter((vault) => vault.name.toLowerCase().includes(debouncedQuery));

    if (isConnected && sortedBy === SortKeys.DEPOSITED) {
      return _filteredvaults.sort((a, b) => {
        const aValue = new BN(a.balanceUsd);
        const bValue = new BN(b.balanceUsd);
        if (aValue === bValue) {
          return new BN(b.walletBalance)
            .minus(new BN(a.walletBalance))
            .toNumber();
        } else {
          return bValue.minus(aValue).toNumber();
        }
      });
    } else if (sortedBy === SortKeys.EARNED) {
      return _filteredvaults.sort((a, b) => {
        const aValue = new BN(a.pending_reward);
        const bValue = new BN(b.pending_reward);
        if (isConnected && aValue === bValue) {
          return new BN(b.walletBalance)
            .minus(new BN(a.walletBalance))
            .toNumber();
        } else {
          return bValue.minus(aValue).toNumber();
        }
      });
    } else if (sortedBy === SortKeys.EMISSIONS) {
      return _filteredvaults.sort((a, b) => {
        const aValue = new BN(a.emissions);
        const bValue = new BN(b.emissions);
        if (isConnected && aValue === bValue) {
          return new BN(b.walletBalance)
            .minus(new BN(a.walletBalance))
            .toNumber();
        } else {
          return bValue.minus(aValue).toNumber();
        }
      });
    } else {
      return _filteredvaults.sort((a, b) => {
        const aValue = new BN(a.tvl);
        const bValue = new BN(b.tvl);
        if (isConnected && aValue === bValue) {
          return new BN(b.walletBalance)
            .minus(new BN(a.walletBalance))
            .toNumber();
        } else {
          return bValue.minus(aValue).toNumber();
        }
      });
    }
  }, [vaults, debouncedQuery, sortedBy, isConnected]);

  // console.log(vaults);

  const [loading, setLoading] = useState(false);

  async function refetchVaults() {
    setLoading(true);
    await fetchVaults(actualWallet.address, true);
    setLoading(false);
  }

  return (
    <div className="relative text-primary-900 h-content">
      <div className="w-full h-auto relative">
        <div className="mx-auto">
          <div className="max-w-xl mx-auto text-center">
            <div
              className={classNames(
                "text-3xl md:text-5xl font-black inline-block",
                textGradient
              )}
            >
              Vaults
            </div>
            <div className="mt-5 text-lg text-black dark:text-white">
              Stake tokens to earn more tokens.
            </div>
          </div>
          {!isConnected ? (
            <div className="flex justify-center mt-5 mb-2">
              <button
                className="font-extrabold text-lg text-white dark:text-black bg-primary-700 dark:bg-white py-2 px-4 rounded-xl hover:shadow-lg hover:shadow-primary-300"
                onClick={handleClick}
              >
                <WalletIcon className="w-5 h-5 inline-block mr-2 mb-0.5" />
                Connect your wallet
              </button>
            </div>
          ) : (
            <div className="">
              <div className="text-lg mt-6 font-semibold flex justify-center">
                <div className="mr-1 text-primary-500 dark:text-white">
                  {truncate(actualWallet.address, [8, 8])}
                </div>
              </div>
              <div className="mt-1 flex justify-center">
                <button
                  disabled={loading}
                  onClick={async () => {
                    await refetchVaults();
                  }}
                  className="hover:opacity-50 mr-1 hover:animate-spin disabled:opacity-20 disabled:animate-spin"
                >
                  <ArrowPathIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                </button>
                <button
                  onClick={handleCopy}
                  className="cursor-pointer hover:opacity-50"
                >
                  <textarea
                    ref={inputRef}
                    value={actualWallet.address}
                    readOnly
                    className="border-0 p-0 bg-transparent resize-none w-0 h-0 absolute -z-50"
                  />
                  {isCopied ? (
                    <CheckCircleIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                  ) : (
                    <DocumentDuplicateIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                  )}
                </button>
                <button onClick={handleClick} className="hover:opacity-50 ml-1">
                  <EllipsisHorizontalIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                </button>
              </div>
            </div>
          )}

          <div className="py-4 relative z-0">
            <div className="w-full md:grid md:grid-cols-2 md:max-w-3xl mx-auto">
              {sortedVaults.map((vault) => {
                return (
                  <div
                    key={vault.name}
                    className={classNames(
                      isConnected &&
                        vaultWallet === actualWallet.address &&
                        vault.balance > 0
                        ? "shadow-md shadow-primary-500 dark:shadow-primary-500/60 dark:shadow-lg"
                        : "shadow shadow-primary-300 dark:shadow-white/20",
                      "rounded-xl min-w-[330px] sm:min-w-[370px] max-w-3xl mx-auto mb-4",
                      vaultCardGradient
                    )}
                  >
                    <>
                      <div className="relative flex mx-auto">
                        <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium mt-2">
                          <div className="flex items-center h-full">
                            <div className="flex items-center shrink-0">
                              {!vault?.stakingToken.address ? (
                                <>
                                  <SkeletonCircle
                                    bg="bg-primary-300/50 dark:bg-white/30"
                                    width="w-[30px]"
                                  />
                                </>
                              ) : (
                                <>
                                  {vault?.lpTokens ? (
                                    <div className="relative inline-block">
                                      <AssetIcon
                                        address={
                                          vault?.lpTokens?.[0]?.address || ""
                                        }
                                        style={{
                                          zIndex: 1,
                                          bottom: 2,
                                          right: -8,
                                          width: 30,
                                          height: 30,
                                        }}
                                        marketingInfo={
                                          marketingTokens?.[
                                            vault?.lpTokens?.[0]?.address
                                          ]
                                        }
                                      />
                                      <AssetIcon
                                        address={
                                          vault?.lpTokens?.[1]?.address || ""
                                        }
                                        style={{
                                          position: "absolute",
                                          bottom: 2,
                                          right: 6,
                                          width: 30,
                                          height: 30,
                                        }}
                                        marketingInfo={
                                          marketingTokens?.[
                                            vault?.lpTokens?.[1]?.address
                                          ]
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <AssetIcon
                                      className=""
                                      address={
                                        vault?.stakingToken.address || ""
                                      }
                                      alt={vault?.stakingToken.address || ""}
                                      style={{
                                        width: 30,
                                        height: 30,
                                      }}
                                      marketingInfo={
                                        marketingTokens?.[
                                          vault?.stakingToken.address
                                        ]
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                            <div className="ml-3 text-lg font-semibold">
                              <div className="text-black dark:text-white">
                                {vault.name}
                              </div>
                              <div className="flex justify-start">
                                <div>
                                  <span
                                    className="text-xs text-primary-100 bg-primary-500 px-3 py-1 rounded-l-xl cursor-help mr-1"
                                    data-tooltip-id={`zerofees-${vault?.name}`}
                                    data-tooltip-html={`Our zero fees vault charge 0 fees on deposits, withdrawals and yields.`}
                                  >
                                    Zero Fees
                                  </span>
                                  <Tooltip
                                    style={{
                                      zIndex: 50,
                                      fontSize: "12px",
                                      textAlign: "center",
                                      padding: "10px",
                                      lineHeight: "20px",
                                    }}
                                    id={`zerofees-${vault?.name}`}
                                  />
                                </div>

                                {vault.isAutoCompound && (
                                  <div>
                                    <span
                                      className="text-xs text-primary-100 bg-primary-500 px-3 py-1 rounded-r-xl cursor-help"
                                      data-tooltip-id={`autocompounding-${vault?.name}`}
                                      data-tooltip-html={`Yields are autocompounded up to at least 48x a day`}
                                    >
                                      Autocompounding
                                    </span>
                                    <Tooltip
                                      style={{
                                        zIndex: 50,
                                        fontSize: "12px",
                                        textAlign: "center",
                                        padding: "10px",
                                        lineHeight: "20px",
                                      }}
                                      id={`autocompounding-${vault?.name}`}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between mx-2 px-3 text-black dark:text-white">
                        <div className="whitespace-nowrap">
                          <div
                            className="text-sm font-semibold mb-0.5 cursor-help"
                            data-tooltip-id={`apy-${vault?.name}`}
                            data-tooltip-html={`The Annual Percentage Yield (APY) takes into account<br/>the compounding effect of yields harvested.<br/>APR is the rate at which yields are harvested on a<br/>yearly basis without auto compounding.`}
                          >
                            APY{" "}
                            <QuestionMarkCircleIcon className="w-4 h-4 inline-block -mt-0.5 -ml-0.5" />
                          </div>
                          <Tooltip
                            style={{
                              zIndex: 50,
                              fontSize: "12px",
                              textAlign: "center",
                              padding: "10px",
                              lineHeight: "20px",
                            }}
                            id={`apy-${vault?.name}`}
                          />
                          <div>
                            {loading ||
                            !vault.apr ||
                            parseFloat(vault?.apr) === 0 ? (
                              <Skeleton
                                bg="bg-primary-300/50 dark:bg-white/30"
                                height="h-[24px]"
                                width="w-32"
                              />
                            ) : (
                              <>{vault.apr}%</>
                            )}
                          </div>
                        </div>
                        <div className="whitespace-nowrap mr-3">
                          <div className="text-sm font-semibold mb-0.5">
                            TVL
                          </div>
                          <div>
                            {loading || !vault.tvl || vault.tvl === 0 ? (
                              <Skeleton
                                bg="bg-primary-300/50 dark:bg-white/30"
                                height="h-[24px]"
                                width="w-32"
                              />
                            ) : (
                              <>${getFormattedValue(vault.tvl, true, true)}</>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="whitespace-nowrap px-5 w-full py-6 text-black dark:text-white">
                        <div className="grid grid-cols-1 gap-x-4">
                          <div className="rounded-xl mb-4 flex items-center justify-between shrink-0">
                            <div>
                              <div className="text-sm font-semibold mb-0.5">
                                Earned
                              </div>
                              {/* <div
                                  className="text-sm font-semibold mb-0.5 cursor-help"
                                  data-tooltip-id={`earned-${vault?.name}`}
                                  data-tooltip-html={`Your rewards are already automatically compounded in Earned as well.<br/>Earned & Deposited are displayed separately to give users the option<br/>to withdraw their earned yields without touching their capital.`}
                                >
                                  Earned{" "}
                                  <QuestionMarkCircleIcon className="w-4 h-4 inline-block -mt-0.5 -ml-0.5" />
                                </div>
                                <Tooltip
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    padding: "10px",
                                    lineHeight: "20px",
                                  }}
                                  id={`earned-${vault?.name}`}
                                /> */}
                              <div>
                                {loading || !vaults || vault.loading ? (
                                  <Skeleton
                                    bg="bg-primary-300/50 dark:bg-white/30"
                                    height="h-[24px]"
                                    width="w-32"
                                  />
                                ) : (
                                  <>
                                    {isConnected
                                      ? getFormattedValue(vault.earningBalance)
                                      : "0"}{" "}
                                    <span className="">
                                      ${vault.earningToken.symbol}
                                    </span>
                                  </>
                                )}
                              </div>
                              <div className="text-xs mt-0.5">
                                {loading || !vaults || vault.loading ? (
                                  <Skeleton
                                    bg="bg-primary-300/50 dark:bg-white/30"
                                    height="h-[16px]"
                                    width="w-20"
                                  />
                                ) : (
                                  <>
                                    ~$
                                    {isConnected
                                      ? vault.earningBalanceUsd.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      : "0"}
                                  </>
                                )}
                              </div>
                            </div>
                            <HarvestButton
                              vault={vault}
                              fetchVault={fetchVault}
                            />
                          </div>
                          <div className="rounded-xl mb-3 flex items-center justify-between gap-x-3 shrink-0">
                            <div className="flex items-center justify-between flex-grow">
                              <div>
                                <div
                                  className="text-sm font-semibold mb-0.5 cursor-help"
                                  data-tooltip-id={`deposited-${vault?.name}`}
                                  data-tooltip-html={`Your rewards are already automatically compounded in Earned as well.<br/>Earned & Deposited are displayed separately to give users the option<br/>to withdraw their earned yields without touching their deposit.`}
                                >
                                  Deposited{" "}
                                  <QuestionMarkCircleIcon className="w-4 h-4 inline-block -mt-0.5 -ml-0.5" />
                                </div>
                                <Tooltip
                                  style={{
                                    zIndex: 50,
                                    fontSize: "12px",
                                    textAlign: "center",
                                    padding: "10px",
                                    lineHeight: "20px",
                                  }}
                                  id={`deposited-${vault?.name}`}
                                />
                                <div>
                                  {loading || !vaults || vault.loading ? (
                                    <Skeleton
                                      bg="bg-primary-300/50 dark:bg-white/30"
                                      height="h-[24px]"
                                      width="w-24"
                                    />
                                  ) : (
                                    <>
                                      {isConnected
                                        ? getFormattedValue(vault.balance)
                                        : "0"}
                                    </>
                                  )}
                                </div>
                                <div className="text-xs mt-0.5">
                                  {loading || !vaults || vault.loading ? (
                                    <Skeleton
                                      bg="bg-primary-300/50 dark:bg-white/30"
                                      height="h-[16px]"
                                      width="w-20"
                                    />
                                  ) : (
                                    <>
                                      ~$
                                      {isConnected
                                        ? getFormattedValue(vault.balanceUsd)
                                        : "0"}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="text-right">
                                <div className="text-sm font-semibold mb-0.5">
                                  Wallet
                                </div>
                                <div>
                                  {loading || !vaults || vault.loading ? (
                                    <Skeleton
                                      bg="bg-primary-300/50 dark:bg-white/30"
                                      height="h-[24px]"
                                      width="w-24"
                                      className="ml-auto"
                                    />
                                  ) : (
                                    <>
                                      {isConnected
                                        ? getFormattedValue(vault.walletBalance)
                                        : "0"}
                                    </>
                                  )}
                                </div>
                                <div className="text-xs mt-0.5 text-right">
                                  {loading || !vaults || vault.loading ? (
                                    <Skeleton
                                      bg="bg-primary-300/50 dark:bg-white/30"
                                      height="h-[16px]"
                                      width="w-20"
                                      className="ml-auto"
                                    />
                                  ) : (
                                    <>
                                      ~$
                                      {isConnected
                                        ? getFormattedValue(vault.walletUsd)
                                        : "0"}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <button
                                disabled={!isConnected}
                                onClick={() => {
                                  if (vault.walletBalance > 0 && isConnected) {
                                    setSelectedVault(vault);
                                    setModalType("deposit");
                                  }
                                }}
                                className={`rounded-xl p-3 ${
                                  vault?.walletBalance > 0 && isConnected
                                    ? "hover:opacity-80 cursor-pointer bg-primary-700 text-primary-100"
                                    : "opacity-30 hover:opacity-30 cursor-not-allowed border border-primary-500 dark:border-white"
                                }`}
                              >
                                <PlusIcon className="w-4 h-4" />
                              </button>
                              <button
                                disabled={!isConnected}
                                onClick={() => {
                                  if (vault.balance > 0 && isConnected) {
                                    setSelectedVault(vault);
                                    setModalType("withdraw");
                                  }
                                }}
                                className={`rounded-xl border border-primary-500 dark:border-white p-3 ${
                                  vault.balance > 0 && isConnected
                                    ? "hover:opacity-80 cursor-pointer"
                                    : "opacity-30 hover:opacity-30 cursor-not-allowed"
                                }`}
                              >
                                <MinusIcon className="w-4 h-4" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <DepositModal
        vault={selectedVault}
        isOpen={modalType === "deposit" && txState.txStage === undefined}
        onClose={() => {
          setSelectedVault(undefined);
          setModalType("");
        }}
        onDeposit={() => {
          setModalType("");
          fetchVault(selectedVault.name, actualWallet.address);
        }}
      />

      <WithdrawModal
        vault={selectedVault}
        isOpen={modalType === "withdraw" && txState.txStage === undefined}
        onClose={() => {
          setSelectedVault(undefined);
          setModalType("");
        }}
        onWithdraw={() => {
          setModalType("");
          fetchVault(selectedVault.name, actualWallet.address);
        }}
      />
      <TxResultModal
        txState={txState}
        isOpen={txState.txStage !== undefined}
        onClose={() => resetTxState()}
      />
    </div>
  );
};

export default memo(Vault);
