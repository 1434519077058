import BN from "bignumber.js"
import { Network } from "@injectivelabs/networks"
import useEphemeralStore from "../store/ephemeralStore"
import useNetwork from "hooks/useNetwork"
import useURL from "hooks/useURL"
import { NETWORK } from "libs/services"
import { PublicPoolData, pools as POOLS, PoolConfig } from "constants/pools"
import { toBase64 } from "@injectivelabs/sdk-ts"
import { contracts } from "../types/StakingPool"
import {
  FORCE_ON_CHAIN_FETCH,
  INJECTIVE_BURN_WALLET,
} from "constants/constants"
import { multicallFetcher } from "utils/multicallFetcher"
import _ from "lodash"
import dayjs from "dayjs"
import { BigNumberInWei } from "@injectivelabs/utils"
import useWallet from "./useWallet"
import { PoolData, PoolsMap } from "store/interfaces/pools.interface"
import { TESTNET_TOKEN_ADDRESSES } from "constants/tokens"
import useDashboardAPI from "rest/useDashboardAPI.ts"
import { CACHED_FARM_DURATION_SECS } from "hooks/useFarms.ts"
import createTimeout from "utils/createTimeout"

export const initialPools = () =>
  POOLS[NETWORK === Network.Mainnet ? "mainnet" : "testnet"]
    ?.filter((pool) => !pool.hidden)
    .reduce((dict, obj) => {
      dict[obj.name] = {
        ...obj,
        apr: "0",
        balance: 0,
        balanceUsd: 0,
        emissions: 0,
        pending_reward: "0",
        total_bond_amount: "0",
        tvl: 0,
        walletUsd: 0,
        walletBalance: 0,
      }
      return dict
    }, {})

// How often to CHECK if pools need to be fetched (in milliseconds)
export const FETCH_POOLS_FREQUENCY_MS = 30000

// Pools will be cached for below duration,
// and not fetched whilst cached.
// Regardless of FETCH_POOLS_FREQUENCY_MS.
const CACHED_POOL_DURATION_SECS = 30

const BIG_TEN = new BN(10)
const { StakingPool } = contracts

function getAPR(
  distribution_schedule: [number, number, string][],
  stakeAmt: string,
  rewardTokenPrice: number,
  stakeTokenPrice: number,
) {
  const schedule =
    distribution_schedule?.find((schedule) => {
      const now = Date.now() / 1000
      return now > schedule[0] && now < schedule[1]
    }) ?? []
  const unixTimeNow = dayjs().unix()

  if (unixTimeNow > schedule?.[1] || unixTimeNow < schedule?.[0]) {
    return "0"
  }

  const delta = schedule?.[1] - schedule?.[0]
  const totalRewardAmt = new BigNumberInWei(schedule?.[2])

  const dailyRewardAmtInUSD = new BigNumberInWei(totalRewardAmt)
    .div(delta)
    .times(86400)
    .multipliedBy(rewardTokenPrice)
  const stakeAmtInUSD = new BigNumberInWei(stakeAmt).multipliedBy(
    stakeTokenPrice,
  )

  const apr = dailyRewardAmtInUSD.div(stakeAmtInUSD).times(365).times(100)
  return apr.dp(2).toJSON()
}

function getEmissions(
  distribution_schedule: [number, number, string][],
  earningTokenDecimals: number,
) {
  const schedule =
    distribution_schedule?.find((schedule) => {
      const now = Date.now() / 1000
      return now > schedule[0] && now < schedule[1]
    }) ?? []
  const unixTimeNow = dayjs().unix()

  if (unixTimeNow > schedule?.[1] || unixTimeNow < schedule?.[0]) {
    return "0"
  }

  const delta = schedule?.[1] - schedule?.[0]
  const totalRewardAmt = new BigNumberInWei(schedule?.[2])
  return totalRewardAmt
    .div(BIG_TEN.pow(earningTokenDecimals))
    .div(delta)
    .times(86400)
    .dp(2)
    .toString()
  // const dailyRewardAmtInUSD = new BigNumberInWei(dailyRewardAmt).multipliedBy(
  //   rewardTokenPrice,
  // )
  // const stakeAmtInUSD = new BigNumberInWei(stakeAmt).multipliedBy(
  //   stakeTokenPrice,
  // )
  // const apr = dailyRewardAmtInUSD.div(stakeAmtInUSD).times(365).times(100)
  // return apr.dp(2).toJSON()
}

function getTVL(pool: PublicPoolData, tokenPrice: number) {
  const tvl = new BN(tokenPrice)
    .times(pool.vault?.state.total_bond_amount)
    .div(BIG_TEN.pow(pool.poolToken.decimals))
    .toNumber()

  return tvl.toString() === "NaN" ? "0" : tvl.toString()
}

// Re-fetching of the full pool data is selective, based on how stale each pool is
// TODO: Fetching of pool balance is specific, only make required calls
export const usePools = () => {
  const { isConnected, wallet } = useWallet()
  const cachedWallet = useEphemeralStore((s) => s.poolWallet)
  const poolsByWallet = useEphemeralStore((s) => s.poolsByWallet)
  const cachedPools =
    poolsByWallet?.[
      isConnected
        ? wallet.address
        : cachedWallet && cachedWallet !== ""
          ? cachedWallet
          : INJECTIVE_BURN_WALLET
    ]
  const setPoolWallet = useEphemeralStore((s) => s.setPoolWallet)
  const setAllPools = useEphemeralStore((s) => s.setAllPools)
  const setPool = useEphemeralStore((s) => s.setPool)
  const pricesBySymbol = useEphemeralStore((s) => s.pricesBySymbol)
  const tokensSymbolMap = useEphemeralStore((s) => s.jsonData?.tokensSymbolMap)

  const { multicall } = useNetwork()
  const getURL = useURL()
  const { api } = useDashboardAPI()

  const key = NETWORK === Network.Testnet ? "testnet" : "mainnet"
  const allPools = POOLS[key]?.filter((pool) => !pool.hidden)
  const hiddenPools = POOLS[key]
    ?.filter((pool) => pool.hidden)
    .map((pool) => pool.name)

  const getPoolJson = (poolsToFetch: PoolConfig[]) => {
    return _.flatMap(
      poolsToFetch.map((pool) => {
        const _queries = [
          {
            address: pool.stakingAddress,
            data: toBase64(StakingPool.StakingPoolQueryMsgBuilder.config()),
          },
          {
            address: pool.stakingAddress,
            data: toBase64(StakingPool.StakingPoolQueryMsgBuilder.state({})),
          },
          {
            address: pool.stakingAddress,
            data: toBase64(
              StakingPool.StakingPoolQueryMsgBuilder.stakerInfo({
                staker:
                  wallet.address && wallet.address !== ""
                    ? wallet.address
                    : INJECTIVE_BURN_WALLET,
                blockHeight: Math.round(Date.now() / 1000),
              }),
            ),
          },
          {
            address: pool.lpAddress,
            data: toBase64({ token_info: {} }),
          },
          {
            address: pool.lpAddress,
            data: toBase64({ balance: { address: pool.stakingAddress } }),
          },
          {
            address:
              NETWORK === Network.Testnet
                ? TESTNET_TOKEN_ADDRESSES.DOJO
                : tokensSymbolMap.DOJO.address,
            data: toBase64({ balance: { address: pool.stakingAddress } }),
          },
        ]
        if (wallet.address !== "") {
          _queries.push({
            address: pool.lpAddress,
            data: toBase64({ balance: { address: wallet.address } }),
          })
        }
        return _queries
      }),
    )
  }
  // ------------------
  // EXECUTION
  // ------------------

  // Fetches only pools that NEED to be fetched every FETCH_POOLS_FREQUENCY_SECS.
  // Conditions are:
  // 1. Pool not hidden
  // 2. Pool is stale for more than CACHED_POOL_DURATION_SECS
  // 3. Wallet address has changed (if wallet is disconnected, dont refetch)

  const fetchPools = async (
    walletAddress: string,
    forceAll?: boolean,
    forcePools?: string[],
    forceOnChain?: boolean,
  ) => {
    if (!forceOnChain && !FORCE_ON_CHAIN_FETCH) {
      try {
        const poolsToFetch = await fetchPoolsFromJSON(
          cachedPools,
          hiddenPools,
          forceAll,
          forcePools,
        )
        const { promise: timeout, cancel } = createTimeout(8)
        const publicPools = await Promise.race([
          fetchPublicPools(poolsToFetch, walletAddress, forceAll, forcePools),
          timeout,
        ])
        cancel()
        const parsedPools = parsePools(publicPools as any)
        setAllPools(parsedPools, walletAddress)
      } catch (e) {
        console.warn(
          "error on fetching pools via dashboard api, fetch from onchain",
          e,
        )
        return fetchPoolsOnChain(walletAddress, forceAll, forcePools)
      }
    } else {
      return fetchPoolsOnChain(walletAddress, forceAll, forcePools)
    }
  }

  const fetchPoolsOnChain = async (
    walletAddress: string,
    forceAll?: boolean,
    forcePools?: string[],
  ) => {
    const poolsToFetch = []
    const now = dayjs()
    allPools.forEach((pool) => {
      const cachedPool = cachedPools?.[pool.name]
      // got to do this check cause we are looping through cached data
      if (!hiddenPools.includes(pool.name)) {
        // only fetch if it has been stale for CACHED_POOL_DURATION_SECS
        if (
          forceAll ||
          forcePools?.includes(pool.name) ||
          cachedPool === undefined ||
          cachedPool?.lastFetched === undefined ||
          now.diff(dayjs.unix(cachedPool?.lastFetched), "seconds") >
            CACHED_POOL_DURATION_SECS
        ) {
          poolsToFetch.push(pool)
        }
      }
    })
    // if (poolsToFetch.length > 0) {
    //   console.log("fetching pools: ", poolsToFetch)
    // }
    const queries = getPoolJson(poolsToFetch)
    const publicPools = await fetchPublicPoolsOnChain(
      poolsToFetch,
      queries,
      walletAddress,
    )
    const parsedPools = parsePools(publicPools)
    setAllPools(parsedPools, walletAddress)
    setPoolWallet(walletAddress)
  }

  const fetchPool = async (poolName: string, walletAddress: string) => {
    const pool = cachedPools[poolName]
    setPool(
      poolName,
      {
        ...pool,
        loading: true,
        lastFetched: undefined,
      },
      walletAddress,
    )
    await fetchPools(walletAddress, false, [poolName], true)
  }

  // ------------------
  // HELPERS
  // ------------------
  const fetchPublicPools = async (
    poolsToFetch: PoolConfig[],
    walletAddress: string,
    force?: boolean,
    forcePools?: string[],
  ) => {
    const tvlData = await api.pools.getTvl({
      walletAddress: wallet.address,
      force,
      forcePools,
    })
    const missedPoolsArr = poolsToFetch.filter((farm) => !tvlData[farm.name])
    let missedPoolsData
    if (missedPoolsArr.length > 0) {
      const queriesForMissedPoolFormApi = getPoolJson(missedPoolsArr)
      const publicPools = await fetchPublicPoolsOnChain(
        missedPoolsArr,
        queriesForMissedPoolFormApi,
        walletAddress,
      )
      missedPoolsData = parsePools(publicPools)
    }

    return poolsToFetch.map((pool) => {
      return {
        ...pool,
        ...(tvlData[pool.name]
          ? {
              vault: tvlData[pool.name].vault,
              tvl: tvlData[pool.name].tvl,
            }
          : {}),
        ...(missedPoolsData &&
        missedPoolsData.length &&
        missedPoolsData[pool.name]
          ? {
              vault: missedPoolsData[pool.name].vault,
              tvl: missedPoolsData[pool.name].tvl,
            }
          : {}),
      }
    }) as PublicPoolData[]
  }

  const fetchPublicPoolsOnChain = async (
    poolsToFetch: PoolConfig[],
    queries: {
      address: any
      data: string
    }[],
    walletAddress: string,
  ) => {
    const fetcher = multicallFetcher(multicall, getURL)
    const resp = await fetcher([queries])

    const collectResults = (resp ?? []).reduce(
      (acc, curr) => acc.concat(curr.data.data.return_data),
      [],
    )

    const data = collectResults?.map((e: any) => {
      return e.length == 0
        ? null
        : JSON.parse(Buffer.from(e.data, "base64").toString())
    })

    // 4 because query length is 4
    const chunkedResults = _.chunk(data, walletAddress !== "" ? 7 : 6)

    return poolsToFetch.map((pool, index) => {
      const config = chunkedResults?.[index]?.[0]
      const state = chunkedResults?.[index]?.[1]
      const staker = chunkedResults?.[index]?.[2]
      const lpInfo: any = chunkedResults?.[index]?.[3] ?? {}
      const stakedBalance: any = chunkedResults?.[index]?.[4] ?? {}
      const dojoBalanceInStaking: any = chunkedResults?.[index]?.[5] ?? {}

      const walletBalance: any =
        chunkedResults?.[index]?.length > 6
          ? chunkedResults?.[index]?.[6]["balance"]
          : "0"

      return {
        ...pool,
        vault: {
          config,
          staker,
          state,
          total_rewards: dojoBalanceInStaking?.balance,
          stakedBalance: stakedBalance?.balance,
          lpInfo: {
            ...lpInfo,
          },
          walletBalance,
        },
      }
    }) as PublicPoolData[]
  }

  const parsePools = (data: PublicPoolData[]) => {
    const prices = pricesBySymbol
    let _pools = {}
    data?.forEach((item) => {
      const stakingTokenPrice =
        item.poolToken.symbol === "POINT"
          ? 0.0000001
          : prices?.[item.poolToken.symbol]
      const earningTokenPrice = prices?.[item.earningToken.symbol]
      if (stakingTokenPrice && earningTokenPrice) {
        const { distribution_schedule = [] } = item?.vault?.config ?? {}
        // const { amount } = item?.vault?.staker ?? {}
        const { pending_reward = "0" } = item?.vault?.staker ?? {}
        const { total_bond_amount } = item?.vault?.state ?? {}
        const apr = getAPR(
          distribution_schedule,
          total_bond_amount,
          earningTokenPrice,
          stakingTokenPrice,
        )
        const emissions = getEmissions(
          distribution_schedule,
          item.earningToken.decimals,
        )

        const tvl = getTVL(item, stakingTokenPrice)
        // const totalSupply = new BN(item?.vault?.lpInfo?.total_supply).div(
        //   new BN(10).pow(item.poolToken.decimals),
        // )

        const balance = new BN(item?.vault?.staker?.bond_amount ?? "0")
          .div(BIG_TEN.pow(item.poolToken.decimals))
          .toNumber()
        const balanceUsd = new BN(stakingTokenPrice).times(balance)
        const walletBalance = new BN(item?.vault?.walletBalance ?? "0").div(
          BIG_TEN.pow(item.poolToken.decimals),
        )
        const walletUsd = walletBalance.times(stakingTokenPrice).toNumber()
        const earningBalanceUsd = new BN(earningTokenPrice).times(
          new BN(pending_reward).div(BIG_TEN.pow(item.earningToken.decimals)),
        )

        _pools[item.name] = {
          ...item,
          apr,
          emissions: emissions === "NaN" ? 0 : emissions,
          total_bond_amount,
          tvl: new BN(tvl).toNumber(),
          balance,
          balanceUsd: !isNaN(balanceUsd.toNumber()) ? balanceUsd.toNumber() : 0,
          pending_reward: new BN(pending_reward)
            .div(BIG_TEN.pow(item.earningToken.decimals))
            .toString(),
          earningBalanceUsd: earningBalanceUsd.toNumber(),
          stakingTokenPrice: new BN(stakingTokenPrice),
          earningTokenPrice: new BN(earningTokenPrice),
          walletBalance: walletBalance.toNumber(),
          walletUsd: walletUsd,
          lastFetched: dayjs().unix(),
          loading: false,
        } as PoolData
      }
    })
    return _pools
  }

  return { fetchPool, fetchPools, pools: cachedPools }
}

async function fetchPoolsFromJSON(
  cachedPools: PoolsMap,
  hiddenPools: string[],
  forceAll: boolean,
  forcePools: string[],
) {
  const poolsToFetch = []
  const now = dayjs()
  const poolResp = await fetch(`./json/pools.json`)
  const poolData = await poolResp.json()
  const tokensSymbolMap = useEphemeralStore((s) => s.jsonData?.tokensSymbolMap)
  // const tokenResp = await fetch("./json/tokens-dictionary.json")
  // const tokenData = await tokenResp.json()
  poolData?.forEach((pool) => {
    const cachedFarm = cachedPools?.[pool.name]
    // got to do this check cause we are looping through cached data
    if (!hiddenPools.includes(pool.name)) {
      // only fetch if it has been stale for CACHED_FARM_DURATION_SECS
      if (
        forceAll ||
        forcePools?.includes(pool.name) ||
        cachedFarm === undefined ||
        cachedFarm?.lastFetched === undefined ||
        now.diff(dayjs.unix(cachedFarm?.lastFetched), "seconds") >
          CACHED_FARM_DURATION_SECS
      ) {
        pool.poolToken = tokensSymbolMap[pool.poolToken]
        pool.earningToken = tokensSymbolMap[pool.earningToken]
        poolsToFetch.push(pool)
      }
    }
  })
  return poolsToFetch
}
