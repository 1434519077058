import { useEffect } from "react";
import Header from "./Header";
import useEphemeralStore from "../store/ephemeralStore";
import {
  FETCH_FARMS_FREQUENCY_MS,
  // FETCH_FARMS_FREQUENCY_MS,
  useFarms,
} from "hooks/useFarms";
import { usePools } from "hooks/usePools";
import useWallet from "hooks/useWallet";
import { FETCH_VAULTS_FREQUENCY_MS, useVaults } from "hooks/useVaults";
import { useBalances } from "hooks/useBalances";
import { useMoneyMarket } from "hooks/useMoneyMarket";

import { useStaking } from "hooks/useStaking";
// import { FETCH_PRICES_FREQUENCY_MS, usePrices } from "hooks/usePrices";
import useLocalStore from "../store/localStore";
import { loadDexScreener } from "hooks/loadDexScreener";
import { loadJsonData } from "hooks/loadJsonData";
import { FETCH_PRICES_FREQUENCY_MS, usePrices } from "hooks/usePrices";

const LOCALSTORAGE_KEYS_TO_CLEAR = [
  "ninjavault",
  "ninjavault-1",
  "ninjavault-2",
  "ninjavault-3",
  "ninjavault-4",
  "ninjavault-5",
  "ninjavault-6",
  "ninjavault-7",
  "ninjavault-8",
  "ninjavault-9",
];

const App = () => {
  const darkMode = useLocalStore((s) => s.darkMode);
  const pricesBySymbol = useEphemeralStore((s) => s.pricesBySymbol);
  const selectedFarmsVersion = useEphemeralStore((s) => s.selectedFarmsVersion);
  const setSelectedFarmsVersion = useEphemeralStore(
    (s) => s.setSelectedFarmsVersion
  );
  const cachedFarmWallet = useEphemeralStore((s) => s.farmWallet);
  const { farms: cachedFarms, initialFarms } = useFarms();
  const farms = useEphemeralStore((s) => s.jsonData?.farms);
  const tokensSymbolMap = useEphemeralStore((s) => s.jsonData?.tokensSymbolMap);
  const jsonIsLoading = useEphemeralStore((s) => s.jsonData?.isLoading);

  const setAllFarms = useEphemeralStore((s) => s.setAllFarms);
  const setFarmWallet = useEphemeralStore((s) => s.setFarmWallet);

  loadJsonData();
  loadDexScreener();

  // usePrices()
  const { fetchFarms } = useFarms();
  const { fetchPools } = usePools();
  const { fetchVaults } = useVaults();
  const { fetchBalances } = useBalances();
  const { fetchMoneyMarket } = useMoneyMarket();
  const { fetchStaking } = useStaking();
  const { fetchPrices } = usePrices();

  const { wallet } = useWallet();

  useEffect(() => {
    if (tokensSymbolMap === undefined || jsonIsLoading) {
      return;
    }
    fetchPrices();
    const intervalId = setInterval(() => {
      fetchPrices();
    }, FETCH_PRICES_FREQUENCY_MS);

    return () => clearInterval(intervalId);
  }, [tokensSymbolMap, jsonIsLoading]);

  useEffect(() => {
    const body = document.body;
    if (darkMode) {
      body.classList.add("dark");
    } else {
      body.classList.remove("dark");
    }
  }, [darkMode]);

  // initial load
  useEffect(() => {
    if (farms !== undefined && !jsonIsLoading) {
      // This must be loaded to ensure that all prices in other pages are populated correctly
      const loadV2Farms = async () => {
        console.log("initial loading of farms");
        await fetchFarms({
          forceAll: false,
          version: selectedFarmsVersion,
          walletAddress: wallet.address,
        });
      };
      loadV2Farms();
    }
  }, [selectedFarmsVersion, farms !== undefined, jsonIsLoading]);

  useEffect(() => {
    if (
      wallet.address !== cachedFarmWallet &&
      farms !== undefined &&
      !jsonIsLoading
    ) {
      console.log("refreshing all farms due to wallet change");
      const run = async () => {
        await fetchFarms({
          forceAll: true,
          version: 2,
          walletAddress: wallet.address,
        });
      };
      const cachedV2 = cachedFarms?.["v2"] ?? initialFarms(2);
      let _farmsV2 = {};
      Object.keys(cachedV2).map((key) => {
        const _farm = cachedV2[key];
        _farmsV2[key] = {
          ..._farm,
          loading: true,
          lastFetched: undefined,
        };
      });
      setAllFarms(_farmsV2, 2, wallet.address);
      if (selectedFarmsVersion === 1) {
        setSelectedFarmsVersion(2);
      }
      run();
    }
    setFarmWallet(wallet.address);
  }, [wallet.address, farms !== undefined, jsonIsLoading]);

  useEffect(() => {
    const refreshFarms = async () => {
      if (!jsonIsLoading) {
        await fetchFarms({
          version: selectedFarmsVersion,
          walletAddress: wallet.address,
        });
      }
    };
    const intervalId = setInterval(() => {
      refreshFarms();
    }, FETCH_FARMS_FREQUENCY_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedFarmsVersion, wallet.address, jsonIsLoading]);

  useEffect(() => {
    const run = async (force: boolean) => {
      await fetchPools(wallet.address, force);
      await fetchVaults(wallet.address, force);
      await fetchBalances();
      await fetchMoneyMarket();
      await fetchStaking();
    };

    // const intervalId = setInterval(() => {
    //   run(false);
    // }, FETCH_VAULTS_FREQUENCY_MS);

    if (pricesBySymbol?.["DOJO"] !== undefined && !jsonIsLoading) {
      run(false);
    }

    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [
    pricesBySymbol?.["DOJO"] !== undefined,
    cachedFarms?.["v2"] !== undefined,
    wallet.address,
    jsonIsLoading,
  ]);

  useEffect(() => {
    // Define your condition for clearing specific keys
    LOCALSTORAGE_KEYS_TO_CLEAR.forEach((key) => {
      localStorage.removeItem(key);
    });
  }, []);

  return <Header />;
};

export default App;
