import { CreateTxOptions } from "@terra-money/terra.js";
import { IS_DEVELOPMENT } from "../libs/services";
import { useAccount } from "wagmi";
import _ from "lodash";
import { WalletStore } from "../types/wallet";
import useLocalStore from "../store/localStore";
import { isCosmosWallet } from "@injectivelabs/wallet-ts";
import { WalletConnectStatus } from "../types/enums";
import { useEffect } from "react";
import { connect } from "../libs/wallet";
import { getEthereumSignerAddress } from "libs/walletUtils";

export enum WalletStatus {
  INITIALIZING = "INITIALIZING",
  WALLET_NOT_CONNECTED = "WALLET_NOT_CONNECTED",
  WALLET_CONNECTED = "WALLET_CONNECTED",
}

export interface NetworkInfo {
  /** Network name (e.g. mainnet) */
  name: string;
  /** chainID (e.g. columbus-5) */
  chainID: string;
  /** lcd endpoint (e.g. https://lcd.terra.dev) */
  lcd: string;
}

export interface TxResult extends CreateTxOptions {
  result: {
    height: number;
    raw_log: string;
    txhash: string;
  };
  success: boolean;
}

const mainnet = {
  api: "https://external.api.injective.network",
  chainID: "injective-1",
  hive: "https://phoenix-hive.terra.dev/graphql",
  // lcd: "https://sentry.lcd.injective.network:443",
  lcd: _.sample([
    // "https://inj18583.allnodes.me:1317/MOdKBWvqaA0m0ig3",
    // "https://inj56634.allnodes.me:1317/lr77ZyGM7KCQbfkc",
    // "https://inj24984.allnodes.me:1317/iAeAChGmajFpOeRk",
    // "https://inj32954.allnodes.me:1317/4Wc21W4Wbkcxlrvm",
    // "https://sentry.lcd.injective.network:443",
    "https://inj-rest.w3node.com/12d538c0dfd23b324739be8c7cea8e47e4f1fc6e7cf1360920c345ea35319438/api",
    "https://02b39825-d9c8-4a30-8e88-34fd1c0617ca.injective-1.mesa-rest.newmetric.xyz",
    // "https://injective-rest.publicnode.com",
    "https://sentry.lcd.injective.network:443",
  ]),
  name: "mainnet",
  walletconnectID: 1,
};

const testnet = {
  api: "https://external.api.injective.network",
  chainID: "injective-888",
  hive: "https://phoenix-hive.terra.dev/graphql",
  lcd: "https://testnet.sentry.lcd.injective.network:443",
  name: "testnet",
  walletconnectID: 1,
};

// Custom useWallet hook to allow us to use the wallet without the wallet provider
const useWallet = (): {
  wallet: WalletStore;
  actualWallet: WalletStore;
  isCosmosWallet: boolean;
  isConnected: boolean;
  wagmiStatus: WalletStatus;
  network: NetworkInfo;
  disconnect: () => void;
} => {
  const wallet = useLocalStore((s) => s.wallet);
  const mockWallet = useLocalStore((s) => s.mockWallet);

  const disconnect = useLocalStore((s) => s.disconnect);

  const { isConnected } = useAccount();
  let status: WalletStatus = WalletStatus.WALLET_NOT_CONNECTED;

  if (isConnected) {
    status = WalletStatus.WALLET_CONNECTED;
  }

  // need to force wallet strategy to swap wallets on load
  useEffect(() => {
    const loadStrategy = async () => connect({ wallet: wallet.walletType });
    loadStrategy();
  }, []);

  return {
    wallet: mockWallet
      ? {
          address: mockWallet,
          ethereumAddress: getEthereumSignerAddress(mockWallet),
        }
      : wallet,
    actualWallet: wallet,
    isCosmosWallet: isCosmosWallet(wallet.walletType),
    isConnected: wallet.connectStatus === WalletConnectStatus.connected,
    wagmiStatus: status,
    // network: isMainnet ? mainnet : isTestnet ? testnet : mainnet,
    network: IS_DEVELOPMENT ? testnet : mainnet,
    disconnect,
  };
};

export default useWallet;
