import classNames from "classnames";
import { textGradient } from "constants/gradient";
import { useEffect, useRef } from "react";
import { default as ReactCountUp } from "react-countup";

const CountUp = ({
  value,
  decimals,
  prefix,
}: {
  value: number;
  decimals: number;
  prefix: string;
}) => {
  const prevValueRef = useRef(0);

  useEffect(() => {
    prevValueRef.current = value;
  }, [value]);

  return (
    <ReactCountUp
      className={classNames("text-4xl font-extrabold", textGradient)}
      start={prevValueRef.current}
      end={value}
      separator=","
      decimals={decimals}
      prefix={prefix}
      redraw={true}
    />
  );
};

export default CountUp;
