import axios from "axios"

const DEXSCREENER_API = "https://api.dexscreener.com"

export async function getDexScreenerPair(address: string) {
  const url = `${DEXSCREENER_API}/latest/dex/pairs/injective/${address}`
  const res = (await axios.get(url)).data
  return res
}

export async function getDexScreenerPairs(addresses: string[]) {
  const url = `${DEXSCREENER_API}/latest/dex/pairs/injective/${addresses?.join(
    ",",
  )}`
  const res = (await axios.get(url)).data
  return res
}
