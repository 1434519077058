import { StateCreator } from "zustand";
import { DarkModeSlice } from "store/interfaces/darkMode.interface";

export const darkModeSlice: StateCreator<
  DarkModeSlice,
  [],
  [],
  DarkModeSlice
> = (set) => ({
  darkMode: true,
  toggleDarkMode: () =>
    set((s) => ({
      ...s,
      darkMode: !s.darkMode,
    })),
});
