import BN from "bignumber.js";
import {
  GeneralException,
  TransactionException,
  UnspecifiedErrorCode,
} from "@injectivelabs/exceptions";
import {
  BigNumberInBase,
  DEFAULT_BLOCK_TIMEOUT_HEIGHT,
  LocalStorage,
  getStdFee,
} from "@injectivelabs/utils";
import {
  Web3Client,
  Web3Composer,
  TokenService,
  DenomClientAsync,
  UiBridgeTransformer,
  peggyGraphQlEndpointForNetwork,
} from "@injectivelabs/sdk-ui-ts";
import {
  ApolloConsumer,
  ChainGrpcGovApi,
  ChainGrpcBankApi,
  ChainGrpcWasmApi,
  ChainGrpcMintApi,
  ChainGrpcAuthZApi,
  ChainGrpcPeggyApi,
  ChainGrpcOracleApi,
  IndexerGrpcSpotApi,
  ChainGrpcStakingApi,
  ChainGrpcExchangeApi,
  IndexerGrpcOracleApi,
  IndexerGrpcAccountApi,
  IndexerGrpcTradingApi,
  IndexerGrpcExplorerApi,
  IndexerRestExplorerApi,
  ChainGrpcDistributionApi,
  IndexerRestSpotChronosApi,
  ChainGrpcInsuranceFundApi,
  IndexerGrpcDerivativesApi,
  IndexerRestMarketChronosApi,
  IndexerGrpcAccountPortfolioApi,
  IndexerRestLeaderboardChronosApi,
  IndexerRestDerivativesChronosApi,
  ChainRestAuthApi,
  BaseAccount,
  ChainRestTendermintApi,
  getEip712TypedData,
  recoverTypedSignaturePubKey,
  hexToBase64,
  createTransaction,
  createWeb3Extension,
  createTxRawEIP712,
  getEthereumAddress,
  MsgExecuteContractCompat,
  hexToBuff,
  SIGN_AMINO,
  getGasPriceBasedOnMessage,
  DirectSignResponse,
  CreateTransactionWithSignersArgs,
  createTransactionWithSigners,
  TxGrpcApi,
  MsgInstantiateContract,
  MsgExecuteContract,
} from "@injectivelabs/sdk-ts";
import {
  MsgBroadcaster,
  MsgBroadcasterTxOptions,
  MsgBroadcasterTxOptionsWithAddresses,
  WalletDeviceType,
  Web3Broadcaster,
  isCosmosWallet,
} from "@injectivelabs/wallet-ts";
import { TokenMetaUtilsFactory } from "@injectivelabs/token-metadata";

import {
  Network,
  NetworkEndpoints,
  networkEndpoints,
} from "@injectivelabs/networks";
import { ChainId, EthereumChainId } from "@injectivelabs/ts-types";
import { Wallet, WalletStrategy } from "@injectivelabs/wallet-ts";
import {
  getEthereumSignerAddress,
  getInjectiveSignerAddress,
} from "./walletUtils";
import {
  KeplrWallet,
  createEip712StdSignDoc,
} from "@injectivelabs/wallet-ts/dist/cjs/utils/wallets";
import { Params as TransactParams } from "../types/msgExecuteContract";
import { Params as InstantiateParams } from "../types/msgInstantiateContract";
import _ from "lodash";

export const IS_DEVELOPMENT: boolean =
  process.env?.REACT_APP_TESTNET === "true" ? true : false;

export const NETWORK: Network = IS_DEVELOPMENT
  ? Network.Testnet
  : Network.Mainnet;
export const CHAIN_ID: ChainId = IS_DEVELOPMENT
  ? ChainId.Testnet
  : ChainId.Mainnet;

const endpoints =
  networkEndpoints[
    NETWORK === Network.Mainnet ? Network.MainnetSentry : Network.TestnetSentry
  ];

const grpcEndpoint =
  NETWORK === Network.Mainnet
    ? _.sample([
        "https://inj18583.allnodes.me:9091/MOdKBWvqaA0m0ig3",
        "https://inj56634.allnodes.me:9091/lr77ZyGM7KCQbfkc",
        "https://inj24984.allnodes.me:9091/iAeAChGmajFpOeRk",
        // "https://inj32954.allnodes.me:1317/4Wc21W4Wbkcxlrvm",
        endpoints.grpc,
        // "https://inj-grpc.w3node.com/12d538c0dfd23b324739be8c7cea8e47e4f1fc6e7cf1360920c345ea35319438/api",
        // networkEndpoints[Network.MainnetSentry].rest
      ])
    : networkEndpoints[Network.TestnetSentry].grpc;

const chainRestAuthEndpoint =
  NETWORK === Network.Mainnet
    ? _.sample([
        "https://inj18583.allnodes.me:1317/MOdKBWvqaA0m0ig3",
        "https://inj56634.allnodes.me:1317/lr77ZyGM7KCQbfkc",
        "https://inj24984.allnodes.me:1317/iAeAChGmajFpOeRk",
        // "https://inj32954.allnodes.me:1317/4Wc21W4Wbkcxlrvm",
        endpoints.rest,
        "https://inj-rest.w3node.com/12d538c0dfd23b324739be8c7cea8e47e4f1fc6e7cf1360920c345ea35319438/api",
      ])
    : networkEndpoints[Network.TestnetSentry].rest;

export const ENDPOINTS: NetworkEndpoints = { ...endpoints, grpc: grpcEndpoint };
export const ETHEREUM_CHAIN_ID = IS_DEVELOPMENT
  ? EthereumChainId.Goerli
  : EthereumChainId.Mainnet;

export const walletStrategy = new WalletStrategy({
  chainId: CHAIN_ID,
  wallet: Wallet.Metamask,
  ethereumOptions: {
    ethereumChainId: ETHEREUM_CHAIN_ID,
    rpcUrl: "https://1rpc.io/eth",
  },
  disabledWallets: [Wallet.WalletConnect, Wallet.CosmostationEth],
});

// Services
export const bankApi = new ChainGrpcBankApi(ENDPOINTS.grpc);
export const wasmApi = new ChainGrpcWasmApi(ENDPOINTS.grpc);
export const mintApi = new ChainGrpcMintApi(ENDPOINTS.grpc);
export const peggyApi = new ChainGrpcPeggyApi(ENDPOINTS.grpc);
export const oracleApi = new ChainGrpcOracleApi(ENDPOINTS.grpc);
export const governanceApi = new ChainGrpcGovApi(ENDPOINTS.grpc);
export const stakingApi = new ChainGrpcStakingApi(ENDPOINTS.grpc);
export const exchangeApi = new ChainGrpcExchangeApi(ENDPOINTS.grpc);
export const insuranceApi = new ChainGrpcInsuranceFundApi(ENDPOINTS.grpc);
export const distributionApi = new ChainGrpcDistributionApi(ENDPOINTS.grpc);
export const authZApi = new ChainGrpcAuthZApi(ENDPOINTS.grpc);

export const indexerOracleApi = new IndexerGrpcOracleApi(ENDPOINTS.indexer);
export const indexerAccountApi = new IndexerGrpcAccountApi(ENDPOINTS.indexer);
export const indexerAccountPortfolioApi = new IndexerGrpcAccountPortfolioApi(
  ENDPOINTS.indexer
);
export const indexerGrpcTradingApi = new IndexerGrpcTradingApi(
  ENDPOINTS.indexer
);

export const indexerExplorerApi = new IndexerGrpcExplorerApi(
  ENDPOINTS.explorer
);
export const indexerRestExplorerApi = new IndexerRestExplorerApi(
  `${ENDPOINTS.explorer}/api/explorer/v1`
);
export const indexerRestDerivativesChronosApi =
  new IndexerRestDerivativesChronosApi(
    `${ENDPOINTS.chronos}/api/chronos/v1/derivative`
  );
export const indexerRestSpotChronosApi = new IndexerRestSpotChronosApi(
  `${ENDPOINTS.chronos}/api/chronos/v1/spot`
);
export const indexerRestLeaderboardChronosApi =
  new IndexerRestLeaderboardChronosApi(
    `${ENDPOINTS.chronos}/api/chronos/v1/leaderboard`
  );
export const indexerRestMarketChronosApi = new IndexerRestMarketChronosApi(
  `${ENDPOINTS.chronos}/api/chronos/v1/market`
);
export const indexerDerivativesApi = new IndexerGrpcDerivativesApi(
  ENDPOINTS.indexer
);
export const indexerSpotApi = new IndexerGrpcSpotApi(ENDPOINTS.indexer);

export const apolloConsumer = new ApolloConsumer(
  peggyGraphQlEndpointForNetwork(NETWORK)
);

// Transaction broadcaster
export const msgBroadcastClient = new MsgBroadcaster({
  walletStrategy,
  network: NETWORK,
  networkEndpoints: ENDPOINTS,
  // feePayerPubKey: FEE_PAYER_PUB_KEY,
  simulateTx: true,
});

export const web3Client = new Web3Client({
  network: NETWORK,
  rpc: "https://1rpc.io/eth",
});
export const web3Composer = new Web3Composer({
  network: NETWORK,
  rpc: "https://1rpc.io/eth",
  ethereumChainId: ETHEREUM_CHAIN_ID,
});
export const web3Broadcaster = new Web3Broadcaster({
  walletStrategy,
  network: NETWORK,
  ethereumChainId: ETHEREUM_CHAIN_ID,
});

// Token Services
export const tokenService = new TokenService({
  chainId: CHAIN_ID,
  network: NETWORK,
  endpoints: ENDPOINTS,
});
export const tokenMetaUtils = TokenMetaUtilsFactory.make(NETWORK);
// export const tokenPrice = new TokenPrice(COIN_GECKO_OPTIONS)
export const denomClient = new DenomClientAsync(NETWORK, {
  endpoints: ENDPOINTS,
  alchemyRpcUrl: "https://1rpc.io/eth",
});

// UI Services
export const bridgeTransformer = new UiBridgeTransformer(NETWORK);

// Singletons
export const localStorage: LocalStorage = new LocalStorage(
  `ninjavault-v1-${NETWORK}`
);

interface TransactionProps {
  isCosmosWallet: boolean;
  injectiveAddress: string;
  memo?: string;
  data: TransactParams;
  additionalData?: TransactParams;
  extraData?: TransactParams;
  activeChain?: string;
  gasMultiplier?: number;
  txSentCallback?: () => void;
  forceCompat?: boolean;
}

export async function sendInjectiveTransaction({
  isCosmosWallet,
  injectiveAddress,
  memo,
  data,
  additionalData,
  extraData,
  gasMultiplier,
  txSentCallback,
  forceCompat,
}: TransactionProps) {
  let msgs = undefined;

  if (additionalData && extraData) {
    msgs =
      isCosmosWallet && !forceCompat
        ? [
            MsgExecuteContract.fromJSON(data),
            MsgExecuteContract.fromJSON(additionalData),
            MsgExecuteContract.fromJSON(extraData),
          ]
        : [
            MsgExecuteContractCompat.fromJSON(data),
            MsgExecuteContractCompat.fromJSON(additionalData),
            MsgExecuteContractCompat.fromJSON(extraData),
          ];
  } else if (additionalData) {
    msgs =
      isCosmosWallet && !forceCompat
        ? [
            MsgExecuteContract.fromJSON(data),
            MsgExecuteContract.fromJSON(additionalData),
          ]
        : [
            MsgExecuteContractCompat.fromJSON(data),
            MsgExecuteContractCompat.fromJSON(additionalData),
          ];
  } else {
    msgs =
      isCosmosWallet && !forceCompat
        ? MsgExecuteContract.fromJSON(data)
        : MsgExecuteContractCompat.fromJSON(data);
  }

  return await broadcast({
    tx: {
      memo,
      address: injectiveAddress,
      ethereumAddress: getEthereumAddress(injectiveAddress),
      injectiveAddress,
      msgs,
    },
    gasMultiplier: gasMultiplier,
    txSentCallback,
  });
}

interface InstantiateProps {
  injectiveAddress: string;
  memo?: string;
  data: InstantiateParams;
  activeChain?: string;
  gasMultiplier?: number;
  txSentCallback?: () => void;
}

export async function instantiateInjectiveContract({
  injectiveAddress,
  memo,
  data,
  gasMultiplier,
  txSentCallback,
}: InstantiateProps) {
  const msgs = MsgInstantiateContract.fromJSON(data);
  return await broadcast({
    tx: {
      memo,
      address: injectiveAddress,
      ethereumAddress: getEthereumAddress(injectiveAddress),
      injectiveAddress,
      msgs,
    },
    gasMultiplier,
    txSentCallback,
  });
}

export async function broadcast({
  tx,
  gasMultiplier,
  txSentCallback,
}: {
  tx: MsgBroadcasterTxOptions;
  gasMultiplier?: number;
  txSentCallback?: () => void;
}) {
  const txWithAddresses = {
    ...tx,
    ethereumAddress: getEthereumSignerAddress(
      tx.injectiveAddress || tx.address
    ),
    injectiveAddress: getInjectiveSignerAddress(
      tx.injectiveAddress || tx.address
    ),
  } as MsgBroadcasterTxOptionsWithAddresses;

  return isCosmosWallet(walletStrategy.wallet)
    ? broadcastCosmos(txWithAddresses, gasMultiplier ?? 1, txSentCallback)
    : broadcastWeb3(txWithAddresses, gasMultiplier ?? 1, txSentCallback);
}

async function getTxWithSignersAndStdFee(
  args: CreateTransactionWithSignersArgs
) {
  // skipping the simulation cause (1) very very slow, and (2) it's causing some unmarshaling issues to big int
  return {
    ...createTransactionWithSigners(args),
    // stdFee: getStdFee({ ...args.fee }),
  };
  // const result = await simulateTxWithSigners(args)
  // if (!result.gasInfo?.gasUsed) {
  //   return {
  //     ...createTransactionWithSigners(args),
  //     stdFee: getStdFee({ ...args.fee }),
  //   }
  // }

  // const stdGasFee = {
  //   ...getStdFee({
  //     ...args.fee,
  //     gas: DEFAULT_STD_FEE.amount,
  //     // gas: new BigNumberInBase(result.gasInfo.gasUsed).times(1.2).toFixed(),
  //   }),
  // }

  // return {
  //   ...createTransactionWithSigners({
  //     ...args,
  //     fee: stdGasFee,
  //   }),
  //   stdFee: stdGasFee,
  // }
}

async function broadcastCosmos(
  tx: MsgBroadcasterTxOptionsWithAddresses,
  gasMultiplier: number,
  txSentCallback?: () => void
) {
  // const { options, endpoints, chainId } = this
  // const { walletStrategy } = options
  const msgs = Array.isArray(tx.msgs) ? tx.msgs : [tx.msgs];

  /**
   * When using Ledger with Keplr we have
   * to send EIP712 to sign on Keplr
   */
  if (walletStrategy.getWallet() === Wallet.Keplr) {
    const walletDeviceType = await walletStrategy.getWalletDeviceType();
    const isLedgerConnectedOnKeplr =
      walletDeviceType === WalletDeviceType.Hardware;

    if (isLedgerConnectedOnKeplr) {
      return experimentalBroadcastKeplrWithLedger(tx);
    }
  }

  /** Account Details * */
  const chainRestAuthApi = new ChainRestAuthApi(endpoints.rest);
  const accountDetailsResponse = await chainRestAuthApi.fetchAccount(
    tx.injectiveAddress
  );
  const baseAccount = BaseAccount.fromRestApi(accountDetailsResponse);
  const accountDetails = baseAccount.toAccountDetails();

  /** Block Details */
  const chainRestTendermintApi = new ChainRestTendermintApi(endpoints.rest);
  const latestBlock = await chainRestTendermintApi.fetchLatestBlock();
  const latestHeight = latestBlock.header.height;
  const timeoutHeight = new BigNumberInBase(latestHeight).plus(
    DEFAULT_BLOCK_TIMEOUT_HEIGHT
  );

  const pubKey = await walletStrategy.getPubKey();
  const gas = (tx.gas?.gas || getGasPriceBasedOnMessage(msgs)).toString();

  // @ts-ignore
  const fee = getStdFeeDojo({
    ...tx.gas,
    gas: new BN(gas).times(gasMultiplier).toString(),
  });

  /** Prepare the Transaction * */
  const { txRaw } = await getTxWithSignersAndStdFee({
    chainId: CHAIN_ID,
    memo: tx.memo,
    message: msgs,
    timeoutHeight: timeoutHeight.toNumber(),
    signers: {
      pubKey,
      accountNumber: accountDetails.accountNumber,
      sequence: accountDetails.sequence,
    },
    fee,
  });

  const directSignResponse = (await walletStrategy.signCosmosTransaction({
    txRaw,
    chainId: CHAIN_ID,
    address: tx.injectiveAddress,
    accountNumber: accountDetails.accountNumber,
  })) as DirectSignResponse;

  if (txSentCallback) {
    txSentCallback();
  }

  return walletStrategy.sendTransaction(directSignResponse, {
    chainId: CHAIN_ID,
    endpoints,
    address: tx.injectiveAddress,
  });
}

async function broadcastWeb3(
  tx: MsgBroadcasterTxOptionsWithAddresses,
  gasMultiplier: number,
  txSentCallback?: () => void
) {
  const msgs = Array.isArray(tx.msgs) ? tx.msgs : [tx.msgs];

  if (!ETHEREUM_CHAIN_ID) {
    throw new GeneralException(new Error("Please provide ethereumChainId"));
  }

  /** Account Details * */
  const chainRestAuthApi = new ChainRestAuthApi(endpoints.rest);
  const accountDetailsResponse = await chainRestAuthApi.fetchAccount(
    tx.injectiveAddress
  );
  const baseAccount = BaseAccount.fromRestApi(accountDetailsResponse);
  const accountDetails = baseAccount.toAccountDetails();

  /** Block Details */
  const chainRestTendermintApi = new ChainRestTendermintApi(endpoints.rest);
  const latestBlock = await chainRestTendermintApi.fetchLatestBlock();
  const latestHeight = latestBlock.header.height;
  const timeoutHeight = new BigNumberInBase(latestHeight).plus(
    DEFAULT_BLOCK_TIMEOUT_HEIGHT
  );

  const gas = (tx.gas?.gas || getGasPriceBasedOnMessage(msgs)).toString();
  // export const DEFAULT_GAS_PRICE = 160000000

  const maxLimit = new BN(40000000); // 40m
  const gasToSet = new BN(gas).times(gasMultiplier).gte(maxLimit)
    ? maxLimit
    : new BN(gas).times(gasMultiplier);

  // @ts-ignore
  // TODO: Something is wrong here, when i set the gas price, it doesn't work
  const fee = getStdFeeDojo({
    ...tx.gas,
    // gasPrice : 250000000,
    gas: gasToSet.toString(),
  });

  /** EIP712 for signing on Ethereum wallets */
  const eip712TypedData = getEip712TypedData({
    msgs,
    fee,
    tx: {
      memo: tx.memo,
      accountNumber: accountDetails.accountNumber.toString(),
      sequence: accountDetails.sequence.toString(),
      timeoutHeight: timeoutHeight.toFixed(),
      chainId: CHAIN_ID,
    },
    ethereumChainId: ETHEREUM_CHAIN_ID,
  });

  /** Signing on Ethereum */
  const signature = (await walletStrategy.signEip712TypedData(
    JSON.stringify(eip712TypedData),
    tx.ethereumAddress
  )) as string;
  if (txSentCallback) {
    txSentCallback();
  }

  const signatureBuff = hexToBuff(signature);

  /** Get Public Key of the signer */
  const publicKeyHex = recoverTypedSignaturePubKey(eip712TypedData, signature);
  const publicKeyBase64 = hexToBase64(publicKeyHex);

  /** Preparing the transaction for client broadcasting */
  const { txRaw } = createTransaction({
    message: msgs,
    memo: tx.memo,
    signMode: SIGN_AMINO,
    fee,
    pubKey: publicKeyBase64,
    sequence: baseAccount.sequence,
    timeoutHeight: timeoutHeight.toNumber(),
    accountNumber: baseAccount.accountNumber,
    chainId: CHAIN_ID,
  });

  const web3Extension = createWeb3Extension({
    ethereumChainId: ETHEREUM_CHAIN_ID,
  });
  const txRawEip712 = createTxRawEIP712(txRaw, web3Extension);

  // if (options.simulateTx) {
  //   await this.simulateTxRaw(txRawEip712)
  // }

  /** Append Signatures */
  txRawEip712.signatures = [signatureBuff];

  return walletStrategy.sendTransaction(txRawEip712, {
    chainId: CHAIN_ID,
    endpoints,
    address: tx.injectiveAddress,
  });
}

async function experimentalBroadcastKeplrWithLedger(
  this: any,
  tx: MsgBroadcasterTxOptionsWithAddresses
) {
  const { options, endpoints, chainId, ethereumChainId } = this;
  const { walletStrategy } = options;
  const msgs = Array.isArray(tx.msgs) ? tx.msgs : [tx.msgs];

  /**
   * We can only use this method when Keplr is connected
   * with ledger
   */
  if (walletStrategy.getWallet() === Wallet.Keplr) {
    const walletDeviceType = await walletStrategy.getWalletDeviceType();
    const isLedgerConnectedOnKeplr =
      walletDeviceType === WalletDeviceType.Hardware;

    if (!isLedgerConnectedOnKeplr) {
      throw new GeneralException(
        new Error(
          "This method can only be used when Keplr is connected with Ledger"
        )
      );
    }
  }

  if (!ethereumChainId) {
    throw new GeneralException(new Error("Please provide ethereumChainId"));
  }

  const keplrWallet = new KeplrWallet(chainId, {
    rest: endpoints.rest,
    rpc: endpoints.rpc,
  });
  /** Account Details * */
  const chainRestAuthApi = new ChainRestAuthApi(endpoints.rest);
  const accountDetailsResponse = await chainRestAuthApi.fetchAccount(
    tx.injectiveAddress
  );
  const baseAccount = BaseAccount.fromRestApi(accountDetailsResponse);
  const accountDetails = baseAccount.toAccountDetails();

  /** Block Details */
  const chainRestTendermintApi = new ChainRestTendermintApi(endpoints.rest);
  const latestBlock = await chainRestTendermintApi.fetchLatestBlock();
  const latestHeight = latestBlock.header.height;
  const timeoutHeight = new BigNumberInBase(latestHeight).plus(
    DEFAULT_BLOCK_TIMEOUT_HEIGHT
  );

  const pubKey = await walletStrategy.getPubKey();
  const gas = (tx.gas?.gas || getGasPriceBasedOnMessage(msgs)).toString();

  /** EIP712 for signing on Ethereum wallets */
  const eip712TypedData = getEip712TypedData({
    msgs,
    fee: getStdFee({ ...tx.gas, gas }),
    tx: {
      memo: tx.memo,
      accountNumber: accountDetails.accountNumber.toString(),
      sequence: accountDetails.sequence.toString(),
      timeoutHeight: timeoutHeight.toFixed(),
      chainId,
    },
    ethereumChainId,
  });

  const aminoSignResponse = await keplrWallet.signEIP712CosmosTx({
    eip712: eip712TypedData,
    signDoc: createEip712StdSignDoc({
      ...tx,
      ...baseAccount,
      msgs,
      chainId,
      gas: gas || tx.gas?.gas?.toString(),
      timeoutHeight: timeoutHeight.toFixed(),
    }),
  });

  /**
   * Create TxRaw from the signed tx that we
   * get as a response in case the user changed the fee/memo
   * on the Keplr popup
   */
  const { txRaw } = createTransaction({
    pubKey,
    message: msgs,
    memo: aminoSignResponse.signed.memo,
    signMode: SIGN_AMINO,
    fee: aminoSignResponse.signed.fee,
    sequence: parseInt(aminoSignResponse.signed.sequence, 10),
    timeoutHeight: parseInt(
      (aminoSignResponse.signed as any).timeout_height,
      10
    ),
    accountNumber: parseInt(aminoSignResponse.signed.account_number, 10),
    chainId,
  });

  /** Preparing the transaction for client broadcasting */
  const web3Extension = createWeb3Extension({
    ethereumChainId,
  });
  const txRawEip712 = createTxRawEIP712(txRaw, web3Extension);

  if (options.simulateTx) {
    await this.simulateTxRaw(txRawEip712);
  }

  /** Append Signatures */
  const signatureBuff = Buffer.from(
    aminoSignResponse.signature.signature,
    "base64"
  );
  txRawEip712.signatures = [signatureBuff];

  /** Broadcast the transaction */
  const response = await new TxGrpcApi(endpoints.grpc).broadcast(txRawEip712);

  if (response.code !== 0) {
    throw new TransactionException(new Error(response.rawLog), {
      code: UnspecifiedErrorCode,
      contextCode: response.code,
      contextModule: response.codespace,
    });
  }

  return response;
}

// async function simulateTxWithSigners(args: CreateTransactionWithSignersArgs) {
//   const { txRaw } = createTransactionWithSigners(args);

//   txRaw.signatures = Array(
//     Array.isArray(args.signers) ? args.signers.length : 1
//   ).fill(new Uint8Array(0));

//   const simulationResponse = await new TxGrpcApi(endpoints.grpc).simulate(
//     txRaw
//   );

//   return simulationResponse;
// }

const DEFAULT_GAS_LIMIT = 400000;
const DEFAULT_GAS_PRICE = "160000000";

const getStdFeeDojo = ({
  gas = DEFAULT_GAS_LIMIT.toString(),
  gasPrice = DEFAULT_GAS_PRICE,
  granter,
  feePayer,
}) => ({
  amount: [
    {
      denom: "inj",
      // amount: new BigNumberInBase(gas).times(gasPrice).toString(),
      amount: new BigNumberInBase(gas).times(DEFAULT_GAS_PRICE).toString(),
    },
  ],
  gas: gas.toString(),
  granter,
  feePayer,
});
