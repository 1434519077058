import BN from "bignumber.js";
import { Network } from "@injectivelabs/networks";
import useEphemeralStore from "../store/ephemeralStore";
import useURL from "../hooks/useURL";
import { NETWORK } from "../libs/services";
import {
  PublicVaultData,
  vaults as VAULTS,
  VaultConfig,
} from "../constants/vaults";
import { toBase64 } from "@injectivelabs/sdk-ts";
import { contracts } from "../types/StakingPool";
import { multicallFetcher } from "../utils/multicallFetcher";
import _ from "lodash";
import dayjs from "dayjs";
import { BigNumberInWei } from "@injectivelabs/utils";
import { VaultData, VaultsMap } from "store/interfaces/vaults.interface";
import useWallet from "./useWallet";
import useNetwork from "./useNetwork";
import { useFarms } from "./useFarms";
import { CACHED_FARM_DURATION_SECS } from "hooks/useFarms.ts";
import {
  FORCE_ON_CHAIN_FETCH,
  INJECTIVE_BURN_WALLET,
} from "constants/constants";

export const initialVaults = () =>
  VAULTS[NETWORK === Network.Mainnet ? "mainnet" : "testnet"]
    ?.filter((vault) => !vault.hidden)
    .reduce((dict, obj) => {
      dict[obj.name] = {
        ...obj,
        apr: "0",
        emissions: 0,
        balance: 0,
        balanceUsd: 0,
        tvl: 0,
        walletUsd: 0,
        walletBalance: 0,
        earningBalance: 0,
        earningBalanceUsd: 0,
      };
      return dict;
    }, {});

export const FETCH_VAULTS_FREQUENCY_MS = 30000;

const CACHED_VAULT_DURATION_SECS = 30;

const BIG_TEN = new BN(10);
const { StakingPool } = contracts;

const getMaximiserApy = ({ stakedAPR, dojoApr }) => {
  const dailyStakingReward = stakedAPR / 36500;
  const dailyPacocaReward = dojoApr / 36500;

  let sweetAPY = dailyStakingReward;

  for (const i of Array(364))
    sweetAPY = sweetAPY + sweetAPY * dailyPacocaReward + dailyStakingReward;

  return sweetAPY * 100;
};

function getAPR(
  distribution_schedule: [number, number, string][],
  stakeAmt: string,
  rewardTokenPrice: number,
  stakeTokenPrice: number,
  vault: PublicVaultData
) {
  const schedule =
    distribution_schedule?.find((schedule) => {
      const now = Date.now() / 1000;
      return now > schedule[0] && now < schedule[1];
    }) ?? [];
  const unixTimeNow = dayjs().unix();

  if (unixTimeNow > schedule?.[1] || unixTimeNow < schedule?.[0]) {
    return "0";
  }

  const delta = schedule?.[1] - schedule?.[0];
  const totalRewardAmt = new BigNumberInWei(schedule?.[2]);

  const dailyRewardAmtInUSD = new BigNumberInWei(totalRewardAmt)
    .div(delta)
    .times(86400)
    .multipliedBy(rewardTokenPrice);
  const stakeAmtInUSD = new BigNumberInWei(stakeAmt).multipliedBy(
    vault.isMaximiser ? rewardTokenPrice : stakeTokenPrice
  );

  const decimalDiff = vault.yieldToken
    ? vault.yieldToken.decimals - vault.stakingToken.decimals
    : vault.earningToken.decimals - vault.stakingToken.decimals;
  const apr = dailyRewardAmtInUSD
    .div(stakeAmtInUSD)
    .div(decimalDiff === 0 ? 1 : BIG_TEN.pow(decimalDiff))
    .times(365)
    .times(100);

  const tvl = new BN(stakeTokenPrice.toString())
    .times(vault?.data?.balance ?? "0")
    .div(BIG_TEN.pow(vault.stakingToken.decimals))
    .toNumber();

  // console.log({
  //   tvl,
  //   decimalDiff,
  //   apr: apr.toString(),
  //   stakeAmt: stakeAmt.toString(),
  //   rewardTokenPrice: rewardTokenPrice.toString(),
  //   stakeTokenPrice: stakeTokenPrice.toString(),
  //   dailyRewardAmtInUSD: dailyRewardAmtInUSD.toString(),
  //   totalRewardAmt: totalRewardAmt.toString(),
  //   stakeAmtInUSD: stakeAmtInUSD.toString(),
  // });

  if (vault.isMaximiser) {
    // const baseApr =
    return getMaximiserApy({
      stakedAPR: vault.baseApr,
      dojoApr: apr.toNumber(),
    })
      ?.toFixed(2)
      .toString();
  }

  if (vault.isAutoCompound) {
    return new BN(((1 + apr.toNumber() / 100 / 365) ** 365 - 1) * 100)
      .dp(2)
      .toJSON();
  }
  return apr.dp(2).toJSON();
}

function getEmissions(
  distribution_schedule: [number, number, string][],
  earningTokenDecimals: number
) {
  const schedule =
    distribution_schedule?.find((schedule) => {
      const now = Date.now() / 1000;
      return now > schedule[0] && now < schedule[1];
    }) ?? [];
  const unixTimeNow = dayjs().unix();

  if (unixTimeNow > schedule?.[1] || unixTimeNow < schedule?.[0]) {
    return "0";
  }

  const delta = schedule?.[1] - schedule?.[0];
  const totalRewardAmt = new BigNumberInWei(schedule?.[2]);
  return totalRewardAmt
    .div(BIG_TEN.pow(earningTokenDecimals))
    .div(delta)
    .times(86400)
    .dp(2)
    .toString();
  // const dailyRewardAmtInUSD = new BigNumberInWei(dailyRewardAmt).multipliedBy(
  //   rewardTokenPrice,
  // )
  // const stakeAmtInUSD = new BigNumberInWei(stakeAmt).multipliedBy(
  //   stakeTokenPrice,
  // )
  // const apr = dailyRewardAmtInUSD.div(stakeAmtInUSD).times(365).times(100)
  // return apr.dp(2).toJSON()
}

function getTVL(vault: PublicVaultData, tokenPrice: number) {
  // const tokenDecimals = vault.stakingToken.decimals

  const tvl = new BN(tokenPrice)
    .times(vault?.data?.balance ?? "0")
    .div(BIG_TEN.pow(vault.stakingToken.decimals))
    .toNumber();

  return tvl.toString() === "NaN" ? "0" : tvl.toString();
}

// Re-fetching of the full vault data is selective, based on how stale each vault is
// Fetching of vault balance is specific, only make required calls
export const useVaults = () => {
  const { isConnected, wallet } = useWallet();
  const cachedWallet = useEphemeralStore((s) => s.vaultWallet);
  const vaultsByWallet = useEphemeralStore((s) => s.vaultsByWallet);
  const cachedVaults =
    vaultsByWallet?.[
      isConnected
        ? wallet.address
        : cachedWallet && cachedWallet !== ""
        ? cachedWallet
        : INJECTIVE_BURN_WALLET
    ];
  const setVaultWallet = useEphemeralStore((s) => s.setVaultWallet);
  const setAllVaults = useEphemeralStore((s) => s.setAllVaults);
  const setVault = useEphemeralStore((s) => s.setVault);
  const pricesBySymbol = useEphemeralStore((s) => s.pricesBySymbol);

  const { farms } = useFarms();

  const { multicall } = useNetwork();
  const getURL = useURL();

  const key = NETWORK === Network.Testnet ? "testnet" : "mainnet";
  const allVaults = VAULTS[key]?.filter((vault) => !vault.hidden);
  const hiddenVaults = VAULTS[key]
    ?.filter((vault) => vault.hidden)
    .map((vault) => vault.name);

  const getVaultJson = (vaultsToFetch: VaultConfig[]) => {
    return _.flatMap(
      vaultsToFetch.map((vault) => {
        const _queries = [
          {
            address: vault.farmAddress,
            data: toBase64(StakingPool.StakingPoolQueryMsgBuilder.config()),
          },
          {
            address: vault.farmAddress,
            data: toBase64(StakingPool.StakingPoolQueryMsgBuilder.state({})),
          },
          {
            address: vault.vaultAddress,
            data: toBase64({ balance: {} }),
          },
          {
            address: vault.vaultAddress,
            data: toBase64({ total_supply: {} }),
          },
          {
            address: vault.vaultAddress,
            data: toBase64({ config: {} }),
          },
        ];
        if (wallet?.address !== "") {
          _queries.push(
            ...[
              {
                address: vault.vaultAddress,
                data: toBase64({ get_user: { address: wallet?.address } }),
              },
              {
                address: vault.vaultAddress,
                data: toBase64({ earned: { address: wallet?.address } }),
              },
              {
                address: vault.vaultAddress,
                data: toBase64({ balance_of: { address: wallet?.address } }),
              },
              {
                address: vault.stakingToken.address,
                data: toBase64({ balance: { address: wallet?.address } }),
              },
            ]
          );
        }
        return _queries;
      })
    );
  };

  const fetchVaults = async (
    walletAddress: string,
    forceAll?: boolean,
    forceVaults?: string[]
  ) => {
    if (!FORCE_ON_CHAIN_FETCH) {
      try {
        const vaultsToFetch = await fetchVaultsFromJSON(
          cachedVaults,
          hiddenVaults,
          forceAll,
          forceVaults
        );
        const publicVaults = await fetchPublicVaults(
          vaultsToFetch,
          walletAddress,
          forceAll,
          forceVaults
        );
        const parsedVaults = parseVaults(publicVaults);
        setAllVaults(parsedVaults, walletAddress);
      } catch (e) {
        console.error(e);
        return fetchVaultsOnChain(walletAddress, forceAll, forceVaults);
      }
    } else {
      return fetchVaultsOnChain(walletAddress, forceAll, forceVaults);
    }
  };

  const fetchVaultsOnChain = async (
    walletAddress: string,
    forceAll?: boolean,
    forceVaults?: string[]
  ) => {
    const vaultsToFetch = [];
    const now = dayjs();
    allVaults.forEach((vault) => {
      const cachedVault = cachedVaults?.[vault.name];
      // got to do this check cause we are looping through cached data
      if (!hiddenVaults.includes(vault.name)) {
        // only fetch if it has been stale for CACHED_VAULT_DURATION_SECS
        if (
          forceAll ||
          forceVaults?.includes(vault.name) ||
          cachedVault === undefined ||
          cachedVault?.lastFetched === undefined ||
          now.diff(dayjs.unix(cachedVault?.lastFetched), "seconds") >
            CACHED_VAULT_DURATION_SECS
        ) {
          vaultsToFetch.push(vault);
        }
      }
    });
    // if (vaultsToFetch.length > 0) {
    //   console.log("fetching vaults: ", vaultsToFetch)
    // }
    const queries = getVaultJson(vaultsToFetch);
    const publicVaults = await fetchPublicVaultsOnChain(
      vaultsToFetch,
      queries,
      walletAddress
    );
    const parsedVaults = parseVaults(publicVaults);
    setAllVaults(parsedVaults, walletAddress);
    setVaultWallet(walletAddress);
  };

  const fetchVault = async (vaultName: string, walletAddress: string) => {
    const vault = cachedVaults[vaultName];
    setVault(
      vaultName,
      {
        ...vault,
        loading: true,
        lastFetched: undefined,
      },
      walletAddress
    );
    await fetchVaults(walletAddress, false, [vaultName]);
  };

  // ------------------
  // HELPERS
  // ------------------

  const fetchPublicVaults = async (
    vaultsToFetch: VaultConfig[],
    walletAddress: string,
    force?: boolean,
    forceVaults?: string[]
  ) => {
    // const tvlData = await api.vaults.getTvl({
    //   walletAddress: wallet.address,
    //   force,
    //   forceVaults,
    // })
    // const missedVaultsArr = vaultsToFetch.filter((farm) => !tvlData[farm.name])
    const missedVaultsArr = vaultsToFetch;
    let missedVaultsData;
    if (missedVaultsArr.length > 0) {
      const queriesForMissedVaultFormApi = getVaultJson(missedVaultsArr);
      const publicVaults = await fetchPublicVaultsOnChain(
        missedVaultsArr,
        queriesForMissedVaultFormApi,
        walletAddress
      );
      missedVaultsData = parseVaults(publicVaults);
    }

    return vaultsToFetch.map((vault) => {
      return {
        ...vault,
        // ...(tvlData[vault.name]
        //   ? {
        //       vault: tvlData[vault.name].vault,
        //       tvl: tvlData[vault.name].tvl,
        //     }
        //   : {}),
        ...(missedVaultsData && missedVaultsData[vault.name]
          ? {
              data: missedVaultsData[vault.name].data,
              tvl: missedVaultsData[vault.name].tvl,
            }
          : {}),
      };
    }) as PublicVaultData[];
  };

  const fetchPublicVaultsOnChain = async (
    vaultsToFetch: VaultConfig[],
    queries: {
      address: any;
      data: string;
    }[],
    walletAddress: string
  ) => {
    const fetcher = multicallFetcher(multicall, getURL);
    const resp = await fetcher([queries]);

    const collectResults = (resp ?? []).reduce(
      (acc, curr) => acc.concat(curr.data.data.return_data),
      []
    );

    const data = collectResults?.map((e: any) => {
      return e.length == 0
        ? null
        : JSON.parse(Buffer.from(e.data, "base64").toString());
    });

    const chunkedResults = _.chunk(data, walletAddress !== "" ? 9 : 5);

    return vaultsToFetch.map((vault, index) => {
      const farmConfig = chunkedResults?.[index]?.[0];
      const farmState = chunkedResults?.[index]?.[1];
      const balance = chunkedResults?.[index]?.[2];
      const totalSupply = chunkedResults?.[index]?.[3];
      const config = chunkedResults?.[index]?.[4];
      const user =
        chunkedResults?.[index]?.length > 5
          ? chunkedResults?.[index]?.[5]
          : "0";
      const earned =
        chunkedResults?.[index]?.length > 6
          ? chunkedResults?.[index]?.[6]
          : "0";
      const balanceOf =
        chunkedResults?.[index]?.length > 7
          ? chunkedResults?.[index]?.[7]
          : "0";
      const walletBalance =
        chunkedResults?.[index]?.length > 8
          ? chunkedResults?.[index]?.[8]?.["balance"]
          : "0";

      return {
        ...vault,
        data: {
          farmConfig,
          farmState,
          balance,
          totalSupply,
          config,
          user,
          earned,
          balanceOf,
          walletBalance,
        },
      };
    }) as PublicVaultData[];
  };

  const parseVaults = (data: PublicVaultData[]) => {
    let _vaults = {};
    data?.forEach((item) => {
      let stakingTokenPrice = pricesBySymbol?.[item.stakingToken.symbol];
      let earningTokenPrice = pricesBySymbol?.[item.earningToken.symbol];

      if (!stakingTokenPrice && farms?.["v2"]) {
        // Check if staking token is an lp token
        stakingTokenPrice = Number(
          new BN(
            Object.values(farms?.["v2"]).find(
              (farm) => farm?.lpAddress === item?.stakingToken?.address
            )?.lpPrice
          )
        );
      }
      if (!earningTokenPrice && farms?.["v2"]) {
        // Check if earning token is an lp token
        earningTokenPrice = Number(
          new BN(
            Object.values(farms?.["v2"]).find(
              (farm) => farm?.lpAddress === item?.earningToken?.address
            )?.lpPrice
          )
        );
      }

      if (stakingTokenPrice && earningTokenPrice) {
        const { distribution_schedule = [] } = item?.data?.farmConfig ?? {};
        const { total_bond_amount } = item?.data?.farmState ?? {};
        const apr = getAPR(
          distribution_schedule,
          total_bond_amount,
          item.yieldToken
            ? pricesBySymbol[item.yieldToken.symbol]
            : earningTokenPrice,
          stakingTokenPrice,
          item
        );
        const emissions = getEmissions(
          distribution_schedule,
          item.earningToken.decimals
        );

        const tvl = getTVL(item, stakingTokenPrice);
        // const totalSupply = new BN(item.data.totalSupply).div(
        //   new BN(10).pow(item?.stakingToken.decimals)
        // );

        const balance = new BN(item.data?.user?.principal ?? "0")
          .div(BIG_TEN.pow(item?.stakingToken.decimals))
          .toJSON();
        const balanceUsd = new BN(stakingTokenPrice).times(balance);

        const walletBalance = new BN(item?.data?.walletBalance ?? "0")
          .div(BIG_TEN.pow(item?.stakingToken.decimals))
          .toJSON();
        const walletUsd = new BN(stakingTokenPrice).times(walletBalance);

        const earningBalance = new BN(item?.data?.earned ?? "0")
          .div(BIG_TEN.pow(item?.earningToken.decimals))
          .toNumber();
        const earningBalanceUsd = new BN(earningTokenPrice).times(
          earningBalance
        );

        _vaults[item.name] = {
          ...item,
          apr,
          emissions: emissions === "NaN" ? 0 : emissions,
          tvl: new BN(tvl).toNumber(),
          stakingTokenPrice,
          earningTokenPrice,
          balance,
          balanceUsd: !isNaN(balanceUsd.toNumber()) ? balanceUsd.toNumber() : 0,
          walletBalance,
          walletUsd: !isNaN(walletUsd.toNumber()) ? walletUsd.toNumber() : 0,
          earningBalance,
          earningBalanceUsd: !isNaN(earningBalanceUsd.toNumber())
            ? earningBalanceUsd.toNumber()
            : 0,
          lastFetched: dayjs().unix(),
          loading: false,
        } as VaultData;
      }
    });
    return _vaults;
  };

  return { fetchVault, fetchVaults, vaults: cachedVaults };
};

async function fetchVaultsFromJSON(
  cachedVaults: VaultsMap,
  hiddenVaults: string[],
  forceAll: boolean,
  forceVaults: string[]
) {
  const vaultsToFetch = [];
  const now = dayjs();
  const vaultData = useEphemeralStore((s) => s.jsonData?.vaults);
  const tokensSymbolMap = useEphemeralStore((s) => s.jsonData?.tokensSymbolMap);
  vaultData?.forEach((vault) => {
    const cachedFarm = cachedVaults?.[vault.name];
    // got to do this check cause we are looping through cached data
    if (!hiddenVaults.includes(vault.name)) {
      // only fetch if it has been stale for CACHED_FARM_DURATION_SECS
      if (
        forceAll ||
        forceVaults?.includes(vault.name) ||
        cachedFarm === undefined ||
        cachedFarm?.lastFetched === undefined ||
        now.diff(dayjs.unix(cachedFarm?.lastFetched), "seconds") >
          CACHED_FARM_DURATION_SECS
      ) {
        vault.stakingToken = tokensSymbolMap[vault.stakingToken];
        vault.earningToken = tokensSymbolMap[vault.earningToken];
        vaultsToFetch.push(vault);
      }
    }
  });
  return vaultsToFetch;
}
