import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Vault from "./components/Vault";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WalletConnectProvider from "./components/WalletConnectProvider";
import { WagmiConfig, createConfig, mainnet } from "wagmi";
import { createPublicClient, http } from "viem";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "components/App";
import Portfolio from "components/Portfolio";
import Footer from "components/Footer";
import BgGradient from "components/Icons/BgGradient";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: mainnet,
    transport: http(),
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <WagmiConfig config={config}>
          <WalletConnectProvider>
            <ToastContainer pauseOnHover theme="light" />
            <div className="flex flex-col relative w-screen h-screen overflow-hidden">
              <BgGradient />
              <div
                className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                  style={{
                    clipPath:
                      "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                  }}
                />
              </div>
              <App />
              <div className="h-full overflow-y-auto px-2 sm:px-4">
                <Routes>
                  <Route path="/" element={<Portfolio />} />
                  <Route path="/vault" element={<Vault />} />
                </Routes>
                <Footer />
              </div>
            </div>
          </WalletConnectProvider>
        </WagmiConfig>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
