import { NETWORK } from "libs/services"
import { Network } from "@injectivelabs/networks"
import {
  FarmToken,
  TokenType,
  serializeToken,
} from "./tokens"

export interface FarmConfig {
  name: string
  lpAddress: string
  contractAddress: string
  stakingAddress: string
  token0: FarmToken
  token1: FarmToken
  hidden?: boolean
}

export interface PublicFarmData extends FarmConfig {
  vault: {
    config: {
      distribution_schedule: [number, number, string][]
    }
    stakedBalance: string
    walletBalance: string
    staker: {
      bond_amount: string
      pending_reward: string
      reward_index: string
    }
    state: {
      global_reward_index: string
      last_distributed: number
      total_bond_amount: string
    }
    lpInfo: {
      total_supply: string
      token0Supply: string
      token1Supply: string
    }
    total_rewards: string
  }
}

export const farmsV2: { mainnet: FarmConfig[]; testnet: FarmConfig[] } = {
  mainnet: [
    // {
    //   name: "SUSHI-DOJO LP",
    //   lpAddress: "inj1500pn2xwlczkms8h8ggy5ptv9etyu5yxx45x70",
    //   contractAddress: "inj12eca9xszt84qm9tztyuje96nn3v2wd3v4yrzge",
    //   stakingAddress: "inj1yqtcds4gpvhcdlpjh9u45xjx9lxwame7fa265x",
    //   token0: "SUSHI" as unknown as FarmToken,
    //   token1: "DOJO" as unknown as FarmToken,
    // },
    // {
    //   name: "DOJO-INJ LP",
    //   lpAddress: "inj17pda96ujt7fzr3d5jmfkh4dzvrqzc0nk56kt34",
    //   contractAddress: "inj1grtkdl7552kjsrkqn5wqpk4fp8m3m4y0tzqfqr",
    //   stakingAddress: "inj19rutrad95wzcw93gfnuranetmc570cvtj8j8cg",
    //   token0: "DOJO" as unknown as FarmToken,
    //   token1: "INJ" as unknown as FarmToken,
    // },
    // {
    //   name: "DOJO-dINJ LP",
    //   lpAddress: "inj1lugaw82rk02ckm3zeu24su0p0r853sagn9qkaa",
    //   contractAddress: "inj1wpdh2qmq0lzjnyvae8p94g892mcq2jmpc984qp",
    //   stakingAddress: "inj1ycnddgnj49lntk3z5ky8pj0rpvhkvggmyjsmv7",
    //   token0: "dINJ" as unknown as FarmToken,
    //   token1: "DOJO" as unknown as FarmToken,
    // },
  ],
  testnet: [
    // {
    //   name: "INJ-USDT LP",
    //   lpAddress: "inj1knxx8crp0auxyvx8hn3jjl7htfsandx5xf0n8q",
    //   contractAddress: "inj1qppa5008z4ekt6tq7ax8ryre0y0w9lrhpjsnup",
    //   stakingAddress: "inj1624rmgycjv8xfyu4xeprguju2smcq46c94x8hd",
    //   token0: serializeToken("INJ", "Injective", "inj", 18, TokenType.NATIVE),
    //   token1: serializeToken(
    //     "USDT",
    //     "USD Tether",
    //     "peggy0x87aB3B4C8661e07D6372361211B96ed4Dc36B1B5",
    //     6,
    //     TokenType.NATIVE,
    //   ),
    // },
  ],
}

export const farms: { mainnet: FarmConfig[]; testnet: FarmConfig[] } = {
  mainnet: [
    {
      name: "INJ-USDT LP",
      lpAddress: "inj15xk5d4d3we8z9s9avcqfns2xsrqq9u5mgaw6q6",
      contractAddress: "inj1h0mpv48ctcsmydymh2hnkal7hla5gl4gftemqv",
      stakingAddress: "inj1ewdcesmpdq5vz67a2rmfr682gjqecedymqm74f",
      token0: "INJ" as unknown as FarmToken,
      token1: "USDT" as unknown as FarmToken,
    },
    {
      name: "NINJA-INJ LP",
      lpAddress: "inj1q62knrccf8n2386jzzv2plr6rat2lfvx95muqv",
      contractAddress: "inj1efdrt4s78gffnntlqlhcctu3h0ndsd0nr86edc",
      stakingAddress: "inj1eendvgucfvftcwmp4gt40nn9twvpnen025q9ru",
      token0: "NINJA" as unknown as FarmToken,
      token1: "INJ" as unknown as FarmToken,
    },
    {
      name: "KIRA-INJ LP",
      lpAddress: "inj1nssppa309t2pty5agwrmrjqx9md9764ntesce6",
      contractAddress: "inj1eswdzx773we5zu2mz0zcmm7l5msr8wcss8ek0f",
      stakingAddress: "inj1fafdyxs0uz2fmj9duqvdy83hrpr39p8c29x67w",
      token0: "KIRA" as unknown as FarmToken,
      token1: "INJ" as unknown as FarmToken,
    },
  ],
  testnet: [
    {
      name: "INJ-USDT LP",
      lpAddress: "inj1knxx8crp0auxyvx8hn3jjl7htfsandx5xf0n8q",
      contractAddress: "inj1qppa5008z4ekt6tq7ax8ryre0y0w9lrhpjsnup",
      stakingAddress: "inj1624rmgycjv8xfyu4xeprguju2smcq46c94x8hd",
      token0: serializeToken("INJ", "Injective", "inj", 18, TokenType.NATIVE),
      token1: serializeToken(
        "USDT",
        "USD Tether",
        "peggy0x87aB3B4C8661e07D6372361211B96ed4Dc36B1B5",
        6,
        TokenType.NATIVE,
      ),
    },
  ],
}

export const getFarmByLpAddress = (address: string) => {
  const _farms = NETWORK === Network.Mainnet ? farms.mainnet : farms.testnet
  return _farms.find((item) => item.lpAddress === address)
}
