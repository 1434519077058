import classNames from "classnames";

const Twitter = ({ className }: { className?: string }) => {
  return (
    <div className={classNames(className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 500 416.385135"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill="currentColor" fillRule="nonzero">
            <path d="M157.213488,416.385135 C345.814121,416.385135 449.058386,256.326637 449.058386,117.614897 C449.058386,113.173457 449.058386,108.732016 448.780623,104.012986 C468.957591,89.1009942 486.303727,70.7033095 500,49.688616 C481.584356,58.0163171 461.779901,63.5681177 440.919949,66.3440181 C462.501842,52.9491946 478.564897,32.2672996 486.195211,8.05011098 C466.390756,20.2640725 444.447531,28.8693635 420.865507,33.5883941 C401.896348,12.2340197 374.692454,0.00945958459 346.11966,0 C289.428365,0 243.347592,47.1625467 243.347592,105.123346 C243.347592,113.451047 244.430865,121.223568 246.069663,128.996089 C160.96328,124.832238 85.3563691,82.6663121 34.6925171,19.1537123 C25.5686,35.3128414 20.8020451,53.5637346 20.8599522,72.1178908 C20.8599522,108.482185 39.0256097,140.65487 66.4129771,159.503233 C50.1338111,158.951895 34.2292643,154.475861 20.0544414,146.456502 L20.0544414,147.844452 C20.0544414,198.643428 55.5524693,241.336775 102.160991,250.774837 C93.4948058,253.273147 84.5508583,254.383507 75.051386,254.383507 C68.5517471,254.383507 62.0521082,253.828327 55.8302317,252.440377 C68.8295095,294.328713 106.771846,324.558268 151.74157,325.391038 C115.698103,354.517853 70.7400471,370.392852 24.387534,370.360623 C16.2490973,370.360623 7.86067441,370.083033 0,368.972673 C46.5761356,399.931814 101.276083,416.428202 157.213488,416.385135 Z"></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Twitter;
