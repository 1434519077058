import { useState } from "react";
import { isCosmosWallet } from "@injectivelabs/wallet-ts";
import { UserDenied } from "@terra-dev/wallet-types";
import { sendInjectiveTransaction } from "../libs/services";
import useEphemeralStore from "../store/ephemeralStore";
import { TxStage } from "../store/interfaces/txState.interface";
import { Params } from "../types/msgExecuteContract";
import { WalletStore } from "../types/wallet";
import MESSAGE from "../lang/MESSAGE.json";
import { toast } from "react-toastify";

const useSendTransaction = () => {
  const updateTxStage = useEphemeralStore((s) => s.updateTxStage);
  const updateTxType = useEphemeralStore((s) => s.updateTxType);
  const updateTxResult = useEphemeralStore((s) => s.updateTxResult);
  const updateTxSummary = useEphemeralStore((s) => s.updateTxSummary);
  const [loading, setLoading] = useState(false);

  const sendTransaction = async ({
    wallet,
    txType,
    data,
    additionalData,
    extraData,
    successMessage,
    forceCompat,
    gasMultiplier,
    hideResultModal,
    callback,
    onError,
  }: {
    wallet: WalletStore;
    txType: string;
    data: Params;
    additionalData?: Params;
    extraData?: Params;
    successMessage: string;
    forceCompat?: boolean;
    gasMultiplier?: number;
    hideResultModal?: boolean;
    callback?: () => void;
    onError?: () => void;
  }) => {
    setLoading(true);
    if (!hideResultModal) {
      updateTxType(txType);
    }

    let txResult: any;
    try {
      if (!hideResultModal) updateTxStage(TxStage.SIGNING);
      txResult = await sendInjectiveTransaction({
        isCosmosWallet: isCosmosWallet(wallet.walletType),
        injectiveAddress: wallet.address,
        data,
        additionalData,
        extraData,
        txSentCallback: () => {
          // sets the TxResultModal from signing to loading
          if (!hideResultModal) updateTxStage(TxStage.LOADING);
          if (callback) callback();
        },
        gasMultiplier,
        forceCompat,
      });

      // addToTxHistory(
      //   wallet.address,
      //   txType,
      //   txResult,
      // )

      if (!hideResultModal) {
        updateTxSummary(successMessage);
        updateTxStage(TxStage.RESULT);
        updateTxResult(txResult);
      }
      toast.success(successMessage);

      return [txResult, true];
    } catch (error) {
      console.error(error);

      const message =
        // @ts-ignore
        (error as AxiosError)?.response?.data?.message ||
        error?.message ||
        (error instanceof UserDenied && MESSAGE.Result.DENIED) ||
        JSON.stringify(error);
      toast.error(message);

      if (onError) onError();

      if (!hideResultModal) {
        updateTxSummary(message);
        updateTxStage(TxStage.FAILED);
      }

      return [txResult, false];
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    sendTransaction,
  };
};

export default useSendTransaction;
