import { useEffect } from "react"
import useEphemeralStore from "../store/ephemeralStore"
import tokensSymbolMap from "public/json/tokens-dictionary.json"
import vaultsData from "public/json/vaults.json"
import farmFees from "public/json/farms_fees.json"
import farmsV1Data from "public/json/farms_v1.json"
import farmsV2Data from "public/json/farms_v2.json" 
import poolsData from "public/json/pools.json"
import presalesData from "public/json/presales.json"
import feeApr from "public/json/feeApr.json"
import { TokensAddressMap } from "store/interfaces/json.interface"

export const loadJsonData = () => {
  const jsonData = useEphemeralStore((s) => s.jsonData)
  const setJsonData = useEphemeralStore((s) => s.setJsonData)
  const setTokenAddressMap = useEphemeralStore((s) => s.setTokenAddressMap)
  const setPresales = useEphemeralStore((s) => s.setPresales)

  async function loadJsonData() {
    try {
      // console.log("loading JSON data", {
      //   vaults: vaultsData,
      //   tokensSymbolMap: tokensSymbolMap,
      //   farmFees: farmFees,
      //   farmsV1: farmsV1Data,
      //   farms: farmsV2Data,
      //   pools: poolsData,
      // })
      setJsonData({
        vaults: vaultsData,
        tokensSymbolMap: tokensSymbolMap,
        farmFees: farmFees,
        farmsV1: farmsV1Data,
        farms: farmsV2Data,
        pools: poolsData,
        feeApr: feeApr,
      })
      setPresales(presalesData as unknown as any)
    } catch (error) {
      const _vaultsData = await fetch("./json/vaults.json").then((res) =>
        res.json(),
      )
      const _tokensSymbolMap = await fetch(
        "./json/tokens-dictionary.json",
      ).then((res) => res.json())

      const _farmFees = await fetch("./json/farms_fees.json").then((res) =>
        res.json(),
      )
      const _farmsV1Data = await fetch("./json/farms_v1.json").then((res) =>
        res.json(),
      )
      const _farmsV2Data = await fetch("./json/farms_v2.json").then((res) =>
        res.json(),
      )
      const _poolsData = await fetch("./json/pools.json").then((res) =>
        res.json(),
      )
      const _feesApr = await fetch("./json/feeApr.json").then((res) =>
        res.json(),
      )
      setJsonData({
        vaults: _vaultsData,
        tokensDictionary: _tokensSymbolMap,
        farmFees: _farmFees,
        farmsV1: _farmsV1Data,
        farms: _farmsV2Data,
        pools: _poolsData,
        feeApr: _feesApr,
      })
      console.error(error)
    } finally {
      // console.log({ jsonData })
    }
  }

  useEffect(() => {
    if (
      tokensSymbolMap &&
      vaultsData &&
      farmFees &&
      farmsV1Data &&
      farmsV2Data &&
      poolsData &&
      feeApr
    ) {
      loadJsonData()
    }
  }, [
    tokensSymbolMap,
    vaultsData,
    farmFees,
    farmsV1Data,
    farmsV2Data,
    poolsData,
    feeApr,
  ])

  useEffect(() => {
    const load = async () => {
      const tokenAddressMapping: TokensAddressMap = Object.values(
        tokensSymbolMap ?? {},
      )
        .map((item: any) => item)
        .reduce((dict: any, item: any) => {
          dict[item.address] = item
          return dict
        }, {} as any)
      setTokenAddressMap(tokenAddressMapping)
    }
    if (tokensSymbolMap !== undefined) {
      load()
    }
  }, [tokensSymbolMap])

  return { jsonData, loadJsonData }
}
