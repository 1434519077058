export enum TokenType {
  CW20 = "CW20",
  NATIVE = "NATIVE",
  IBC = "IBC",
}

export interface FarmToken {
  symbol: string
  name: string
  address: string
  decimals: number
  type: TokenType
}

export interface DenomUnit {
  denom: string
  exponent: number
  aliases: string[]
}

export interface DenomMetadata {
  base: string
  denomUnits: DenomUnit[]
  description: string
  display: string
  name: string
  symbol: string
  uri: string
  uriHash: string
}

export interface MarketingInfo {
  description: string
  logo: {
    url?: string
  }
  marketing: string
  project: string
}

export interface TokenResult {
  address: string
  name: string
  symbol: string
  decimals: number
  icon: string
  verified: boolean
  total_supply?: string
  marketingInfo?: MarketingInfo
  denomMetadata?: DenomMetadata
}

export const serializeToken = (
  symbol: string,
  name: string,
  address: string,
  decimals: number,
  type: TokenType,
): FarmToken => {
  return {
    symbol,
    name,
    address,
    decimals,
    type,
  }
}

// Mainnet tokens
export const POINT = serializeToken(
  "POINT",
  "POINT Token",
  "inj1l73x8hh6du0h8upp65r7ltzpj5twadtp5490n0",
  18,
  TokenType.CW20,
)
export const DOJO = serializeToken(
  "DOJO",
  "Dojo Token",
  "inj1zdj9kqnknztl2xclm5ssv25yre09f8908d4923",
  18,
  TokenType.CW20,
)
export const BABYDOJO = serializeToken(
  "babyDOJO",
  "babyDojo Reflection Token",
  "inj19dtllzcquads0hu3ykda9m58llupksqwekkfnw",
  6,
  TokenType.CW20,
)
export const INJ = serializeToken(
  "INJ",
  "Injective",
  "inj",
  18,
  TokenType.NATIVE,
)
export const dINJ = serializeToken(
  "dINJ",
  "Dojo Staked Injective",
  "inj134wfjutywny9qnyux2xgdmm0hfj7mwpl39r3r9",
  18,
  TokenType.CW20,
)
export const ROLL = serializeToken(
  "ROLL",
  "NinjaRoll",
  "inj1qv98cmfdaj5f382a0klq7ps4mnjp6calzh20h3",
  18,
  TokenType.CW20,
)
export const DOJO_ROLL = serializeToken(
  "DOJO-ROLL",
  "DOJO ROLL LP",
  "inj17m8xuqntg5sc3m78jzajte5r4my4erwhujq3uh",
  6,
  TokenType.CW20,
)
export const KAGE = serializeToken(
  "KAGE",
  "Kage Finance",
  "inj1l49685vnk88zfw2egf6v65se7trw2497wsqk65",
  18,
  TokenType.CW20,
)
export const USDT = serializeToken(
  "USDT",
  "USD Tether",
  "peggy0xdAC17F958D2ee523a2206206994597C13D831ec7",
  6,
  TokenType.NATIVE,
)
export const USDC = serializeToken(
  "USDC",
  "USD Coin",
  "peggy0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  6,
  TokenType.NATIVE,
)
export const SUSHI = serializeToken(
  "SUSHI",
  "SUSHI FIGHTER Token",
  "inj1n73yuus64z0yrda9hvn77twkspc4uste9j9ydd",
  18,
  TokenType.CW20,
)
export const JUTSU = serializeToken(
  "JUTSU",
  "JUTSU Token",
  "inj14m99e5ge2cactnstklfz8w7rc004w0e0p7ezze",
  18,
  TokenType.CW20,
)
export const DAOJO = serializeToken(
  "DAOJO",
  "DAOJO Token",
  "inj1jusxykgkl04rgaam65gmvyyymth9flxjdpdruh",
  9,
  TokenType.CW20,
)
export const SUSHI_DOJO = serializeToken(
  "SUSHI-DOJO",
  "SUSHI-DOJO LP",
  "inj1500pn2xwlczkms8h8ggy5ptv9etyu5yxx45x70",
  6,
  TokenType.CW20,
)
export const BP_POINTS = serializeToken(
  "POINTS",
  "Black Panther Points",
  "factory/inj1zaem9jqplp08hkkd5vcl6vmvala9qury79vfj4/point",
  0,
  TokenType.NATIVE,
)
export const BLACK = serializeToken(
  "BLACK",
  "Black Panther",
  "factory/inj16eckaf75gcu9uxdglyvmh63k9t0l7chd0qmu85/black",
  6,
  TokenType.NATIVE,
)

export const EXCLUDE_TOKENS = ["DAI", "KAVA"]

export const TOKEN_SYMBOLS = ["INJ", "KAVA", "DAI"]

export const TESTNET_TOKEN_ADDRESSES = {
  USDT: "peggy0x87aB3B4C8661e07D6372361211B96ed4Dc36B1B5",
  WETH: "factory/inj17vytdwqczqz72j65saukplrktd4gyfme5agf6c/weth",
  USDC: "factory/inj17vytdwqczqz72j65saukplrktd4gyfme5agf6c/usdc",
  ATOM: "factory/inj17vytdwqczqz72j65saukplrktd4gyfme5agf6c/atom",
  DOJO: "inj1375v9e5awxf340cgv2pzh4seu074lxw0d092gd",
  ROLL: "inj1damsnn4rxkqhxpe890u2guzj72ndmvmj228hjd",
}

export const EXCLUDE_FROM_SWAP = []

export const popularTokens = {
  DOJO: true,
  SUSHI: true,
  babyDOJO: true,
}
