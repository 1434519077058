export const formatNumber = (num: number | string) => {
  const numberFormatter = Intl.NumberFormat("en-US");
  return numberFormatter.format(Number(num));
};

export const formatMoney = (num: number, fix = 2) => {
  const units = ["M", "B", "T", "Q"];
  const unit = Math.floor((num / 1.0e1).toFixed(0).toString().length);
  const r = unit % 3;
  const x =
    Math.abs(Number(num)) / Number(Number("1.0e+" + (unit - r)).toFixed(2));
  return units[Math.floor(unit / 3) - 2]
    ? formatNumber(x.toFixed(fix)) + units[Math.floor(unit / 3) - 2]
    : formatNumber(num.toFixed(fix));
};

export const formatToBillions = (num: number, fix = 2) => {
  const units = ["M", "B", "T"];
  const absNum = Math.abs(num);

  if (absNum >= 1.0e9) {
    return formatNumber((absNum / 1.0e9).toFixed(fix)) + units[1];
  } else if (absNum >= 1.0e6) {
    return formatNumber((absNum / 1.0e6).toFixed(fix)) + units[0];
  } else {
    return formatNumber(num.toFixed(fix));
  }
};
