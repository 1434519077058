import classNames from "classnames";

const NinjaVaultIcon = ({ className }: { className?: string }) => {
  return (
    <div className={classNames(className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 536 485"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient
            x1="3.5331157e-15%"
            y1="60.4693977%"
            x2="100%"
            y2="48.0030375%"
            id="linearGradient-ehb9b-v4zi-1">
            <stop stopColor="#2563EB" offset="0%"></stop>
            <stop stopColor="#9333EA" offset="100%"></stop>
          </linearGradient>
          <linearGradient
            x1="5.03890379e-15%"
            y1="49.4550659%"
            x2="100%"
            y2="36.9206758%"
            id="linearGradient-ehb9b-v4zi-2">
            <stop stopColor="#2563EB" offset="0%"></stop>
            <stop stopColor="#9333EA" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g>
            <path
              d="M190,0 C168.106311,1.63421012 139.669543,17.0223912 104.689697,46.1645431 C83.5231962,63.7986222 62.4957418,94.4882146 40.8834092,119.268501 C26.4751875,135.788692 12.8473844,150.6001 0,163.702725 C8.94817493,170.654574 16.1343177,177.688356 21.5584283,184.804072 C26.9825389,191.919788 30.2896471,198.651764 31.4797529,205 L190,0 Z"
              fill="#22D3EE"></path>
            <path
              d="M479,83.0830273 C427.552593,52.8900853 380.456511,31.6643402 337.711756,19.4057921 C294.967001,7.14724398 245.494723,0.678646615 189.294921,0 C144.886324,20.679138 107.556711,51.8854307 77.3060825,93.6188779 C47.0554541,135.352325 31.6200932,172.479366 31,205 C68.1009548,182.112791 99.1428222,166.350632 124.125602,157.713522 C149.108382,149.076412 177.532216,143.206598 209.397103,140.10408 C209.165091,146.718863 205.771941,156.272063 199.217653,168.76368 C192.663365,181.255297 184.706328,193.33407 175.346544,205 C200.686201,185.702454 219.599045,169.940295 232.085075,157.713522 C244.571104,145.486749 255.880838,131.653865 266.014278,116.214867 C241.400942,114.166314 215.827823,114.166314 189.294921,116.214867 C162.762019,118.263421 137.265025,122.206446 112.80394,128.043941 C136.54855,80.8146081 181.960458,57.1999418 249.039664,57.1999418 C349.658473,57.1999418 429.404943,88.5167982 448.048666,88.5167982 C460.477815,88.5167982 470.794926,86.7055412 479,83.0830273 Z"
              fill="url(#linearGradient-ehb9b-v4zi-1)"></path>
            <path
              d="M446.478974,155 C446.712019,161.678 445.359106,169.130052 442.420234,177.356155 C439.481362,185.582258 435.895566,191.953993 431.662844,196.471358 C433.509476,198.437703 435.372572,201.093176 437.252131,204.437776 C439.131691,207.782375 440.854392,211.507681 442.420234,215.613693 C435.460421,275.635039 407.744652,329.011264 359.272928,375.742367 C310.801203,422.47347 245.376894,458.892681 163,485 C204.770352,484.525028 239.392674,481.914651 266.866967,477.168868 C294.34126,472.423085 322.484385,464.191627 351.296343,452.474492 C386.028697,424.065358 414.293211,385.652433 436.089886,337.235715 C457.886561,288.818997 465.166091,248.278323 457.928475,215.613693 C462.740741,212.166144 466.561875,208.944707 469.391879,205.949381 C472.221882,202.954055 474.424589,199.794715 476,196.471358 C474.181148,189.018399 470.324682,181.14683 464.430602,172.856652 C458.536523,164.566473 452.552647,158.614256 446.478974,155 Z"
              fill="currentColor"></path>
            <path
              d="M9,313.617158 C30.5807739,335.849595 47.1554138,358.213357 58.7239197,380.708442 C70.2924257,403.203527 75.554099,423.300713 74.5089396,441 C148.776774,428.992506 212.73479,407.442148 266.382988,376.348926 C320.031187,345.255703 360.903524,299.19452 389,238.165377 C340.624452,286.688281 299.752115,318.218461 266.382988,332.755915 C233.013861,347.293369 189.848991,354.329038 136.888376,353.862922 C132.878444,338.559043 130.417136,326.959381 129.50445,319.063938 C128.591764,311.168494 128.591764,301.475921 129.50445,289.986217 C134.36,287.841581 140.627408,286.129023 148.306673,284.848542 C155.985939,283.568061 163.675776,282.953315 171.376182,283.004304 C179.972248,268.996834 190.315288,254.050525 202.405301,238.165377 C214.495314,222.280228 227.239735,206.89177 240.638564,192 C221.09505,205.656328 205.203348,217.772303 192.963458,228.347924 C180.723568,238.923544 169.280228,250.425842 158.633436,262.854817 C124.744693,270.306795 98.9851288,277.02329 81.3547427,283.004304 C63.7243565,288.985318 39.606109,299.189603 9,313.617158 Z"
              fill="url(#linearGradient-ehb9b-v4zi-2)"></path>
            <path
              d="M48.8611498,248 C44.1275432,248.492277 39.4261471,252.66247 34.7569615,260.510579 C30.0877759,268.358687 21.5021221,286.521827 9,315 C25.0109639,306.826452 46.8881017,298.052281 74.6314136,288.677488 C102.374725,279.302695 130.497588,271.181274 159,264.313225 C127.280446,268.377065 104.224475,271.736322 89.8320876,274.390998 C75.4397003,277.045673 60.2947691,280.73257 44.3972939,285.45169 C43.6465103,280.058156 43.6465103,274.211871 44.3972939,267.912836 C45.1480775,261.613801 46.6360295,254.976189 48.8611498,248 Z"
              fill="#22D3EE"></path>
            <path
              d="M98.5721146,220.567495 C96.2042081,221.329707 93.6390285,223.993756 90.8765757,228.559642 C88.1141229,233.125529 86.1552644,237.938982 85,243 C88.4474226,240.596717 95.0122216,237.799436 104.694397,234.608157 C119.21766,229.821239 138,227.568759 138,227.568759 C138,227.568759 129.699481,223.511906 115.614996,221.322751 C106.22534,219.863315 100.544379,219.611563 98.5721146,220.567495 Z"
              fill="currentColor"></path>
            <path
              d="M484.970374,233 C487.799194,252.089637 487.799194,274.470886 484.970374,300.143747 C483.980898,309.123705 482.703905,317.974694 481.139395,326.696714 C479.200543,337.50566 497.487411,353.273422 536,374 C530.418738,342.055817 524.498776,317.437066 518.240112,300.143747 C511.981449,282.850428 500.891536,260.469179 484.970374,233 Z"
              fill="currentColor"></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default NinjaVaultIcon;
