import { PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";

export interface Props {
  /** Icon above title */
  icon?: ReactNode;
  header?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;

  /** Card acts as a link */
  to?: string;
  /** Button to the right of the title */
  action?: ReactNode;
  /** Badges */
  badges?: Badge[];

  /** Card class */
  className?: string;
  /** More padding and more rounded corners */
  lg?: boolean;
  /** No padding */
  full?: boolean;
  /** Box shadow */
  shadow?: boolean;
  /** Show loading indicator to the right of title */
  loading?: boolean;
}

interface Badge {
  label: string;
  color: string;
}

const Card: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, to, badges, className } = props;

  const attrs = {
    className: `mx-4 sm:mx-0 max-w-full relative text-[white] rounded-[15px] ${className}`,
    children: (
      <>
        {badges && (
          <section className={"absolute left-0 top-0 flex justify-start"}>
            {badges.map(({ label, color }) => (
              <span
                className={`inline-flex text-[11px] h-5 px-[15px] py-0 bg-${color}`}
                key={label}
              >
                {label}
              </span>
            ))}
          </section>
        )}

        <section className={"px-[25px] py-5 h-full"}>{children}</section>
      </>
    ),
  };

  return to ? <Link {...attrs} to={to} /> : <div {...attrs} />;
};

export default Card;
