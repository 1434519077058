import { useEffect } from "react";
import { Network } from "@injectivelabs/networks";
import useEphemeralStore from "../store/ephemeralStore";
import useNetwork from "hooks/useNetwork";
import useURL from "hooks/useURL";
import { NETWORK } from "libs/services";
import BN from "bignumber.js";

import { toBase64 } from "@injectivelabs/sdk-ts";

import { FarmToken } from "constants/tokens";
import { multicallFetcher } from "utils/multicallFetcher";
import _ from "lodash";

import useWallet from "./useWallet";

// Money Market will be cached for below duration,
// and not fetched whilst cached.

export const useMoneyMarket = () => {
  const cachedMoneyMarketWallet = useEphemeralStore((s) => s.moneyMarketWallet);
  const setMoneyMarketWallet = useEphemeralStore((s) => s.setMoneyMarketWallet);
  const setSupplyBySymbol = useEphemeralStore((s) => s.setSupplyBySymbol);
  const setBorrowBySymbol = useEphemeralStore((s) => s.setBorrowBySymbol);
  const setIsMoneyMarketLoading = useEphemeralStore(
    (s) => s.setIsMoneyMarketLoading
  );

  const { multicall } = useNetwork();
  const { wallet } = useWallet();
  const getURL = useURL();

  // ------------------
  // EXECUTION
  // ------------------

  // Conditions are:
  // 1. Balance not hidden
  // 2. Farm is stale for more than CACHED_FARM_DURATION_SECS
  // 3. Wallet address has changed (if wallet is disconnected, dont refetch)
  // TODO: Stagger loading such that only maximum of 5-10 farms are refetched per FETCH_FARMS_FREQUENCY_SECS
  const fetchMoneyMarket = async () => {
    if (wallet.address !== "") {
      setIsMoneyMarketLoading(true);
      console.log("--Money Market Call--", wallet.address);

      const queries = [];

      const tokenResp = await fetch("./json/tokens-dictionary.json");
      const tokenData = await tokenResp.json();
      const ALL_TOKENS: FarmToken[] = Object.values(tokenData);
      queries.push({
        address: "inj1dffuj4ud2fn7vhhw7dec6arx7tuyxd56srjwk4",
        data: toBase64({ user_collaterals: { user: wallet.address } }),
      });
      queries.push({
        address: "inj1dffuj4ud2fn7vhhw7dec6arx7tuyxd56srjwk4",
        data: toBase64({ user_debts: { user: wallet.address } }),
      });

      const fetcher = multicallFetcher(multicall, getURL);
      const resp = await fetcher([queries]);
      const collectResults = (resp ?? []).reduce(
        (acc, curr) => acc.concat(curr.data.data.return_data),
        []
      );
      const results = collectResults?.map((e: any) => {
        return e.length == 0
          ? null
          : JSON.parse(Buffer.from(e.data, "base64").toString());
      });

      const SUPPLY_MAPPING = results?.[0]?.map((token) => {
        const tokenDenom = token.denom.includes("factory/")
          ? token.denom.split("/")?.[2]
          : token.denom;
        const foundToken = ALL_TOKENS.find((a) => a.address === tokenDenom);
        return {
          name: `Supply ${foundToken.symbol}`,
          symbol: foundToken.symbol,
          address: foundToken.address,
          decimals: foundToken.decimals,
          amount: new BN(token.amount)
            .div(Math.pow(10, foundToken.decimals))
            .toString(),
          amount_scaled: new BN(token.amount_scaled)
            .div(Math.pow(10, foundToken.decimals))
            .toString(),
          type: "supply",
        };
      });

      const BORROW_MAPPING = results?.[1]?.map((token) => {
        const tokenDenom = token.denom.includes("factory/")
          ? token.denom.split("/")?.[2]
          : token.denom;
        const foundToken = ALL_TOKENS.find((a) => a.address === tokenDenom);
        return {
          name: `Borrow ${foundToken.symbol}`,
          symbol: foundToken.symbol,
          address: foundToken.address,
          decimals: foundToken.decimals,
          amount: new BN(-token.amount)
            .div(Math.pow(10, foundToken.decimals))
            .toString(),
          amount_scaled: new BN(-token.amount_scaled)
            .div(Math.pow(10, foundToken.decimals))
            .toString(),
          type: "borrow",
        };
      });

      setSupplyBySymbol(SUPPLY_MAPPING, wallet?.address);
      setBorrowBySymbol(BORROW_MAPPING, wallet?.address);

      setMoneyMarketWallet(wallet.address);
      setIsMoneyMarketLoading(false);
    }
  };

  useEffect(() => {
    const run = async () => {
      await fetchMoneyMarket();
    };
    if (wallet.address !== "" && cachedMoneyMarketWallet !== wallet.address) {
      console.log("refreshing all money market data due to wallet change");
      run();
    }
  }, [wallet.address]);

  return { fetchMoneyMarket };
};
