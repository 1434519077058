import { useEffect } from "react";
import { getDexScreenerPairs } from "rest/useDexScreener";
import useEphemeralStore from "../store/ephemeralStore";

const EXTRA_PAIRS = [
  "inj17ufy5gqw33t0prwhkwa6ensv0jpj3xfvylgx8j", // BABYDOJO LP
  "inj1dn52v0uxdgke7gm7phmgdnrctck5nxzrynktu7", // QUNT LP
  "inj14z0hjhtwq7v6hep3dek9df8wl93zww7erhuhfg", // SPUUN LP
];

export const loadDexScreener = () => {
  // const dexScreenerData = useEphemeralStore((s) => s.dexScreenerData)
  const setDexScreenerData = useEphemeralStore((s) => s.setDexScreenerData);
  const farmsData = useEphemeralStore((s) => s.jsonData?.farms);

  async function loadDexScreenerData() {
    try {
      const pairData = await getDexScreenerPairs([
        // ...farmsData?.map((farm) => farm?.contractAddress),
        ...EXTRA_PAIRS,
      ]);
      setDexScreenerData(pairData?.pairs);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (farmsData) {
      loadDexScreenerData();
    }
  }, [farmsData]);

  return { loadDexScreenerData };
};
