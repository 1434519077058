import { StateCreator } from "zustand";
import {
  BalancesBySymbol,
  BalancesSlice,
} from "store/interfaces/balances.interface";
import { INJECTIVE_BURN_WALLET } from "constants/constants";

export const balancesSlice: StateCreator<
  BalancesSlice,
  [],
  [],
  BalancesSlice
> = (set) => ({
  balanceWallet: undefined,
  setBalanceWallet: (wallet: string) =>
    set((state) => ({
      ...state,
      balanceWallet: wallet,
    })),
  isBalanceLoading: false,
  setIsBalanceLoading: (isLoading: boolean) =>
    set(() => ({
      isBalanceLoading: isLoading,
    })),
  balancesBySymbol: undefined,
  setBalancesBySymbol: (balances: BalancesBySymbol, wallet: string) => {
    const balanceWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET;
    set((s) => ({
      ...s,
      balancesBySymbol: {
        ...s.balancesBySymbol,
        [balanceWallet]: {
          ...s.balancesBySymbol?.[balanceWallet],
          ...balances,
        },
      },
    }));
  },
});
