export const DEFAULT_TOKEN_IMAGE_URL = "/images/tokens/default-token.svg";

export const INJECTIVE_BURN_WALLET =
  "inj1fj6syxy0wmvqhr3n57hwrljxwsng3k55t3yxgj";

export const DOJO_INJ_LP = "DOJO-INJ LP";
export const DOJO_INJ_LP_ADDRESS = "inj17pda96ujt7fzr3d5jmfkh4dzvrqzc0nk56kt34";
export const LP = "LP";

export const FORCE_ON_CHAIN_FETCH = true;
