import axios from "./request"
import useNetwork from "hooks/useNetwork"
import { useMemo } from "react"
import BigNumber from "bignumber.js"
import useEphemeralStore from "../store/ephemeralStore"
import { FarmToken } from "../constants/tokens.ts"

interface Token {
  tokenAddress: string
  symbol: string
  price: string
  marketingInfo: {
    project: string | null
    description: string | null
    marketing: string | null
    logo: { url: string | null }
  }
}

interface Volume24h {
  volumeUST: string
  token0Volume: string
  token1Volume: string
}

interface Volume {
  volume: string
  timestamp: Date
}

interface Liquidity {
  liquidity: string
  timestamp: Date
}

export interface Pair {
  timestamp: Date
  pairAddress: string
  token0: string
  token0Volume: string
  token0Reserve: string
  token1: string
  token1Volume: string
  token1Reserve: string
  totalLpTokenShare: string
  volumeUst: string
  liquidityUst: string
  token0Symbol: string
  token0Decimals: number
  token1Symbol: string
  token1Decimals: number
  dailyVolumeUst?: string
  apr: string
  pairAlias: string
  lpPrice: string
  token0Marketing: {
    project: string | null
    description: string | null
    marketing: string | null
    logo: { url: string | null }
  }
  token1Marketing: {
    project: string | null
    description: string | null
    marketing: string | null
    logo: { url: string | null }
  }
}

export interface PairDetail {
  token0: Token
  token1: Token
  volume24h: Volume24h
  volumes: Volume[]
  liquidities: Liquidity[]
  lpTokenAddress: string
  pairAddress: string
  token0Reserve: string
  token1Reserve: string
}

export interface Recent {
  volume: string
  volumeIncreasedRate: string
  liquidity: string
  liquidityIncreasedRate: string
  fee: string
  feeIncreasedRate: string
  timestamp: Date
  height: number
}

export interface Transaction {
  ID: number
  timestamp: Date
  txHash: string
  pairAddress: string
  action: "swap" | "provide_liquidity" | "withdraw_liquidity"
  token0Amount: string
  token1Amount: string
}

export interface Farm {
  name: string
  lpAddress: string
  contractAddress: string
  stakingAddress: string
  token0: FarmToken
  token1: FarmToken
  vault: {
    config: {
      dojo_token: string
      staking_token: string
      distribution_schedule: [number, number, string][]
    }
    staker: {
      staker: string
      reward_index: string
      bond_amount: string
      pending_reward: string
    }
    state: {
      last_distributed: number
      total_bond_amount: string
      global_reward_index: string
    }
    total_rewards: string
    stakedBalance: string
    lpInfo: {
      name: string
      symbol: string
      decimals: 6
      total_supply: string
      token0Supply: string
      token1Supply: string
    }
    walletBalance?: string
  }
  tvl: number
}

export interface Price {
  contractAddress: string
  symbol: string
  price: string
  liquidity: string
}

export interface TokenMarketingInfo {
  project: string
  description: string
  marketing: string
  logo: {
    url: string
  }
}

export interface Pool {
  name: string
  lpAddress: string
  poolToken: FarmToken
  earningToken: FarmToken
  stakingAddress: string
  vault: {
    config: {
      dojo_token: string
      staking_token: string
      distribution_schedule: [number, number, string][]
    }
    staker: {
      staker: string
      reward_index: string
      bond_amount: string
      pending_reward: string
    }
    state: {
      last_distributed: number
      total_bond_amount: string
      global_reward_index: string
    }
    total_rewards: string
    stakedBalance: string
    lpInfo: {
      name: string
      symbol: string
      decimals: number
      total_supply: string
    }
    walletBalance: string
  }
  tvl: number
}

const useDashboardAPI = () => {
  const { dashboard: dashboardBaseUrl, supportServer } = useNetwork()
  const tokensSymbolMap = useEphemeralStore((s) => s.jsonData?.tokensSymbolMap)
  const tokensAddressMap = useEphemeralStore(
    (s) => s.jsonData?.tokensAddressMap,
  )
  const jsonData = useEphemeralStore((s) => s.jsonData)
  const feeApr = useEphemeralStore((s) => s.jsonData?.feeApr)

  const api = useMemo(() => {
    return {
      terraswap: {
        async getChartData(params: {
          unit: "day" | "month" | "year"
          from: string
          to: string
        }) {
          const res = await axios.get<
            { timestamp: Date; volumeUst: string; liquidityUst: string }[]
          >(`${dashboardBaseUrl}/dojoswap`, { params })
          if (Array.isArray(res?.data)) {
            return res.data
          }
          throw Error("no data")
        },
        async getRecent() {
          const res = await axios.get<{ daily: Recent; weekly: Recent }>(
            `${dashboardBaseUrl}/dojoswap/recent`,
          )
          if (res.data?.daily) {
            return res.data
          }
          throw Error("no data")
        },
        async getLSD() {
          const res = await axios.get<{ state: any }>(`${dashboardBaseUrl}/ldp`)
          const res2 = await axios.get<{ price: any }>(
            `${dashboardBaseUrl}/pairs/prices?tokenaddress=inj`,
          )
          // console.log("res", res);
          if (res.data?.state && res2.data) {
            return new BigNumber(res.data.state.tvl_utoken)
              .div(new BigNumber(10 ** 18))
              .multipliedBy(new BigNumber(res2.data.price))
              .toNumber()
          }
          return 0
        },
      },
      pairs: {
        async list() {
          const res = await axios.get<Pair[]>(`${dashboardBaseUrl}/pairs`)
          if (Array.isArray(res?.data)) {
            if (!tokensSymbolMap) return []
            return res.data.map((item) => {
              const allTokens = Object.values(tokensSymbolMap)
              const token0 = allTokens?.find((x) => x.address === item?.token0)
              const token1 = allTokens?.find((x) => x.address === item?.token1)
              const token0Symbol = tokensSymbolMap[item.token0Symbol]?.symbol
                ? tokensSymbolMap[item.token0Symbol]?.symbol
                : item.token0Symbol

              const token1Symbol = tokensSymbolMap[item.token1Symbol]?.symbol
                ? tokensSymbolMap[item.token1Symbol]?.symbol
                : item.token1Symbol

              return {
                ...item,
                pairAlias: `${token0?.symbol || token0Symbol}-${
                  token1?.symbol || token1Symbol
                }`,
                token0Symbol: token0?.symbol || token0Symbol,
                token1Symbol: token1?.symbol || token1Symbol,
              }
            })
          }
          throw Error("no data")
        },
        async feeAprMapping() {
          // const returnData = {}
          // Object.keys(jsonData?.farmFees ?? {}).forEach((item) => {
          //   const farm = jsonData?.farms.find((x) => x.contractAddress === item)
          //   const farmName = farm.name
          //   returnData[farmName] = jsonData?.farmFees[item]
          // })
          // return returnData

          if (!tokensSymbolMap) {
            // console.log("no tokensSymbolMap")
            return []
          }
          return feeApr || {}

          try {
            const res = await axios.get<Pair[]>(`${dashboardBaseUrl}/pairs`)
            if (Array.isArray(res?.data)) {
              // Rename Token Names
              const dashboardPairs = res.data.map((item) => {
                const token0Symbol = tokensSymbolMap[item.token0Symbol]
                  ? tokensSymbolMap[item.token0Symbol]
                  : item.token0Symbol

                const token1Symbol = tokensSymbolMap[item.token1Symbol]
                  ? tokensSymbolMap[item.token1Symbol]
                  : item.token1Symbol
                return {
                  ...item,
                  pairAlias: `${token0Symbol}-${token1Symbol}`,
                  token0Symbol,
                  token1Symbol,
                }
              })
              // console.log(feeApr)
              let data2 = Object.fromEntries([
                ...dashboardPairs
                  .filter((coin) => parseFloat(coin?.apr ?? "0") > 0)
                  .map((item) => [
                    `${item.token0Symbol}-${item.token1Symbol} LP`,
                    (parseFloat(item.apr) * 150).toFixed(2), // change to 100 later
                  ]),
                ...dashboardPairs
                  .filter((coin) => parseFloat(coin?.apr ?? "0") > 0)
                  .map((item) => [
                    `${item.token1Symbol}-${item.token0Symbol} LP`,
                    (parseFloat(item.apr) * 150).toFixed(2),
                  ]),
              ])
              // console.log(data2)
              return data2
            }
            throw Error("no data")
          } catch (err) {
            console.error(err)
            return feeApr
          }
        },
        async tokenMapping() {
          const res = await axios.get<Pair[]>(`${dashboardBaseUrl}/pairs`)

          // const DESIGNATED_STABLECOIN = "USDT"
          if (Array.isArray(res?.data)) {
            // Rename Token Names
            const dashboardPairs = res.data.map((item) => {
              const token0Symbol = tokensSymbolMap[item.token0Symbol]
                ? tokensSymbolMap[item.token0Symbol]
                : item.token0Symbol

              const token1Symbol = tokensSymbolMap[item.token1Symbol]
                ? tokensSymbolMap[item.token1Symbol]
                : item.token1Symbol
              return {
                ...item,
                pairAlias: `${token0Symbol}-${token1Symbol}`,
                token0Symbol,
                token1Symbol,
              }
            })

            const SINGLE_TOKEN_PRICE_MAP = {}

            let allTokens = []

            dashboardPairs
              .sort((a, b) =>
                new BigNumber(a.liquidityUst)
                  .minus(new BigNumber(b.liquidityUst))
                  .toNumber(),
              )
              .forEach((t: any) => {
                SINGLE_TOKEN_PRICE_MAP[`${t.token1Symbol}`] = parseFloat(
                  t.token1USD,
                )
                SINGLE_TOKEN_PRICE_MAP[`${t.token0Symbol}`] = parseFloat(
                  t.token0USD,
                )
              })
            return { ...SINGLE_TOKEN_PRICE_MAP, USDC: 1 }
            // dashboardPairs.forEach((t) => {
            //   SINGLE_TOKEN_PRICE_MAP[`${t.token0Symbol}/${t.token1Symbol}`] =
            //     parseFloat(t.token1Reserve) /
            //     Math.pow(10, t.token1Decimals) /
            //     (parseFloat(t.token0Reserve) / Math.pow(10, t.token0Decimals))

            //   SINGLE_TOKEN_PRICE_MAP[`${t.token1Symbol}/${t.token0Symbol}`] =
            //     parseFloat(t.token0Reserve) /
            //     Math.pow(10, t.token0Decimals) /
            //     (parseFloat(t.token1Reserve) / Math.pow(10, t.token1Decimals))

            //   allTokens.push(t.token0Symbol)
            //   allTokens.push(t.token1Symbol)
            // })
            // allTokens = [...new Set(allTokens)]

            // const tokensWithoutStablePairing = allTokens.filter(
            //   (token) =>
            //     !SINGLE_TOKEN_PRICE_MAP[`${token}/${DESIGNATED_STABLECOIN}`],
            // )
            // tokensWithoutStablePairing.forEach((t) => {
            //   const availablePairings = Object.keys(SINGLE_TOKEN_PRICE_MAP)
            //     .filter((pair) => pair.includes(`${t}/`))
            //     .map((at) => at.split("/")[1])

            //   // Prioritise INJ/USDT Conversion
            //   const chosenToken = SINGLE_TOKEN_PRICE_MAP[`${t}/INJ`]
            //     ? "INJ"
            //     : availablePairings.find(
            //       (item) =>
            //         SINGLE_TOKEN_PRICE_MAP[
            //         `${item}/${DESIGNATED_STABLECOIN}`
            //         ],
            //     )

            //   const toPairing = `${chosenToken}/${DESIGNATED_STABLECOIN}`

            //   const tokenPrice =
            //     SINGLE_TOKEN_PRICE_MAP[`${t}/${chosenToken}`] *
            //     SINGLE_TOKEN_PRICE_MAP[toPairing]
            //   SINGLE_TOKEN_PRICE_MAP[`${t}/${DESIGNATED_STABLECOIN}`] =
            //     tokenPrice
            // })

            // const FINAL_MAPPING = Object.fromEntries(
            //   Object.entries(SINGLE_TOKEN_PRICE_MAP)
            //     .filter(
            //       ([key]) =>
            //         !isNaN(SINGLE_TOKEN_PRICE_MAP[key]) &&
            //         key.includes(`/${DESIGNATED_STABLECOIN}`),
            //     )
            //     .map((item) => [
            //       item[0].replace(`/${DESIGNATED_STABLECOIN}`, ""),
            //       item[1],
            //     ]),
            // )
            // // console.log(FINAL_MAPPING)
            // return { ...FINAL_MAPPING, USDC: 1 }
          }
          throw Error("no data")
        },
        async findOne(address: string) {
          const res = await axios.get<PairDetail>(
            `${dashboardBaseUrl}/pairs/${address}`,
          )
          if (res?.data) {
            return {
              ...res.data,
              token0: {
                ...res.data.token0,
                symbol: tokensSymbolMap[res.data.token0.symbol]?.symbol
                  ? tokensSymbolMap[res.data.token0.symbol]?.symbol
                  : res.data.token0.symbol,
              },
              token1: {
                ...res.data.token1,
                symbol: tokensSymbolMap[res.data.token1.symbol]?.symbol
                  ? tokensSymbolMap[res.data.token1.symbol]?.symbol
                  : res.data.token1.symbol,
              },
            }
          }
          throw Error("no data")
        },
        async getRecent(address: string) {
          const res = await axios.get<{ daily: Recent; weekly: Recent }>(
            `${dashboardBaseUrl}/pairs/${address}/recent`,
          )
          if (res.data?.daily) {
            return res.data
          }
          throw Error("no data")
        },
      },
      txs: {
        async list({
          page,
          pairAddress: pair,
        }: {
          page: number
          pairAddress: string
        }) {
          const params = { page, pair }
          const res = await axios.get<{
            txs: Transaction[]
            totalCount: number
          }>(`${dashboardBaseUrl}/txs`, { params })
          if (Array.isArray(res?.data?.txs)) {
            return res.data
          }
          throw Error("no data")
        },
      },
      farms: {
        async getTvl(params) {
          const { version, walletAddress, force = false, forceFarms } = params
          const res = await axios.get<Record<string, Farm>>(
            `${dashboardBaseUrl}/farms/tvl/v${version}`,
            {
              params: {
                force,
                ...(walletAddress && {
                  wallet: walletAddress,
                }),
                ...(forceFarms && {
                  forceFarms,
                }),
              },
            },
          )
          if (Object.keys(res?.data).length > 0) {
            return res.data
          }
          throw Error("no data")
        },
      },
      prices: {
        async list() {
          const res = await axios.get<Price[]>(
            `${supportServer}/dojoswapapi/prices`,
          )
          if (res?.data) {
            return Object.keys(res?.data ?? {}).map((key) => {
              const tokenAddress = key
              const item = res?.data[key]
              const tokenSymbol = tokensAddressMap?.[tokenAddress]?.symbol
                ? tokensAddressMap?.[tokenAddress]?.symbol
                : tokenAddress
              return {
                contractAddress: tokenAddress,
                symbol: tokenSymbol,
                price: item?.price,
                liquidity: item?.liquidityUst,
              }
            })
          }
          throw Error("no data")
        },
        async supportList() {
          const res = await axios.get<Price[]>(
            `https://data.dojo.trading/supportServer/dojoswapapi/prices`,
          )
          if (res?.data) {
            return res?.data
          }
          throw Error("no data")
        },
      },
      treasury: {
        async treasury() {
          const res = await axios.get(
            `https://data.dojo.trading/ninjavault/portfolio/treasury/latest`,
          )
          if (res?.data) {
            return res?.data
          }
          throw Error("no data")
        },
        async fee() {
          const res = await axios.get(
            `https://data.dojo.trading/ninjavault/portfolio/fee/latest`,
          )
          if (res?.data) {
            return res?.data
          }
          throw Error("no data")
        },
        async history() {
          const res = await axios.get(
            `https://data.dojo.trading/ninjavault/portfolio/all`,
          )
          if (res?.data) {
            return res?.data?.portfolio
          }
          throw Error("no data")
        },
      },
      tokens: {
        async marketingInfo(contract: string) {
          const res = await axios.get<TokenMarketingInfo>(
            `${dashboardBaseUrl}/tokens/marketing?contract=${encodeURIComponent(
              contract,
            )}`,
          )
          return res?.data
        },
        async allMarketingInfo() {
          const res = await axios.get<TokenMarketingInfo[]>(
            `${dashboardBaseUrl}/tokens/marketing/all`,
          )
          return res?.data
        },
      },
      pools: {
        async getTvl(params) {
          const { walletAddress, force = false, forcePools } = params
          const res = await axios.get<Record<string, Pool>>(
            `${dashboardBaseUrl}/pools/tvl`,
            {
              params: {
                force,
                ...(walletAddress && {
                  wallet: walletAddress,
                }),
                ...(forcePools && {
                  forcePools,
                }),
              },
            },
          )
          if (Object.keys(res?.data).length > 0) {
            return res.data
          }
          throw Error("no data")
        },
      },
    }
  }, [dashboardBaseUrl, tokensAddressMap, jsonData])

  return { api }
}

export default useDashboardAPI
