import classNames from "classnames";

const DojoSwapIcon = ({ className }: { className?: string }) => {
  return (
    <div className={classNames(className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 200 200"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill="currentColor" fillRule="nonzero">
            <g>
              <path d="M30.3,0 C64.8177969,0 92.8,27.9822031 92.8,62.5 L92.8,92.8 L0,92.8 L0,0 L30.3,0 Z M30.3,20 L20,20 L20,72.8 L72.8,72.8 L72.8,62.5 C72.8,39.2626192 54.150757,20.3810024 31.0028152,20.0056938 L30.3,20 Z"></path>
              <path
                d="M200,0 L200,46.4 C200,72.0260124 179.226012,92.8 153.6,92.8 C127.973988,92.8 107.2,72.0260124 107.2,46.4 C107.2,20.7739876 127.973988,0 153.6,0 L200,0 Z M180,20 L153.6,20 C139.019683,20 127.2,31.8196826 127.2,46.4 C127.2,60.9803174 139.019683,72.8 153.6,72.8 C168.180317,72.8 180,60.9803174 180,46.4 L180,20 Z"
                transform="translate(153.6, 46.4) scale(-1, -1) translate(-153.6, -46.4)"></path>
              <path d="M93,107 L93,148.5 C93,176.942665 69.9426646,200 41.5,200 L0,200 L0,180 L41.5,180 C58.8969696,180 73,165.89697 73,148.5 L73,107 L93,107 Z"></path>
              <path
                d="M153.6,107.2 C179.226012,107.2 200,127.973988 200,153.6 L200,200 L153.6,200 C127.973988,200 107.2,179.226012 107.2,153.6 C107.2,127.973988 127.973988,107.2 153.6,107.2 Z M153.6,127.2 C139.019683,127.2 127.2,139.019683 127.2,153.6 C127.2,168.180317 139.019683,180 153.6,180 L180,180 L180,153.6 C180,139.165486 168.415529,127.43667 154.036572,127.203537 L153.6,127.2 Z"
                transform="translate(153.6, 153.6) scale(-1, -1) translate(-153.6, -153.6)"></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default DojoSwapIcon;
