// need to write condition where fetching will occur
// need to store any failed requests and attempt after 30s. after 2 tries just give up
// when users refresh balances, only fetch the specific farm to reduce calls
// using axios with throttling should help: https://www.npmjs.com/package/axios-rate-limit

import { StateCreator } from "zustand"
import { PoolData, PoolsMap, PoolsSlice } from "../interfaces/pools.interface"
import { INJECTIVE_BURN_WALLET } from "constants/constants"

export const poolsSlice: StateCreator<PoolsSlice, [], [], PoolsSlice> = (
  set,
) => ({
  poolWallet: undefined,
  setPoolWallet: (wallet: string) =>
    set((state) => ({
      ...state,
      poolWallet: wallet,
    })),
  poolsByWallet: undefined,
  setAllPools: (pools: PoolsMap, wallet: string) => {
    const farmWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET
    set((state) => ({
      ...state,
      poolsByWallet: {
        ...state.poolsByWallet,
        [farmWallet]: {
          ...state.poolsByWallet?.[farmWallet],
          ...pools,
        },
      },
    }))
  },
  setPool: (name: string, pool: PoolData, wallet: string) => {
    const farmWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET
    set((state) => ({
      ...state,
      poolsByWallet: {
        ...state.poolsByWallet,
        [farmWallet]: {
          ...state.poolsByWallet?.[farmWallet],
          [name]: pool,
        },
      },
    }))
  },
  selectedPool: undefined,
  setSelectedPool: (pool: PoolData) =>
    set((state) => ({
      ...state,
      selectedPool: pool,
    })),
})
