// need to write condition where fetching will occur
// need to store any failed requests and attempt after 30s. after 2 tries just give up
// when users refresh balances, only fetch the specific vault to reduce calls
// using axios with throttling should help: https://www.npmjs.com/package/axios-rate-limit

import { StateCreator } from "zustand";
import {
  VaultData,
  VaultsMap,
  VaultsSlice,
} from "../interfaces/vaults.interface";
import { INJECTIVE_BURN_WALLET } from "constants/constants";

export const vaultsSlice: StateCreator<VaultsSlice, [], [], VaultsSlice> = (
  set
) => ({
  vaultWallet: undefined,
  setVaultWallet: (wallet: string) =>
    set((state) => ({
      ...state,
      vaultWallet: wallet,
    })),
  vaultsByWallet: undefined,
  setAllVaults: (vaults: VaultsMap, wallet: string) => {
    const farmWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET;
    set((state) => ({
      ...state,
      vaultsByWallet: {
        ...state.vaultsByWallet,
        [farmWallet]: {
          ...state.vaultsByWallet?.[farmWallet],
          ...vaults,
        },
      },
    }));
  },
  setVault: (name: string, vault: VaultData, wallet: string) => {
    const farmWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET;
    set((state) => ({
      ...state,
      vaultsByWallet: {
        ...state.vaultsByWallet,
        [farmWallet]: {
          ...state.vaultsByWallet?.[farmWallet],
          [name]: vault,
        },
      },
    }));
  },
  selectedVault: undefined,
  setSelectedVault: (vault: VaultData) =>
    set((state) => ({
      ...state,
      selectedVault: vault,
    })),
});
