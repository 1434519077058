import classNames from "classnames";

const Telegram = ({ className }: { className?: string }) => {
  return (
    <div className={classNames(className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 500 500"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill="currentColor" fillRule="nonzero">
            <path d="M250,0 C111.95,0 0,111.95 0,249.75 C0,387.775 111.95,500 250,500 C388.05,500 500,387.8 500,249.75 C500,111.925 388.05,0 250,0 Z M373.125,157 C371.525,180.175 328.625,353.4 328.625,353.4 C328.625,353.4 325.95,363.55 316.625,363.8 C312.044417,364.017593 307.588404,362.271563 304.375,359 C294.5,350.75 272.125,334.75 251.075,320.1 C250.245028,320.966206 249.350818,321.768488 248.4,322.5 C243.6,326.75 236.4,332.9 228.675,340.35 C225.496803,343.208408 222.387577,346.142584 219.35,349.15 L219.1,349.4 C217.576085,350.917528 215.96502,352.344948 214.275,353.675 C203.9,362.2 202.825,355 202.825,351.275 L208.425,290.25 L208.425,289.725 L208.675,289.175 C208.95,288.375 209.5,288.1 209.5,288.1 C209.5,288.1 318.5,191.1 321.425,180.7 C321.675,180.175 320.9,179.65 319.575,180.175 C312.375,182.575 186.825,262 172.975,270.8 C172.175,271.3 169.775,271.05 169.775,271.05 L108.75,251.05 C108.75,251.05 101.55,248.125 103.95,241.475 C104.475,240.15 105.275,238.8 108.2,236.95 C121.8,227.35 358.2,142.325 358.2,142.325 C358.2,142.325 364.875,140.2 368.875,141.525 C370.725,142.325 371.8,143.125 372.875,145.775 C373.125,146.85 373.4,148.975 373.4,151.375 C373.4,152.725 373.15,154.325 373.15,156.975 L373.125,157 Z"></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Telegram;
