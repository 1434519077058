import classNames from "classnames";

const Announcements = ({ className }: { className?: string }) => {
  return (
    <div className={classNames(className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 500 408.481607"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill="currentColor" fillRule="nonzero">
            <path d="M306.767573,199.64988 C306.767573,136.5756 318.365751,81.3036148 337.620227,41.8668495 C224.439874,106.901489 125.19151,111.054955 75.3954134,114.055268 C47.3960189,115.741622 21.614063,133.963551 8.20892704,162.497204 C-2.71745951,185.755977 -2.65292528,213.174563 7.90424082,236.599433 C21.2078147,266.125432 47.1399978,285.485703 75.3943555,285.242377 C80.0725585,285.202175 85.6732838,285.273057 91.9024244,285.483587 C81.8149829,330.880776 107.818048,378.906943 154.585267,399.173866 C168.878014,405.368095 183.947286,408.481607 198.886433,408.481607 C210.529044,408.481607 222.094425,406.590014 233.142474,402.775088 C258.690626,393.965636 278.440218,376.000786 288.750884,352.207754 C291.151346,346.673679 292.778455,340.877235 294.020475,334.995098 C308.338612,341.610386 322.918059,349.058272 337.661487,357.512257 C318.383735,318.070202 306.767573,262.77071 306.767573,199.64988 Z M269.638171,343.926213 C261.678244,362.28779 246.308516,376.192273 226.348395,383.080509 C206.060312,390.078771 183.508242,389.000732 162.867866,380.048458 C124.168484,363.282252 102.989618,323.383168 113.007235,286.73407 C152.774077,289.960782 210.214836,299.639859 274.57028,326.437436 C273.692191,332.451815 272.061908,338.331836 269.638171,343.926213 Z"></path>
            <path d="M413.800247,0 C379.347429,0 349.70294,46.9280262 335.901078,114.590585 C381.910813,115.686609 419.020114,153.383066 419.020114,199.650938 C419.020114,245.917753 381.908697,283.604689 335.901078,284.700713 C349.699767,352.365387 379.345313,399.295529 413.800247,399.295529 C461.407469,399.295529 500,309.909266 500,199.647765 C500,89.3862632 461.407469,0 413.800247,0 Z"></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Announcements;
