import { formatToBillions } from "./parse";

export function getFormattedValue(
  amount: number | string,
  includeNegativeSign?: boolean,
  noDecimals?: boolean,
  includeSubscript?: boolean
) {
  const subscripts = [
    "\u2080",
    "\u2081",
    "\u2082",
    "\u2083",
    "\u2084",
    "\u2085",
    "\u2086",
    "\u2087",
    "\u2088",
    "\u2089",
  ];
  let _amount = typeof amount === "string" ? parseFloat(amount) : amount;
  let displayDecimals = 0;
  const absAmount = Math.abs(_amount);
  if (absAmount > 1000000) {
    return formatToBillions(absAmount);
  }

  // // Handle very small numbers with scientific notation
  // if (absAmount > 0 && absAmount < 0.000001) {
  //   return `${
  //     _amount < 0 && includeNegativeSign ? "−" : ""
  //   }${_amount.toExponential(2)}`;
  // }

  if (!noDecimals) {
    if (absAmount === 0) {
      displayDecimals = 0;
    } else if (absAmount >= 1000) {
      displayDecimals = 2;
    } else if (absAmount >= 100) {
      displayDecimals = 2;
    } else if (absAmount >= 1) {
      displayDecimals = 2;
    } else if (absAmount >= 0.01) {
      displayDecimals = 4;
    } else if (absAmount > 0.0001) {
      displayDecimals = 6;
    } else {
      displayDecimals = 10;
    }
  }
  if (includeSubscript) {
    if (_amount?.toString()?.includes("e-")) {
      const newValue = _amount?.toString().split("e-");
      return `${newValue?.[0].slice(0, 5)}e-${newValue?.[1]}`;
    }
    const noOfZeros = -Math.floor(Math.log10(_amount) + 1);
    if (noOfZeros > 4) {
      return _amount
        .toString()
        .replace("0".repeat(noOfZeros), `0${subscripts[noOfZeros]}`)
        .slice(0, 7);
    }
  }
  return `${
    _amount < 0 && includeNegativeSign ? "−" : ""
  }${absAmount?.toLocaleString(undefined, {
    minimumFractionDigits: displayDecimals,
    maximumFractionDigits: displayDecimals,
  })}`;
}
