import { useCallback, useEffect, useState } from "react";
import BN from "bignumber.js";
import Modal from "./Modal";
import { toBase64 } from "@injectivelabs/sdk-ts";
import { getFormattedValue } from "../libs/getFormattedValue";
import { VaultData } from "../store/interfaces/vaults.interface";
import useSendTransaction from "../hooks/useSendTransaction";
import useWallet from "../hooks/useWallet";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

interface Props {
  vault?: VaultData;
  isOpen: boolean;
  onClose: () => void;
  onDeposit: () => void;
}

const DepositModal = (props: Props) => {
  const tokenDecimals = Math.pow(10, props?.vault?.stakingToken?.decimals);

  const [balance, setBalance] = useState("-");
  const [value, setValue] = useState("");
  const { wallet } = useWallet();

  const { loading, sendTransaction } = useSendTransaction();

  useEffect(() => {
    if (props?.vault?.walletBalance) {
      setBalance(`${props?.vault?.walletBalance}`);
    }
  }, [props?.vault?.name, props?.vault?.walletBalance]);

  const submit = useCallback(async () => {
    const data = {
      sender: wallet.address,
      contractAddress: props?.vault?.stakingToken.address,
      msg: {
        send: {
          contract: props?.vault?.vaultAddress,
          amount: new BN(value).times(tokenDecimals).toFixed(),
          msg: toBase64({ bond: {} }),
        },
      },
    };
    // const additionalData = {
    //   sender: wallet.address,
    //   contractAddress: props?.vault?.vaultAddress,
    //   msg: {
    //     harvest: {},
    //   },
    // };
    await sendTransaction({
      wallet,
      txType: "deposit-vault",
      data,
      // additionalData,
      successMessage: `Deposited ${getFormattedValue(value)} ${
        props?.vault?.stakingToken?.name
      } successfully`,
      callback: () => {
        setValue("");
        props.onClose();
      },
      gasMultiplier: props.vault?.gasMultiplier ?? 1.5,
      // hideResultModal: true,
    });
    // toast.error(`Failed to deposit ${props?.vault?.name}: ${e.message}`)

    props.onDeposit();
  }, [
    wallet.address,
    props?.vault?.stakingToken?.name,
    props?.vault?.stakingToken.address,
    props?.vault?.vaultAddress,
    value,
    tokenDecimals,
  ]);

  return (
    <Modal
      isOpen={props.isOpen}
      close={() => {
        setValue("");
        props.onClose();
      }}
      open={() => {}}
      isCloseBtn={true}
    >
      <div
        className={
          "px-4 sm:px-6 flex flex-col bg-primary-100 border border-primary-300 my-0 p-3 rounded-xl border-solid mx-4 sm:mx-2"
        }
      >
        <h1
          className={
            "text-xl font-bold not-italic leading-[normal] tracking-[normal] text-center text-primary-900 mt-2.5 mb-[30px]"
          }
        >
          Deposit
        </h1>

        <div className={"mt-2 mb-1 flex items-center justify-between mx-1"}>
          <div className={"text-primary-900 shrink-0"}>
            Balance:{" "}
            {!isNaN(parseFloat(balance))
              ? getFormattedValue(new BN(balance).toNumber())
              : "0"}{" "}
            {props?.vault?.stakingToken.name}
          </div>
          <div className="flex justify-end">
            <div
              className="font-medium text-sm text-primary-100 bg-primary-700 rounded-lg px-3 hover:opacity-80 cursor-pointer"
              onClick={() => {
                if (!isNaN(parseFloat(balance))) {
                  setValue(new BN(balance).toString());
                }
              }}
            >
              Max
            </div>
          </div>
        </div>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={"Enter Amount"}
          className="text-primary-900 rounded-xl bg-primary-200/50 border border-primary-500 px-4 py-2 focus:outline-none"
        />

        <button
          className={`mt-4 py-2 text-white font-bold rounded-xl bg-primary-700 pointer-events-auto w-full ${
            new BN(balance).lte(new BN(0)) ||
            loading ||
            !value ||
            new BN(value).lte(new BN(0)) ||
            new BN(value).gt(new BN(balance))
              ? "opacity-50 hover:opacity-50 cursor-default"
              : "hover:opacity-80 cursor-pointer"
          }`}
          disabled={
            new BN(balance).lte(new BN(0)) ||
            loading ||
            !value ||
            new BN(value).lte(new BN(0)) ||
            new BN(value).gt(new BN(balance))
          }
          onClick={() => submit()}
        >
          Deposit
        </button>

        <div className="mt-6 mb-2 flex justify-center items-center text-primary-900">
          <a
            href={`https://dojo.trading/swap`}
            rel="noreferrer"
            target="_blank"
            className="cursor-pointer hover:opacity-50 flex justify-center items-center"
          >
            Buy {props?.vault?.stakingToken?.name}{" "}
            <ArrowTopRightOnSquareIcon className="w-5 h-5 ml-1" />
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default DepositModal;
