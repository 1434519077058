import {
  memo,
  useMemo,
  useState,
  Fragment,
  useEffect,
  useRef,
  useCallback,
} from "react";
import classNames from "classnames";
import AssetIcon from "./AssetIcon";
import BN from "bignumber.js";
import DepositModal from "./DepositModal";
import HarvestButton from "./HarvestButton";
import WithdrawModal from "./WithdrawModal";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
// import { ReactComponent as Icon } from "../images/DojoSwapIcon.svg";
import CountUp from "./CountUp";
// import { ReactComponent as NinjaVaultBlackIcon } from "../images/NinjaVaultBlackIcon.svg";
// import { ReactComponent as NinjaVaultIcon } from "../images/NinjaVaultIcon.svg";
import { Tooltip } from "react-tooltip";
import { useQuery } from "react-query";

import useEphemeralStore from "../store/ephemeralStore";
import useLocalStore from "../store/localStore";

import useWallet from "../hooks/useWallet";
import { getFormattedValue } from "libs/getFormattedValue";
import { useBalances } from "hooks/useBalances";
import { useMoneyMarket } from "hooks/useMoneyMarket";

import {
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  CircleStackIcon,
  ClipboardIcon,
  EllipsisHorizontalCircleIcon,
  EllipsisHorizontalIcon,
  PlayIcon,
  UserIcon,
  WalletIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  ArrowPathIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowRightIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronRightIcon,
  DocumentDuplicateIcon,
  UserIcon as UserOutlineIcon,
} from "@heroicons/react/24/outline";
import { useConnectModal } from "hooks/useConnectModal";
import Skeleton from "./Skeleton";
import { truncate } from "libs/text";
import { useFarms } from "hooks/useFarms";
import { usePools } from "hooks/usePools";
import { useVaults } from "hooks/useVaults";
import { useStaking } from "hooks/useStaking";
import NinjaVaultIcon from "./Icons/NinjaVaultIcon";
import InjectiveLogo from "./Icons/InjectiveLogo";
import { textGradient } from "constants/gradient";
import DojoSwapIcon from "./Icons/DojoSwapIcon";
import Staking from "./Icons/Staking";
import Money from "./Icons/Money";
import useDashboardAPI from "rest/useDashboardAPI";
import { useSearchParams } from "react-router-dom";
import { useDexScreenerPrices } from "hooks/usePrices";

dayjs.extend(relative);

const Portfolio = () => {
  const { isConnected, wallet, actualWallet } = useWallet();
  const connectModal = useConnectModal();
  let [searchParams, _setSearchParams] = useSearchParams();
  const { getPriceOfToken } = useDexScreenerPrices();

  const handleClick = () => connectModal.open();

  // Farms / Pools / Vaults / Prices
  const { farms } = useFarms();
  const { pools } = usePools();
  const { vaults } = useVaults();
  const delegations = useEphemeralStore((s) => s.delegations);
  const isFarmsLoading =
    !farms?.v2 || Object.values(farms?.v2).some((farm) => farm.loading);
  const isPoolsLoading =
    !pools || Object.values(pools).some((pool) => pool.loading);
  const isVaultsLoading =
    !vaults || Object.values(vaults).some((vault) => vault.loading);
  const setMockWallet = useLocalStore((s) => s.setMockWallet);
  const mockWallet = useLocalStore((s) => s.mockWallet);
  const [mockAddress, setMockAddress] = useState(wallet?.address);
  const { api } = useDashboardAPI();
  const { data: marketingTokens } = useQuery(`marketing-all`, () =>
    api.tokens.allMarketingInfo()
  );
  useEffect(() => {
    if (actualWallet?.address) {
      setMockWallet("");
      setMockAddress(actualWallet?.address);
    }
  }, [actualWallet.address]);

  useEffect(() => {
    const searchAddress = searchParams.get("portfolioAddress");
    if (searchAddress && isValidInjectiveAddress(searchAddress)) {
      setMockAddress(searchAddress);
      setMockWallet(searchAddress);
    }
  }, [searchParams]);

  const { fetchFarms } = useFarms();
  const { fetchPools } = usePools();
  const { fetchVaults } = useVaults();
  const { fetchBalances } = useBalances();
  const { fetchMoneyMarket } = useMoneyMarket();
  const { fetchStaking } = useStaking();

  const [loading, setLoading] = useState(false);
  const [showFarmLowValue, setShowFarmLowValue] = useState(true);
  const [showVaultLowValue, setShowVaultLowValue] = useState(true);
  const [showWalletLowValue, setShowWalletLowValue] = useState(true);
  const [showStakingLowValue, setShowStakingLowValue] = useState(true);
  const [showMoneyMarketLowVaue, setShowMoneyMarketLowVaue] = useState(true);

  async function refetchAll() {
    setLoading(true);
    await fetchFarms({
      forceAll: false,
      version: 2,
      walletAddress: wallet.address,
    });
    await fetchFarms({
      forceAll: false,
      version: 1,
      walletAddress: wallet.address,
    });
    await fetchBalances();
    await fetchMoneyMarket();
    await fetchStaking();
    await fetchPools(wallet.address, true);
    await fetchVaults(wallet.address, true);
    setLoading(false);
  }

  function isValidInjectiveAddress(address: string) {
    if (address.startsWith("inj") && address?.length === 42) {
      return true;
    }
  }

  const balances = useEphemeralStore((s) => s.balancesBySymbol);
  const isBalanceLoading = useEphemeralStore((s) => s.isBalanceLoading);

  const moneyMarketBorrow = useEphemeralStore((s) => s.borrowBySymbol);
  const moneyMarketSupply = useEphemeralStore((s) => s.supplyBySymbol);
  const isMoneyMarketLoading = useEphemeralStore((s) => s.isMoneyMarketLoading);
  // const vaults = useEphemeralStore((s) => s.vaults);
  const pricesBySymbol = useEphemeralStore((s) => s.pricesBySymbol);
  const pricesByTokenAddress = useEphemeralStore((s) => s.pricesByTokenAddress);

  const userFarmsAndPools = useMemo(() => {
    const farmsV1WithStakedBalances = Object.values(farms?.["v1"] ?? [])
      .filter((farm) => farm.balance)
      .map((item) => {
        return {
          ...item,
          rewardsInUsd: new BN(item.pending_reward).times(0).toString(),
          totalInUsd: new BN(item.pending_reward)
            .times(0)
            .plus(item.balanceUsd)
            .toString(),
          isLp: true,
          isV1: true,
        };
      });
    const farmsWithStakedBalances = Object.values(farms?.["v2"] ?? [])
      .filter((farm) => farm.balance)
      .map((item) => {
        return {
          ...item,
          rewardsInUsd: new BN(item.pending_reward)
            .times(pricesBySymbol["DOJO"])
            .toString(),
          totalInUsd: new BN(item.pending_reward)
            .times(pricesBySymbol["DOJO"])
            .plus(item.balanceUsd)
            .toString(),
          isLp: true,
          isV1: false,
        };
      });

    const poolsWithStakedBalances = Object.values(pools ?? [])
      .filter((pool) => pool.balance)
      .map((item) => {
        return {
          ...item,
          rewardsInUsd: item.earningBalanceUsd,
          totalInUsd: new BN(item.earningBalanceUsd)
            .plus(item.balanceUsd)
            .toString(),
          isLp: false,
          isV1: false,
        };
      });
    return [
      ...farmsV1WithStakedBalances,
      ...farmsWithStakedBalances,
      ...poolsWithStakedBalances,
    ].sort((a, b) => parseFloat(b.totalInUsd) - parseFloat(a.totalInUsd));
  }, [farms, pools, pricesBySymbol]);

  const userMoneyMarket = useMemo(() => {
    if (
      moneyMarketSupply &&
      moneyMarketSupply[wallet?.address] &&
      moneyMarketBorrow &&
      moneyMarketBorrow[wallet?.address] &&
      pricesBySymbol
    ) {
      const supply = Object.values(moneyMarketSupply?.[wallet?.address]).map(
        (item: any) => {
          return {
            ...item,
            price: pricesBySymbol[item.symbol],
            amountInUsd: new BN(item.amount)
              .times(pricesBySymbol[item.symbol])
              .toString(),
          };
        }
      );
      const borrow = Object.values(moneyMarketBorrow?.[wallet?.address]).map(
        (item: any) => {
          return {
            ...item,
            price: pricesBySymbol[item.symbol],
            amountInUsd: new BN(item.amount)
              .times(pricesBySymbol[item.symbol])
              .toString(),
          };
        }
      );
      return {
        supply,
        borrow,
      };
    }
  }, [moneyMarketBorrow, moneyMarketSupply, pricesBySymbol, wallet?.address]);

  const userMoneyMarketTotalValue = useMemo(() => {
    if (
      userMoneyMarket?.supply?.length > 0 ||
      userMoneyMarket?.borrow?.length > 0
    ) {
      return new BN(
        userMoneyMarket?.supply.reduce(
          (acc, curr) => acc.plus(new BN(curr.amountInUsd)),
          new BN(0)
        )
      )
        .plus(
          userMoneyMarket?.borrow.reduce(
            (acc, curr) => acc.plus(new BN(curr.amountInUsd)),
            new BN(0)
          )
        )
        .toString();
    }
    return "0";
  }, [userMoneyMarket]);

  const userVaults = useMemo(() => {
    return Object.values(vaults ?? [])
      .filter((vault) => parseFloat(vault?.balance) > 0)
      .map((item) => {
        return {
          ...item,
          totalInUsd: new BN(item.earningBalanceUsd)
            .plus(item.balanceUsd)
            .toString(),
        };
      })
      .sort(
        (a, b) =>
          parseFloat(b.balanceUsd.toString()) -
          parseFloat(a.balanceUsd.toString())
      );
  }, [vaults, pricesBySymbol]);

  const userStaking = useMemo(() => {
    if (delegations && delegations?.[wallet.address]) {
      return Object.values(delegations?.[wallet?.address] ?? [])

        .map((item) => {
          return {
            ...item,
            balanceUsd: new BN(item.balance)
              .times(pricesBySymbol?.["INJ"])
              .toString(),
          };
        })
        .sort(
          (a, b) =>
            parseFloat(b.balanceUsd.toString()) -
            parseFloat(a.balanceUsd.toString())
        );
    }
    return [];
  }, [delegations, pricesBySymbol]);

  const userStakingTotalValue = useMemo(() => {
    if (userStaking?.length > 0) {
      //TODO: Does not include staking rewards
      return userStaking
        .reduce((acc, curr) => acc.plus(new BN(curr.balanceUsd)), new BN(0))
        .toString();
    }
    return "0";
  }, [userStaking]);

  const userVaultsTotalValue = useMemo(() => {
    if (userVaults?.length > 0) {
      return userVaults
        .reduce((acc, curr) => acc.plus(new BN(curr.totalInUsd)), new BN(0))
        .toString();
    }
    return "0";
  }, [userVaults]);

  const userFarmsAndPoolsTotalValue = useMemo(() => {
    if (userFarmsAndPools?.length > 0) {
      return userFarmsAndPools
        .reduce((acc, curr) => acc.plus(new BN(curr.totalInUsd)), new BN(0))
        .toString();
    }
    return "0";
  }, [userFarmsAndPools]);

  const userWallets = useMemo(() => {
    let singleTokenBalances = [];
    let lpTokenBalances = [];
    if (balances && balances[wallet?.address] && pricesBySymbol) {
      singleTokenBalances = Object.entries(balances?.[wallet?.address])
        .filter((token) => new BN(token[1].balance).gt(0))
        .map((item) => {
          // if (item[0] === "BLACK") {
          //   console.log(
          //     pricesBySymbol[item[0]],
          //     pricesByTokenAddress[item[1].address],
          //     getPriceOfToken({ symbol: item[0] })
          //   );
          // }
          const price =
            item[0] === "SOL"
              ? pricesBySymbol["SOLLEGACY"]
              : pricesBySymbol[item[0]]
              ? pricesBySymbol[item[0]]
              : pricesByTokenAddress[item[1].address]
              ? new BN(pricesByTokenAddress[item[1].address])
              : getPriceOfToken({ symbol: item[0] })
              ? getPriceOfToken({ symbol: item[0] })
              : 0;
          if (price === 0) {
            console.log("Unable to retrieve price for", item[0]);
          }
          return {
            price,
            symbol: item[0],
            address: item[1].address,
            amount: item[1].balance,
            amountInUsd: new BN(item[1].balance).times(price).toString(),
          };
        });
    }
    const farmV1 = Object.values(farms?.["v1"] ?? []);
    const farmV2 = Object.values(farms?.["v2"] ?? []);

    if ((farmV1?.length > 0 || farmV2?.length > 0) && pricesBySymbol) {
      lpTokenBalances = [...farmV1, ...farmV2]
        .filter((each) => new BN(each.walletBalance).gt(0))
        .map((item) => {
          return {
            price: item?.["lpPrice"],
            symbol: item.name,
            address: item.lpAddress,
            amount: item.walletBalance,
            amountInUsd: item.walletUsd,
            token0: item?.["token0"],
            token1: item?.["token1"],
          };
        });
    }

    return [...singleTokenBalances, ...lpTokenBalances]?.sort(
      (a, b) => parseFloat(b.amountInUsd) - parseFloat(a.amountInUsd)
    );
  }, [farms, balances, pricesBySymbol, pricesByTokenAddress]);
  // console.log(userWallets);
  const userWalletTotalValue = useMemo(() => {
    if (userWallets?.length > 0) {
      return userWallets
        .reduce((acc, curr) => acc.plus(new BN(curr.amountInUsd)), new BN(0))
        .toString();
    }
    return "0";
  }, [userWallets]);

  const netWorth = useMemo(() => {
    return (
      parseFloat(isNaN(userWalletTotalValue) ? 0 : userWalletTotalValue) +
      parseFloat(userFarmsAndPoolsTotalValue) +
      parseFloat(userVaultsTotalValue) +
      parseFloat(userStakingTotalValue) +
      parseFloat(userMoneyMarketTotalValue)
    );
  }, [
    userWalletTotalValue,
    userFarmsAndPoolsTotalValue,
    userVaultsTotalValue,
    userStakingTotalValue,
    userMoneyMarketTotalValue,
  ]);

  // console.log({
  //   userFarmsAndPools,
  //   userWallets,
  //   userVaults,
  //   userStaking,
  // });

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const [isCopied, setIsCopied] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const handleCopy = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (timer?.current) {
        clearTimeout(timer.current);
      }
      setIsCopied(true);
      if (navigator?.clipboard?.writeText) {
        navigator?.clipboard?.writeText(inputRef?.current?.innerHTML || "");
      } else {
        inputRef?.current?.focus();
        inputRef?.current?.select();
        document.execCommand("copy");
      }

      if (event.currentTarget) {
        event.currentTarget.blur();
      }

      timer.current = setTimeout(() => {
        setIsCopied(false);
      }, 1200);
    },
    []
  );

  const isAllLoading =
    isBalanceLoading ||
    loading ||
    isFarmsLoading ||
    isPoolsLoading ||
    isVaultsLoading ||
    isMoneyMarketLoading;
  useEffect(() => {
    const searchAddress = searchParams.get("portfolioAddress");
    if (searchAddress && !isAllLoading) {
      localStorage.setItem(
        searchAddress,
        JSON.stringify({
          userFarmsAndPools,
          userWallets,
          userVaults,
          userStaking,
          userMoneyMarket,
        })
      );
    }
  }, [
    searchParams,
    isAllLoading,
    userWallets,
    userFarmsAndPools,
    userVaults,
    userStaking,
    userMoneyMarket,
  ]);

  if (!isConnected) {
    return (
      <div className="flex justify-center items-center w-full h-content px-4 max-w-screen overflow-x-hidden">
        <div className="w-full max-w-xl lg:max-w-3xl">
          <div className="flex justify-center">
            {/* <NinjaVaultBlackIcon className="h-20" /> */}
            <NinjaVaultIcon className="w-20 h-20 text-black dark:text-white" />
          </div>
          {/* <div className="text-3xl mb-6 text-center font-black bg-gradient-to-r from-sky-600 to-purple-600 dark:from-cyan-300 dark:to-cyan-300 text-transparent bg-clip-text">
            Say Hello to NinjaVault
          </div> */}
          <div className="flex flex-row items-center gap-x-2 justify-center my-3">
            <span className="text-black dark:text-white font-medium text-[0.85rem] sm:text-lg">
              EXCLUSIVELY ON
            </span>{" "}
            <InjectiveLogo className="text-black dark:text-white w-24 sm:w-32 h-auto inline" />
          </div>
          <div
            className={classNames(
              "text-center text-3xl lg:text-5xl font-bold leading-tight lg:leading-snug mb-8",
              textGradient
            )}
          >
            Portfolio Manager & Automated Yield Farming Protocol
          </div>
          <div className="flex justify-center">
            <button
              className="font-bold text-lg text-white bg-primary-700 dark:bg-white dark:text-black py-4 px-16 rounded-xl hover:scale-110 transition-scale duration-300"
              onClick={handleClick}
            >
              <WalletIcon className="w-5 h-5 inline-block mr-1 -mt-1" />
              Connect your wallet
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative text-primary-900 h-content">
      <div className="sm:flex justify-center">
        <div className="mt-10 sm:mx-auto">
          <div className="max-w-xl mx-auto text-center">
            <div>
              {isBalanceLoading ||
              loading ||
              isFarmsLoading ||
              isPoolsLoading ||
              isVaultsLoading ||
              isMoneyMarketLoading ? (
                <div className="flex justify-center">
                  <Skeleton
                    bg="bg-primary-300 dark:bg-white/30"
                    height="h-[68px] rounded-xl"
                    width="w-40"
                  />
                </div>
              ) : (
                <>
                  <div>
                    <CountUp value={netWorth} decimals={2} prefix={"$"} />
                  </div>

                  <div className={classNames("text-lg", textGradient)}>
                    Net Worth
                  </div>
                </>
              )}
              <div
                className={classNames(
                  "text-lg mt-6 font-semibold flex justify-center",
                  textGradient
                )}
              >
                <div className="w-full">
                  <input
                    spellCheck="false"
                    placeholder="Simulate INJ Address"
                    id="mockAddress"
                    name="mockAddress"
                    type="text"
                    value={mockAddress}
                    onClick={() => {
                      if (mockAddress === actualWallet?.address) {
                        setMockAddress("");
                      }
                    }}
                    onChange={(e) => {
                      const value = e?.target?.value;
                      setMockAddress(value);
                      if (isValidInjectiveAddress(value)) {
                        setMockWallet(value);
                      }
                    }}
                    disabled={
                      isBalanceLoading ||
                      loading ||
                      isFarmsLoading ||
                      isPoolsLoading ||
                      isVaultsLoading ||
                      isMoneyMarketLoading ||
                      mockWallet !== ""
                    }
                    className={classNames(
                      "w-[340px] sm:w-[420px] text-center text-sm sm:text-base text-primary-500 dark:text-white rounded-lg py-1 placeholder-primary-300 dark:placeholder-gray-400 focus:outline-none",
                      !isValidInjectiveAddress(mockAddress)
                        ? "bg-pink-200/20"
                        : "bg-primary-300/20"
                    )}
                  />

                  {(mockWallet !== "" ||
                    !isValidInjectiveAddress(mockAddress)) && (
                    <button
                      onClick={() => {
                        setMockWallet("");
                        setMockAddress(actualWallet?.address);
                      }}
                    >
                      <XMarkIcon className="ml-1 w-5 h-5 text-primary-500 inline-block hover:text-primary-700" />
                    </button>
                  )}
                </div>
              </div>
              {mockWallet === "" ? (
                !isValidInjectiveAddress(mockAddress) ? (
                  <div className="mt-1 mb-6 text-red-400 font-bold text-sm">
                    {mockAddress?.length === 0
                      ? " "
                      : "Paste a valid INJ address"}
                  </div>
                ) : (
                  <div className="mt-1 mb-6">
                    <button
                      disabled={
                        isBalanceLoading ||
                        loading ||
                        isFarmsLoading ||
                        isPoolsLoading ||
                        isVaultsLoading ||
                        isMoneyMarketLoading
                      }
                      onClick={async () => {
                        await refetchAll();
                      }}
                      className="hover:opacity-50 mr-1 hover:animate-spin disabled:opacity-20 disabled:animate-spin"
                    >
                      <ArrowPathIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                    </button>
                    <button
                      onClick={handleCopy}
                      className="cursor-pointer hover:opacity-50"
                    >
                      <textarea
                        ref={inputRef}
                        value={actualWallet.address}
                        readOnly
                        className="border-0 p-0 bg-transparent resize-none w-0 h-0 absolute -z-50"
                      />
                      {isCopied ? (
                        <CheckCircleIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                      ) : (
                        <DocumentDuplicateIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                      )}
                    </button>
                    <button
                      className="ml-1 cursor-default"
                      data-tooltip-id={`simulate`}
                      data-tooltip-html={`💡 Simulate other portfolios<br/>by clicking on your wallet<br/>address above`}
                    >
                      <UserOutlineIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                    </button>
                    <Tooltip
                      id={`simulate`}
                      place="bottom"
                      style={{
                        borderRadius: "12px",
                        fontSize: "12px",
                        textAlign: "center",
                        padding: "8px",
                        lineHeight: "20px",
                      }}
                    />
                    <button
                      onClick={handleClick}
                      className="hover:opacity-50 ml-1"
                    >
                      <EllipsisHorizontalIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                    </button>
                  </div>
                )
              ) : (
                <div className="mt-1 mb-6 flex justify-center">
                  <div className="mt-1">
                    <button
                      disabled={
                        isBalanceLoading ||
                        loading ||
                        isFarmsLoading ||
                        isPoolsLoading ||
                        isVaultsLoading ||
                        isMoneyMarketLoading
                      }
                      onClick={async () => {
                        await refetchAll();
                      }}
                      className="hover:opacity-50 mr-1 hover:animate-spin disabled:opacity-20 disabled:animate-spin"
                    >
                      <ArrowPathIcon className="w-5 h-5 text-primary-500 dark:text-white" />
                    </button>
                  </div>
                  <div className="font-bold dark:text-white">
                    <UserIcon className="w-4 h-4 text-primary-500 dark:text-white inline-block mr-1" />
                    Simulated Wallet
                  </div>
                </div>
              )}
            </div>
          </div>

          {isBalanceLoading ||
          loading ||
          isFarmsLoading ||
          isPoolsLoading ||
          isVaultsLoading ||
          isMoneyMarketLoading ? (
            <div className="min-h-[46vh] flex justify-around items-center">
              <div className="flex space-x-2 justify-center items-center">
                <span className="sr-only">Loading...</span>
                <div className="h-6 w-6 bg-primary-300 dark:bg-white rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="h-6 w-6 bg-primary-300 dark:bg-white rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="h-6 w-6 bg-primary-300 dark:bg-white rounded-full animate-bounce"></div>
              </div>
            </div>
          ) : (
            <div className="rounded-xl bg-primary-100/60 dark:bg-white/10 shadow-lg shadow-primary-300 dark:shadow-white/10 flex justify-center items-center px-0 sm:px-4">
              {netWorth === 0 &&
              userFarmsAndPools?.length === 0 &&
              userVaults?.length === 0 &&
              userStaking?.length === 0 &&
              userMoneyMarket?.borrow?.length === 0 &&
              userMoneyMarket?.supply?.length === 0 ? (
                <div className="mx-2 my-6 text-xl font-medium text-center text-black dark:text-white">
                  Kickstart your Injective Journey <br />
                  by{" "}
                  <a
                    href={`https://hub.injective.network/bridge/`}
                    target="_blank"
                    rel="noreferrer"
                    className={classNames(
                      "cursor-pointer hover:brightness-75 px-1 font-bold",
                      textGradient
                    )}
                  >
                    bridging tokens
                  </a>{" "}
                  from another chain.
                </div>
              ) : (
                <div className="w-full">
                  {netWorth > 0 && (
                    <div className="p-2 sm:p-3 w-full">
                      <div className="mb-1 flex justify-between">
                        <div className="cursor-default text-black dark:text-white">
                          <WalletIcon className="w-5 h-5 inline-block -mt-1 mr-1" />
                          <span className="text-lg font-semibold">Wallet</span>
                        </div>
                        <div className="text-lg font-bold flex items-center pr-1  text-black dark:text-white">
                          $
                          {getFormattedValue(
                            userWalletTotalValue,
                            false,
                            false,
                            true
                          )}
                        </div>
                      </div>
                      <div className="font-medium mx-auto text-gray-950">
                        <table className="table-auto mx-auto w-full min-w-80">
                          <thead className="text-primary-100 text-sm">
                            <tr>
                              <th className="bg-primary-500/80 dark:bg- text-left px-2 py-1 rounded-l-lg">
                                Token
                              </th>
                              <th className="bg-primary-500/80 text-left px-2 sm:table-cell hidden">
                                Price
                              </th>
                              <th className="bg-primary-500/80 text-left">
                                Amount
                              </th>
                              <th className="bg-primary-500/80 text-right px-2 rounded-r-lg">
                                USD
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userWallets
                              ?.filter((item) =>
                                showWalletLowValue
                                  ? parseFloat(item.amountInUsd) > 1
                                  : parseFloat(item.amountInUsd) > 0
                              )
                              ?.map((token) => (
                                <tr
                                  key={token.symbol}
                                  className="px-2 py-1 mt-1  dark:border-white/10 tracking-tight text-sm sm:text-base"
                                >
                                  <td className="text-left p-2">
                                    {token?.["token0"] ? (
                                      <div className="mr-1 inline-block relative">
                                        <AssetIcon
                                          address={token?.token0?.address || ""}
                                          style={{
                                            zIndex: 888,
                                            bottom: 2,
                                            right: 0,
                                          }}
                                          marketingInfo={
                                            marketingTokens?.[
                                              token?.token0?.address
                                            ]
                                          }
                                        />
                                        <AssetIcon
                                          address={token?.token1?.address || ""}
                                          style={{
                                            position: "absolute",
                                            bottom: 2,
                                            right: 10,
                                          }}
                                          marketingInfo={
                                            marketingTokens?.[
                                              token?.token1?.address
                                            ]
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <AssetIcon
                                        className="mr-1 -mt-1"
                                        address={token.address}
                                        alt={token.address}
                                        style={{
                                          right: 6,
                                        }}
                                        marketingInfo={
                                          marketingTokens?.[token?.address]
                                        }
                                      />
                                    )}

                                    <span className="inline-block text-black dark:text-white">
                                      {token.symbol}
                                    </span>
                                  </td>
                                  <td className="px-2 text-black dark:text-white hidden sm:table-cell">
                                    $
                                    {getFormattedValue(
                                      token.price,
                                      false,
                                      false,
                                      true
                                    )}
                                  </td>
                                  <td className="text-black dark:text-white">
                                    {getFormattedValue(token.amount)}
                                  </td>
                                  <td className="text-right p-2 text-black dark:text-white">
                                    {parseFloat(token.amountInUsd) < 1
                                      ? "<$1"
                                      : `$${getFormattedValue(
                                          token.amountInUsd,
                                          false,
                                          parseFloat(token.amountInUsd) > 10000
                                        )}`}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {userWallets?.filter(
                          (item) => parseFloat(item.amountInUsd) <= 1
                        )?.length > 0 && (
                          <div className="flex justify-end mx-1">
                            <button
                              className="text-xs text-primary-500/80 dark:text-cyan-300"
                              onClick={() =>
                                setShowWalletLowValue(!showWalletLowValue)
                              }
                            >
                              {showWalletLowValue ? (
                                <ChevronDoubleDownIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              ) : (
                                <ChevronDoubleUpIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              )}{" "}
                              {showWalletLowValue ? "Show" : "Hide"} Low Value
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {userFarmsAndPools?.length > 0 && (
                    <div className="my-8 w-full">
                      <div className="mb-1 flex justify-between ml-4 mx-4">
                        <a
                          href="https://dojo.trading"
                          target="_blank"
                          rel="noreferrer"
                          className="cursor-pointer hover:scale-105 text-black dark:text-white flex items-center"
                        >
                          <DojoSwapIcon className="inline-block mr-2 h-[16px] w-[16px] text-black dark:text-white" />
                          <span className="text-lg font-semibold">
                            Dojoswap
                          </span>
                        </a>
                        <div className="text-lg font-bold flex items-center pr-1 text-black dark:text-white">
                          ${getFormattedValue(userFarmsAndPoolsTotalValue)}
                        </div>
                      </div>
                      <div className="font-medium mx-auto text-gray-950 px-2 sm:px-4 text-black dark:text-white">
                        <table className="table-auto mx-auto w-full min-w-80">
                          <thead className="text-primary-100 text-sm">
                            <tr>
                              <th className="bg-primary-500/80 text-left px-2 py-1 rounded-l-lg">
                                Pool
                              </th>
                              <th className="bg-primary-500/80 text-left px-2">
                                Balance
                              </th>
                              <th className="bg-primary-500/80 text-left">
                                Rewards
                              </th>
                              <th className="bg-primary-500/80 text-right px-2 rounded-r-lg">
                                USD
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userFarmsAndPools
                              ?.filter((item) =>
                                showFarmLowValue
                                  ? parseFloat(item.totalInUsd) > 1
                                  : parseFloat(item.totalInUsd) > 0
                              )
                              ?.map((farm) => (
                                <tr
                                  key={`${farm.name}-${
                                    farm.isV1 ? "v1" : "v2"
                                  }`}
                                  className="px-2 py-1 mt-1  tracking-tight text-sm sm:text-base"
                                >
                                  <td className="text-left p-2">
                                    {farm?.isLp ? (
                                      <div className="mr-1 relative hidden sm:inline-block">
                                        <AssetIcon
                                          address={
                                            farm?.["token0"]?.address || ""
                                          }
                                          style={{
                                            zIndex: 888,
                                            bottom: 2,
                                            right: 0,
                                          }}
                                          marketingInfo={
                                            marketingTokens?.[
                                              farm?.["token0"]?.address
                                            ]
                                          }
                                        />
                                        <AssetIcon
                                          address={
                                            farm?.["token1"]?.address || ""
                                          }
                                          style={{
                                            position: "absolute",
                                            bottom: 2,
                                            right: 10,
                                          }}
                                          marketingInfo={
                                            marketingTokens?.[
                                              farm?.["token1"]?.address
                                            ]
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div className="hidden sm:inline-block">
                                        <AssetIcon
                                          className="mr-1 -mt-1"
                                          address={farm?.["poolToken"]?.address}
                                          alt={farm?.["poolToken"]?.address}
                                          style={{
                                            right: 6,
                                          }}
                                          marketingInfo={
                                            marketingTokens?.[
                                              farm?.["poolToken"]?.address
                                            ]
                                          }
                                        />
                                      </div>
                                    )}

                                    <span className="inline-block">
                                      {farm.name}
                                    </span>
                                  </td>
                                  <td className="px-2">
                                    {getFormattedValue(farm.balance)}
                                  </td>
                                  <td>
                                    {getFormattedValue(farm.pending_reward)}{" "}
                                    <span className="mr-1">
                                      {farm.isV1
                                        ? "POINT"
                                        : farm.isLp
                                        ? "DOJO"
                                        : farm?.["earningToken"]?.symbol}
                                    </span>
                                    ($
                                    {getFormattedValue(farm.rewardsInUsd)})
                                  </td>
                                  <td className="text-right p-2">
                                    $
                                    {getFormattedValue(
                                      farm.totalInUsd,
                                      false,
                                      parseFloat(farm.totalInUsd) > 10000
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {userFarmsAndPools?.filter(
                          (item) => parseFloat(item.totalInUsd) <= 1
                        )?.length > 0 && (
                          <div className="flex justify-end mx-1">
                            <button
                              className="text-xs text-primary-500/80 dark:text-cyan-300"
                              onClick={() =>
                                setShowFarmLowValue(!showFarmLowValue)
                              }
                            >
                              {showFarmLowValue ? (
                                <ChevronDoubleDownIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              ) : (
                                <ChevronDoubleUpIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              )}{" "}
                              {showFarmLowValue ? "Show" : "Hide"} Low Value
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {userVaults?.length > 0 && (
                    <div className="my-8 w-full">
                      <div className="mb-1 flex justify-between ml-4 mx-4">
                        <div className="text-black dark:text-white flex items-center">
                          <NinjaVaultIcon className="h-[16px] w-[16px] mr-2" />
                          <span className="text-lg font-semibold">
                            NinjaVault
                          </span>
                        </div>
                        <div className="text-lg font-bold flex items-center pr-1 text-black dark:text-white">
                          ${getFormattedValue(userVaultsTotalValue)}
                        </div>
                      </div>
                      <div className="font-medium mx-auto text-gray-950 px-2 sm:px-4 text-black dark:text-white">
                        <table className="table-auto mx-auto w-full min-w-80">
                          <thead className="text-primary-100 text-sm">
                            <tr>
                              <th className="bg-primary-500/80 text-left px-2 py-1 rounded-l-lg">
                                Vault
                              </th>
                              <th className="bg-primary-500/80 text-left px-2">
                                Balance
                              </th>
                              <th className="bg-primary-500/80 text-left">
                                Rewards
                              </th>
                              <th className="bg-primary-500/80 text-right px-2 rounded-r-lg">
                                USD
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userVaults
                              ?.filter((item) =>
                                showVaultLowValue
                                  ? parseFloat(item.totalInUsd) > 1
                                  : parseFloat(item.totalInUsd) > 0
                              )
                              ?.map((vault) => (
                                <tr
                                  key={vault.name}
                                  className="px-2 py-1 mt-1  tracking-tight text-sm sm:text-base"
                                >
                                  <td className="text-left p-2">
                                    {vault?.lpTokens ? (
                                      <div className="mr-1 relative hidden sm:inline-block">
                                        <AssetIcon
                                          address={
                                            vault?.lpTokens?.[0]?.address || ""
                                          }
                                          style={{
                                            zIndex: 888,
                                            bottom: 2,
                                            right: 0,
                                          }}
                                          marketingInfo={
                                            marketingTokens?.[
                                              vault?.lpTokens?.[0]?.address
                                            ]
                                          }
                                        />
                                        <AssetIcon
                                          address={
                                            vault?.lpTokens?.[1]?.address || ""
                                          }
                                          style={{
                                            position: "absolute",
                                            bottom: 2,
                                            right: 10,
                                          }}
                                          marketingInfo={
                                            marketingTokens?.[
                                              vault?.lpTokens?.[1]?.address
                                            ]
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div className="hidden sm:inline-block">
                                        <AssetIcon
                                          className="mr-1 -mt-1"
                                          address={vault?.stakingToken.address}
                                          alt={vault?.stakingToken.address}
                                          style={{
                                            right: 6,
                                          }}
                                          marketingInfo={
                                            marketingTokens?.[
                                              vault?.stakingToken.address
                                            ]
                                          }
                                        />
                                      </div>
                                    )}

                                    <span className="inline-block">
                                      {vault.stakingToken.symbol}
                                    </span>
                                  </td>
                                  <td className="px-2">
                                    {getFormattedValue(vault.balance)}
                                  </td>
                                  <td>
                                    {getFormattedValue(vault.earningBalance)}{" "}
                                    {vault.earningToken.symbol} ($
                                    {getFormattedValue(vault.earningBalanceUsd)}
                                    )
                                  </td>
                                  <td className="text-right p-2">
                                    $
                                    {getFormattedValue(
                                      vault.totalInUsd,
                                      false,
                                      parseFloat(vault.totalInUsd) > 10000
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {userVaults?.filter(
                          (item) => parseFloat(item.totalInUsd) <= 1
                        )?.length > 0 && (
                          <div className="flex justify-end mx-1">
                            <button
                              className="text-xs text-primary-500/80 dark:text-cyan-300"
                              onClick={() =>
                                setShowVaultLowValue(!showVaultLowValue)
                              }
                            >
                              {showVaultLowValue ? (
                                <ChevronDoubleDownIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              ) : (
                                <ChevronDoubleUpIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              )}{" "}
                              {showVaultLowValue ? "Show" : "Hide"} Low Value
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {userStaking?.length > 0 && (
                    <div className="my-8 w-full">
                      <div className="mb-1 flex justify-between ml-4 mx-4 text-black dark:text-white">
                        <div
                          className="cursor-pointer hover:scale-105 flex items-center"
                          onClick={() =>
                            window.open(
                              "https://hub.injective.network/staking/"
                            )
                          }
                        >
                          <Staking className="h-[18px] w-[18px] mr-2" />
                          <span className="text-lg font-semibold">Staking</span>
                        </div>
                        <div className="text-lg font-bold flex items-center pr-1">
                          ${getFormattedValue(userStakingTotalValue)}
                        </div>
                      </div>
                      <div className="font-medium mx-auto text-gray-950 px-2 sm:px-4">
                        <table className="table-auto mx-auto w-full min-w-80">
                          <thead className="text-primary-100 text-sm">
                            <tr>
                              <th className="bg-primary-500/80 text-left px-2 py-1 rounded-l-lg">
                                Validator
                              </th>
                              <th className="bg-primary-500/80 text-left px-2">
                                Balance
                              </th>
                              {/* <th className="bg-primary-500/80 text-left">
                            Rewards
                          </th> */}
                              <th className="bg-primary-500/80 text-right px-2 rounded-r-lg">
                                USD
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {userStaking
                              ?.filter((item) =>
                                showStakingLowValue
                                  ? parseFloat(item.balanceUsd) > 1
                                  : parseFloat(item.balanceUsd) > 0
                              )
                              ?.map((staking) => (
                                <tr
                                  key={staking.name}
                                  className="px-2 py-1 mt-1 tracking-tight text-sm sm:text-base text-black dark:text-white"
                                >
                                  <td className="text-left p-2">
                                    <img
                                      src={staking.image}
                                      className="rounded-full w-7 h-7 -ml-2 mr-2 inline-block border -mt-0.5"
                                    />
                                    {staking.name}
                                  </td>
                                  <td className="px-2">
                                    {getFormattedValue(staking.balance)} INJ
                                  </td>
                                  {/* <td>
                                {getFormattedValue(vault.earningBalance)}{" "}
                                {vault.earningToken.symbol} ($
                                {getFormattedValue(vault.earningBalanceUsd)})
                              </td> */}
                                  <td className="text-right p-2">
                                    $
                                    {getFormattedValue(
                                      staking.balanceUsd,
                                      false,
                                      parseFloat(staking.balanceUsd) > 10000
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {userStaking?.filter(
                          (item) => parseFloat(item.balanceUsd) <= 1
                        )?.length > 0 && (
                          <div className="flex justify-end mx-1">
                            <button
                              className="text-xs text-primary-500/80 dark:text-cyan-300"
                              onClick={() =>
                                setShowStakingLowValue(!showStakingLowValue)
                              }
                            >
                              {showStakingLowValue ? (
                                <ChevronDoubleDownIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              ) : (
                                <ChevronDoubleUpIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              )}{" "}
                              {showStakingLowValue ? "Show" : "Hide"} Low Value
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {(userMoneyMarket?.supply?.length > 0 ||
                    userMoneyMarket?.borrow?.length > 0) && (
                    <div className="my-8 w-full">
                      <div className="mb-1 flex justify-between ml-4 mx-4 text-black dark:text-white">
                        <div
                          className="cursor-pointer hover:scale-105 flex items-center"
                          onClick={() =>
                            window.open("https://market.injera.io/")
                          }
                        >
                          <Money className="h-[18px] w-[18px] mr-2" />
                          <span className="text-lg font-semibold">
                            Money Market
                          </span>
                        </div>
                        <div className="text-lg font-bold flex items-center pr-1">
                          ${getFormattedValue(userMoneyMarketTotalValue)}
                        </div>
                      </div>
                      <div className="font-medium mx-auto text-gray-950 px-2 sm:px-4">
                        <table className="table-auto mx-auto w-full min-w-80">
                          <thead className="text-primary-100 text-sm">
                            <tr>
                              <th className="bg-primary-500/80 dark:bg- text-left px-2 py-1 rounded-l-lg">
                                Token
                              </th>
                              <th className="bg-primary-500/80 text-left px-2 sm:table-cell hidden">
                                Price
                              </th>
                              <th className="bg-primary-500/80 text-left">
                                Amount
                              </th>
                              <th className="bg-primary-500/80 text-right px-2 rounded-r-lg">
                                USD
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {[
                              ...userMoneyMarket?.supply,
                              ...userMoneyMarket?.borrow,
                            ]
                              ?.filter((item) =>
                                showMoneyMarketLowVaue
                                  ? parseFloat(item.amountInUsd) > 50 ||
                                    parseFloat(item.amountInUsd) < 0
                                  : parseFloat(item.amountInUsd) > 0 ||
                                    parseFloat(item.amountInUsd) < 0
                              )
                              ?.map((item) => (
                                <tr
                                  key={item.symbol}
                                  className="px-2 py-1 mt-1  dark:border-white/10 tracking-tight text-sm sm:text-base"
                                >
                                  <td className="text-left p-2">
                                    {
                                      <AssetIcon
                                        className="mr-1 -mt-1"
                                        address={item.address}
                                        alt={item.address}
                                        style={{
                                          right: 6,
                                        }}
                                        marketingInfo={
                                          marketingTokens?.[item?.address]
                                        }
                                      />
                                    }

                                    <span className="inline-block text-black dark:text-white">
                                      {item.name}
                                    </span>
                                  </td>
                                  <td className="px-2 text-black dark:text-white hidden sm:table-cell">
                                    $
                                    {getFormattedValue(
                                      item.price,
                                      false,
                                      false,
                                      true
                                    )}
                                  </td>
                                  <td className="text-black dark:text-white">
                                    {getFormattedValue(item.amount, true)}
                                  </td>
                                  <td className="text-right p-2 text-black dark:text-white">
                                    {parseFloat(item.amountInUsd) < 1 &&
                                    parseFloat(item.amountInUsd) > 0
                                      ? "<$1"
                                      : `$${getFormattedValue(
                                          item.amountInUsd,
                                          true,
                                          parseFloat(item.amountInUsd) > 10000
                                        )}`}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {[
                          ...userMoneyMarket?.supply,
                          ...userMoneyMarket?.borrow,
                        ]?.filter(
                          (item) =>
                            parseFloat(item.amountInUsd) <= 50 &&
                            parseFloat(item.amountInUsd) > 0
                        )?.length > 0 && (
                          <div className="flex justify-end mx-1">
                            <button
                              className="text-xs text-primary-500/80 dark:text-cyan-300"
                              onClick={() =>
                                setShowMoneyMarketLowVaue(
                                  !showMoneyMarketLowVaue
                                )
                              }
                            >
                              {showMoneyMarketLowVaue ? (
                                <ChevronDoubleDownIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              ) : (
                                <ChevronDoubleUpIcon className="w-3 h-3 -mt-0.5 inline-block" />
                              )}{" "}
                              {showMoneyMarketLowVaue ? "Show" : "Hide"} Low
                              Value
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Portfolio);
