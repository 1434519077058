import { FC, useState } from "react";
import ReactModal from "react-modal";
import { XMarkIcon } from "@heroicons/react/24/solid";

ReactModal.setAppElement("#root");

export interface IModal {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  setInfo: (url: string, name: string) => void;
  url: string;
  name: string;
  getConfirmation: any;
  confirm: () => void;
}

const Modal: FC<Modal> = ({
  className,
  overlayClassName,
  isOpen,
  close,
  children,
  isCloseBtn = false,
  ...props
}) => {
  return (
    <ReactModal
      className={`w-screen max-w-[460px] relative mx-auto my-0 outline-0 ${
        className || ""
      }`}
      overlayClassName={`bg-[rgba(0,0,0,.7)] fixed right-0 left-0 top-0 bottom-0 justify-center items-center z-[6000] inset-0 flex ${
        overlayClassName || ""
      }`}
      isOpen={isOpen}
      onRequestClose={close}
      {...props}>
      {isCloseBtn && (
        <div
          className={
            "absolute text-right z-[1] right-8 sm:right-5 top-5"
          }>
          <span onClick={close} className="cursor-pointer">
            <XMarkIcon className="w-5 h-5 text-black" />
          </span>
        </div>
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;

/* modal */
export const useModal = (): IModal => {
  const [isOpen, setIsOpen] = useState(false);

  const [url, setURL] = useState("");
  const [name, setName] = useState("");
  const [resolver, setResolver] = useState<any>();

  const createPromise = () => {
    let resolver;
    return [
      new Promise((resolve, _reject) => {
        resolver = resolve;
      }),
      resolver,
    ];
  };

  const getConfirmation = async () => {
    const [promise, resolve] = await createPromise();
    setResolver({ resolve });
    return promise;
  };

  const resolving = async (status) => {
    if (resolver) {
      resolver.resolve(status);
    }
  };

  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => {
      setIsOpen(false);
      resolving(false);
    },
    confirm: () => {
      setIsOpen(false);
      resolving(true);
    },
    setInfo: (url: string, name: string) => {
      setURL(url);
      setName(name);
    },
    url,
    name,
    getConfirmation,
  };
};
